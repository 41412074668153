// LICENSE_CODE TLM
/* eslint-disable quote-props,quotes,max-len */
let lbin = {
  "file": "editor_tester_20240905__v2__TOOLIUM_ORG.aaf",
  "etag": "19cd4c74",
  "lbin": {
    "type": "editor",
    "ver_avid": "22.7",
    "operations": [
      {
        "name": "ColorAdapter",
        "count": 1
      },
      {
        "name": "SubCap",
        "count": 1
      },
      {
        "name": "SBlend_v2",
        "count": 1
      },
      {
        "name": "RGBColorCorrect_2",
        "count": 1
      },
      {
        "name": "Paint",
        "count": 1
      },
      {
        "name": "Motion Control",
        "count": 1
      },
      {
        "name": "Timecode Burn-In",
        "count": 1
      }
    ],
    "rec_monitor_in": {
      "type": "sequence",
      "mob_id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.f25b092e.0558a406.c6f3de73.3b8ab5b8",
      "start": 0,
      "start_tc": 90000,
      "resolution": {
        "w": 1920,
        "h": 1080
      },
      "lbl": "editor_tester_20240905.Exported.01",
      "len": 2102,
      "mark_in": null,
      "mark_out": null,
      "editrate": {
        "n": 25,
        "d": 1
      },
      "curser_pos": 1260,
      "tracks": [
        {
          "type": "timeline_track",
          "height": "medium",
          "depth": 0,
          "lbl": "V4",
          "id": "V4",
          "start": 0,
          "len": 2102,
          "abs_start": 0,
          "editrate": {
            "n": 25,
            "d": 1
          },
          "is_power": true,
          "is_synclock": false,
          "is_lock": false,
          "is_edit": true,
          "ctrl": {
            "has_monitor": true,
            "has_power": true,
            "has_synclock": true,
            "has_lock": true,
            "has_mute": false,
            "has_solo": false,
            "has_waveform": false
          },
          "arr": [
            {
              "type": "segment",
              "is_hide": true,
              "start": 0,
              "len": 2102,
              "arr": [
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "operation",
                  "start": 0,
                  "len": 2102,
                  "color": "#788069",
                  "lbl": "Filler",
                  "is_icon_gray": false,
                  "is_icon_purple": true,
                  "is_icon_motion": false,
                  "icon": {
                    "is_icon_inp": false,
                    "is_icon_out": true,
                    "icon_inp": [],
                    "icon_out": "general"
                  },
                  "is_on_double_click_track": true,
                  "pars": [],
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 2102,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 0,
                          "depth": 4,
                          "type": "filler",
                          "len": 2102,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 0
                        }
                      ],
                      "depth": 3,
                      "abs_start": 0,
                      "track_lbl": "V4.1"
                    }
                  ],
                  "depth": 2,
                  "abs_start": 0,
                  "trim": {
                    "start_pre": 0,
                    "start_post": 0,
                    "end_pre": 2102,
                    "end_post": 9223372036854776000
                  }
                }
              ],
              "depth": 1,
              "abs_start": 0
            }
          ],
          "power": {
            "color": "#4b88ba",
            "v": true
          },
          "is_monitor_selected": true
        },
        {
          "type": "timeline_track",
          "height": "medium",
          "depth": 0,
          "lbl": "V3",
          "id": "V3",
          "start": 0,
          "len": 2102,
          "abs_start": 0,
          "editrate": {
            "n": 25,
            "d": 1
          },
          "is_power": true,
          "is_synclock": false,
          "is_lock": false,
          "is_edit": true,
          "ctrl": {
            "has_monitor": true,
            "has_power": true,
            "has_synclock": true,
            "has_lock": true,
            "has_mute": false,
            "has_solo": false,
            "has_waveform": false
          },
          "arr": [
            {
              "type": "segment",
              "is_hide": true,
              "start": 0,
              "len": 2102,
              "arr": [
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 0,
                  "depth": 2,
                  "type": "filler",
                  "len": 143,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 0,
                  "trim": {
                    "start_pre": 0,
                    "start_post": 0,
                    "end_pre": 143,
                    "end_post": 143
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "operation_motion",
                  "start": 143,
                  "len": 123,
                  "color": "#788069",
                  "lbl": "Volcano_Flight",
                  "is_icon_gray": false,
                  "is_icon_purple": false,
                  "is_icon_motion": false,
                  "icon": {
                    "is_icon_inp": false,
                    "is_icon_out": true,
                    "icon_inp": [],
                    "icon_out": "timewarp"
                  },
                  "is_on_double_click_track": false,
                  "pars": [],
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 489,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 185,
                          "len": 489,
                          "color": "#788069",
                          "lbl": "Volcano_Flight",
                          "id": "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.11cb6607.92e1db2f.2fc1b4a1.13c2b993",
                          "track_lbl": "V1",
                          "start_frame_abs": 185,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Volcano_Flight",
                              "tc": "00:00:00:00",
                              "d": 7218,
                              "tr": "V1",
                              "is_video": true,
                              "is_audio": false,
                              "is_audio_only": false,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 143
                        }
                      ],
                      "depth": 3,
                      "abs_start": 143,
                      "track_lbl": "V3.1"
                    }
                  ],
                  "depth": 2,
                  "abs_start": 143,
                  "playrate": 3.98,
                  "trim": {
                    "start_pre": 143,
                    "start_post": 143,
                    "end_pre": 123,
                    "end_post": 1462
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 266,
                  "depth": 2,
                  "type": "filler",
                  "len": 1462,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 266,
                  "trim": {
                    "start_pre": 123,
                    "start_post": 1462,
                    "end_pre": 1462,
                    "end_post": 1462
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "depth": 2,
                  "type": "source_clip",
                  "start": 1728,
                  "start_tc": 6844,
                  "len": 374,
                  "color": "#848484",
                  "lbl": "Volcano_Flight",
                  "id": "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.11cb6607.92e1db2f.2fc1b4a1.13c2b993",
                  "track_lbl": "V1",
                  "start_frame_abs": 6844,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "src": {
                    "type": "aaf_info",
                    "v": {
                      "n": "Volcano_Flight",
                      "tc": "00:00:00:00",
                      "d": 7218,
                      "tr": "V1",
                      "is_video": true,
                      "is_audio": false,
                      "is_audio_only": false,
                      "editrate": {
                        "n": 25,
                        "d": 1
                      }
                    }
                  },
                  "abs_start": 1728,
                  "is_lbl_italic": true,
                  "rndr": {
                    "is_mute": true,
                    "is_pass": true,
                    "is_color": true
                  },
                  "trim": {
                    "start_pre": 1462,
                    "start_post": 1462,
                    "end_pre": 374,
                    "end_post": 0
                  }
                },
                {
                  "type": "transition",
                  "zidx": 50,
                  "opacity": 0.5,
                  "is_no_arr": true,
                  "start": 2069,
                  "len": 33,
                  "is_icon_purple": true,
                  "icon": {
                    "is_icon_out": true,
                    "icon_out": "transition"
                  },
                  "depth": 2,
                  "abs_start": 2069
                }
              ],
              "depth": 1,
              "abs_start": 0
            }
          ],
          "power": {
            "color": "#4b88ba",
            "v": true
          }
        },
        {
          "type": "timeline_track",
          "height": "medium",
          "depth": 0,
          "lbl": "V2",
          "id": "V2",
          "start": 0,
          "len": 2102,
          "abs_start": 0,
          "editrate": {
            "n": 25,
            "d": 1
          },
          "is_power": true,
          "is_synclock": false,
          "is_lock": false,
          "is_edit": true,
          "ctrl": {
            "has_monitor": true,
            "has_power": true,
            "has_synclock": true,
            "has_lock": true,
            "has_mute": false,
            "has_solo": false,
            "has_waveform": false
          },
          "arr": [
            {
              "type": "segment",
              "is_hide": true,
              "start": 0,
              "len": 2102,
              "arr": [
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 0,
                  "depth": 2,
                  "type": "filler",
                  "len": 266,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 0,
                  "trim": {
                    "start_pre": 0,
                    "start_post": 0,
                    "end_pre": 266,
                    "end_post": 112
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 266,
                  "depth": 2,
                  "type": "filler",
                  "len": 112,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 266,
                  "trim": {
                    "start_pre": 266,
                    "start_post": 112,
                    "end_pre": 112,
                    "end_post": 112
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "operation_subcap",
                  "start": 378,
                  "len": 103,
                  "color": "#788069",
                  "lbl": "Filler",
                  "is_icon_gray": false,
                  "is_icon_purple": true,
                  "is_icon_motion": false,
                  "icon": {
                    "is_icon_inp": false,
                    "is_icon_out": true,
                    "icon_inp": [],
                    "icon_out": "generator"
                  },
                  "is_on_double_click_track": true,
                  "pars": [
                    {
                      "lbl": "Justify Anchor",
                      "value": "center"
                    },
                    {
                      "lbl": "Font Size",
                      "value": 60
                    },
                    {
                      "lbl": "Font",
                      "value": "Arial"
                    },
                    {
                      "lbl": "X",
                      "value": {
                        "n": 0,
                        "d": 1
                      }
                    },
                    {
                      "lbl": "Caption",
                      "value": "subtitles for avid media composer"
                    },
                    {
                      "lbl": "Y",
                      "value": {
                        "n": 11011011,
                        "d": 25000000
                      }
                    }
                  ],
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 103,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 0,
                          "depth": 4,
                          "type": "filler",
                          "len": 103,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 378
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378,
                      "track_lbl": "V2.1"
                    }
                  ],
                  "depth": 2,
                  "abs_start": 378,
                  "trim": {
                    "start_pre": 112,
                    "start_post": 112,
                    "end_pre": 103,
                    "end_post": 103
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 481,
                  "depth": 2,
                  "type": "filler",
                  "len": 267,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 481,
                  "trim": {
                    "start_pre": 103,
                    "start_post": 103,
                    "end_pre": 216,
                    "end_post": 153
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "depth": 2,
                  "type": "source_clip",
                  "start": 748,
                  "start_tc": 216,
                  "len": 153,
                  "color": "#788069",
                  "lbl": "Water_Fall_CU",
                  "id": "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.24a4f7f7.297e09a6.9428ee13.4eec1461",
                  "track_lbl": "V1",
                  "start_frame_abs": 216,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "src": {
                    "type": "aaf_info",
                    "v": {
                      "n": "Water_Fall_CU",
                      "tc": "00:00:00:00",
                      "d": 454,
                      "tr": "V1",
                      "is_video": true,
                      "is_audio": false,
                      "is_audio_only": false,
                      "editrate": {
                        "n": 25,
                        "d": 1
                      }
                    }
                  },
                  "abs_start": 748,
                  "trim": {
                    "start_pre": 216,
                    "start_post": 153,
                    "end_pre": 153,
                    "end_post": 85
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 901,
                  "depth": 2,
                  "type": "filler",
                  "len": 249,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 901,
                  "trim": {
                    "start_pre": 153,
                    "start_post": 85,
                    "end_pre": 249,
                    "end_post": 240
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "operation",
                  "start": 1150,
                  "len": 240,
                  "color": "#788069",
                  "lbl": "Volcano_Flight",
                  "is_icon_gray": false,
                  "is_icon_purple": true,
                  "is_icon_motion": false,
                  "icon": {
                    "is_icon_inp": false,
                    "is_icon_out": true,
                    "icon_inp": [],
                    "icon_out": "general"
                  },
                  "is_on_double_click_track": true,
                  "pars": [],
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 240,
                      "arr": [
                        {
                          "is_no_arr": false,
                          "zidx": 10,
                          "type": "operation",
                          "start": 0,
                          "len": 240,
                          "color": "#788069",
                          "lbl": "Volcano_Flight",
                          "is_icon_gray": false,
                          "is_icon_purple": true,
                          "is_icon_motion": false,
                          "icon": {
                            "is_icon_inp": false,
                            "is_icon_out": true,
                            "icon_inp": [],
                            "icon_out": "general"
                          },
                          "is_on_double_click_track": true,
                          "pars": [],
                          "arr": [
                            {
                              "type": "segment",
                              "is_hide": true,
                              "start": 0,
                              "len": 240,
                              "arr": [
                                {
                                  "is_no_arr": true,
                                  "zidx": 10,
                                  "start": 0,
                                  "depth": 6,
                                  "type": "filler",
                                  "len": 240,
                                  "rndr": {
                                    "is_pass": true
                                  },
                                  "abs_start": 1150
                                }
                              ],
                              "depth": 5,
                              "abs_start": 1150,
                              "track_lbl": "V2.1.1"
                            },
                            {
                              "type": "segment",
                              "is_hide": true,
                              "start": 0,
                              "len": 240,
                              "arr": [
                                {
                                  "is_no_arr": false,
                                  "zidx": 10,
                                  "type": "operation",
                                  "start": 0,
                                  "len": 240,
                                  "color": "#788069",
                                  "lbl": "Volcano_Flight",
                                  "is_icon_gray": false,
                                  "is_icon_purple": true,
                                  "is_icon_motion": false,
                                  "icon": {
                                    "is_icon_inp": false,
                                    "is_icon_out": true,
                                    "icon_inp": [],
                                    "icon_out": "general"
                                  },
                                  "is_on_double_click_track": true,
                                  "pars": [],
                                  "arr": [
                                    {
                                      "type": "segment",
                                      "is_hide": true,
                                      "start": 0,
                                      "len": 240,
                                      "arr": [
                                        {
                                          "is_no_arr": true,
                                          "zidx": 10,
                                          "depth": 8,
                                          "type": "source_clip",
                                          "start": 0,
                                          "start_tc": 5717,
                                          "len": 240,
                                          "color": "#788069",
                                          "lbl": "Volcano_Flight",
                                          "id": "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.11cb6607.92e1db2f.2fc1b4a1.13c2b993",
                                          "track_lbl": "V1",
                                          "start_frame_abs": 5717,
                                          "editrate": {
                                            "n": 25,
                                            "d": 1
                                          },
                                          "src": {
                                            "type": "aaf_info",
                                            "v": {
                                              "n": "Volcano_Flight",
                                              "tc": "00:00:00:00",
                                              "d": 7218,
                                              "tr": "V1",
                                              "is_video": true,
                                              "is_audio": false,
                                              "is_audio_only": false,
                                              "editrate": {
                                                "n": 25,
                                                "d": 1
                                              }
                                            }
                                          },
                                          "abs_start": 1150
                                        }
                                      ],
                                      "depth": 7,
                                      "abs_start": 1150,
                                      "track_lbl": "V2.1.2.1"
                                    },
                                    {
                                      "type": "segment",
                                      "is_hide": true,
                                      "start": 0,
                                      "len": 240,
                                      "arr": [
                                        {
                                          "is_no_arr": true,
                                          "zidx": 10,
                                          "start": 0,
                                          "depth": 8,
                                          "type": "filler",
                                          "len": 240,
                                          "rndr": {
                                            "is_pass": true
                                          },
                                          "abs_start": 1150
                                        }
                                      ],
                                      "depth": 7,
                                      "abs_start": 1150,
                                      "track_lbl": "V2.1.2.2"
                                    }
                                  ],
                                  "depth": 6,
                                  "abs_start": 1150
                                }
                              ],
                              "depth": 5,
                              "abs_start": 1150,
                              "track_lbl": "V2.1.2"
                            }
                          ],
                          "depth": 4,
                          "abs_start": 1150
                        }
                      ],
                      "depth": 3,
                      "abs_start": 1150,
                      "track_lbl": "V2.1"
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 240,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 0,
                          "depth": 4,
                          "type": "filler",
                          "len": 240,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 1150
                        }
                      ],
                      "depth": 3,
                      "abs_start": 1150,
                      "track_lbl": "V2.2"
                    }
                  ],
                  "depth": 2,
                  "abs_start": 1150,
                  "trim": {
                    "start_pre": 249,
                    "start_post": 240,
                    "end_pre": 240,
                    "end_post": 240
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "depth": 2,
                  "type": "source_clip",
                  "start": 1390,
                  "start_tc": 3654,
                  "len": 181,
                  "color": "#788069",
                  "lbl": "Water_Fall_Drizzle",
                  "id": "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.770c0802.0fbaead1.5d4b0e92.4404eeca",
                  "track_lbl": "V1",
                  "start_frame_abs": 191,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "src": {
                    "type": "aaf_info",
                    "v": {
                      "n": "Water_Fall_Drizzle",
                      "tc": "00:02:18:13",
                      "d": 502,
                      "tr": "V1",
                      "is_video": true,
                      "is_audio": false,
                      "is_audio_only": false,
                      "editrate": {
                        "n": 25,
                        "d": 1
                      }
                    }
                  },
                  "abs_start": 1390,
                  "trim": {
                    "start_pre": 240,
                    "start_post": 240,
                    "end_pre": 181,
                    "end_post": 130
                  }
                },
                {
                  "type": "marker",
                  "zidx": 100,
                  "is_no_arr": true,
                  "start": 1510,
                  "len": 1,
                  "color": "#a12f19",
                  "color_rgb16": {
                    "red": 41471,
                    "green": 12134,
                    "blue": 6564
                  },
                  "comment": null,
                  "depth": 1,
                  "abs_start": 1510
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 1571,
                  "depth": 2,
                  "type": "filler",
                  "len": 531,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 1571,
                  "trim": {
                    "start_pre": 181,
                    "start_post": 130,
                    "end_pre": 531,
                    "end_post": 9223372036854776000
                  }
                }
              ],
              "depth": 1,
              "abs_start": 0
            }
          ],
          "power": {
            "color": "#4b88ba",
            "v": true
          }
        },
        {
          "type": "timeline_track",
          "height": "medium",
          "depth": 0,
          "lbl": "V1",
          "id": "V1",
          "start": 0,
          "len": 2102,
          "abs_start": 0,
          "editrate": {
            "n": 25,
            "d": 1
          },
          "is_power": true,
          "is_synclock": false,
          "is_lock": false,
          "is_edit": true,
          "ctrl": {
            "has_monitor": true,
            "has_power": true,
            "has_synclock": true,
            "has_lock": true,
            "has_mute": false,
            "has_solo": false,
            "has_waveform": false
          },
          "arr": [
            {
              "type": "segment",
              "is_hide": true,
              "start": 0,
              "len": 2102,
              "arr": [
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "operation",
                  "start": 0,
                  "len": 143,
                  "color": "#788069",
                  "lbl": "Toolium_Logo",
                  "is_icon_gray": true,
                  "is_icon_purple": false,
                  "is_icon_motion": false,
                  "icon": {
                    "is_icon_inp": true,
                    "is_icon_out": false,
                    "icon_inp": [
                      "C"
                    ],
                    "icon_out": null
                  },
                  "is_on_double_click_track": false,
                  "pars": [],
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 143,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 80,
                          "len": 143,
                          "color": "#788069",
                          "lbl": "Toolium_Logo",
                          "id": "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.ffdf5719.f4935fa7.0d67f6fb.3b468171",
                          "track_lbl": "V1",
                          "start_frame_abs": 80,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Toolium_Logo",
                              "tc": "00:00:00:00",
                              "d": 750,
                              "tr": "V1",
                              "is_video": true,
                              "is_audio": false,
                              "is_audio_only": false,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 0
                        }
                      ],
                      "depth": 3,
                      "abs_start": 0,
                      "track_lbl": "V1.1"
                    }
                  ],
                  "depth": 2,
                  "abs_start": 0,
                  "trim": {
                    "start_pre": 0,
                    "start_post": 0,
                    "end_pre": 80,
                    "end_post": 143
                  }
                },
                {
                  "type": "marker",
                  "zidx": 100,
                  "is_no_arr": true,
                  "start": 0,
                  "len": 1,
                  "color": "#3333cc",
                  "color_rgb16": {
                    "red": 13107,
                    "green": 13107,
                    "blue": 52428
                  },
                  "comment": null,
                  "depth": 1,
                  "abs_start": 0
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 143,
                  "depth": 2,
                  "type": "filler",
                  "len": 123,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 143,
                  "trim": {
                    "start_pre": 80,
                    "start_post": 143,
                    "end_pre": 123,
                    "end_post": 112
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "selector",
                  "start": 266,
                  "len": 112,
                  "color": "#788069",
                  "is_no_border": true,
                  "is_on_right_click_menu": true,
                  "select_idx": 1,
                  "abs_start": 266,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "lbl": "Clip0001.new.01 (G)",
                  "id": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60",
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "A141C001_200425B8_CANON.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1573844,
                          "len": 40,
                          "color": "#5846e5",
                          "lbl": "A141C001_200425B8_CANON.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104",
                          "track_lbl": "V1",
                          "start_frame_abs": 1856,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "A141C001_200425B8_CANON",
                              "tc": "17:27:59:13",
                              "d": 1896,
                              "tr": "V1",
                              "is_video": true,
                              "is_audio": false,
                              "is_audio_only": false,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 40,
                          "depth": 4,
                          "type": "filler",
                          "len": 72,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 306,
                          "color": "#788069"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "Clip0001.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1565550,
                          "len": 61,
                          "color": "#42d4f4",
                          "lbl": "Clip0001.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104",
                          "track_lbl": "V1",
                          "start_frame_abs": 2195,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Clip0001",
                              "tc": "17:22:14:05",
                              "d": 2256,
                              "tr": "V1",
                              "is_video": true,
                              "is_audio": false,
                              "is_audio_only": false,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 61,
                          "depth": 4,
                          "type": "filler",
                          "len": 51,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 327,
                          "color": "#788069"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "9S5A0786.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1984875,
                          "len": 112,
                          "color": "#65c553",
                          "lbl": "9S5A0786.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.19ad290a.9882a405.486454c4.62e0a104",
                          "track_lbl": "V1",
                          "start_frame_abs": 1583,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "9S5A0786",
                              "tc": "22:02:11:17",
                              "d": 1704,
                              "tr": "V1",
                              "is_video": true,
                              "is_audio": false,
                              "is_audio_only": false,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "GH014182.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1575469,
                          "len": 112,
                          "color": "#e4c600",
                          "lbl": "GH014182.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1a453721.9882a405.9b0354c4.62e0a104",
                          "track_lbl": "V1",
                          "start_frame_abs": 2397,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "GH014182",
                              "tc": "17:28:42:22",
                              "d": 2651,
                              "tr": "V1",
                              "is_video": true,
                              "is_audio": false,
                              "is_audio_only": false,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    }
                  ],
                  "depth": 2,
                  "trim": {
                    "start_pre": 123,
                    "start_post": 112,
                    "end_pre": 112,
                    "end_post": 112
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "selector",
                  "start": 378,
                  "len": 1407,
                  "color": "#788069",
                  "is_no_border": true,
                  "is_on_right_click_menu": true,
                  "select_idx": 0,
                  "abs_start": 378,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "lbl": "A141C001_200425B8_CANON.new.01 (G)",
                  "id": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60",
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "A141C001_200425B8_CANON.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1572391,
                          "len": 1407,
                          "color": "#5846e5",
                          "lbl": "A141C001_200425B8_CANON.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104",
                          "track_lbl": "V1",
                          "start_frame_abs": 403,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "A141C001_200425B8_CANON",
                              "tc": "17:27:59:13",
                              "d": 1896,
                              "tr": "V1",
                              "is_video": true,
                              "is_audio": false,
                              "is_audio_only": false,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "Clip0001.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1564097,
                          "len": 1407,
                          "color": "#42d4f4",
                          "lbl": "Clip0001.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104",
                          "track_lbl": "V1",
                          "start_frame_abs": 742,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Clip0001",
                              "tc": "17:22:14:05",
                              "d": 2256,
                              "tr": "V1",
                              "is_video": true,
                              "is_audio": false,
                              "is_audio_only": false,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "9S5A0786.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1983422,
                          "len": 1407,
                          "color": "#65c553",
                          "lbl": "9S5A0786.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.19ad290a.9882a405.486454c4.62e0a104",
                          "track_lbl": "V1",
                          "start_frame_abs": 130,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "9S5A0786",
                              "tc": "22:02:11:17",
                              "d": 1704,
                              "tr": "V1",
                              "is_video": true,
                              "is_audio": false,
                              "is_audio_only": false,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "GH014182.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574016,
                          "len": 1407,
                          "color": "#e4c600",
                          "lbl": "GH014182.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1a453721.9882a405.9b0354c4.62e0a104",
                          "track_lbl": "V1",
                          "start_frame_abs": 944,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "GH014182",
                              "tc": "17:28:42:22",
                              "d": 2651,
                              "tr": "V1",
                              "is_video": true,
                              "is_audio": false,
                              "is_audio_only": false,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    }
                  ],
                  "depth": 2,
                  "trim": {
                    "start_pre": 112,
                    "start_post": 112,
                    "end_pre": 1159,
                    "end_post": 300
                  }
                },
                {
                  "type": "marker",
                  "zidx": 100,
                  "is_no_arr": true,
                  "start": 513,
                  "len": 1,
                  "color": "#e6e619",
                  "color_rgb16": {
                    "red": 58981,
                    "green": 58981,
                    "blue": 6553
                  },
                  "comment": "here we have marker text in english that is very long and passes the end of the monitor. it should have 3 dots... at the end of the text on screen",
                  "depth": 1,
                  "abs_start": 513
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 1785,
                  "depth": 2,
                  "type": "filler",
                  "len": 317,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 1785,
                  "trim": {
                    "start_pre": 1159,
                    "start_post": 300,
                    "end_pre": 317,
                    "end_post": 9223372036854776000
                  }
                },
                {
                  "type": "marker",
                  "zidx": 100,
                  "is_no_arr": true,
                  "start": 1915,
                  "len": 1,
                  "color": "#33cc33",
                  "color_rgb16": {
                    "red": 13107,
                    "green": 52428,
                    "blue": 13107
                  },
                  "comment": null,
                  "depth": 1,
                  "abs_start": 1915
                }
              ],
              "depth": 1,
              "abs_start": 0
            }
          ],
          "power": {
            "color": "#4b88ba",
            "v": true
          }
        },
        {
          "type": "tc_track",
          "height": "small",
          "depth": 0,
          "lbl": "TC1",
          "id": "TC1",
          "start": 0,
          "len": 2102,
          "abs_start": 0,
          "editrate": {
            "n": 25,
            "d": 1
          },
          "is_power": true,
          "is_synclock": false,
          "is_lock": false,
          "is_edit": true,
          "ctrl": {
            "has_monitor": false,
            "has_power": false,
            "has_synclock": true,
            "has_lock": false,
            "has_mute": false,
            "has_solo": false,
            "has_waveform": false
          },
          "arr": [
            {
              "type": "segment",
              "is_hide": true,
              "start": 0,
              "len": 2102,
              "arr": [
                {
                  "type": "marker",
                  "zidx": 100,
                  "is_no_arr": true,
                  "start": 1028,
                  "len": 1,
                  "color": "#cc33cc",
                  "color_rgb16": {
                    "red": 52428,
                    "green": 13107,
                    "blue": 52428
                  },
                  "comment": "פה יש לנו טקסט בעברית בתוך הסמן שלנו",
                  "depth": 1,
                  "abs_start": 1028
                }
              ],
              "depth": 1,
              "abs_start": 0
            }
          ],
          "power": {
            "color": "#4b88ba",
            "v": true
          },
          "start_tc": 90000
        },
        {
          "type": "timeline_track",
          "height": "tall",
          "depth": 0,
          "lbl": "A1",
          "id": "A1",
          "start": 0,
          "len": 2102,
          "abs_start": 0,
          "editrate": {
            "n": 25,
            "d": 1
          },
          "is_power": true,
          "is_synclock": false,
          "is_lock": false,
          "is_edit": true,
          "ctrl": {
            "has_monitor": false,
            "has_power": true,
            "has_synclock": true,
            "has_lock": true,
            "has_mute": true,
            "has_solo": true,
            "has_waveform": true
          },
          "arr": [
            {
              "type": "segment",
              "is_hide": true,
              "start": 0,
              "len": 2102,
              "arr": [
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 0,
                  "depth": 2,
                  "type": "filler",
                  "len": 266,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 0,
                  "trim": {
                    "start_pre": 0,
                    "start_post": 0,
                    "end_pre": 266,
                    "end_post": 112
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "selector",
                  "start": 266,
                  "len": 112,
                  "color": "#85989f",
                  "is_no_border": true,
                  "is_on_right_click_menu": true,
                  "select_idx": 3,
                  "abs_start": 266,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "lbl": "Clip0001.new.01 (G)",
                  "id": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60",
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "4CH000M.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1575462,
                          "len": 87,
                          "color": "#c14ba1",
                          "lbl": "4CH000M.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1ad15863.9882a405.4edb54c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 2612,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000M",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 87,
                          "depth": 4,
                          "type": "filler",
                          "len": 25,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 353,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "4CH000I.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1575462,
                          "len": 87,
                          "color": "#7e3169",
                          "lbl": "4CH000I.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1acffd99.9882a405.aa9054c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 2612,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000I",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 87,
                          "depth": 4,
                          "type": "filler",
                          "len": 25,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 353,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "A141C001_200425B8_CANON.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1573844,
                          "len": 40,
                          "color": "#5846e5",
                          "lbl": "A141C001_200425B8_CANON.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 1856,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "A141C001_200425B8_CANON",
                              "tc": "17:27:59:13",
                              "d": 1896,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 75840,
                              "d25fps": 1896,
                              "tc25fps": "17:27:59:13",
                              "dur_ts": 1896,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 40,
                          "depth": 4,
                          "type": "filler",
                          "len": 72,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 306,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "Clip0001.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1565550,
                          "len": 61,
                          "color": "#42d4f4",
                          "lbl": "Clip0001.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 2195,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Clip0001",
                              "tc": "17:22:14:05",
                              "d": 2256,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 90240,
                              "d25fps": 2256,
                              "tc25fps": "17:22:14:05",
                              "dur_ts": 2256,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 61,
                          "depth": 4,
                          "type": "filler",
                          "len": 51,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 327,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "9S5A0786.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1984875,
                          "len": 112,
                          "color": "#65c553",
                          "lbl": "9S5A0786.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.19ad290a.9882a405.486454c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 1583,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "9S5A0786",
                              "tc": "22:02:11:17",
                              "d": 1704,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 68160,
                              "d25fps": 1704,
                              "tc25fps": "22:02:11:17",
                              "dur_ts": 1704,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "GH014182.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1575469,
                          "len": 112,
                          "color": "#e4c600",
                          "lbl": "GH014182.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1a453721.9882a405.9b0354c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 2397,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "GH014182",
                              "tc": "17:28:42:22",
                              "d": 2651,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 106040,
                              "d25fps": 2651,
                              "tc25fps": "17:28:42:22",
                              "dur_ts": 2651,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    }
                  ],
                  "depth": 2,
                  "trim": {
                    "start_pre": 266,
                    "start_post": 112,
                    "end_pre": 112,
                    "end_post": 112
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "selector",
                  "start": 378,
                  "len": 1407,
                  "color": "#85989f",
                  "is_no_border": true,
                  "is_on_right_click_menu": true,
                  "select_idx": 0,
                  "abs_start": 378,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "lbl": "4CH000M.wav.new.01 (G)",
                  "id": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60",
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "4CH000M.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574009,
                          "len": 1407,
                          "color": "#c14ba1",
                          "lbl": "4CH000M.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1ad15863.9882a405.4edb54c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 1159,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000M",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "4CH000I.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574009,
                          "len": 1407,
                          "color": "#7e3169",
                          "lbl": "4CH000I.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1acffd99.9882a405.aa9054c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 1159,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000I",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "A141C001_200425B8_CANON.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1572391,
                          "len": 1407,
                          "color": "#5846e5",
                          "lbl": "A141C001_200425B8_CANON.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 403,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "A141C001_200425B8_CANON",
                              "tc": "17:27:59:13",
                              "d": 1896,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 75840,
                              "d25fps": 1896,
                              "tc25fps": "17:27:59:13",
                              "dur_ts": 1896,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "Clip0001.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1564097,
                          "len": 1407,
                          "color": "#42d4f4",
                          "lbl": "Clip0001.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 742,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Clip0001",
                              "tc": "17:22:14:05",
                              "d": 2256,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 90240,
                              "d25fps": 2256,
                              "tc25fps": "17:22:14:05",
                              "dur_ts": 2256,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "9S5A0786.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1983422,
                          "len": 1407,
                          "color": "#65c553",
                          "lbl": "9S5A0786.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.19ad290a.9882a405.486454c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 130,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "9S5A0786",
                              "tc": "22:02:11:17",
                              "d": 1704,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 68160,
                              "d25fps": 1704,
                              "tc25fps": "22:02:11:17",
                              "dur_ts": 1704,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "GH014182.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574016,
                          "len": 1407,
                          "color": "#e4c600",
                          "lbl": "GH014182.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1a453721.9882a405.9b0354c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 944,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "GH014182",
                              "tc": "17:28:42:22",
                              "d": 2651,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 106040,
                              "d25fps": 2651,
                              "tc25fps": "17:28:42:22",
                              "dur_ts": 2651,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    }
                  ],
                  "depth": 2,
                  "trim": {
                    "start_pre": 112,
                    "start_post": 112,
                    "end_pre": 1159,
                    "end_post": 300
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 1785,
                  "depth": 2,
                  "type": "filler",
                  "len": 317,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 1785,
                  "trim": {
                    "start_pre": 1159,
                    "start_post": 300,
                    "end_pre": 317,
                    "end_post": 9223372036854776000
                  }
                }
              ],
              "depth": 1,
              "abs_start": 0
            }
          ],
          "power": {
            "color": "#4b88ba",
            "v": true
          },
          "is_solo": false,
          "is_mute": false,
          "solo": {
            "color": "#666666",
            "v": false
          },
          "mute": {
            "color": "#666666",
            "v": false
          }
        },
        {
          "type": "timeline_track",
          "height": "tall",
          "depth": 0,
          "lbl": "A2",
          "id": "A2",
          "start": 0,
          "len": 2102,
          "abs_start": 0,
          "editrate": {
            "n": 25,
            "d": 1
          },
          "is_power": true,
          "is_synclock": false,
          "is_lock": false,
          "is_edit": true,
          "ctrl": {
            "has_monitor": false,
            "has_power": true,
            "has_synclock": true,
            "has_lock": true,
            "has_mute": true,
            "has_solo": true,
            "has_waveform": true
          },
          "arr": [
            {
              "type": "segment",
              "is_hide": true,
              "start": 0,
              "len": 2102,
              "arr": [
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 0,
                  "depth": 2,
                  "type": "filler",
                  "len": 266,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 0,
                  "trim": {
                    "start_pre": 0,
                    "start_post": 0,
                    "end_pre": 266,
                    "end_post": 112
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "selector",
                  "start": 266,
                  "len": 112,
                  "color": "#85989f",
                  "is_no_border": true,
                  "is_on_right_click_menu": true,
                  "select_idx": 3,
                  "abs_start": 266,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "lbl": "Clip0001.new.01 (G)",
                  "id": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60",
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "4CH000M.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1575462,
                          "len": 87,
                          "color": "#c14ba1",
                          "lbl": "4CH000M.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1ad15863.9882a405.4edb54c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 2612,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000M",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 87,
                          "depth": 4,
                          "type": "filler",
                          "len": 25,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 353,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "4CH000I.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1575462,
                          "len": 87,
                          "color": "#7e3169",
                          "lbl": "4CH000I.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1acffd99.9882a405.aa9054c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 2612,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000I",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 87,
                          "depth": 4,
                          "type": "filler",
                          "len": 25,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 353,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "A141C001_200425B8_CANON.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1573844,
                          "len": 40,
                          "color": "#5846e5",
                          "lbl": "A141C001_200425B8_CANON.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 1856,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "A141C001_200425B8_CANON",
                              "tc": "17:27:59:13",
                              "d": 1896,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 75840,
                              "d25fps": 1896,
                              "tc25fps": "17:27:59:13",
                              "dur_ts": 1896,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 40,
                          "depth": 4,
                          "type": "filler",
                          "len": 72,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 306,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "Clip0001.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1565550,
                          "len": 61,
                          "color": "#42d4f4",
                          "lbl": "Clip0001.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 2195,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Clip0001",
                              "tc": "17:22:14:05",
                              "d": 2256,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 90240,
                              "d25fps": 2256,
                              "tc25fps": "17:22:14:05",
                              "dur_ts": 2256,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 61,
                          "depth": 4,
                          "type": "filler",
                          "len": 51,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 327,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "9S5A0786.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1984875,
                          "len": 112,
                          "color": "#65c553",
                          "lbl": "9S5A0786.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.19ad290a.9882a405.486454c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 1583,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "9S5A0786",
                              "tc": "22:02:11:17",
                              "d": 1704,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 68160,
                              "d25fps": 1704,
                              "tc25fps": "22:02:11:17",
                              "dur_ts": 1704,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "GH014182.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1575469,
                          "len": 112,
                          "color": "#e4c600",
                          "lbl": "GH014182.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1a453721.9882a405.9b0354c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 2397,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "GH014182",
                              "tc": "17:28:42:22",
                              "d": 2651,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 106040,
                              "d25fps": 2651,
                              "tc25fps": "17:28:42:22",
                              "dur_ts": 2651,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    }
                  ],
                  "depth": 2,
                  "trim": {
                    "start_pre": 266,
                    "start_post": 112,
                    "end_pre": 112,
                    "end_post": 112
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "selector",
                  "start": 378,
                  "len": 1407,
                  "color": "#85989f",
                  "is_no_border": true,
                  "is_on_right_click_menu": true,
                  "select_idx": 0,
                  "abs_start": 378,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "lbl": "4CH000M.wav.new.01 (G)",
                  "id": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60",
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "4CH000M.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574009,
                          "len": 1407,
                          "color": "#c14ba1",
                          "lbl": "4CH000M.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1ad15863.9882a405.4edb54c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 1159,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000M",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "4CH000I.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574009,
                          "len": 1407,
                          "color": "#7e3169",
                          "lbl": "4CH000I.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1acffd99.9882a405.aa9054c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 1159,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000I",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "A141C001_200425B8_CANON.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1572391,
                          "len": 1407,
                          "color": "#5846e5",
                          "lbl": "A141C001_200425B8_CANON.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 403,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "A141C001_200425B8_CANON",
                              "tc": "17:27:59:13",
                              "d": 1896,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 75840,
                              "d25fps": 1896,
                              "tc25fps": "17:27:59:13",
                              "dur_ts": 1896,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "Clip0001.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1564097,
                          "len": 1407,
                          "color": "#42d4f4",
                          "lbl": "Clip0001.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 742,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Clip0001",
                              "tc": "17:22:14:05",
                              "d": 2256,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 90240,
                              "d25fps": 2256,
                              "tc25fps": "17:22:14:05",
                              "dur_ts": 2256,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "9S5A0786.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1983422,
                          "len": 1407,
                          "color": "#65c553",
                          "lbl": "9S5A0786.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.19ad290a.9882a405.486454c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 130,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "9S5A0786",
                              "tc": "22:02:11:17",
                              "d": 1704,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 68160,
                              "d25fps": 1704,
                              "tc25fps": "22:02:11:17",
                              "dur_ts": 1704,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "GH014182.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574016,
                          "len": 1407,
                          "color": "#e4c600",
                          "lbl": "GH014182.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1a453721.9882a405.9b0354c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 944,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "GH014182",
                              "tc": "17:28:42:22",
                              "d": 2651,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 106040,
                              "d25fps": 2651,
                              "tc25fps": "17:28:42:22",
                              "dur_ts": 2651,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    }
                  ],
                  "depth": 2,
                  "trim": {
                    "start_pre": 112,
                    "start_post": 112,
                    "end_pre": 1159,
                    "end_post": 300
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 1785,
                  "depth": 2,
                  "type": "filler",
                  "len": 317,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 1785,
                  "trim": {
                    "start_pre": 1159,
                    "start_post": 300,
                    "end_pre": 317,
                    "end_post": 9223372036854776000
                  }
                }
              ],
              "depth": 1,
              "abs_start": 0
            }
          ],
          "power": {
            "color": "#4b88ba",
            "v": true
          },
          "is_solo": false,
          "is_mute": false,
          "solo": {
            "color": "#666666",
            "v": false
          },
          "mute": {
            "color": "#666666",
            "v": false
          }
        },
        {
          "type": "timeline_track",
          "height": "tall",
          "depth": 0,
          "lbl": "A3",
          "id": "A3",
          "start": 0,
          "len": 2102,
          "abs_start": 0,
          "editrate": {
            "n": 25,
            "d": 1
          },
          "is_power": true,
          "is_synclock": false,
          "is_lock": false,
          "is_edit": true,
          "ctrl": {
            "has_monitor": false,
            "has_power": true,
            "has_synclock": true,
            "has_lock": true,
            "has_mute": true,
            "has_solo": true,
            "has_waveform": true
          },
          "arr": [
            {
              "type": "segment",
              "is_hide": true,
              "start": 0,
              "len": 2102,
              "arr": [
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 0,
                  "depth": 2,
                  "type": "filler",
                  "len": 266,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 0,
                  "trim": {
                    "start_pre": 0,
                    "start_post": 0,
                    "end_pre": 266,
                    "end_post": 112
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "selector",
                  "start": 266,
                  "len": 112,
                  "color": "#85989f",
                  "is_no_border": true,
                  "is_on_right_click_menu": true,
                  "select_idx": 0,
                  "abs_start": 266,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "lbl": "A141C001_200425B8_CANON.new.01 (G)",
                  "id": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60",
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "A141C001_200425B8_CANON.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1573844,
                          "len": 40,
                          "color": "#5846e5",
                          "lbl": "A141C001_200425B8_CANON.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104",
                          "track_lbl": "A3",
                          "start_frame_abs": 1856,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "A141C001_200425B8_CANON",
                              "tc": "17:27:59:13",
                              "d": 1896,
                              "tr": "A3",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 75840,
                              "d25fps": 1896,
                              "tc25fps": "17:27:59:13",
                              "dur_ts": 1896,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 40,
                          "depth": 4,
                          "type": "filler",
                          "len": 72,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 306,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "Clip0001.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1565550,
                          "len": 61,
                          "color": "#42d4f4",
                          "lbl": "Clip0001.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104",
                          "track_lbl": "A3",
                          "start_frame_abs": 2195,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Clip0001",
                              "tc": "17:22:14:05",
                              "d": 2256,
                              "tr": "A3",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 90240,
                              "d25fps": 2256,
                              "tc25fps": "17:22:14:05",
                              "dur_ts": 2256,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 61,
                          "depth": 4,
                          "type": "filler",
                          "len": 51,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 327,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    }
                  ],
                  "depth": 2,
                  "trim": {
                    "start_pre": 266,
                    "start_post": 112,
                    "end_pre": 112,
                    "end_post": 112
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "selector",
                  "start": 378,
                  "len": 1407,
                  "color": "#848484",
                  "is_no_border": true,
                  "is_on_right_click_menu": true,
                  "select_idx": 1,
                  "abs_start": 378,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "lbl": "4CH000I.wav.new.01 (G)",
                  "id": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60",
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "4CH000M.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574009,
                          "len": 1407,
                          "color": "#c14ba1",
                          "lbl": "4CH000M.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1ad15863.9882a405.4edb54c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 1159,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000M",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "4CH000I.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574009,
                          "len": 1407,
                          "color": "#7e3169",
                          "lbl": "4CH000I.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1acffd99.9882a405.aa9054c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 1159,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000I",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "A141C001_200425B8_CANON.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1572391,
                          "len": 1407,
                          "color": "#5846e5",
                          "lbl": "A141C001_200425B8_CANON.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 403,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "A141C001_200425B8_CANON",
                              "tc": "17:27:59:13",
                              "d": 1896,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 75840,
                              "d25fps": 1896,
                              "tc25fps": "17:27:59:13",
                              "dur_ts": 1896,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "Clip0001.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1564097,
                          "len": 1407,
                          "color": "#42d4f4",
                          "lbl": "Clip0001.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 742,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Clip0001",
                              "tc": "17:22:14:05",
                              "d": 2256,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 90240,
                              "d25fps": 2256,
                              "tc25fps": "17:22:14:05",
                              "dur_ts": 2256,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "9S5A0786.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1983422,
                          "len": 1407,
                          "color": "#65c553",
                          "lbl": "9S5A0786.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.19ad290a.9882a405.486454c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 130,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "9S5A0786",
                              "tc": "22:02:11:17",
                              "d": 1704,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 68160,
                              "d25fps": 1704,
                              "tc25fps": "22:02:11:17",
                              "dur_ts": 1704,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "GH014182.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574016,
                          "len": 1407,
                          "color": "#e4c600",
                          "lbl": "GH014182.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1a453721.9882a405.9b0354c4.62e0a104",
                          "track_lbl": "A1",
                          "start_frame_abs": 944,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "GH014182",
                              "tc": "17:28:42:22",
                              "d": 2651,
                              "tr": "A1",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 106040,
                              "d25fps": 2651,
                              "tc25fps": "17:28:42:22",
                              "dur_ts": 2651,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    }
                  ],
                  "depth": 2,
                  "is_lbl_italic": true,
                  "rndr": {
                    "is_mute": true,
                    "is_pass": true,
                    "is_color": true
                  },
                  "trim": {
                    "start_pre": 112,
                    "start_post": 112,
                    "end_pre": 1159,
                    "end_post": 300
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 1785,
                  "depth": 2,
                  "type": "filler",
                  "len": 317,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 1785,
                  "trim": {
                    "start_pre": 1159,
                    "start_post": 300,
                    "end_pre": 317,
                    "end_post": 9223372036854776000
                  }
                }
              ],
              "depth": 1,
              "abs_start": 0
            }
          ],
          "power": {
            "color": "#4b88ba",
            "v": true
          },
          "is_solo": false,
          "is_mute": false,
          "solo": {
            "color": "#666666",
            "v": false
          },
          "mute": {
            "color": "#666666",
            "v": false
          }
        },
        {
          "type": "timeline_track",
          "height": "tall",
          "depth": 0,
          "lbl": "A4",
          "id": "A4",
          "start": 0,
          "len": 2102,
          "abs_start": 0,
          "editrate": {
            "n": 25,
            "d": 1
          },
          "is_power": true,
          "is_synclock": false,
          "is_lock": false,
          "is_edit": true,
          "ctrl": {
            "has_monitor": false,
            "has_power": true,
            "has_synclock": true,
            "has_lock": true,
            "has_mute": true,
            "has_solo": true,
            "has_waveform": true
          },
          "arr": [
            {
              "type": "segment",
              "is_hide": true,
              "start": 0,
              "len": 2102,
              "arr": [
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 0,
                  "depth": 2,
                  "type": "filler",
                  "len": 266,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 0,
                  "trim": {
                    "start_pre": 0,
                    "start_post": 0,
                    "end_pre": 266,
                    "end_post": 112
                  }
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "selector",
                  "start": 266,
                  "len": 112,
                  "color": "#85989f",
                  "is_no_border": true,
                  "is_on_right_click_menu": true,
                  "select_idx": 0,
                  "abs_start": 266,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "lbl": "A141C001_200425B8_CANON.new.01 (G)",
                  "id": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60",
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "A141C001_200425B8_CANON.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1573844,
                          "len": 40,
                          "color": "#5846e5",
                          "lbl": "A141C001_200425B8_CANON.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104",
                          "track_lbl": "A4",
                          "start_frame_abs": 1856,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "A141C001_200425B8_CANON",
                              "tc": "17:27:59:13",
                              "d": 1896,
                              "tr": "A4",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 75840,
                              "d25fps": 1896,
                              "tc25fps": "17:27:59:13",
                              "dur_ts": 1896,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 40,
                          "depth": 4,
                          "type": "filler",
                          "len": 72,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 306,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 112,
                      "lbl": "Clip0001.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1565550,
                          "len": 61,
                          "color": "#42d4f4",
                          "lbl": "Clip0001.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104",
                          "track_lbl": "A4",
                          "start_frame_abs": 2195,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Clip0001",
                              "tc": "17:22:14:05",
                              "d": 2256,
                              "tr": "A4",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 90240,
                              "d25fps": 2256,
                              "tc25fps": "17:22:14:05",
                              "dur_ts": 2256,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 266,
                          "is_lbl_hide": true
                        },
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "start": 61,
                          "depth": 4,
                          "type": "filler",
                          "len": 51,
                          "rndr": {
                            "is_pass": true
                          },
                          "abs_start": 327,
                          "color": "#85989f"
                        }
                      ],
                      "depth": 3,
                      "abs_start": 266
                    }
                  ],
                  "depth": 2,
                  "trim": {
                    "start_pre": 266,
                    "start_post": 112,
                    "end_pre": 1135,
                    "end_post": 300
                  }
                },
                {
                  "type": "transition",
                  "zidx": 50,
                  "opacity": 0.5,
                  "is_no_arr": true,
                  "start": 354,
                  "len": 50,
                  "is_icon_purple": true,
                  "icon": {
                    "is_icon_out": true,
                    "icon_out": "transition"
                  },
                  "depth": 2,
                  "abs_start": 354
                },
                {
                  "is_no_arr": false,
                  "zidx": 10,
                  "type": "selector",
                  "start": 378,
                  "len": 1407,
                  "color": "#85989f",
                  "is_no_border": true,
                  "is_on_right_click_menu": true,
                  "select_idx": 1,
                  "abs_start": 378,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "lbl": "4CH000I.wav.new.01 (G)",
                  "id": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60",
                  "arr": [
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "4CH000M.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574009,
                          "len": 1407,
                          "color": "#c14ba1",
                          "lbl": "4CH000M.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1ad15863.9882a405.4edb54c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 1159,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000M",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "4CH000I.wav.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574009,
                          "len": 1407,
                          "color": "#7e3169",
                          "lbl": "4CH000I.wav.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1acffd99.9882a405.aa9054c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 1159,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "4CH000I",
                              "tc": "17:28:34:00",
                              "d": 2699,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": true,
                              "dms": 107960,
                              "d25fps": 2699,
                              "tc25fps": "17:28:34:00",
                              "dur_ts": 2699,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              },
                              "shoot_date": "2020-04-25 17:28:34"
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "A141C001_200425B8_CANON.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1572391,
                          "len": 1407,
                          "color": "#5846e5",
                          "lbl": "A141C001_200425B8_CANON.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 403,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "A141C001_200425B8_CANON",
                              "tc": "17:27:59:13",
                              "d": 1896,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 75840,
                              "d25fps": 1896,
                              "tc25fps": "17:27:59:13",
                              "dur_ts": 1896,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "Clip0001.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1564097,
                          "len": 1407,
                          "color": "#42d4f4",
                          "lbl": "Clip0001.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 742,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "Clip0001",
                              "tc": "17:22:14:05",
                              "d": 2256,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 90240,
                              "d25fps": 2256,
                              "tc25fps": "17:22:14:05",
                              "dur_ts": 2256,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "9S5A0786.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1983422,
                          "len": 1407,
                          "color": "#65c553",
                          "lbl": "9S5A0786.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.19ad290a.9882a405.486454c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 130,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "9S5A0786",
                              "tc": "22:02:11:17",
                              "d": 1704,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 68160,
                              "d25fps": 1704,
                              "tc25fps": "22:02:11:17",
                              "dur_ts": 1704,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    },
                    {
                      "type": "segment",
                      "is_hide": true,
                      "start": 0,
                      "len": 1407,
                      "lbl": "GH014182.new.01",
                      "is_lbl_hide": true,
                      "arr": [
                        {
                          "is_no_arr": true,
                          "zidx": 10,
                          "depth": 4,
                          "type": "source_clip",
                          "start": 0,
                          "start_tc": 1574016,
                          "len": 1407,
                          "color": "#e4c600",
                          "lbl": "GH014182.new.01",
                          "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1a453721.9882a405.9b0354c4.62e0a104",
                          "track_lbl": "A2",
                          "start_frame_abs": 944,
                          "editrate": {
                            "n": 25,
                            "d": 1
                          },
                          "src": {
                            "type": "aaf_info",
                            "v": {
                              "n": "GH014182",
                              "tc": "17:28:42:22",
                              "d": 2651,
                              "tr": "A2",
                              "is_video": false,
                              "is_audio": true,
                              "is_audio_only": false,
                              "dms": 106040,
                              "d25fps": 2651,
                              "tc25fps": "17:28:42:22",
                              "dur_ts": 2651,
                              "editrate": {
                                "n": 25,
                                "d": 1
                              }
                            }
                          },
                          "abs_start": 378,
                          "is_lbl_hide": true
                        }
                      ],
                      "depth": 3,
                      "abs_start": 378
                    }
                  ],
                  "depth": 2,
                  "trim": {
                    "start_pre": 138,
                    "start_post": 112,
                    "end_pre": 317,
                    "end_post": 317
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 1785,
                  "depth": 2,
                  "type": "filler",
                  "len": 317,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 1785,
                  "trim": {
                    "start_pre": 1135,
                    "start_post": 300,
                    "end_pre": 317,
                    "end_post": 9223372036854776000
                  }
                }
              ],
              "depth": 1,
              "abs_start": 0
            }
          ],
          "power": {
            "color": "#4b88ba",
            "v": true
          },
          "is_solo": false,
          "is_mute": false,
          "solo": {
            "color": "#666666",
            "v": false
          },
          "mute": {
            "color": "#666666",
            "v": false
          }
        },
        {
          "type": "timeline_track",
          "height": "tall",
          "depth": 0,
          "lbl": "A5",
          "id": "A5",
          "start": 0,
          "len": 2102,
          "abs_start": 0,
          "editrate": {
            "n": 25,
            "d": 1
          },
          "is_power": true,
          "is_synclock": false,
          "is_lock": false,
          "is_edit": true,
          "ctrl": {
            "has_monitor": false,
            "has_power": true,
            "has_synclock": true,
            "has_lock": true,
            "has_mute": true,
            "has_solo": true,
            "has_waveform": true
          },
          "arr": [
            {
              "type": "segment",
              "is_hide": true,
              "start": 0,
              "len": 2102,
              "arr": [
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 0,
                  "depth": 2,
                  "type": "filler",
                  "len": 0,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 0,
                  "trim": {
                    "start_pre": 0,
                    "start_post": 0,
                    "end_pre": 456,
                    "end_post": 456
                  }
                },
                {
                  "type": "transition",
                  "zidx": 50,
                  "opacity": 0.5,
                  "is_no_arr": true,
                  "start": 0,
                  "len": 33,
                  "is_icon_purple": true,
                  "icon": {
                    "is_icon_out": true,
                    "icon_out": "transition"
                  },
                  "depth": 2,
                  "abs_start": 0
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "depth": 2,
                  "type": "source_clip",
                  "start": 0,
                  "start_tc": 4641,
                  "len": 456,
                  "color": "#85989f",
                  "lbl": "DWIG_Orange_Evening_Laut_Luise.wav",
                  "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.7466905c.0168a406.e8445406.8ff27504",
                  "track_lbl": "A1",
                  "start_frame_abs": 4641,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "src": {
                    "type": "aaf_info",
                    "v": {
                      "n": "DWIG_Orange_Evening_Laut_Luise",
                      "tc": "00:00:00:00",
                      "d": 10900,
                      "tr": "A1",
                      "is_video": false,
                      "is_audio": true,
                      "is_audio_only": true,
                      "dms": 436000,
                      "d25fps": 10900,
                      "tc25fps": "00:00:00:00",
                      "dur_ts": 10900,
                      "editrate": {
                        "n": 25,
                        "d": 1
                      },
                      "shoot_date": "2023-08-12 12:01:29"
                    }
                  },
                  "abs_start": 0,
                  "trim": {
                    "start_pre": 0,
                    "start_post": 0,
                    "end_pre": 456,
                    "end_post": 1329
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 456,
                  "depth": 2,
                  "type": "filler",
                  "len": 1329,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 456,
                  "trim": {
                    "start_pre": 456,
                    "start_post": 1329,
                    "end_pre": 1329,
                    "end_post": 1329
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "depth": 2,
                  "type": "source_clip",
                  "start": 1785,
                  "start_tc": 10313,
                  "len": 258,
                  "color": "#85989f",
                  "lbl": "DWIG_Orange_Evening_Laut_Luise.wav",
                  "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.7466905c.0168a406.e8445406.8ff27504",
                  "track_lbl": "A1",
                  "start_frame_abs": 10313,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "src": {
                    "type": "aaf_info",
                    "v": {
                      "n": "DWIG_Orange_Evening_Laut_Luise",
                      "tc": "00:00:00:00",
                      "d": 10900,
                      "tr": "A1",
                      "is_video": false,
                      "is_audio": true,
                      "is_audio_only": true,
                      "dms": 436000,
                      "d25fps": 10900,
                      "tc25fps": "00:00:00:00",
                      "dur_ts": 10900,
                      "editrate": {
                        "n": 25,
                        "d": 1
                      },
                      "shoot_date": "2023-08-12 12:01:29"
                    }
                  },
                  "abs_start": 1785,
                  "trim": {
                    "start_pre": 1329,
                    "start_post": 1329,
                    "end_pre": 258,
                    "end_post": 258
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 2043,
                  "depth": 2,
                  "type": "filler",
                  "len": 59,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 2043,
                  "trim": {
                    "start_pre": 258,
                    "start_post": 258,
                    "end_pre": 59,
                    "end_post": 9223372036854776000
                  }
                }
              ],
              "depth": 1,
              "abs_start": 0
            }
          ],
          "power": {
            "color": "#4b88ba",
            "v": true
          },
          "is_solo": false,
          "is_mute": false,
          "solo": {
            "color": "#666666",
            "v": false
          },
          "mute": {
            "color": "#666666",
            "v": false
          }
        },
        {
          "type": "timeline_track",
          "height": "tall",
          "depth": 0,
          "lbl": "A6",
          "id": "A6",
          "start": 0,
          "len": 2102,
          "abs_start": 0,
          "editrate": {
            "n": 25,
            "d": 1
          },
          "is_power": true,
          "is_synclock": false,
          "is_lock": false,
          "is_edit": true,
          "ctrl": {
            "has_monitor": false,
            "has_power": true,
            "has_synclock": true,
            "has_lock": true,
            "has_mute": true,
            "has_solo": true,
            "has_waveform": true
          },
          "arr": [
            {
              "type": "segment",
              "is_hide": true,
              "start": 0,
              "len": 2102,
              "arr": [
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "depth": 2,
                  "type": "source_clip",
                  "start": 0,
                  "start_tc": 4641,
                  "len": 456,
                  "color": "#85989f",
                  "lbl": "DWIG_Orange_Evening_Laut_Luise.wav",
                  "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.7466905c.0168a406.e8445406.8ff27504",
                  "track_lbl": "A2",
                  "start_frame_abs": 4641,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "src": {
                    "type": "aaf_info",
                    "v": {
                      "n": "DWIG_Orange_Evening_Laut_Luise",
                      "tc": "00:00:00:00",
                      "d": 10900,
                      "tr": "A2",
                      "is_video": false,
                      "is_audio": true,
                      "is_audio_only": true,
                      "dms": 436000,
                      "d25fps": 10900,
                      "tc25fps": "00:00:00:00",
                      "dur_ts": 10900,
                      "editrate": {
                        "n": 25,
                        "d": 1
                      },
                      "shoot_date": "2023-08-12 12:01:29"
                    }
                  },
                  "abs_start": 0,
                  "trim": {
                    "start_pre": 0,
                    "start_post": 0,
                    "end_pre": 456,
                    "end_post": 1329
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 456,
                  "depth": 2,
                  "type": "filler",
                  "len": 1329,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 456,
                  "trim": {
                    "start_pre": 456,
                    "start_post": 1329,
                    "end_pre": 1329,
                    "end_post": 1329
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "depth": 2,
                  "type": "source_clip",
                  "start": 1785,
                  "start_tc": 10313,
                  "len": 258,
                  "color": "#85989f",
                  "lbl": "DWIG_Orange_Evening_Laut_Luise.wav",
                  "id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.7466905c.0168a406.e8445406.8ff27504",
                  "track_lbl": "A2",
                  "start_frame_abs": 10313,
                  "editrate": {
                    "n": 25,
                    "d": 1
                  },
                  "src": {
                    "type": "aaf_info",
                    "v": {
                      "n": "DWIG_Orange_Evening_Laut_Luise",
                      "tc": "00:00:00:00",
                      "d": 10900,
                      "tr": "A2",
                      "is_video": false,
                      "is_audio": true,
                      "is_audio_only": true,
                      "dms": 436000,
                      "d25fps": 10900,
                      "tc25fps": "00:00:00:00",
                      "dur_ts": 10900,
                      "editrate": {
                        "n": 25,
                        "d": 1
                      },
                      "shoot_date": "2023-08-12 12:01:29"
                    }
                  },
                  "abs_start": 1785,
                  "trim": {
                    "start_pre": 1329,
                    "start_post": 1329,
                    "end_pre": 258,
                    "end_post": 258
                  }
                },
                {
                  "is_no_arr": true,
                  "zidx": 10,
                  "start": 2043,
                  "depth": 2,
                  "type": "filler",
                  "len": 59,
                  "rndr": {
                    "is_pass": true
                  },
                  "abs_start": 2043,
                  "trim": {
                    "start_pre": 258,
                    "start_post": 258,
                    "end_pre": 59,
                    "end_post": 9223372036854776000
                  }
                }
              ],
              "depth": 1,
              "abs_start": 0
            }
          ],
          "power": {
            "color": "#4b88ba",
            "v": true
          },
          "is_solo": false,
          "is_mute": false,
          "solo": {
            "color": "#666666",
            "v": false
          },
          "mute": {
            "color": "#666666",
            "v": false
          }
        }
      ]
    },
    "tmarkers": [
      {
        "num": 1,
        "color": "#cc33cc",
        "name": "Toolium",
        "track": "TC1",
        "timecode": "01:00:41:03",
        "comment": "פה יש לנו טקסט בעברית בתוך הסמן שלנו",
        "abs_frame": 1028
      },
      {
        "num": 2,
        "color": "#3333cc",
        "name": "Toolium",
        "track": "V1",
        "timecode": "01:00:00:00",
        "comment": "",
        "abs_frame": 0
      },
      {
        "num": 3,
        "color": "#e6e619",
        "name": "Toolium",
        "track": "V1",
        "timecode": "01:00:20:13",
        "comment": "here we have marker text in english that is very long and passes the end of the monitor. it should have 3 dots... at the end of the text on screen",
        "abs_frame": 513
      },
      {
        "num": 4,
        "color": "#33cc33",
        "name": "Toolium",
        "track": "V1",
        "timecode": "01:01:16:15",
        "comment": "",
        "abs_frame": 1915
      },
      {
        "num": 5,
        "color": "#a12f19",
        "name": "Toolium",
        "track": "V2",
        "timecode": "01:01:00:10",
        "comment": "",
        "abs_frame": 1510
      }
    ],
    "tbin": {
      "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.770c0802.0fbaead1.5d4b0e92.4404eeca": {
        "type": "masterclip",
        "name": "Water_Fall_Drizzle",
        "mob_id": "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.770c0802.0fbaead1.5d4b0e92.4404eeca",
        "fps": 25,
        "start_tc": "00:02:18:13",
        "end_tc": "00:02:38:15",
        "length_frames": 502,
        "length_tc": "00:00:20:02",
        "color": null,
        "color_rgb16": null,
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1, A1-2",
        "source_path": "file:///C%3A/Users/cavan/Desktop/toolium/editor/demo_tech/Rushes/Water_Fall_Drizzle.mov",
        "source_file": "Water_Fall_Drizzle.mov",
        "reel": "Water_Fall_Drizzle",
        "user_comments": [
          {
            "Video": "QTFF"
          }
        ],
        "creation_date": "2023-8-12T8:50:54.0Z",
        "last_modified": "2024-9-5T13:12:49.0Z"
      },
      "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.11cb6607.92e1db2f.2fc1b4a1.13c2b993": {
        "type": "masterclip",
        "name": "Volcano_Flight",
        "mob_id": "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.11cb6607.92e1db2f.2fc1b4a1.13c2b993",
        "fps": 25,
        "start_tc": "00:00:00:00",
        "end_tc": "00:04:48:18",
        "length_frames": 7218,
        "length_tc": "00:04:48:18",
        "color": null,
        "color_rgb16": null,
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1, A1-2",
        "source_path": "file:///C%3A/Users/cavan/Desktop/toolium/editor/demo_tech/Rushes/Volcano_Flight.mov",
        "source_file": "Volcano_Flight.mov",
        "reel": "Volcano_Flight",
        "user_comments": [
          {
            "Video": "QTFF"
          }
        ],
        "creation_date": "2023-8-12T8:50:54.0Z",
        "last_modified": "2024-9-5T13:12:49.0Z",
        "mark_in_tc": "00:03:51:05"
      },
      "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.ffdf5719.f4935fa7.0d67f6fb.3b468171": {
        "type": "masterclip",
        "name": "Toolium_Logo",
        "mob_id": "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.ffdf5719.f4935fa7.0d67f6fb.3b468171",
        "fps": 25,
        "start_tc": "00:00:00:00",
        "end_tc": "00:00:30:00",
        "length_frames": 750,
        "length_tc": "00:00:30:00",
        "color": null,
        "color_rgb16": null,
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1",
        "source_path": "file:///C%3A/Users/cavan/Desktop/toolium/editor/demo_tech/Rushes/Toolium_Logo.png",
        "source_file": "Toolium_Logo.png",
        "reel": "Toolium_Logo",
        "creation_date": "2023-8-12T8:44:9.0Z",
        "last_modified": "2024-9-5T13:12:49.0Z",
        "mark_in_tc": "00:00:05:21"
      },
      "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.24a4f7f7.297e09a6.9428ee13.4eec1461": {
        "type": "masterclip",
        "name": "Water_Fall_CU",
        "mob_id": "urn:smpte:umid:060a2b34.01010100.01010f00.13000000.24a4f7f7.297e09a6.9428ee13.4eec1461",
        "fps": 25,
        "start_tc": "00:00:00:00",
        "end_tc": "00:00:18:04",
        "length_frames": 454,
        "length_tc": "00:00:18:04",
        "color": null,
        "color_rgb16": null,
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1, A1-2",
        "source_path": "file:///C%3A/Users/cavan/Desktop/toolium/editor/demo_tech/Rushes/Water_Fall_CU.mov",
        "source_file": "Water_Fall_CU.mov",
        "reel": "Water_Fall_CU",
        "user_comments": [
          {
            "Video": "QTFF"
          }
        ],
        "creation_date": "2023-8-12T8:50:54.0Z",
        "last_modified": "2024-9-5T13:12:49.0Z"
      },
      "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60": {
        "type": "groupclip",
        "name": "4CH000M.wav.new.01.Grp.01",
        "mob_id": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7257a60",
        "fps": 25,
        "start_tc": "",
        "end_tc": "",
        "length_frames": null,
        "length_tc": "",
        "color": null,
        "color_rgb16": null,
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1, A1-4",
        "mob_id_match": "urn:smpte:umid:060a2b34.01010101.01010f00.13000000.060e2b34.7f7f2a80.64a76694.f7267a60",
        "creation_date": "2023-7-7T1:12:52.0Z",
        "last_modified": "2024-9-5T13:12:49.0Z"
      },
      "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.19ad290a.9882a405.486454c4.62e0a104": {
        "type": "masterclip",
        "name": "9S5A0786.new.01",
        "mob_id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.19ad290a.9882a405.486454c4.62e0a104",
        "fps": 25,
        "start_tc": "22:02:11:17",
        "end_tc": "22:03:19:21",
        "length_frames": 1704,
        "length_tc": "00:01:08:04",
        "color": "#65c553",
        "color_rgb16": {
          "red": 25856,
          "green": 50432,
          "blue": 21248
        },
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1, A1-2",
        "source_path": "file:///D%3A/multicam%20test/CAV_01_250420_PC001/DCIM/100EOS5D/9S5A0786.MOV",
        "source_file": "9S5A0786.MOV",
        "reel": "9S5A0786",
        "user_comments": [
          {
            "Video": "QTFF"
          },
          {
            "Camroll": "CAV_01_250420_PC001"
          }
        ],
        "creation_date": "2022-10-30T11:20:28.000Z",
        "last_modified": "2024-9-5T13:12:49.0Z"
      },
      "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.70f96417.0168a406.cee15406.8ff27504": {
        "type": "titleclip",
        "name": "Title: Untitled",
        "mob_id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.70f96417.0168a406.cee15406.8ff27504",
        "fps": 25,
        "start_tc": "",
        "end_tc": "",
        "length_frames": null,
        "length_tc": "",
        "color": null,
        "color_rgb16": null,
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1",
        "creation_date": "2023-8-12T8:42:0.0Z",
        "last_modified": "2024-9-5T13:12:49.0Z"
      },
      "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1a453721.9882a405.9b0354c4.62e0a104": {
        "type": "masterclip",
        "name": "GH014182.new.01",
        "mob_id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1a453721.9882a405.9b0354c4.62e0a104",
        "fps": 25,
        "start_tc": "17:28:42:22",
        "end_tc": "17:30:28:23",
        "length_frames": 2651,
        "length_tc": "00:01:46:01",
        "color": "#e4c600",
        "color_rgb16": {
          "red": 58368,
          "green": 50688,
          "blue": 0
        },
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1, A1-2",
        "source_path": "file:///D%3A/multicam%20test/CAV_01_250420_GP001/DCIM/100GOPRO/GH014182.MP4",
        "source_file": "GH014182.MP4",
        "reel": "GH014182",
        "user_comments": [
          {
            "Video": "QTFF"
          },
          {
            "Camroll": "CAV_01_250420_GP001"
          }
        ],
        "creation_date": "2022-10-30T11:23:49.000Z",
        "last_modified": "2024-9-5T13:12:49.0Z"
      },
      "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.f25b092e.0558a406.c6f3de73.3b8ab5b8": {
        "type": "sequence",
        "name": "editor_tester_20240905.Exported.01",
        "mob_id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.f25b092e.0558a406.c6f3de73.3b8ab5b8",
        "fps": 25,
        "start_tc": "01:00:00:00",
        "end_tc": "01:01:24:02",
        "length_frames": 2102,
        "length_tc": "00:01:24:02",
        "color": null,
        "color_rgb16": null,
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1, A1-6",
        "creation_date": "2024-9-5T15:18:37.0Z",
        "last_modified": "2024-9-5T15:18:32.0Z"
      },
      "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.70f92237.0168a406.97a25406.8ff27504": {
        "type": "alphaclip",
        "name": "Untitled",
        "mob_id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.70f92237.0168a406.97a25406.8ff27504",
        "fps": 25,
        "start_tc": "01:00:00:00",
        "end_tc": "01:02:00:00",
        "length_frames": 3000,
        "length_tc": "00:02:00:00",
        "color": null,
        "color_rgb16": null,
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1-2",
        "source_path": "file:///C%3A/Users/Public/Documents/Shared%20Avid%20Projects/title-3aeb66c6.PICT",
        "source_file": "title-3aeb66c6.PICT",
        "reel": "title-3aeb66c6",
        "creation_date": "2023-8-12T8:42:0.0Z",
        "last_modified": "2023-8-12T8:42:0.0Z"
      },
      "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.7466905c.0168a406.e8445406.8ff27504": {
        "type": "masterclip",
        "name": "DWIG_Orange_Evening_Laut_Luise.wav",
        "mob_id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.7466905c.0168a406.e8445406.8ff27504",
        "fps": 25,
        "start_tc": "00:00:00:00",
        "end_tc": "00:07:16:00",
        "length_frames": 10900,
        "length_tc": "00:07:16:00",
        "color": null,
        "color_rgb16": null,
        "is_hide": false,
        "is_audio_only": true,
        "tracks": "A1-2",
        "source_path": "file:///C%3A/Users/cavan/Desktop/toolium/editor/demo_tech/Rushes/DWIG_Orange_Evening_Laut_Luise.wav",
        "source_file": "DWIG_Orange_Evening_Laut_Luise.wav",
        "reel": "DWIG_Orange_Evening_Laut_Luise",
        "user_comments": [
          {
            "Comments": "DWIG_Orange_Evening_Laut_Luise.wav"
          },
          {
            "TapeID": "DWIG_Orange_Evening_Laut_Luise.wav"
          },
          {
            "Shoot Date": "2023-08-12 12:01:29"
          },
          {
            "TRK1": "L"
          },
          {
            "TRK2": "R"
          },
          {
            "Production": "toolium video"
          },
          {
            "Soundroll TC Rate": "25"
          }
        ],
        "creation_date": "2023-8-12T9:1:34.0Z",
        "last_modified": "2024-9-5T13:12:49.0Z"
      },
      "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104": {
        "type": "masterclip",
        "name": "Clip0001.new.01",
        "mob_id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1b87b462.9882a405.f21a54c4.62e0a104",
        "fps": 25,
        "start_tc": "17:22:14:05",
        "end_tc": "17:23:44:11",
        "length_frames": 2256,
        "length_tc": "00:01:30:06",
        "color": "#42d4f4",
        "color_rgb16": {
          "red": 16896,
          "green": 54272,
          "blue": 62464
        },
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1, A1-4",
        "source_path": "file:///C%3A/Users/cavan/Downloads/toolium_video_test/rushes/Clip0001.MXF",
        "source_file": "Clip0001.MXF",
        "reel": "Clip0001",
        "user_comments": [
          {
            "Camroll": "CAV_01_250420_B001"
          }
        ],
        "creation_date": "2022-10-30T11:30:54.000Z",
        "last_modified": "2024-9-5T13:12:49.0Z",
        "mark_in_tc": "17:22:14:05",
        "mark_out_tc": "17:23:44:11",
        "in_out_tc": "00:01:30:06"
      },
      "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1ad15863.9882a405.4edb54c4.62e0a104": {
        "type": "masterclip",
        "name": "4CH000M.wav.new.01",
        "mob_id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1ad15863.9882a405.4edb54c4.62e0a104",
        "fps": 25,
        "start_tc": "17:28:34:00",
        "end_tc": "17:30:21:24",
        "length_frames": 2699,
        "length_tc": "00:01:47:24",
        "color": "#c14ba1",
        "color_rgb16": {
          "red": 49408,
          "green": 19200,
          "blue": 41216
        },
        "is_hide": false,
        "is_audio_only": true,
        "tracks": "A1-2",
        "source_path": "file:///C%3A/Users/cavan/Downloads/toolium_video_test/rushes/temp/4CH000M.wav",
        "source_file": "4CH000M.wav",
        "reel": "4CH000M",
        "user_comments": [
          {
            "TapeID": "4CH000M.wav"
          },
          {
            "Shoot Date": "2020-04-25 17:28:34"
          },
          {
            "Soundroll TC Rate": "25"
          },
          {
            "Camroll": "CAV_01_250420_SND001"
          }
        ],
        "creation_date": "2022-10-30T11:26:54.000Z",
        "last_modified": "2024-9-5T13:12:49.0Z"
      },
      "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1acffd99.9882a405.aa9054c4.62e0a104": {
        "type": "masterclip",
        "name": "4CH000I.wav.new.01",
        "mob_id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1acffd99.9882a405.aa9054c4.62e0a104",
        "fps": 25,
        "start_tc": "17:28:34:00",
        "end_tc": "17:30:21:24",
        "length_frames": 2699,
        "length_tc": "00:01:47:24",
        "color": "#7e3169",
        "color_rgb16": {
          "red": 32256,
          "green": 12544,
          "blue": 26880
        },
        "is_hide": false,
        "is_audio_only": true,
        "tracks": "A1-2",
        "source_path": "file:///D%3A/multicam%20test/CAV_01_250420_SND001/4CH/FOLDER01/4CH000I.wav",
        "source_file": "4CH000I.wav",
        "reel": "4CH000I",
        "user_comments": [
          {
            "TapeID": "4CH000I.wav"
          },
          {
            "Shoot Date": "2020-04-25 17:28:34"
          },
          {
            "Soundroll TC Rate": "25"
          },
          {
            "Camroll": "CAV_01_250420_SND001"
          }
        ],
        "creation_date": "2022-10-30T11:26:52.000Z",
        "last_modified": "2024-9-5T13:12:49.0Z"
      },
      "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104": {
        "type": "masterclip",
        "name": "A141C001_200425B8_CANON.new.01",
        "mob_id": "urn:smpte:umid:060a2b34.01010105.01010f10.13000000.1bbc58b9.9882a405.694554c4.62e0a104",
        "fps": 25,
        "start_tc": "17:27:59:13",
        "end_tc": "17:29:15:09",
        "length_frames": 1896,
        "length_tc": "00:01:15:21",
        "color": "#5846e5",
        "color_rgb16": {
          "red": 22528,
          "green": 17920,
          "blue": 58624
        },
        "is_hide": false,
        "is_audio_only": false,
        "tracks": "V1, A1-4",
        "source_path": "file:///C%3A/Users/cavan/Downloads/toolium_video_test/rushes/A141C001_200425B8_CANON.MXF",
        "source_file": "A141C001_200425B8_CANON.MXF",
        "reel": "A141C001_200425B8_CANON",
        "user_comments": [
          {
            "Camroll": "CAV_01_250420_A001"
          }
        ],
        "creation_date": "2022-10-30T11:32:3.0Z",
        "last_modified": "2024-9-5T13:12:49.0Z"
      }
    }
  }
};
/* eslint-enable quote-props,quotes,max-len */
export default lbin;
