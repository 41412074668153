// LICENSE_CODE TLM
import path from 'node:path';

let E = {};
export default E;

E.BYTE = 1;
E.KB = E.BYTE * 1000;
E.MB = E.KB * 1000;
E.GB = E.MB * 1000;

E.extname = (file, opt)=>{
  if (opt?.is_no_period)
    return path.extname(file).toLowerCase().slice(1);
  return path.extname(file).toLowerCase();
};

E.is_hidden = file=>file.startsWith('.'); // XXX: add win support using stat

E.basename = (_path, opt)=>{
  let _parse;
  if (opt?.is_win)
    _parse = path.win32.parse(_path);
  else
    _parse = path.parse(_path);
  if (opt?.is_no_suffix)
    return _parse.name;
  return _parse.base;
};
