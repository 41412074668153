// LICENSE_CODE
import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Row, Col, Button, Divider, Typography, Space, Image} from 'antd';
import Icon, {HistoryOutlined, FastForwardOutlined, RobotOutlined,
  DollarCircleOutlined, CheckCircleFilled} from '@ant-design/icons';
import {Clickable} from './comp.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import metric from './metric.js';
import logo_survivor from './assets/logo_survivor.png';
import logo_amazing_race from './assets/logo_amazing_race.svg';
import logo_masterchef from './assets/logo_masterchef.svg';
import logo_shark_tank from './assets/logo_shark_tank.svg';

let {Title, Text} = Typography;
const Home = ()=>{
  let {t} = useTranslation();
  let [country, set_country] = React.useState();
  React.useEffect(()=>{
    let es = eserf(function* _country_use_effect(){
      let res = yield back_app.ping();
      if (res.err)
        return void metric.error('ping_err', res.err);
      set_country(res.country);
    });
    return ()=>es.return();
  }, []);
  return <>
    <Row data-aos="fade-down" justify="space-around" gutter={[32, 32]}>
      <Col xs={{span: 20}} md={{span: 8, offset: 2}}>
        <Row>
          <Title>{t('Create group clips')}<br/>{t('made easy')}</Title>
        </Row>
        <Row>
          <Title level={2}>{t('free with Google connect')}</Title>
        </Row>
        <Row justify="center">
          <Clickable>
            <Button type="primary" size="large">
              <Link to={{pathname: '/grouper', search: location.search}}>
                {t('Free Get started')}
              </Link>
            </Button>
          </Clickable>
        </Row>
      </Col>
      <Col sm={{span: 24}} md={{span: 7, pull: 2}}>
        <iframe width="100%" height="100%" type="text/html"
          src="https://www.youtube.com/embed/Dh3Fk6sOmBs?autoplay=1&mute=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope;
          picture-in-picture" allowFullScreen>
        </iframe>
      </Col>
    </Row>
    <Row data-aos="fade-up" xdata-aos-anchor-placement="top-center">
      <Col xs={{span: 20, offset: 2}} md={{offset: 3, span: 18}}>
        <Space direction="vertical" size="large">
          <Row style={{textAlign: 'center'}}>
            <Divider/>
            <Title level={1}>
              {t('Toolium - The next generation of post production')}
            </Title>
            <Title level={5}>
              {t('Toolium is a unique online video editing platform. It has a '
                +'variety of automized tools for different aspects of the '
                +'post production process. From transcoding to grouping, fast '
                +'and simple, time is money.')}
            </Title>
          </Row>
        </Space>
      </Col>
    </Row>
    <Row data-aos="fade-up" data-aos-anchor-placement="top-center">
      <Col xs={{span: 20, offset: 2}} md={{span: 18}}>
        <Divider/>
        <Title level={1}>
          {t('Toolium Grouper')}
        </Title>
        <Title level={5}>
          {t('Automation tool for creating group clips and subclips')}
        </Title>
        <Title level={4}>
          {t('Features')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('Renames audio tracks within the group '
            +'clips')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('Creates the new avid multigroup type '
            +'groups')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('Creates avid sub-clips for shortened '
            +'clips')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('Groups are created with the full master '
          +'clips')}
        </Title>
        <br/>
        <Row justify="center">
          <Clickable>
            <Button type="primary" size="large">
              <Link to={{pathname: '/grouper', search: location.search}}>
                <Title level={4}>{t('Free Get started')}</Title>
              </Link>
            </Button>
          </Clickable>
        </Row>
      </Col>
    </Row>
    {false && <Row data-aos="fade-up" data-aos-anchor-placement="top-center">
      <Col xs={{span: 20, offset: 2}} md={{span: 18}}>
        <Divider/>
        <Title level={1}>
          {t('Toolium Syncer')}
        </Title>
        <Title level={5}>
          {t('Integrate us into your Avid Media Composer and we will auto '
          +'sync all your media in seconds')}
        </Title>
      </Col>
    </Row>}
    <Row data-aos="fade-up" data-aos-anchor-placement="top-center">
      <Col xs={{span: 20, offset: 2}} md={{span: 18}}>
        <Divider/>
        <Title level={1}>
          {t('Toolium Cutter')}
        </Title>
        <Title level={5}>
          {t('Get your edited sequences by highlighting text in a '
            +'transcription document')}
        </Title>
        <Title level={4}>
          {t('Features')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('From story producer to editor in one '
            +'minute')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('First of its kind - this instrument is '
            +'required in the world of reality series where there are '
            +'hundreds of hours of transcripts, and editing teams that sit to '
            +'cut selects according to the choices of the story producers.')}
        </Title>
        <br/>
        <Row justify="center">
          <Clickable>
            <Button type="primary" size="large">
              <Link to={{pathname: '/cutter', search: location.search}}>
                <Title level={4}>{t('Free Get started')}</Title>
              </Link>
            </Button>
          </Clickable>
        </Row>
      </Col>
    </Row>
    <Row data-aos="fade-up" data-aos-anchor-placement="top-center">
      <Col xs={{span: 20, offset: 2}} md={{span: 18}}>
        <Divider/>
        <Title level={1}>
          {t('Toolium Editor')}
        </Title>
        <Title level={5}>
          {t('Upload an AAF file and we will provide you an Avid Media '
            +'Composer compatible remote anywhere editor and afterwards '
            +'output you a grouped AAF file for Avid Media Composer')}
        </Title>
        <Title level={4}>
          {t('Features')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('Online all the time, access from any '
            +'device')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('Communicate directly in the system')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('A simplified proffessional editing '
            +'platform, with automized tools that makes work a '
            +' pleasure')}
        </Title>
        <Title level={5}>
          <CheckCircleFilled /> {t('Fully customizable keyboard shortcuts')}
        </Title>
        <br/>
        <Row justify="center">
          <Clickable>
            <Button type="primary" size="large">
              <Link to={{pathname: '/editor', search: location.search}}>
                <Title level={4}>{t('Free Get started')}</Title>
              </Link>
            </Button>
          </Clickable>
        </Row>
      </Col>
    </Row>
    <Row data-aos="fade-up" data-aos-anchor-placement="top-center">
      <Col xs={{span: 20, offset: 2}} md={{span: 18}}>
        <Divider/>
        <Title level={1}>
          {t('Join the best')}
        </Title>
        <Title level={5}>
          {t('You are in good hands.')}
        </Title>
        <Title level={5}>
          {t('These productions are already happy '
            +'Tooliumers')}
        </Title>
        <Row justify="space-around">
          <Image src={logo_survivor} height="64px" preview={false}/>
          <Image src={logo_amazing_race} height="64px" preview={false}/>
          <Image src={logo_masterchef} style={{filter: 'invert(1)'}}
            height="64px" preview={false}/>
          <Image src={logo_shark_tank} height="64px" preview={false}/>
        </Row>
      </Col>
    </Row>
    <div><Text style={{color: 'transparent'}}>x</Text></div>
    <div><Text style={{color: 'transparent'}}>x</Text></div>
    <div><Text style={{color: 'transparent'}}>x</Text></div>
    <br/>
  </>;
};

export default Home;
