// LICENSE_CODE TLM
import React, {useCallback, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Col, Row, Button, Typography, Result, Alert} from 'antd';
import auth from './auth.js';
import {Loading, use_qs, use_qs_clear} from './comp.js';
import je from '../../../util/je.js';
import eserf from '../../../util/eserf.js';
import rand from '../../../util/rand.js';
import back_app from './back_app.js';
import metric from './metric.js';
import config_ext from './config_ext.js';
import {toUpper} from 'lodash/fp.js';
import {PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer}
  from '@paypal/react-paypal-js';
import {purple} from '@ant-design/colors';

let email = config_ext.front.email;
let {Title, Text} = Typography;
export let Checkout = props=>{
  let [{options, isPending: is_pending}, dispatch] = usePayPalScriptReducer();
  let create_sub = (data, actions)=>{
    let plan_id = props.plan_id;
    // create_sub when using user_full and org from use_auth will have old
    // versions of them and not update and work with it as dep
    console.log('create_sub', data, actions);
    let _user_full = je.get('user');
    let _org = _user_full.orgs[_user_full.org_id_select];
    let custom_id = JSON.stringify({org_id: _org.id, user_id: _user_full.id,
      email: _user_full.email, plan_id});
    return actions.subscription.create({
      plan_id: 'P-55H94537N0642660FMYTCHWI', // standard sandbox
      custom_id,
    });
  };
  let create_capture = (data, actions)=>{
    // create_sub when using user_full and org from use_auth will have old
    // versions of them and not update and work with it as dep
    console.log('create_capture', data, actions);
    let _user_full = je.get('user');
    let _org = _user_full.orgs[_user_full.org_id_select];
    let custom_id = JSON.stringify({org_id: _org.id, user_id: _user_full.id,
      plan_id: props.plan_id});
    if (custom_id.length>127)
      metric.error('pay_custom_id_long_err', custom_id);
    let plan = props.plan;
    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [{
        amount: {
          value: (plan.cost.v*1.17).toFixed(2),
          currency: plan.cost.unit,
          breakdown: {
            item_total: {
              currency_code: plan.cost.unit, // USD
              value: plan.cost.v,
            },
            tax_total: {
              currency_code: plan.cost.unit,
              value: (plan.cost.v*0.17).toFixed(2),
            }
          }
        },
        reference_id: 'reference_id',
        description: props.plan_id,
        custom_id,
        invoice_id: 'invoiceid_'+rand.uuid(),
      }],
      payment_source: {
        paypal: {
          experience_context: {
            brand_name: config_ext.front.company_lbl,
          }
        },
        card: {
          experience_context: {
            payment_method_preference: 'IMMEDIATE_PAYMENT_REQUIRED',
            brand_name: config_ext.front.company_lbl,
            //locale: 'en-US',
            landing_page: 'BILLING',
            shipping_preference: 'SET_PROVIDED_ADDRESS',
            user_action: 'PAY_NOW',
            //return_url: 'https://example.com/returnUrl',
            //cancel_url: 'https://example.com/cancelUrl',
          }
        }
      }
    });
  };
  let on_approve_sub = (data, actions)=>eserf(function* _on_approve(){
    let {facilitatorAccessToken: access_token, orderID: order_id,
      paymentSource: pay_src, subscriptionID: sub_id} = data;
    console.log('on_approve_sub', data, actions);
    let _user_full = je.get('user');
    let _org = _user_full.orgs[_user_full.org_id_select];
    let res = yield back_app.org_pay_sub_success(props.token, _user_full.email,
      _org.id, order_id, 'paypal', pay_src, sub_id);
    if (res.err)
      metric.error('org_pay_success_err', _user_full.email, _org.id);
    je.set_inc('pay.update_n');
    //alert(data.subscriptionID);
    // You can add optional success message for the subscriber here
  });
  let on_approve_capture = (data, actions)=>eserf(function* _on_approve(){
    let {facilitatorAccessToken: access_token, orderID: order_id,
      paymentSource: pay_src, payerID: payer_id, paymentID: pay_id} = data;
    console.log('on_approve_capture', data, actions);
    let _user_full = je.get('user');
    let _org = _user_full.orgs[_user_full.org_id_select];
    let res = yield back_app.org_pay_capture_success(props.token,
      _user_full.email, _org.id, order_id, 'paypal', pay_src, payer_id, pay_id);
    if (res.err)
    {
      metric.error('org_pay_success_err', _user_full.email, _org.id);
      return void props.on_err(res);
    }
    je.set_inc('pay.update_n');
    props.on_success();
  });
  if (is_pending)
    return <Loading/>;
  if (0)
  {
    props.on_err({code: 'INSTRUMENT_DECLINED',
      desc: 'The instrument presented  was either declined by the '
      +'processor or bank, or it can\'t be used for this payment.'});
    return <></>;
  }
  // XXX: add onCancel and onError PayPalButtons
  return <>
    {props.is_sub &&
    <PayPalButtons style={{shape: 'rect', color: 'white',
      layout: 'vertical', label: 'subscribe'}}
    fundingSource="card"
    createSubscription={create_sub} onApprove={on_approve_sub}/>}
    {props.is_capture && <PayPalButtons style={{shape: 'rect', color: 'white',
      layout: 'vertical', label: 'subscribe'}}
    fundingSource="card" createOrder={create_capture}
    onApprove={on_approve_capture}/>}
  </>;
};

export let supp_lngs = {en: {country: 'gb'}, fr: {country: 'fr'},
  de: {country: 'de'}, it: {country: 'it'}, hi: {country: 'in'},
  bn: {country: 'bd'}, zh: {country: 'cn'}, es: {country: 'es'},
  pl: {country: 'pl'}, pt: {country: 'pt'}, nl: {country: 'nl'},
  sv: {country: 'se'}};

export let E = ()=>{
  let {t, i18n} = useTranslation();
  let {qs_o} = use_qs();
  use_qs_clear({plan_id: 1, plan: 1});
  let [is_payed, is_payed_set] = useState(false);
  let [is_fail, is_fail_set] = useState(qs_o.dbg_is_fail);
  let [code, code_set] = useState();
  let [desc, desc_set] = useState();
  let {token, user_full, org} = auth.use_auth();
  let on_success = useCallback(()=>{
    metric.conv_purchase();
    is_payed_set(true);
  }, []);
  let on_err = useCallback(({code: _code, desc: _desc})=>{
    code_set(code);
    desc_set(desc);
    is_fail_set(true);
  }, []);
  let [plans, plans_set] = React.useState({});
  React.useEffect(()=>{
    let es = eserf(function* _plan_use_effect(){
      let res = yield back_app.org_get_plan(true);
      if (res.err)
        metric.error('org_get_plan_err', res.err);
      plans_set(res);
    });
    return ()=>es.return();
  }, []);
  let plan = plans[qs_o.plan_id]||qs_o.plan;
  if (!org || !token || !user_full)
    return <Row justify="center"><Loading/></Row>;
  //XXX  colin this var decides if the payment method is 'subscription'
  // or 'capture'
  let is_sub = false;
  let plan_id = plan?.id;
  let supp_lng = supp_lngs[i18n.language];
  let locale = 'en_US'; // XXX: get language from i18n
  if (supp_lng)
    locale = i18n.language+'_'+supp_lng.country.toUpperCase();
  if (is_fail)
  {
    return <Row justify="center">
      <Result
        status="error"
        title={t('Your payment has failed')}
        subTitle={t(desc)}
        extra={[
          <Button type="primary" key="try_again" onClick={()=>{
            is_fail_set(false);
          }}>
            {t('Try again')}
          </Button>,
        ]}
      />
    </Row>;
  }
  return (
    <Row justify="center"><Col>
      <Row justify="center" style={{marginTop: 32}}>
        <Title level={1}>{t('Payment')}</Title></Row>
      <Row justify="center"><Title level={2} style={{marginTop: 16,
        color: purple.primary}}>
        <span>Click to pay</span>&nbsp;
        {`${plan?.cost?.v} ${toUpper(plan?.cost?.unit)}`} + {t('VAT')}
      </Title></Row>
      <Row justify="center" style={{marginTop: 16}}><Title level={4}>
        {`${t(plan?.lbl)}`}&nbsp;
          ({`${plan?.credit} ${t('Credits')}`})
      </Title></Row>
      <Row align="center" style={{marginBottom: 32}}><Text>
        {t('For any questions or issues')}&nbsp;
        <a href={'mailto:'+email}>
          <span style={{color: purple.primary}}>{email}</span></a>
      </Text></Row>
      {!is_payed && <>
        {is_sub &&
        <PayPalScriptProvider options={{intent: 'subscription',
          'client-id': config_ext.paypal.client_id, vault: true,
          locale: locale}}>
          <Checkout is_sub={true} token={token} org={org} plan={plan}/>
        </PayPalScriptProvider>}
        {!is_sub &&
          <PayPalScriptProvider options={{intent: 'capture', currency: 'USD',
            'client-id': config_ext.paypal.client_id, vault: true,
            locale: locale}}>
            <Checkout is_capture={true} token={token} org={org}
              plan_id={plan_id} plan={plan} on_success={on_success}
              on_err={on_err}/>
          </PayPalScriptProvider>}

      </>}
      {is_payed &&
        <Row><Col>
          <Row>
            <Result
              status="success"
              title={t('Successfully purchased plan!')}
              subTitle={t('Thank you for paying!')}
              extra={[
                <Button type="primary" key="start_using">
                  <Link to="/grouper">
                    {t('Start Grouping')}
                  </Link>
                </Button>,
              ]}
            />
          </Row>
        </Col></Row>}
    </Col></Row>);
};

export default auth.with_auth_req(E);
