// LICENSE_CODE TLM
import React, {useState, useCallback, useMemo} from 'react';
import {message, Form, Modal, Input, Layout, theme, Breadcrumb, Space, Radio,
  Button, Grid, Table, Typography} from 'antd';
import {RightOutlined, UnorderedListOutlined,
  AppstoreOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {use_qs, use_effect_eserf, Clickable} from './comp.js';
import assert from 'assert';
import back_app from './back_app.js';
import metric from './metric.js';
import {folder_size_get, bytes_format, File_name, File_card,
  File_preview} from './workspace.js';
import auth from './auth.js';
import str from '../../../util/str.js';
import eserf from '../../../util/eserf.js';
import xdate from '../../../util/date.js';
import xurl from '../../../util/xurl.js';

let Passwd_modal = React.memo(({is_open, on_close, proj_id, proj_link_id,
  on_proj_link_change, on_passwd_change, on_files_change})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [is_loading, is_loading_set] = useState(false);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err)
      return;
    is_loading_set(true);
    let proj_link_res = yield back_app.proj_link.get(proj_id, proj_link_id,
      values.passwd);
    is_loading_set(false);
    if (proj_link_res.err == 'forbidden')
      return form.setFields([{name: 'passwd', errors: [t('Wrong password')]}]);
    if (proj_link_res.err == 'inactive')
      return message_api.error(t('This link is unavailable'));
    if (proj_link_res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('proj_link_get_err', proj_link_res.err);
    }
    let files_res = yield back_app.proj_link.ls(proj_id, proj_link_id,
      values.passwd);
    if (files_res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('proj_link_ls_err', files_res.err);
    }
    on_proj_link_change(proj_link_res.proj_link);
    on_files_change(files_res.files);
    on_passwd_change(values.passwd);
    on_close();
  }), [form, message_api, on_close, proj_link_id, t, on_passwd_change,
    on_proj_link_change, on_files_change, proj_id]);
  return (
    <Modal title={t('Enter password')} open={is_open} closable={false}
      confirmLoading={is_loading} onOk={submit_handle}
      destroyOnClose footer={(origin_node, {OkBtn})=><OkBtn />}>
      {message_ctx_holder}
      <Form form={form} layout="vertical" preserve={false}
        initialValues={{passwd: ''}}
        onFinish={submit_handle}>
        <Form.Item name="passwd"
          rules={[{required: true, message: t('Please, input the password')},
            {max: 255, message: t('Password is too long')}]}>
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
});
let Content = React.memo(({path, on_path_change, proj_link, is_dir, proj_id,
  files})=>{
  let screens = Grid.useBreakpoint();
  let {token: {colorBgContainer: color_bg_container}} = theme.useToken();
  let {qs_o} = use_qs();
  let [time, time_set] = useState(Date.now());
  let {t} = useTranslation();
  let navigate = useNavigate();
  let [appearance, appearance_set] = useState('list');
  let title_click_handle = useCallback(()=>{
    if (!proj_id)
      return;
    on_path_change(str.mongo2path(proj_id), true);
  }, [on_path_change, proj_id]);
  let breadcrumb_items = useMemo(()=>{
    if (!proj_link || !path)
      return [];
    let _breadcrumb_items = [
      {title: <Clickable>
        <Button type="text" onClick={title_click_handle}
          style={{height: '42px'}}>
          <Typography.Title level={3} style={{margin: 0}}>
            {proj_link.lbl}
          </Typography.Title>
        </Button>
      </Clickable>},
    ];
    let arr = path.split('/');
    for (let idx = 4; idx < arr.length; idx += 1)
    {
      let lbl = str.mongo2path(arr[idx]);
      let sub_path = arr.slice(0, idx + 1).join('/');
      _breadcrumb_items.push({title: <Clickable>
        <Button type="text">
          {lbl}
        </Button>
      </Clickable>,
      onClick: ()=>on_path_change(sub_path, true)});
    }
    return _breadcrumb_items;
  }, [path, on_path_change, proj_link, title_click_handle]);
  let cols = useMemo(()=>{
    let _cols = [
      {title: t('Name'), key: 'name', dataIndex: 'name', ellipsis: true,
        sorter: (a, b)=>str.cmp(a.file.id, b.file.id), width: '40%',
        defaultSortOrder: 'ascend'},
    ];
    if (screens.lg)
    {
      _cols.push({title: t('Status'), key: 'status', dataIndex: 'status',
        sorter: (a, b)=>str.cmp(a.status, b.status)});
    }
    if (screens.md)
    {
      _cols.push({title: t('Size'), key: 'size', dataIndex: 'size',
        sorter: (a, b)=>{
          a = a.file.is_dir ? folder_size_get(a.file) : a.file.size;
          b = b.file.is_dir ? folder_size_get(b.file) : b.file.size;
          return b - a;
        }});
      _cols.push({title: t('Date uploaded'), key: 'date_uploaded',
        dataIndex: 'date_uploaded',
        sorter: (a, b)=>xdate.cmp(a.file.ts.insert, b.file.ts.insert)});
      _cols.push({title: t('Uploader'), key: 'uploader',
        dataIndex: 'uploader',
        sorter: (a, b)=>str.cmp(a.uploader, b.uploader)});
    }
    return _cols;
  }, [screens.lg, screens.md, t]);
  let cur_files = useMemo(()=>{
    let root_dir = {children: files};
    let cur_dir = root_dir;
    let arr = path.split('/').slice(4);
    for (let lbl of arr)
    {
      cur_dir = cur_dir.children[lbl];
      if (!cur_dir)
        break;
    }
    if (!cur_dir)
      cur_dir = root_dir;
    return cur_dir.children;
  }, [files, path]);
  let data_src = useMemo(()=>{
    let _data_src = Object.values(cur_files).map(file=>{
      let record = {file_id: file.id, status: '—', file};
      record.uploader = str.mongo2email(file.user_id).split('@')[0];
      record.date_uploaded = xdate.delta2lbl(
        (Date.now()-file.ts.insert.getTime())*xdate.SEC_MS, t) + ' ' + t('ago');
      let filename = str.mongo2path(file.id).split('/').pop();
      if (file.is_dir)
      {
        record.name = <File_name filename={filename} is_dir
          status={Object.keys(file.children).length + ' ' + t('items')} />;
        record.size = bytes_format(folder_size_get(file));
      }
      else
      {
        let status;
        if (file.is_tcode_run)
        {
          let remaining_time_ms = file.ts.insert.getTime() + file.tcode_time_est
            - Date.now();
          let remaining_time_sec = remaining_time_ms / xdate.MS_SEC;
          if (remaining_time_sec < 0)
            remaining_time_sec = 0;
          status = t('Preparing') + ' ・ ' + Math.round(remaining_time_sec) + ' '
            + t('seconds left');
        }
        record.name = <File_name filename={filename} src={file.src.pub_url}
          status={status} is_loading={file.is_tcode_run} />;
        record.size = bytes_format(file.size);
      }
      return record;
    });
    return _data_src;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cur_files, t, time]);
  use_effect_eserf(()=>eserf(function* use_effect_update_time(){
    while (1)
    {
      time_set(Date.now());
      yield eserf.sleep(1000);
    }
  }), []);
  let editor_qs_o_get = useCallback(file=>{
    if (!file)
      assert(0, 'no file');
    return {...qs_o, src: JSON.stringify(file.src), src_path: path,
      src_is_dir: is_dir};
  }, [is_dir, path, qs_o]);
  let open_aaf_in_highlighter = useCallback(file=>{
    if (!file)
      assert(0, 'no file');
    navigate(xurl.url('/highlighter', editor_qs_o_get(file)));
  }, [editor_qs_o_get, navigate]);
  let file_open = useCallback(file=>{
    if (file.is_upload || file.is_tcode_run)
      return;
    if (str.mongo2path(file.id).endsWith('.aaf'))
      return open_aaf_in_highlighter(file);
    on_path_change(str.mongo2path(file.id), file.is_dir);
  }, [on_path_change, open_aaf_in_highlighter]);
  let row_handle = useCallback(record=>{
    return {
      onDoubleClick: ()=>{
        file_open(record.file);
      },
    };
  }, [file_open]);
  let appearance_change_handle = useCallback(e=>{
    appearance_set(e.target.value);
  }, []);
  return (
    <Layout.Content style={{padding: 24, margin: 0, minHeight: 280,
      background: color_bg_container, color: 'white'}}>
      {proj_link && <Space direction="vertical" size="middle"
        style={{display: 'flex'}}>
        <div style={{display: 'flex', justifyContent: 'space-between',
          alignItems: 'center'}}>
          <Breadcrumb items={breadcrumb_items} separator={<RightOutlined />} />
          <Radio.Group value={appearance}
            onChange={appearance_change_handle}>
            <Radio.Button value="list">
              <UnorderedListOutlined />
            </Radio.Button>
            <Radio.Button value="icons">
              <AppstoreOutlined />
            </Radio.Button>
          </Radio.Group>
        </div>
        <Typography.Text type="secondary">
          {t('Shared by')} {str.mongo2email(proj_link.user_id).split('@')[0]}
        </Typography.Text>
        {appearance == 'list' && <Table columns={cols} dataSource={data_src}
          size="middle" onRow={row_handle} pagination={false}
          rowKey="file_id" rowClassName="workspace-table-row"
          showHeader={screens.md} />}
        {appearance == 'icons' && <div style={{display: 'flex',
          gap: '16px', flexWrap: 'wrap'}}>
          {data_src
            .sort((b, a)=>str.cmp(b.file.id, a.file.id))
            .map(record=>{
              let double_click_handle = ()=>{
                file_open(record.file);
              };
              return <File_card key={record.file.id} record={record}
                onDoubleClick={double_click_handle} />;
            })}
        </div>}
      </Space>}
    </Layout.Content>
  );
});
let E = ()=>{
  let [message_api, message_ctx_holder] = message.useMessage();
  let {qs_o, qs_set} = use_qs();
  let {t} = useTranslation();
  let {token, user_full} = auth.use_auth();
  let proj_id = useMemo(()=>qs_o.proj_id, [qs_o.proj_id]);
  let proj_link_id = useMemo(()=>qs_o.proj_link_id, [qs_o.proj_link_id]);
  let [proj_link, proj_link_set] = useState(null);
  let [files, files_set] = useState({});
  let [path, path_set] = useState(qs_o.path || '');
  let [is_dir, is_dir_set] = useState(qs_o.is_dir || false);
  let [is_passwd_modal_open, is_passwd_modal_open_set] = useState(false);
  let [passwd, passwd_set] = useState('');
  use_effect_eserf(()=>eserf(function* _use_effect_proj_link_get(){
    if (!proj_link_id)
      return;
    let proj_link_res = yield back_app.proj_link.get(proj_id, proj_link_id);
    if (proj_link_res.err == 'passwd_required')
      return is_passwd_modal_open_set(true);
    if (proj_link_res.err == 'inactive')
      return message_api.error(t('This link is unavailable'));
    if (proj_link_res.err == 'forbidden')
      return message_api.error(t('Access denied'));
    if (proj_link_res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('proj_link_get_err', proj_link_res.err);
    }
    proj_link_set(proj_link_res.proj_link);
    if (!path)
      path_change_handle(str.mongo2path(proj_id), true);
    let files_res = yield back_app.proj_link.ls(proj_id, proj_link_id);
    if (files_res.err == 'forbidden')
      return message_api.error(t('Access denied'));
    if (files_res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('proj_link_ls_err', files_res.err);
    }
    files_set(files_res.files);
  }), [qs_o.id]);
  let passwd_modal_close_handle = useCallback(()=>{
    is_passwd_modal_open_set(false);
  }, []);
  let path_change_handle = useCallback((_path, _is_dir)=>{
    path_set(_path);
    is_dir_set(_is_dir);
    qs_set({...qs_o, path: _path, is_dir: _is_dir});
  }, [qs_o, qs_set]);
  let is_file_preview = useMemo(()=>{
    if (!path)
      return false;
    return !is_dir;
  }, [is_dir, path]);
  let preview_file_inode = useMemo(()=>{
    if (!is_file_preview)
      return null;
    let queue = Object.values(files);
    let file_id = str.path2mongo(path);
    let _files = [];
    while (queue.length)
    {
      let file = queue.shift();
      if (file.id == file_id)
        return file.inode;
      _files.push(file);
      if (file.children)
        queue = [...queue, ...Object.values(file.children)];
    }
    return null;
  }, [files, is_file_preview, path]);
  let return_handle = useCallback(()=>{
    path_change_handle(path.split('/').slice(0, -1).join('/'), true);
  }, [path, path_change_handle]);
  return <>
    {message_ctx_holder}
    <Passwd_modal is_open={is_passwd_modal_open} proj_id={proj_id}
      on_close={passwd_modal_close_handle} proj_link_id={proj_link_id}
      on_proj_link_change={proj_link_set} on_passwd_change={passwd_set}
      on_files_change={files_set} />
    <Layout style={{minHeight: '90vh'}}>
      {is_file_preview && <File_preview on_return={return_handle}
        is_share inode={preview_file_inode} proj_link_id={proj_link_id}
        proj_link_passwd={passwd} token={token} user_full={user_full} />}
      {!is_file_preview && <Content path={path} files={files} is_dir={is_dir}
        on_path_change={path_change_handle} proj_link={proj_link}
        proj_id={proj_id} />}
    </Layout>
  </>;
};

export default E;
