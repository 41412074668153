// LICENSE_CODE TLM
import assert from 'assert';
import eserf from '../../../util/eserf.js';
import perf from '../../../util/perf.js';
import rand from '../../../util/rand.js';

let E = {};
export default E;
let latencies = {};

export let play = E.play = video_html=>eserf(function* _video_play(){
  let {currentSrc: curr_src, currentTime, duration} = video_html;
  // XXX: remove rand.uuid and play will be called multiple times on same src
  let perf_id = 'video_play__'+curr_src+'__'+rand.uuid();
  let video_id = 'video_play__'+curr_src;
  perf.start(perf_id, 25);
  let res = yield this.wait_ext2(video_html.play());
  let delta_ms = perf.stop(perf_id);
  if (res?.err)
  {
    let {code, message, name} = res.err;
    return {err: name, code, msg: message};
  }
  let latency = latencies[video_id];
  if (!latency)
    latency = latencies[video_id] = {delta_ms, count: 1};
  else
  {
    latency.count++;
    latency.delta_ms =
      (delta_ms+latency.delta_ms*(latency.count-1))/latency.count;
  }
  if (res)
    assert(0, 'video_res_not_null');
  return {};
});

export let pause = E.pause = video_html=>eserf(function* _video_pause(){
  let {currentSrc: curr_src, currentTime, duration} = video_html;
  let perf_id = 'video_play__'+curr_src+'__'+rand.uuid();
  perf.start(perf_id, 25);
  let res = yield this.wait_ext2(video_html.pause());
  perf.stop(perf_id);
  if (res?.err)
  {
    let {code, message, name} = res.err;
    return {err: name, code, msg: message};
  }
  if (res)
    assert(0, 'video_res_not_null');
  return {};
});

export let init = E.init = (src, video_time)=>eserf(function* _video_init(){
  let cancelled;
  this.finally(()=>{
    cancelled = true;
  });
  let video_html = document.createElement('video');
  video_html.preload = 'metadata';
  video_html.crossorigin = 'anonymous';
  video_html.autoplay = false;
  video_html.loop = false;
  video_html.src = src;
  let is_loadedmetadata;
  video_html.addEventListener('loadedmetadata', ()=>{
    is_loadedmetadata = true;
    if (cancelled)
    {
      E.destroy(video_html);
      return;
    }
    this.continue({is_loadedmetadata: true});
  }, {once: true});
  video_html.addEventListener('loadeddata', ()=>{
    if (!is_loadedmetadata)
      assert(0, 'loadedmetadata_err');
    if (cancelled)
      return E.destroy(video_html);
    this.continue({is_loadeddata: true});
  }, {once: true});
  video_html.addEventListener('error', ()=>{
    if (!is_loadedmetadata)
      assert(0, 'loadedmetadata_err');
    if (cancelled)
      return E.destroy(video_html);
    this.continue({err: video_html.error});
  }, {once: true});
  let res = yield this.wait(); // loadedmetadata
  if (!res.is_loadedmetadata)
    assert(0, 'video_init_err');
  video_html.currentTime = video_time;
  video_html.preload = 'auto';
  res = yield this.wait(); // loadeddata or error
  if (!res.is_loadeddata && !res.error)
    assert(0, 'video_init_err');
  if (res.err)
    return res;
  yield E.play(video_html);
  yield E.pause(video_html);
  return video_html;
});

export let destroy = E.destroy = video_html=>{
  video_html.removeAttribute('src');
  video_html.pause();
  video_html.load();
  video_html.remove();
};
