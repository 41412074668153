// LICENSE_CODE MIT
import {useAuth0, withAuthenticationRequired, Auth0Provider}
  from '@auth0/auth0-react';
import assert from 'assert';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Loading, use_focus, use_qs} from './comp.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import metric from './metric.js';
import je from '../../../util/je.js';
import xurl from '../../../util/xurl.js';
import {createClient} from '@supabase/supabase-js';
import config_ext from './config_ext.js';
import {Auth} from '@supabase/auth-ui-react';
import {ThemeSupa} from '@supabase/auth-ui-shared';
import {Row, Col, Button, Typography} from 'antd';
import {blue} from '@ant-design/colors';

let {Text, Title} = Typography;
let E = {};
// XXX colin: auth.js run before this code, so it needs to also have this code
let is_auth_auth0 = localStorage.getItem('is_auth_auth0');
if (is_auth_auth0)
  config_ext.is_sbase = false;
let is_sbase = config_ext.is_sbase;
let is_dark = true;
let theme = is_dark ? 'dark': 'light'

// eslint-disable-next-line
export let Auth0_provider = Auth0Provider;
E.Auth0_provider = Auth0_provider;
let ctx = React.createContext();
E.use_auth_ctx = E.useAuthCtx = ()=>React.useContext(ctx);

export let Auth_provider = E.Auth_provider = ({children, on_redirect_cb})=>{
  let [token, token_set] = useState();
  let [user_auth, user_auth_set] = useState();
  let value = {
    token,
    token_set,
    user_auth,
    user_auth_set,
    on_redirect_cb,
  };
  return <ctx.Provider value={value} >{children}</ctx.Provider>;
};

export let with_auth_ctx = E.with_auth_ctx = Wrapped_component=>{
  return props=>{
    let {token} = E.use_auth();
    if (!token)
      return <Loading />;
    return <Wrapped_component {...props} />;
  };
};

let sbase = {};
sbase.on_auth_change = cb=>{
  let {data: {subscription}} = client.auth.onAuthStateChange(
    (_event, _session)=>cb(_event, _session));
  return ()=>subscription.unsubscribe();
};

sbase.resend = (token, email)=>eserf(function* sbase_resend(){
  let res = yield this.wait_ext2(client.auth.resend({
    type: 'signup',
    email: email,
    //options: {
    //emailRedirectTo: config_ext.front.app.url,
    //}
  }));
  console.log(res);
  if (res.err)
    return res;
  let {data, error} = res;
  if (error)
    return {err: error.message, msg: res.err.message};
  return {...data};
});

let sbase_session; // saved in on_auth_change
sbase.session_get = ()=>eserf.uniq('sbase_session_get', function*
sbase_session_get(){
  let res;
  if (sbase_session)
    return sbase_session;
  while (true)
  {
    res = yield this.wait_ext2(client.auth.getSession());
    yield eserf.sleep(250);
    if (res?.err?.message=='Failed to execute \'invoke\' on '
      +'\'LockGrantedCallback\''
      +': The provided callback is no longer runnable.')
    {
      continue;
      // XXX colin: change message to msg auth0 uses message
      //return {err: 'sbase_not_ready', msg: res.err.message,
      //  message: res.err.message};
    }
    break;
  }
  if (res.err)
    return res;
  let {data, error} = res;
  if (error)
    return {err: error};
  if (data.session===null)
    return {err: 'no_session'};
  return data.session;
});

sbase.logout = ()=>eserf(function* sbase_logout(){
  let res = yield this.wait_ext2(client.auth.signOut());
  if (res.err)
    return res;
  let {error} = res;
  return {err: error};
});

// XXX colin: merge with back/util/sbase.js
let uiavatar_url = email=>{
  // XXX colin: use determistic color according to name
  return xurl.url('https://ui-avatars.com/api/',
    {name: email, format: 'svg', rounded: true, background: 'random'});
};

sbase.user_sbase2user_auth = user_sbase=>{
  let picture, name, email_verified;
  for (let identity of user_sbase.identities)
  {
    // XXX colin: get data from social
    let id_data = identity.identity_data;
    if (!picture)
      picture = id_data.picture;
    if (!name)
      name = id_data.name;
    if (!id_data.email_verified)
      continue;
    email_verified = id_data.email_verified;
  }
  let confirmed_at, email_confirmed_at;
  if (user_sbase.confirmed_at)
    confirmed_at = new Date(user_sbase.confirmed_at);
  if (user_sbase.email_confirmed_at)
    email_confirmed_at = new Date(user_sbase.email_confirmed_at);
  if (!email_verified && (confirmed_at || email_confirmed_at))
    email_verified = true;
  picture = picture||uiavatar_url(user_sbase.email);
  return {...user_sbase, name, picture, email_verified};
};

sbase.user_get = ()=>eserf(function* sbase_user_get(){
  let session = yield sbase.session_get();
  if (session.err)
    return session;
  return sbase.user_sbase2user_auth(session.user);
});

sbase.localization_get = t=>{
  /* eslint-disable quote-props,quotes,max-len */
  // this is taken from here
  // https://github.com/supabase/auth-ui/blob/main/packages/shared/src/localization/en.json
  let localization = {
    "sign_up": {
      "email_label": "Email address",
      "password_label": "Create a Password",
      "email_input_placeholder": "Your email address",
      "password_input_placeholder": "Your password",
      "button_label": "Signup Free",
      "loading_button_label": "Signing up ...",
      "social_provider_text": "Signup with {{provider}}",
      "link_text": "Don't have an account? Signup Free",
      "confirmation_text": "Check your email for the confirmation link"
    },
    "sign_in": {
      "email_label": "Email address",
      "password_label": "Your Password",
      "email_input_placeholder": "Your email address",
      "password_input_placeholder": "Your password",
      "button_label": "Login",
      "loading_button_label": "Signing in ...",
      "social_provider_text": "Login with {{provider}}",
      "link_text": "Already have an account? Login"
    },
    "magic_link": {
      "email_input_label": "Email address",
      "email_input_placeholder": "Your email address",
      "button_label": "Send Magic Link",
      "loading_button_label": "Sending Magic Link ...",
      "link_text": "Send a magic link email",
      "confirmation_text": "Check your email for the magic link"
    },
    "forgotten_password": {
      "email_label": "Email address",
      "password_label": "Your Password",
      "email_input_placeholder": "Your email address",
      "button_label": "Send reset password instructions",
      "loading_button_label": "Sending reset instructions ...",
      "link_text": "Forgot your password?",
      "confirmation_text": "Check your email for the password reset link"
    },
    "update_password": {
      "password_label": "New password",
      "password_input_placeholder": "Your new password",
      "button_label": "Update password",
      "loading_button_label": "Updating password ...",
      "confirmation_text": "Your password has been updated"
    },
    "verify_otp": {
      "email_input_label": "Email address",
      "email_input_placeholder": "Your email address",
      "phone_input_label": "Phone number",
      "phone_input_placeholder": "Your phone number",
      "token_input_label": "Token",
      "token_input_placeholder": "Your Otp token",
      "button_label": "Verify token",
      "loading_button_label": "Signing in ..."
    },
  };
  /* eslint-enable quote-props,quotes,max-len */
  let ret = {variables: {}};
  for (let _field in localization)
  {
    let o = localization[_field];
    for (let field in o)
    {
      if (!ret.variables[_field])
        ret.variables[_field] = {};
      let v = o[field];
      if (field=='social_provider_text' && _field=='sign_in')
      {
        ret.variables[_field][field] = t('Login with')+' {{provider}}';
        ret.variables[_field][field] = t('Login with')+' {{provider}}';
        continue;
      }
      if (field=='social_provider_text' && _field=='sign_up')
      {
        ret.variables[_field][field] = t('Signup with')+' {{provider}}';
        ret.variables[_field][field] = t('Signup with')+' {{provider}}';
        continue;
      }
      ret.variables[_field][field] = t(v);
      ret.variables[_field][field] = t(v);
    }
  }
  return ret;
};

// add error translations
// https://github.com/supabase/auth-ui/issues/86
/*
// * Supabase Auth Error Message Translation
useEffect(() => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type !== "childList" || mutation.addedNodes.length === 0)
        return;

      for (const node of mutation.addedNodes) {
        if (
          node instanceof HTMLElement &&
          (node.classList.contains("supabase-account-ui_ui-message") ||
            node.classList.contains("supabase-auth-ui_ui-message"))
        ) {
          const originErrorMessage = node.innerHTML.trim();

          let translatedErrorMessage = "<DEFAULT MESSAGE>";
          switch (originErrorMessage) {
            case "To signup, please provide your email":
              translatedErrorMessage = "";
              break;
            case "Signup requires a valid password":
              translatedErrorMessage = "";
              break;
            case "User already registered":
              translatedErrorMessage = "";
              break;
            case "Only an email address or phone number should be provided on signup.":
              translatedErrorMessage = "";
              break;
            case "Signups not allowed for this instance":
              translatedErrorMessage = "";
              break;
            case "Email signups are disabled":
              translatedErrorMessage = "";
              break;
            case "Email link is invalid or has expired":
              translatedErrorMessage = "";
              break;
            case "Token has expired or is invalid":
              translatedErrorMessage = "";
              break;
            case "The new email address provided is invalid":
              translatedErrorMessage = "";
              break;
            case "Password should be at least 6 characters":
              translatedErrorMessage = "";
              break;
            case "Invalid login credentials":
              translatedErrorMessage = "";
              break;
          }

          if (!document.querySelector("#auth-forgot-password")) {
            node.innerHTML = translatedErrorMessage;
          }
        }
      }
    });
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
}, []);
*/

sbase.appearance = {
  theme: ThemeSupa, variables: {
    default: {
      colors: {
        brand: blue.primary,
        brandAccent: blue[4],
      },
    },
  },
};

export let Sbase_signup = E.Sbase_signup = E.Sbase_signup = ()=>{
  let {t} = useTranslation();
  let {is_auth} = E.use_sbase();
  let {is_focus} = use_focus();
  let [is_show_resend, is_show_resend_set] = useState(false);
  let [email, email_set] = useState();
  let {qs_o} = use_qs();
  let user_email_resend = ()=>eserf(function* _user_email_resend(){
    yield sbase.resend(undefined, email);
  });
  useEffect(()=>{
    metric.set_tag('signup', 'true');
  }, []);
  useEffect(()=>{
    if (!is_auth)
      return;
    // XXX colin: delete return_to search param using useSearchParams
    return void je.set('auth.return_to', qs_o.return_to||'/');
  }, [is_auth]);
  useEffect(()=>{
    return;
    if (!is_focus)
      return;
    let es = eserf(function* sbase_signup_use_effect(){
      while (true)
      {
        yield eserf.sleep(250);
        let selectors = document.querySelectorAll(
          '.supabase-auth-ui_ui-message');
        if (!selectors.length)
        {
          //is_show_resend_set(false);
          continue;
        }
        if (selectors.length>1)
          metric.error('sbase_signup_too_many_ui_msg');
        let txt = selectors[0].innerText;
        if (txt!=t('Check your email for the confirmation link'))
          continue;
        let _email = document.querySelector('#email').value;
        email_set(_email);
        is_show_resend_set(true);
        // XXX colin: redirect to email_verif
        break;
      }
    });
    return ()=>es.return();
  }, [is_focus]);
  return <Row>
    <Col xs={{span: 22, offset: 1}} md={{span: 16, offset: 4}}>
      <Row style={{marginTop: 16}}>
        <Title className="f-bangers" level={3}>
          {t('Signup with Google to get 500 free credits')}
        </Title>
      </Row>
      <Row>
        <Text>
          {t('You will also get 10 additional free credits every day')}
        </Text>
      </Row>
      <Row><Col span={24}>
        <Auth supabaseClient={client} theme={theme} view={'sign_up'}
          appearance={sbase.appearance} providers={['google']}
          showLinks={true} redirectTo={config_ext.front.url+'/onboard'}
          localization={sbase.localization_get(t)}
        /></Col></Row>
      {is_show_resend && <Row><Col span={24}>
        <Button type="primary" onClick={user_email_resend}>
        {t('Resend verification email')}
      </Button></Col></Row>}
    </Col>
  </Row>;
};

export let Sbase_login = E.Sbase_login = E.SbaseLogin = ()=>{
  let {t} = useTranslation();
  let {is_auth} = E.use_sbase();
  let {qs_o} = use_qs();
  // XXX colin move to metric.js
  useEffect(()=>{
    metric.set_tag('login', 'true');
  }, []);
  useEffect(()=>{
    if (!is_auth)
      return;
    // XXX colin: redirect to where it was pass by prop
    return void je.set('auth.return_to', qs_o.return_to||'/');
  }, [is_auth]);
  return <Row>
    <Col xs={{span: 22, offset: 1}} md={{span: 16, offset: 4}}>
      <Row style={{marginTop: 16}}>
        <Title className="f-bangers" level={3}>
          {t('Signup with Google to get 500 free credits')}
        </Title>
      </Row>
      <Row>
        <Text>
          {t('You will also get 10 additional free credits every day')}
        </Text>
      </Row>
      <Row><Col span={24}>
        <Auth supabaseClient={client} theme={theme} view={'sign_in'}
          appearance={sbase.appearance} providers={['google']}
          showLinks={true} //redirectTo={config_ext.front.url+'/c/nina'}
          localization={sbase.localization_get(t)}
        /></Col></Row>
    </Col>
  </Row>;
};

let client = createClient(config_ext.sbase.url, config_ext.sbase.anon_pub);
export let with_auth_req = E.with_auth_req = Comp=>{
  if (is_sbase)
  {
    return props=>{
      let {token, token_set, user_auth, user_auth_set} = E.use_auth_ctx();
      let [is_loading, set_is_loading] = useState(true);
      let [is_auth, set_is_auth] = useState(false);
      useEffect(()=>{
        let es = eserf(function* with_auth_req_use_effect(){
          set_is_loading(true);
          let res = yield sbase.session_get();
          set_is_loading(false);
          if (res.err)
          {
            token_set(null);
            user_auth_set(null);
            assert(res.err=='no_session', 'cant get session');
            // XXX colin: handle when error and can't auth redirect to signup
            return void je.set('auth.is_redirect_signup', '/');
          }
          set_is_auth(true);
          let session = res;
          token_set(session.access_token);
          user_auth_set(sbase.user_sbase2user_auth(session.user));
        });
        return ()=>es.return();
      }, [token_set]);
      if (is_loading)
        return <Loading/>;
      if (!is_auth) // redirect happens here
        return <Loading/>;
      return <Comp/>;
    };
  }
  return withAuthenticationRequired(Comp, {onRedirecting: ()=><Loading />});
};

E.token_get = get_access_token_silently=>eserf.uniq('token_get', function*
_token_get(){
  if (is_sbase)
  {
    // sbase
    let session = yield sbase.session_get();
    if (session.err)
      return session;
    return {token: session.access_token};
  }
  // auth0
  get_access_token_silently().then(token=>this.continue({token}))
    .catch(err=>this.continue({err}));
  let ret = yield this.wait();
  if (ret?.err?.error=='login_required')
    return ret;
  if (ret.err)
    metric.error('auth_failed', ret.err);
  return ret;
});

let _update_n = 0;
export let use_auth = E.use_auth = E.useAuth = ()=>{
  if (is_sbase)
    return E.use_auth_sbase();
  return E.use_auth_auth0();
};

export let use_auth_sbase = E.use_auth_sbase = E.useAuthSbase = ()=>{
  let {token, user_auth, token_set, user_auth_set} = E.use_auth_ctx();
  let [is_auth, is_auth_set] = useState(false);
  let [user_full, user_full_set] = useState();
  let [org, org_set] = useState();
  let [update_n, update_n_set] = useState(0);
  useEffect(()=>{
    let listeners = [];
    listeners.push(je.on('auth.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('email_verif.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('onboard.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('pay.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('chat.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('char_select.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('create_char.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('profile.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('workspace.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    return ()=>{
      je.off(listeners);
    };
  }, []);
  useEffect(()=>{
    let es = eserf(function* _token_get(){
      let res = yield E.token_get();
      if (res.err)
        return;
      is_auth_set(true);
      token_set(res.token);
      let _user_sbase = yield sbase.user_get();
      user_auth_set(_user_sbase);
      let _user = yield back_app.user_get(res.token, _user_sbase.email);
      if (_user.err_data?.err=='email not verified')
      {
        je.set('auth.is_email_verif', false);
        return;
      }
      je.set('auth.is_email_verif', true);
      if (_user.err)
        _user = yield back_app.user_init(res.token, _user_sbase.email);
      // XXX colin: only happens when on route needing auth, but we
      // should get this also when we are authenticated on normal pages
      metric.set_id(_user.id, _user.org_id_select);
      user_full_set(_user);
      let _org = _user?.orgs[_user?.org_id_select];
      if (_org)
        org_set(_org);
      je.set('auth.is_onboard', !!_user?.flag?.is_onboard);
    });
    return ()=>es.return();
  }, [update_n]);
  return {user: user_auth, token, user_full, org, is_auth};
};
export let use_auth_auth0 = E.use_auth_auth0 = E.useAuthAuth0 = ()=>{
  let {user, getAccessTokenSilently: get_access_token_silently,
    isAuthenticated: is_auth} = useAuth0();
  let [token, token_set] = useState();
  let [user_full, user_full_set] = useState();
  let [org, org_set] = useState();
  let [update_n, update_n_set] = useState(0);
  useEffect(()=>{
    let listeners = [];
    listeners.push(je.on('auth.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('email_verif.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('onboard.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('pay.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('grouper.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('profile.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    listeners.push(je.on('workspace.update_n', ()=>{
      update_n_set(++_update_n);
    }, {init: false}));
    return ()=>{
      je.off(listeners);
    };
  }, []);
  useEffect(()=>{
    let es = eserf(function* _token_get(){
      let res = yield E.token_get(get_access_token_silently);
      if (res.err)
        return;
      if (!user)
        return;
      let _user = yield back_app.user_get(res.token, user.email);
      if (_user.err_data?.err=='email not verified')
      {
        je.set('auth.is_email_verif', false);
        return;
      }
      je.set('auth.is_email_verif', true);
      if (_user.err)
        _user = yield back_app.user_init(res.token, user.email);
      // XXX colin: only happens when on route needing auth, but we
      // should get this also when we are authenticated on normal pages
      metric.set_id(_user.id, _user.org_id_select);
      user_full_set(_user);
      let _org = _user?.orgs[_user?.org_id_select];
      if (_org)
        org_set(_org);
      token_set(res.token);
      je.set('auth.is_onboard', !!_user?.flag?.is_onboard);
    });
    return ()=>es.return();
  }, [update_n, user, get_access_token_silently]);
  return {user, token, user_full, org, is_auth};
};

export let use_sbase = E.use_sbase = E.useSbase = ()=>{
  let {token, user_auth, token_set, user_auth_set} = E.use_auth_ctx();
  let [error, error_set] = useState();
  let [is_loading, is_loading_set] = useState(true);
  let [is_auth, is_auth_set] = useState(false);
  useEffect(()=>{
    let es = eserf(function* _token_get(){
      is_loading_set(true);
      let res = yield E.token_get();
      let _user_sbase = yield sbase.user_get();
      is_loading_set(false);
      if (res.err)
      {
        is_auth_set(false);
        user_auth_set(null);
        error_set(res.err=='no_session' ? null : res.err);
        return;
      }
      is_auth_set(true);
      user_auth_set(_user_sbase);
      error_set(null);
    });
    return ()=>es.return();
  }, []);
  useEffect(()=>{
    return sbase.on_auth_change((_event, _session)=>{
      sbase_session = _session;
      is_auth_set(!!_session);
    });
  }, []);
  return {error, is_loading, is_auth, user: user_auth, logout: opt=>{
    return eserf(function* auth_logout(){
      // XXX colin: redirect using window.location.href = '/'
      je.delete('auth');
      yield sbase.logout();
      // XXX colin: use return_to/returnTo in index.js and app.js
      // je.set('auth.return_to', '/');
      window.location.href = '/';
    });
  }, login: ()=>{}, signup: redirect=>{
    je.set('auth.is_redirect_signup', redirect||'/');
  },
  };
};

export let use_auth_ext = E.use_auth_ext = E.useAuthExt = ()=>{
  if (is_sbase)
    return E.use_sbase();
  return E.use_auth0();
};

export let use_auth0 = E.use_auth0 = E.useAuth0 = ()=>{
  let {i18n} = useTranslation();
  let {user, isAuthenticated: _is_auth,
    loginWithRedirect: login_with_redirect, logout,
    isLoading: is_loading, error,
    getAccessTokenSilently: get_access_token_silently} = useAuth0();

  if (_is_auth)
    localStorage.setItem('is_auth_auth0', 'true');

  return {user,
    is_auth: _is_auth,
    login: ()=>{
      localStorage.setItem('is_auth_auth0', 'true');
      return login_with_redirect({authorizationParams: {
        ui_locales: i18n.language}});
    },
    logout: ({returnTo})=>eserf(function* auth0_logout(){
      localStorage.removeItem('is_auth_auth0');
      je.delete('auth');
      yield this.wait_ext2(logout({returnTo: window.origin}));
      //window.location.href = '/';
    }),
    is_loading, error, get_access_token_silently,
    signup: redirect_to=>{
      localStorage.setItem('is_auth_auth0', 'true');
      let obj = {authorizationParams: {
        screen_hint: 'signup',
        ui_locales: i18n.language}};
      if (redirect_to)
      {
        obj = {...obj, appState: {
          returnTo: redirect_to,
        }};
      }
      return login_with_redirect(obj);
    },
  };
};

export default E;
