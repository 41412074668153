// LICENSE_CODE MIT
import eserf from '../../../util/eserf.js';
import ereq from '../../../util/ereq.js';
import config_ext from './config_ext.js';

let E = {};
export default E;
let prefix = config_ext.back.tcoder.url;

// XXX: support error of tcoder down by showing screen to re-run
E.fs_ls = path=>eserf(function* back_tcoder_fs_ls(){
  let res = yield ereq.get(`${prefix}/pub/fs/ls.json`,
    {qs: {ver: config_ext.ver, path}});
  if (res.err)
    return {err: 'tcoder_down'};
  if (res.data.err)
    return res.data;
  return res.data.files;
});

E.fs_dirs_get = ()=>eserf(function* back_tcoder_fs_dirs_get(){
  let res = yield ereq.get(`${prefix}/pub/fs/dirs_get.json`,
    {qs: {ver: config_ext.ver}});
  if (res.err)
    return {err: 'tcoder_down'};
  if (res.data.err)
    return res.data;
  let {tmp_dir, root_dir, output_dir, ...rest} = res.data;
  return {...rest, tmp_dir, root_dir, output_dir};
});

E.fs_get = path=>eserf(function* back_tcoder_fs_get(){
  let res = yield ereq.get(`${prefix}/pub/fs/get.json`,
    {qs: {ver: config_ext.ver, path}});
  if (res.err)
    return {err: 'tcoder_down'};
  if (res.data.err)
    return res.data;
  return res.data;
});

E.fs_set = (path, data)=>eserf(function* back_tcoder_fs_set(){
  let res = yield ereq.post_upload(`${prefix}/pub/fs/set.json`,
    {qs: {ver: config_ext.ver, path}, data});
  if (res.err)
    return {err: 'tcoder_down'};
  if (res.data.err)
    return res.data;
  return res.data;
});

E.cmd = argv=>eserf(function* back_tcoder_cmd(){
  let res = yield ereq.get(`${prefix}/pub/tcoder/cmd.json`,
    {qs: {ver: config_ext.ver, argv}});
  if (res.err)
    return {err: 'tcoder_down'};
  if (res.data.err)
    return res.data;
  let {stdout, ...rest} = res.data;
  return {...rest, stdout};
});
