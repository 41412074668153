// LICENSE_CODE TLM
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Col, Row, Input, Avatar, Button, Space, Typography, Switch, Select,
  message} from 'antd';
import auth from './auth.js';
import xurl from '../../../util/xurl.js';
import xdate from '../../../util/date.js';
import eserf from '../../../util/eserf.js';
import je from '../../../util/je.js';
import back_app from './back_app.js';
import metric from './metric.js';
import {Loading} from './comp.js';
import config_ext from './config_ext.js';

let {Title, Text} = Typography;
export let E = ()=>{
  let {t} = useTranslation();
  let {token, user_full, org} = auth.use_auth();
  let [invite_loading, invite_loading_set] = useState(false);
  let [message_api, message_ctx_holder] = message.useMessage();
  let on_invite = React.useCallback(email_invite=>{
    let es = eserf(function* _on_invite(){
      invite_loading_set(true);
      this.finally(()=>invite_loading_set(false));
      let res = yield back_app.org_user_add(token, user_full.email,
        email_invite, org.id);
      if (res.err)
      {
        let err_id2str = {
          no_perm: t('No permission to invite a user to org'),
        };
        let err_s = err_id2str[res.err]||res.err;
        if (err_s==res.err)
          metric.error('perm_missing_err_id', err_s);
        else
          metric.error('org_user_add_err_'+res.err);
        message_api.error(err_s);
        return;
      }
      message_api.success(t('Sent invite to '+email_invite));
      // XXX: show error message
    });
    return ()=>es.return();
  }, [token, user_full, org]);
  if (!org || !token || !user_full)
    return <Loading/>;
  let user_ids = Object.keys(org.user_ids);
  return <Row justify="center"><Col>
    {message_ctx_holder}
    <Row justify="center">
      <Title>{t('ORGINIZATION USER PERMISSIONS')}</Title>
    </Row>
    <Row>
      <Col span={16}>
        <Row>
          <Col span={24}>
            <Input.Search size="large"
              placeholder={t('Email of user to invite to org')}
              onSearch={on_invite}
              loading={invite_loading}
              enterButton={<Button type="primary">Invite</Button>}/>
          </Col>
        </Row>
        {null && <Row>
          <Title level={4}>Pending invetations:</Title>
        </Row>}
        <Row>
          <Title level={4}>Users in org:</Title>
        </Row>
        <Row>
          {user_ids.map(user_id=>{
            return <Text key={user_id}>{user_id}</Text>;
          })}
        </Row>
      </Col>
    </Row>
  </Col></Row>;
};

export default auth.with_auth_req(E);
