// LICENSE_CODE MIT
import str from './str.js';
import xurl from './xurl.js';

let E = {};
export default E;
let storage = window.localStorage;

E.get = k=>storage.getItem(k);

E.get_bool = k=>{
  let ret = E.get(k);
  return !(ret===null || ret==='' || ret=='0' || ret=='false');
};

E.get_int = k=>{
  let ret = E.get(k);
  return +ret||0;
};

E.set = (k, v)=>storage.setItem(k, v);
E.get_json = k=>{
  let v = E.get(k);
  let json = xurl.json_parse(v);
  if (json !== undefined)
    return json;
  return v;
};
E.set_json = (k, v)=>storage.setItem(k, str.j2s(v));
E.remove = k=>storage.removeItem(k);

