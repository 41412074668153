// LICENSE_CODE TLM
import * as std_audio_ctx from 'standardized-audio-context';
import eserf from '../../../util/eserf.js';
import ereq from '../../../util/ereq.js';

let E = {};
export default E;

export let audio_ctx = E.audio_ctx = new std_audio_ctx.AudioContext();

E.decode_audio_data = array_buffer=>eserf(function*
audio_decode_audio_data(){
  let cancelled;
  this.finally(()=>{
    cancelled = true;
  });
  E.audio_ctx.decodeAudioData(array_buffer,
    audio_buffer=>{
      if (cancelled)
        return;
      this.continue(audio_buffer);
    },
    err=>{
      if (cancelled)
        return;
      this.continue({err});
    });
  return yield this.wait();
});
let audio_buffers_cache = {};
E.audio_buffer_get = src=>eserf(function* audio_audio_buffer_get(){
  if (audio_buffers_cache[src])
    return audio_buffers_cache[src];
  let res = yield ereq.get(src, {is_resp_buf: true});
  if (res.err)
    return {err: res.err};
  let audio_buffer = yield E.decode_audio_data(res.data);
  if (audio_buffer.err)
    return {err: audio_buffer.err};
  audio_buffers_cache[src] = audio_buffer;
  return audio_buffer;
});
E.audio_buffers_get = srcs=>eserf(function* audio_buffers_get(){
  if (!srcs.length)
    return [];
  let audio_buffers = yield this.wait_ret(srcs.map(E.audio_buffer_get));
  let err_res = audio_buffers.find(audio_buffer=>audio_buffer.err);
  if (err_res)
    return {err: err_res.err};
  return audio_buffers;
});
E.chunk_size = 0.5 * 1024 * 1024; // 1 MB
E.audio_buffer2chunk = (audio_buffer, cur_time)=>{
  if (cur_time < 0)
    cur_time = 0;
  let mid_idx = Math.floor(cur_time * E.audio_ctx.sampleRate);
  let start_idx = mid_idx - E.chunk_size / 2;
  let end_idx = mid_idx + E.chunk_size / 2;
  let chunk_length = end_idx - start_idx;
  let chunk_buffer = E.audio_ctx.createBuffer(1, E.chunk_size,
    E.audio_ctx.sampleRate);
  let ch = audio_buffer.getChannelData(0);
  let _ch = chunk_buffer.getChannelData(0);
  for (let i = 0; i < chunk_length; i++)
    _ch[i] = ch[start_idx + i] || 0;
  return chunk_buffer;
};
E.slice = (audio_buffer, start, end)=>{
  let _length = end - start;
  const _audio_buffer = E.audio_ctx.createBuffer(audio_buffer.numberOfChannels,
    _length, audio_buffer.sampleRate);
  for (let i = 0; i < audio_buffer.numberOfChannels; i++)
  {
    _audio_buffer.copyToChannel(
      audio_buffer.getChannelData(i).slice(start, end), i);
  }
  return _audio_buffer;
};
E.audio_buffer_clone = audio_buffer=>{
  const _audio_buffer = E.audio_ctx.createBuffer(audio_buffer.numberOfChannels,
    audio_buffer.length, audio_buffer.sampleRate);
  for (let i = 0; i < audio_buffer.numberOfChannels; i++)
    _audio_buffer.copyToChannel(audio_buffer.getChannelData(i), i);
  return _audio_buffer;
};
E.mix = (audio_buffers, offsets_sec)=>{
  if (!offsets_sec)
    offsets_sec = Array.from({length: audio_buffers.length}).fill(0);
  let offsets_sample = offsets_sec.map(s=>s * E.audio_ctx.sampleRate);
  let final_length = Math.max(...audio_buffers.map((buffer, idx)=>{
    return buffer.length - offsets_sample[idx];
  }));
  let audio_buffer = E.audio_ctx.createBuffer(1, final_length,
    E.audio_ctx.sampleRate);
  let chs = audio_buffers.map(buffer=>buffer.getChannelData(0));
  let ch = audio_buffer.getChannelData(0);
  for (let sample_idx = 0; sample_idx < audio_buffer.length; sample_idx++)
  {
    let sum = 0;
    for (let buffer_idx = 0; buffer_idx < audio_buffers.length; buffer_idx++)
      sum += chs[buffer_idx][sample_idx + offsets_sample[buffer_idx]] || 0;
    ch[sample_idx] = sum / audio_buffers.length;
  }
  return audio_buffer;
};
E.reverse = audio_buffer=>{
  let _audio_buffer = E.audio_ctx.createBuffer(audio_buffer.numberOfChannels,
    audio_buffer.length, E.audio_ctx.sampleRate);
  for (let i = 0; i < _audio_buffer.numberOfChannels; i++)
  {
    let channel = audio_buffer.getChannelData(i);
    let _channel = _audio_buffer.getChannelData(i);
    for (let j = 0; j < audio_buffer.length; j++)
      _channel[j] = channel[audio_buffer.length - 1 - j];
  }
  return _audio_buffer;
};
E.play = (audio_buffer, start_time=0, playback_rate=1,
  dest=E.audio_ctx.destination)=>{
  if (!audio_buffer)
    return;
  if (playback_rate <= 0)
    return {err: 'playback rate must be positive'};
  audio_ctx.resume();
  let src_buffer = E.audio_ctx.createBufferSource();
  src_buffer.buffer = audio_buffer;
  src_buffer.playbackRate.value = playback_rate;
  src_buffer.connect(dest);
  src_buffer.start(0, start_time);
  return src_buffer;
};
E.bytes2sec = bytes=>{
  return bytes / E.audio_ctx.sampleRate;
};
E.sec2bytes = sec=>{
  return sec * E.audio_ctx.sampleRate;
};
let interpolate = (a, b, fraction)=>{
  return a + (b - a) * fraction;
};
E.stretch = (audio_buffer, ratio)=>{
  if (ratio == 1)
    return audio_buffer;
  let stretched_length = Math.round(audio_buffer.length * ratio);
  let stretched_audio_buffer = E.audio_ctx.createBuffer(
    audio_buffer.numberOfChannels, stretched_length, E.audio_ctx.sampleRate);
  let ch = audio_buffer.getChannelData(0);
  let stretched_ch = stretched_audio_buffer.getChannelData(0);
  for (let i = 0; i < stretched_audio_buffer.length; i++)
  {
    let orig_idx = i / ratio;
    let low_idx = Math.floor(orig_idx);
    let high_idx = Math.ceil(orig_idx);
    let fraction = orig_idx - low_idx;
    let value = interpolate(ch[low_idx], ch[high_idx], fraction);
    stretched_ch[i] = value;
  }
  return stretched_audio_buffer;
};
