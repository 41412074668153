// LICENSE_CODE TLM

let E = {};
export default E;

E.img_draw = (ctx, img_src, x, y, w, h)=>{
  let img = new Image();
  img.src = img_src;
  ctx.drawImage(img, x, y, w, h);
};

E.video_draw = (ctx, html_video_el, x, y, w, h)=>{
  ctx.drawImage(html_video_el, x, y, w, h);
};

E.rect_draw = (ctx, x, y, w, h, color)=>{
  ctx.fillStyle = color;
  ctx.fillRect(x, y, w, h);
};

E.color_fill = (ctx, color)=>{
  E.rect_draw(ctx, 0, 0, ctx.canvas.width, ctx.canvas.height, color);
};
