// LICENSE_CODE TLM
let E = {};
export default E;

E.init = ()=>{};
E.uninit = ()=>{};

const svg_ns = 'http://www.w3.org/2000/svg';

let rotate = (x_o, y_o, x, y, angle)=>{
  let radians = angle * Math.PI / 180;
  let cos = Math.cos(radians);
  let sin = Math.sin(radians);
  let nx = cos * (x - x_o) + sin * (y - y_o) + x_o;
  let ny = cos * (y - y_o) - sin * (x - x_o) + y_o;
  return [nx, ny];
};
let obj2element = obj=>{
  if (obj.type == 'line')
  {
    let element = document.createElementNS(svg_ns, 'path');
    element.setAttribute('d', `M ${obj.x1} ${obj.y1} L ${obj.x2} ${obj.y2}`);
    element.setAttribute('stroke', obj.color);
    element.setAttribute('stroke-width', obj.stroke_width);
    return element;
  }
  if (obj.type == 'circle')
  {
    let element = document.createElementNS(svg_ns, 'circle');
    element.setAttribute('cx', obj.x);
    element.setAttribute('cy', obj.y);
    element.setAttribute('r', obj.r);
    element.setAttribute('fill', obj.color);
    return element;
  }
  if (obj.type == 'arrow')
  {
    let element = document.createElementNS(svg_ns, 'path');
    let dx = obj.x2 - obj.x1;
    let dy = obj.y2 - obj.y1;
    let dr = Math.sqrt(dx * dx + dy * dy);
    let x0 = 10;
    let y01 = 0.5;
    let y02 = 1.5;
    let y01_ratio = y01 / x0;
    let y02_ratio = y02 / x0;
    let x_middle = obj.x1 + 0.7 * dr;
    let angle = Math.atan2(dy, dx) * 180 / Math.PI;
    let [xb, yb] = rotate(obj.x1, obj.y1, x_middle, obj.y1 + y01_ratio * dr,
      -angle);
    let [xc, yc] = rotate(obj.x1, obj.y1, x_middle, obj.y1 + y02_ratio * dr,
      -angle);
    let [xe, ye] = rotate(obj.x1, obj.y1, x_middle, obj.y1 - y02_ratio * dr,
      -angle);
    let [xf, yf] = rotate(obj.x1, obj.y1, x_middle, obj.y1 - y01_ratio * dr,
      -angle);
    element.setAttribute('d',
      // eslint-disable-next-line max-len
      `M ${obj.x1} ${obj.y1} L ${xb} ${yb} L ${xc} ${yc} L ${obj.x2} ${obj.y2} L ${xe} ${ye} L ${xf} ${yf} L ${obj.x1} ${obj.y1} Z`,
    );
    element.setAttribute('fill', obj.color);
    return element;
  }
  if (obj.type == 'rect')
  {
    let element = document.createElementNS(svg_ns, 'rect');
    let width = Math.abs(obj.x1 - obj.x2);
    let height = Math.abs(obj.y1 - obj.y2);
    let x = Math.min(obj.x1, obj.x2);
    let y = Math.min(obj.y1, obj.y2);
    element.setAttribute('x', x);
    element.setAttribute('y', y);
    element.setAttribute('width', width);
    element.setAttribute('height', height);
    element.setAttribute('rx', 3);
    element.setAttribute('stroke', obj.color);
    element.setAttribute('stroke-width', obj.stroke_width);
    return element;
  }
  return null;
};
let max_x_get = objs=>{
  if (!objs.length)
    return 0;
  let x_arr = objs.map(obj=>{
    if (obj.type == 'line')
      return Math.max(obj.x1, obj.x2);
    if (obj.type == 'circle')
      return obj.x + obj.r;
    return 0;
  });
  return Math.max(...x_arr);
};
let max_y_get = objs=>{
  if (!objs.length)
    return 0;
  let y_arr = objs.map(obj=>{
    if (obj.type == 'line')
      return Math.max(obj.y1, obj.y2);
    if (obj.type == 'circle')
      return obj.y + obj.r;
    return 0;
  });
  return Math.max(...y_arr);
};

E.compose = (objs, view_box_x, view_box_y)=>{
  let max_x = max_x_get(objs);
  let max_y = max_y_get(objs);
  let svg = document.createElementNS(svg_ns, 'svg');
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  svg.setAttribute('fill', 'none');
  svg.setAttribute('width', max_x);
  svg.setAttribute('height', max_y);
  svg.setAttribute('viewBox',
    `0 0 ${view_box_x || max_x} ${view_box_y || max_y}`);
  objs.forEach(obj=>{
    svg.append(obj2element(obj));
  });
  return svg.outerHTML;
};
