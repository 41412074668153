// LICENSE_CODE TLM
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Typography, Modal, Input, Avatar, Button, Form, Select,
  Tooltip, Dropdown, Checkbox, Space, Divider, Drawer, TimePicker,
  InputNumber, Popconfirm} from 'antd';
import {ArrowLeftOutlined, SearchOutlined, PlusOutlined, MoreOutlined,
  SendOutlined, AudioOutlined, PushpinOutlined, EditOutlined, CheckOutlined,
  PaperClipOutlined, DownOutlined, CloseCircleOutlined, DoubleRightOutlined,
  CheckCircleOutlined, DeleteOutlined, AudioFilled} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {gray, purple, blue} from '@ant-design/colors';
import speech2el from 'speech-to-element';
import ISO6391 from 'iso-639-1';
import dayjs from 'dayjs';
import eserf from '../../../util/eserf.js';
import xdate from '../../../util/date.js';
import tc from '../../../util/tc.js';
import je from '../../../util/je.js';
import xurl from '../../../util/xurl.js';
import metric from './metric.js';
import config_ext from './config_ext.js';
import back_app from './back_app.js';
import auth from './auth.js';
import player from './player.js';
import {download} from './comp.js';
import {supp_lngs} from './app.js';

let fps_choices = [24, 25, 29.97, 30, 48, 50, 59.94, 60];
let {Text} = Typography;
let {TextArea} = Input;
let prefix = config_ext.back.app.url;
let camera_trans = {};
camera_trans.lbl2id = lbl=>lbl.trim().toLowerCase().replace(/\s+/ug, '_');
camera_trans.arr2obj = camera_lbls=>{
  if (!Array.isArray(camera_lbls) || !camera_lbls.length)
    return {};
  return camera_lbls
    .map(lbl=>({id: camera_trans.lbl2id(lbl), lbl}))
    .reduce((accum, camera)=>({...accum, [camera.id]: camera}), {});
};
camera_trans.obj2arr = cameras_obj=>{
  if (typeof cameras_obj != 'object')
    return [];
  return Object.values(cameras_obj).map(obj=>obj.lbl);
};
camera_trans.arr2indicator = camera_lbls=>{
  if (!Array.isArray(camera_lbls) || !camera_lbls.length)
    return {};
  return camera_lbls
    .reduce((accum, lbl)=>({...accum, [camera_trans.lbl2id(lbl)]: true}), {});
};
camera_trans.indicator2arr = (indicator, cameras)=>{
  if (typeof indicator != 'object' || typeof cameras != 'object')
    return [];
  return Object.keys(indicator)
    .map(camera_id=>{
      let camera = cameras[camera_id];
      if (!camera)
        return null;
      return camera.lbl;
    })
    .filter(Boolean);
};
let Premium_modal = React.memo(({is_open, close_handle})=>{
  let {t} = useTranslation();
  return (
    <Modal
      title={t('This is a premium feature, contact us at support@toolium.org')}
      open={is_open} onOk={close_handle} onCancel={close_handle} />
  );
});
let Log_settings_modal = React.memo(({token, is_open, close_handle,
  user_refetch, org})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [is_loading, is_loading_set] = useState(false);
  let [cameras, cameras_set] = useState(['A', 'B', 'C', 'Audio', 'SND']);
  let [camera_name, camera_name_set] = useState('');
  let initial_values = useMemo(()=>{
    if (!org || !org.log)
      return {fps: '', hide_scene_take: false, cameras: [], add_members: false};
    return {fps: org.log.fps, hide_scene_take: org.log.hide_scene_take,
      cameras: camera_trans.obj2arr(org.log.cameras), add_members: false};
  }, [org]);
  let change_handle = useCallback(e=>camera_name_set(e.target.value), []);
  let camera_add_handle = useCallback(e=>{
    e.preventDefault();
    let lbl = camera_name.trim();
    if (!lbl)
      return;
    cameras_set([...cameras, lbl]);
    camera_name_set('');
  }, [camera_name, cameras]);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    this.continue(form.validateFields());
    let values = yield this.wait();
    is_loading_set(true);
    let resps = yield this.wait_ret([
      back_app.log.setting_set(token, 'fps', values.fps),
      back_app.log.setting_set(token, 'cameras',
        camera_trans.arr2obj(values.cameras)),
      back_app.log.setting_set(token, 'hide_scene_take',
        values.hide_scene_take),
    ]);
    let err_res = resps.find(res=>res.err);
    // XXX Vladimir: show ui message
    if (err_res)
      return;
    is_loading_set(false);
    yield user_refetch();
    close_handle();
    form.resetFields();
  }), [close_handle, form, token, user_refetch]);
  useEffect(()=>form.setFieldsValue(initial_values), [form, initial_values]);
  return (
    <Modal title={t('Logger default settings')} open={is_open}
      onOk={submit_handle} onCancel={close_handle} confirmLoading={is_loading}>
      <Form form={form} layout="vertical" initialValues={initial_values}>
        <Form.Item label={t('Framerate')} name="fps">
          <Select options={fps_choices.map(fps=>({label: fps, value: fps}))}
            style={{width: 300}} />
        </Form.Item>
        <Form.Item label={t('Camera List')} name="cameras">
          <Select mode="multiple" style={{width: 300}}
            options={cameras.map(item=>({label: item, value: item}))}
            dropdownRender={menu=><>
              {menu}
              <Divider style={{margin: '8px 0'}} />
              <Space style={{padding: '0 8px 4px'}}>
                <Input placeholder={t('Camera name')} value={camera_name}
                  onChange={change_handle} />
                <Button type="text" icon={<PlusOutlined />}
                  onClick={camera_add_handle}>
                  {t('Add camera')}
                </Button>
              </Space>
            </>} />
        </Form.Item>
        <Form.Item name="hide_scene_take" valuePropName="checked">
          <Checkbox disabled>{t('Hide scene / take')}</Checkbox>
        </Form.Item>
        <Form.Item name="add_members" valuePropName="checked">
          <Checkbox disabled>
            {t('Automatically add members (premium feature)')}
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
});
let New_log_modal = React.memo(({token, is_open, close_handle, log_add,
  user_full, log_select, drawer_close, org})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [is_loading, is_loading_set] = useState(false);
  let [cameras, cameras_set] = useState([]);
  let [camera_name, camera_name_set] = useState('');
  let initial_values = useMemo(()=>{
    if (!org || !org.log)
      return {lbl: '', fps: '', hide_scene_take: false, cameras: []};
    return {lbl: '', fps: org.log.fps, hide_scene_take: org.log.hide_scene_take,
      cameras: camera_trans.obj2arr(org.log.cameras)};
  }, [org]);
  let change_handle = useCallback(e=>{
    camera_name_set(e.target.value);
  }, []);
  let camera_add_handle = useCallback(e=>{
    e.preventDefault();
    let lbl = camera_name.trim();
    if (!lbl)
      return;
    cameras_set([...cameras, lbl]);
    camera_name_set('');
  }, [camera_name, cameras]);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    this.continue(form.validateFields());
    let values = yield this.wait();
    is_loading_set(true);
    let res = yield back_app.log.insert(token, values.lbl, [user_full.id],
      values.fps, camera_trans.arr2obj(values.cameras), values.hide_scene_take);
    is_loading_set(false);
    // XXX Vladimir: show ui message
    if (res.err)
      return;
    log_add(res.log);
    log_select(res.log);
    close_handle();
    drawer_close();
    form.resetFields();
  }), [close_handle, form, log_add, log_select, token, user_full,
    drawer_close]);
  useEffect(()=>{
    form.setFieldsValue(initial_values);
    cameras_set(initial_values.cameras);
  }, [form, initial_values]);
  return (
    <Modal title={t('New Log')} open={is_open} confirmLoading={is_loading}
      onOk={submit_handle} onCancel={close_handle}>
      <Form form={form} layout="vertical" initialValues={initial_values}>
        <Form.Item label={t('Name')} name="lbl"
          rules={[{required: true, message: t('Please, input the name')}]}>
          <Input />
        </Form.Item>
        <Form.Item label={t('Framerate')} name="fps"
          rules={[{required: true, message: t('Please, select the fps')}]}>
          <Select options={fps_choices.map(fps=>({label: fps, value: fps}))}
            style={{width: 300}} />
        </Form.Item>
        <Form.Item label={t('Camera List')} name="cameras">
          <Select mode="multiple" style={{width: 300}}
            options={cameras.map(item=>({label: item, value: item}))}
            dropdownRender={menu=><>
              {menu}
              <Divider style={{margin: '8px 0'}} />
              <Space style={{padding: '0 8px 4px'}}>
                <Input placeholder={t('Camera name')}
                  value={camera_name} onChange={change_handle} />
                <Button type="text" icon={<PlusOutlined />}
                  onClick={camera_add_handle}>
                  {t('Add camera')}
                </Button>
              </Space>
            </>} />
        </Form.Item>
        <Form.Item name="hide_scene_take" valuePropName="checked">
          <Checkbox disabled>{t('Hide scene / take')}</Checkbox>
        </Form.Item>
        <Form.Item name="add_members" valuePropName="checked">
          <Checkbox disabled>
            {t('Automatically add members (premium feature)')}
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
});
let Export_log_modal = React.memo(({is_open, close_handle, token,
  selected_log, user})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [is_loading, is_loading_set] = useState(false);
  let fmts = useMemo(()=>[
    {label: t('CSV file'), value: 'csv'},
    {label: t('Avid Media Composer Markers'), value: 'avid', disabled: true},
    {label: t('XLS file'), value: 'xls', disabled: true},
    {label: t('DaVinci Resolve EDL'), value: 'davinci', disabled: true},
  ], [t]);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    this.continue(form.validateFields());
    let values = yield this.wait();
    is_loading_set(true);
    let res = yield back_app.log.export(token, selected_log._id, values.fmt);
    is_loading_set(false);
    if (res.err)
      return;
    let download_url = xurl.url(prefix+'/private/user/log/get.csv',
      {email: user.email, file: res.file, token, ver: config_ext.ver});
    download(download_url);
  }), [form, selected_log, token, user]);
  return (
    <Modal title={t('Export Log')} open={is_open} confirmLoading={is_loading}
      onOk={submit_handle} onCancel={close_handle}>
      <Form form={form} layout="vertical"
        initialValues={{fmt: fmts[0].value}}>
        <Form.Item label={t('Export format')} name="fmt"
          rules={[{required: true,
            message: t('Please, select the export format')}]}>
          <Select options={fmts} style={{width: 300}} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
let Log_tile = React.memo(({src, lbl, is_selected, style, ...rest})=>{
  let [is_hover, is_hover_set] = useState(false);
  let mouse_enter_handle = useCallback(()=>is_hover_set(true), []);
  let mouse_leave_handle = useCallback(()=>is_hover_set(false), []);
  let bg = useMemo(()=>{
    if (is_selected)
      return purple.primary;
    if (is_hover)
      return gray[7];
    return gray[8];
  }, [is_hover, is_selected]);
  return (
    <div {...rest} style={{display: 'flex', cursor: 'pointer',
      transition: '0.1s ease', background: bg, ...style}}
    onMouseEnter={mouse_enter_handle} onMouseLeave={mouse_leave_handle}>
      <div style={{padding: '12px'}}>
        <Avatar>LOG</Avatar>
      </div>
      <div style={{display: 'flex', alignItems: 'center',
        borderBottom: `1px solid ${gray[6]}`, width: '100%'}}>
        <Text>{lbl}</Text>
      </div>
    </div>
  );
});
let Log_info_modal = React.memo(({is_open, close_handle, log, token,
  log_update, log_select, pins_set})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [is_loading, is_loading_set] = useState(false);
  let [cameras, cameras_set] = useState([]);
  let [camera_name, camera_name_set] = useState('');
  let initial_values = useMemo(()=>{
    if (!log)
      return {lbl: '', cameras: [], hide_scene_take: false};
    return {lbl: log.lbl, hide_scene_take: log.hide_scene_take,
      cameras: camera_trans.obj2arr(log.cameras)};
  }, [log]);
  let change_handle = useCallback(e=>{
    camera_name_set(e.target.value);
  }, []);
  let camera_add_handle = useCallback(e=>{
    e.preventDefault();
    let lbl = camera_name.trim();
    if (!lbl)
      return;
    cameras_set([...cameras, lbl]);
    camera_name_set('');
  }, [camera_name, cameras]);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    this.continue(form.validateFields());
    let values = yield this.wait();
    is_loading_set(true);
    let res = yield back_app.log.set(token, log._id, values.lbl,
      camera_trans.arr2obj(values.cameras), values.hide_scene_take);
    is_loading_set(false);
    // XXX vladimir: show ui message
    if (res.err)
      return;
    log_update(res.log);
    log_select(res.log);
    pins_set(res.pins);
    close_handle();
    form.resetFields();
  }), [close_handle, form, log._id, log_select, log_update, token, pins_set]);
  useEffect(()=>{
    form.setFieldsValue(initial_values);
    cameras_set(initial_values.cameras);
  }, [form, initial_values]);
  return (
    <Modal title={t('Log info')} open={is_open} confirmLoading={is_loading}
      onOk={submit_handle} onCancel={close_handle}>
      <Form form={form} layout="vertical" initialValues={initial_values}>
        <Form.Item label={t('Name')} name="lbl"
          rules={[{required: true, message: t('Please, input the name')}]}>
          <Input />
        </Form.Item>
        <Form.Item label={t('Camera List')} name="cameras">
          <Select mode="multiple" style={{width: 300}}
            options={cameras.map(item=>({label: item, value: item}))}
            dropdownRender={menu=><>
              {menu}
              <Divider style={{margin: '8px 0'}} />
              <Space style={{padding: '0 8px 4px'}}>
                <Input placeholder={t('Camera name')}
                  value={camera_name} onChange={change_handle} />
                <Button type="text" icon={<PlusOutlined />}
                  onClick={camera_add_handle}>
                  {t('Add camera')}
                </Button>
              </Space>
            </>} />
        </Form.Item>
        <Form.Item name="hide_scene_take" valuePropName="checked">
          <Checkbox disabled>{t('Hide scene / take')}</Checkbox>
        </Form.Item>
        <Form.Item name="add_members" valuePropName="checked">
          <Checkbox disabled>
            {t('Automatically add members (premium feature)')}
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
});
let Logs_list_drawer = React.memo(({logs, user, new_log_modal_open,
  settings_modal_open, log_select, selected_log, is_open, close_handle})=>{
  let {t} = useTranslation();
  let dropdown_items = useMemo(()=>[
    {key: 'default_settings', label: t('Logger default settings')},
  ], [t]);
  let dropdown_click_handle = useCallback(e=>{
    if (e.key == 'default_settings')
      return settings_modal_open();
  }, [settings_modal_open]);
  let select_handler_get = log=>{
    return ()=>{
      log_select(log);
      close_handle();
    };
  };
  return (
    <Drawer placement="left" closable={false} onClose={close_handle}
      open={is_open} getContainer={false} zIndex={1}
      bodyStyle={{padding: 0}}>
      <div style={{height: '100%', borderRight: `1px solid ${gray[6]}`,
        display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', justifyContent: 'space-between',
          padding: '12px', background: gray[7]}}>
          <Avatar src={user.picture} />
          <div style={{display: 'flex'}}>
            <Tooltip title={t('New Log')} placement="bottom">
              <Button type="text" icon={<PlusOutlined />}
                onClick={new_log_modal_open} />
            </Tooltip>
            <Dropdown menu={{items: dropdown_items,
              onClick: dropdown_click_handle}} trigger={['click']}
            placement="bottomRight">
              <Button type="text" icon={<MoreOutlined />} />
            </Dropdown>
          </div>
        </div>
        <div style={{padding: '12px', background: gray[8]}}>
          <Input placeholder="Search" prefix={<SearchOutlined />} disabled />
        </div>
        <div style={{height: '100%', background: gray[8], overflowY: 'auto'}}>
          {logs.map(log=>{
            return (
              <Log_tile key={log._id} src="" lbl={log.lbl}
                is_selected={log == selected_log}
                onClick={select_handler_get(log)} />
            );
          })}
        </div>
      </div>
    </Drawer>
  );
});
let Log_header = React.memo(({token, selected_log, drawer_open, pins_set,
  close_log, log_remove, log_update, log_select, user})=>{
  let {t} = useTranslation();
  let [modal, modal_ctx_holder] = Modal.useModal();
  let [is_export_log_modal_open,
    is_export_log_modal_open_set] = useState(false);
  let [is_log_info_modal_open, is_log_info_modal_open_set] = useState(false);
  let dropdown_items = useMemo(()=>[
    {key: 'info', label: t('Log info')},
    {key: 'export', label: t('Export log')},
    {key: 'delete', label: t('Delete log')},
  ], [t]);
  let log_delete = useCallback(()=>{
    modal.confirm({
      title: t('Are you sure you want to delete ') + selected_log.lbl + '?',
      content: t('This cannot be undone'),
      okType: 'danger',
      onOk: ()=>eserf(function* _log_delete(){
        let res = yield back_app.log.delete(token, selected_log._id);
        // XXX vladimir: show ui message
        if (res.err)
          return;
        close_log();
        log_remove(selected_log);
        drawer_open();
      }),
    });
  }, [close_log, drawer_open, log_remove, modal, selected_log, t, token]);
  let export_log_modal_open = useCallback(()=>{
    is_export_log_modal_open_set(true);
  }, []);
  let export_log_modal_close = useCallback(()=>{
    is_export_log_modal_open_set(false);
  }, []);
  let log_info_modal_open = useCallback(()=>{
    is_log_info_modal_open_set(true);
  }, []);
  let log_info_modal_close = useCallback(()=>{
    is_log_info_modal_open_set(false);
  }, []);
  let dropdown_click_handle = useCallback(e=>{
    if (e.key == 'info')
      return log_info_modal_open();
    if (e.key == 'export')
      return export_log_modal_open();
    if (e.key == 'delete')
      return log_delete();
  }, [log_info_modal_open, export_log_modal_open, log_delete]);
  if (!selected_log)
  {
    return (
      <div style={{display: 'flex', justifyContent: 'space-between',
        padding: '12px', background: gray[7]}}>
        <Tooltip title={t('Open Log List')} placement="bottomLeft">
          <Button type="text" icon={<ArrowLeftOutlined />}
            onClick={drawer_open} />
        </Tooltip>
      </div>
    );
  }
  return (
    <>
      <Export_log_modal is_open={is_export_log_modal_open}
        close_handle={export_log_modal_close} token={token}
        selected_log={selected_log} user={user} />
      <Log_info_modal is_open={is_log_info_modal_open}
        close_handle={log_info_modal_close} log_update={log_update}
        log={selected_log} token={token} log_select={log_select}
        pins_set={pins_set} />
      <div style={{display: 'flex', justifyContent: 'space-between',
        padding: '12px', background: gray[7]}}>
        {modal_ctx_holder}
        <div style={{display: 'flex', gap: '12px', alignItems: 'center'}}>
          <Tooltip title={t('Open Log List')} placement="bottomLeft">
            <Button type="text" icon={<ArrowLeftOutlined />}
              onClick={drawer_open} />
          </Tooltip>
          <Avatar>{selected_log.lbl.slice(0, 2).toUpperCase()}</Avatar>
          <Text>{selected_log.lbl}</Text>
        </div>
        <div style={{display: 'flex'}}>
          <Tooltip title={t('Search pins')} placement="bottom">
            <Button type="text" icon={<SearchOutlined />} disabled />
          </Tooltip>
          <Dropdown menu={{items: dropdown_items,
            onClick: dropdown_click_handle}} trigger={['click']}
          placement="bottomRight">
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        </div>
      </div>
    </>
  );
});
let Pin = React.memo(({tc_in, tc_out, cameras, scene, shot, take, msg,
  is_own_pin, on_edit, on_delete})=>{
  let {t} = useTranslation();
  return (
    <div style={{display: 'flex', margin: '12px 0',
      justifyContent: is_own_pin ? 'flex-end': 'flex-start'}}>
      <div style={{display: 'flex', flexDirection: 'column',
        background: is_own_pin ? purple.primary : gray[7], padding: '12px',
        borderRadius: is_own_pin ? '24px 24px 12px' : '24px 24px 24px 12px',
        minWidth: '320px', maxWidth: '640px'}}>
        <div style={{display: 'flex', justifyContent: 'space-between',
          margin: '0 0 3px 0'}}>
          <Text>{tc_in} - {tc_out}</Text>
        </div>
        {!!cameras.length && <div style={{display: 'flex', gap: '12px',
          margin: '3px 0'}}>
          <Text>{cameras.join(' ')}</Text>
        </div>}
        <div style={{display: 'flex', gap: '12px', margin: '3px 0'}}>
          {scene && <Text>{scene.slice(0, 20)}</Text>}
          <Text>{shot}</Text>
          <Text>{take}</Text>
        </div>
        {msg && <div style={{display: 'flex', margin: '3px 0'}}>
          <Text style={{whiteSpace: 'pre-wrap'}}>{msg}</Text>
        </div>}
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>
            <Tooltip title={t('Edit')} placement="bottom">
              <Button type="text" onClick={on_edit}
                icon={<EditOutlined style={{fontSize: '14px'}} />} />
            </Tooltip>
            <Popconfirm title={t('Are you sure to delete this pin?')}
              description={t('It\'s cannot be undone')} onConfirm={on_delete}
              okText={t('Yes')} cancelText={t('No')}>
              <Tooltip title={t('Delete')} placement="bottom">
                <Button type="text"
                  icon={<DeleteOutlined style={{fontSize: '14px'}} />} />
              </Tooltip>
            </Popconfirm>
          </div>
          <CheckOutlined style={{fontSize: '14px', color: blue[2]}} />
        </div>
      </div>
    </div>
  );
});
let default_scene = '';
let default_shot = 'A';
let default_take = 1;
let Log_conv = React.memo(({token, selected_log, logs_get, user,
  premium_modal_open, user_full, log_update, drawer_open_handle,
  close_log_handle, log_select, log_remove})=>{
  let {t} = useTranslation();
  let pins_container_ref = useRef(null);
  let [pins, pins_set] = useState([]);
  let [is_marked_in, is_marked_in_set] = useState(false);
  let [tc_in, tc_in_set] = useState(0);
  let [tc_out, tc_out_set] = useState(0);
  let [scene, scene_set] = useState(default_scene);
  let [shot, shot_set] = useState(default_shot);
  let [take, take_set] = useState(default_take);
  let [msg, msg_set] = useState('');
  let [cameras, cameras_set] = useState([]);
  let [editing_pin_id, editing_pin_id_set] = useState(null);
  let [editing_tc_in, editing_tc_in_set] = useState(0);
  let [editing_tc_out, editing_tc_out_set] = useState(0);
  let [editing_scene, editing_scene_set] = useState(default_scene);
  let [editing_shot, editing_shot_set] = useState(default_shot);
  let [editing_take, editing_take_set] = useState(default_take);
  let [editing_msg, editing_msg_set] = useState('');
  let [editing_cameras, editing_cameras_set] = useState([]);
  let [scroll_width, scroll_width_set] = useState(0);
  let [is_scroll_btn_visible, is_scroll_btn_visible_set] = useState(false);
  let stt_langs = useMemo(()=>{
    let locales = Object.entries(supp_lngs).map(([key, value])=>{
      return key + '-' + value.country.toUpperCase();
    });
    locales.push('he-IL');
    locales = [...new Set(locales)];
    return locales.map(locale=>({key: locale,
      label: ISO6391.getNativeName(locale.split('-')[0])}));
  }, []);
  let [stt_locale, stt_locale_set] = useState(localStorage.stt_locale
    || stt_langs[0].key);
  let [is_recording, is_recording_set] = useState(false);
  let checkbox_options = useMemo(()=>{
    if (!selected_log)
      return [];
    return camera_trans.obj2arr(selected_log.cameras).map(camera=>{
      return {label: camera, value: camera};
    });
  }, [selected_log]);
  let edit_submit_handle = useCallback(()=>eserf(function*
  _edit_submit_handle(){
    let indicator = camera_trans.arr2indicator(editing_cameras);
    let res = yield back_app.pin.set(token, editing_pin_id, editing_tc_in,
      editing_tc_out, indicator, editing_scene, editing_shot,
      editing_take, editing_msg);
    // XXX Vladimir: show ui message
    if (res.err)
      return;
    let _pins = pins.map(pin=>{
      if (pin._id == res.pin._id)
        return res.pin;
      return pin;
    });
    pins_set(_pins);
    editing_pin_id_set(null);
  }), [editing_cameras, token, editing_pin_id, editing_tc_in, editing_tc_out,
    editing_scene, editing_shot, editing_take, editing_msg, pins]);
  let mark_handle = useCallback(()=>{
    is_marked_in_set(true);
  }, []);
  let pin_submit_handle = useCallback(()=>eserf(function* _pin_submit_handle(){
    let indicator = camera_trans.arr2indicator(cameras);
    let res = yield back_app.pin.insert(token, selected_log._id, tc_in, tc_out,
      indicator, scene, shot, take, msg);
    // XXX Vladimir: show ui message
    if (res.err)
      return;
    yield logs_get();
    log_update(res.log);
    pins_set([...pins, res.pin]);
    msg_set('');
    is_marked_in_set(false);
    take_set(take + 1);
  }), [cameras, log_update, logs_get, msg, pins, selected_log, tc_in, tc_out,
    token, scene, shot, take]);
  let submit_handle = useCallback(()=>{
    if (editing_pin_id)
      return edit_submit_handle();
    if (!is_marked_in)
      return mark_handle();
    return pin_submit_handle();
  }, [edit_submit_handle, editing_pin_id, is_marked_in, mark_handle,
    pin_submit_handle]);
  let get_cur_tc = useCallback(show_frames=>{
    let now = new Date();
    let dtd = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let delta = now.getTime() - dtd.getTime();
    let sec = Math.round(delta / xdate.MS_SEC);
    let frame_in = player.sec2f(sec, selected_log.fps);
    return tc.frame2tc(frame_in, {n: selected_log.fps, d: 1}, show_frames);
  }, [selected_log]);
  let scene_change_handle = useCallback(e=>{
    if (editing_pin_id)
    {
      editing_scene_set(e.target.value);
      editing_shot_set('A');
      editing_take_set(1);
      return;
    }
    scene_set(e.target.value);
    shot_set('A');
    take_set(1);
  }, [editing_pin_id]);
  let shot_click_handle = useCallback(()=>{
    let char_code = shot.charCodeAt();
    char_code += 1;
    if (char_code > 90)
      char_code = 65;
    let _shot = String.fromCharCode(char_code);
    shot_set(_shot);
    take_set(1);
  }, [shot]);
  let take_click_handle = useCallback(()=>{
    take_set(take + 1);
  }, [take]);
  let editing_tc_in_change_handle = useCallback(time=>{
    editing_tc_in_set(time.format('HH:mm:ss:00'));
  }, []);
  let editing_tc_out_change_handle = useCallback(time=>{
    editing_tc_out_set(time.format('HH:mm:ss:00'));
  }, []);
  let editing_shot_change_handle = useCallback(e=>{
    let value = e.target.value;
    if (!value)
      return;
    let last_letter = value.at(-1);
    let _editing_shot = last_letter.toUpperCase();
    let char_code = _editing_shot.charCodeAt(0);
    if (char_code < 65 || char_code > 90)
      return;
    editing_shot_set(_editing_shot);
  }, []);
  let editing_take_change_handle = useCallback(value=>{
    editing_take_set(value);
  }, []);
  let msg_change_handle = useCallback(e=>{
    if (editing_pin_id)
      return editing_msg_set(e.target.value);
    msg_set(e.target.value);
  }, [editing_pin_id]);
  let cameras_change_handle = useCallback(_cameras=>{
    if (editing_pin_id)
      return editing_cameras_set(_cameras);
    cameras_set(_cameras);
  }, [editing_pin_id]);
  let scroll_to_bottom = useCallback(()=>{
    let pins_container = pins_container_ref.current;
    if (!pins_container)
      return;
    pins_container.scrollTo({top: pins_container.scrollHeight, left: 0,
      behavior: 'smooth'});
  }, []);
  let stt_lang_change_handle = useCallback(e=>{
    stt_locale_set(e.key);
    // XXX vladimir: save in user profile
    localStorage.stt_locale = e.key;
  }, []);
  let stt_end_handle = useCallback(()=>{
    speech2el.default.stop();
    is_recording_set(false);
  }, []);
  let stt_start_handle = useCallback(()=>{
    let initial_msg = editing_pin_id ? editing_msg : msg;
    let msg_update = editing_pin_id ? editing_msg_set : msg_set;
    let last_final_text = '';
    let result_handle = (text, is_final)=>{
      if (is_final)
      {
        last_final_text = text;
        msg_update(initial_msg + text);
        return;
      }
      msg_update(initial_msg + last_final_text + text);
    };
    let start_handle = ()=>is_recording_set(true);
    let error_handle = err_msg=>metric.error(err_msg);
    speech2el.default.startWebSpeech({language: stt_locale,
      onResult: result_handle, onStart: start_handle, onError: error_handle});
    document.addEventListener('pointerup', stt_end_handle);
  }, [editing_msg, editing_pin_id, msg, stt_end_handle, stt_locale]);
  let stt_btns_render = useCallback(([left_btn, right_btn])=>{
    return [React.cloneElement(left_btn,
      {onPointerDown: stt_start_handle}), right_btn];
  }, [stt_start_handle]);
  let scroll_handle = useCallback(()=>{
    let pins_container = pins_container_ref.current;
    if (!pins_container)
      return;
    let is_at_bottom = pins_container.scrollTop +
      pins_container.clientHeight == pins_container.scrollHeight;
    is_scroll_btn_visible_set(!is_at_bottom);
  }, []);
  let edit_handler_get = useCallback(pin=>{
    return ()=>{
      editing_pin_id_set(pin._id);
      editing_tc_in_set(pin.tc_in);
      editing_tc_out_set(pin.tc_out);
      editing_cameras_set(camera_trans.indicator2arr(pin.cameras,
        selected_log.cameras));
      editing_scene_set(pin.scene);
      editing_shot_set(pin.shot);
      editing_take_set(pin.take);
      editing_msg_set(pin.msg);
    };
  }, [selected_log]);
  let delete_handler_get = useCallback(pin=>{
    return ()=>eserf(function* _delete_pin(){
      let res = yield back_app.pin.delete(token, pin._id);
      // XXX Vladimir: show ui message
      if (res.err)
        return;
      let _pins = pins.filter(_pin=>_pin._id != pin._id);
      pins_set(_pins);
    });
  }, [pins, token]);
  let editing_cancel = useCallback(pin=>{
    editing_pin_id_set(null);
  }, []);
  let tcs_update = useCallback(()=>{
    let _tc = get_cur_tc();
    tc_out_set(_tc);
    if (!is_marked_in)
      tc_in_set(_tc);
  }, [get_cur_tc, is_marked_in]);
  useEffect(()=>{
    if (!selected_log)
      return;
    tcs_update();
    let interval_id = setInterval(tcs_update, 1000);
    return ()=>clearInterval(interval_id);
  }, [selected_log, tcs_update, is_marked_in]);
  useEffect(()=>{
    let pins_container = pins_container_ref.current;
    if (!pins_container)
      return;
    pins_container.scrollTo({top: pins_container.scrollHeight, left: 0});
  }, [scroll_to_bottom, pins]);
  useEffect(()=>{
    let pins_container = pins_container_ref.current;
    if (!pins_container)
      return;
    is_scroll_btn_visible_set(false);
    scroll_width_set(pins_container.offsetWidth - pins_container.clientWidth);
  }, [pins]);
  let prev_log_id = useRef(null);
  useEffect(()=>{
    let es = eserf(function* use_effect_init_log(){
      if (!selected_log || prev_log_id.current == selected_log._id)
        return;
      prev_log_id.current = selected_log._id;
      scene_set(default_scene);
      shot_set(default_shot);
      take_set(default_take);
      msg_set('');
      pins_set([]);
      let res = yield back_app.pin.get_list(token, selected_log._id);
      if (res.err)
        return;
      pins_set(res.pins);
      if (!res.pins.length)
        return;
      let last_pin = res.pins.at(-1);
      cameras_set(Object.keys(last_pin.cameras)
        .map(camera_id=>selected_log.cameras[camera_id].lbl));
      scene_set(last_pin.scene);
      shot_set(last_pin.shot);
      take_set(last_pin.take + 1);
    });
    return ()=>es.return();
  }, [selected_log, token]);
  let cur_cameras = useMemo(()=>{
    if (editing_pin_id)
      return editing_cameras;
    return cameras;
  }, [editing_pin_id, editing_cameras, cameras]);
  let cur_scene = useMemo(()=>{
    if (editing_pin_id)
      return editing_scene;
    return scene;
  }, [editing_pin_id, editing_scene, scene]);
  let cur_msg = useMemo(()=>{
    if (editing_pin_id)
      return editing_msg;
    return msg;
  }, [editing_pin_id, editing_msg, msg]);
  let btn_tooltip = useMemo(()=>{
    if (editing_pin_id)
      return t('Submit change');
    if (is_marked_in)
      return t('Pin It!');
    return t('Mark Timecode In');
  }, [editing_pin_id, is_marked_in, t]);
  let btn_icon = useMemo(()=>{
    if (editing_pin_id)
      return <CheckCircleOutlined />;
    if (is_marked_in)
      return <SendOutlined />;
    return <PushpinOutlined />;
  }, [editing_pin_id, is_marked_in]);
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <Log_header selected_log={selected_log} drawer_open={drawer_open_handle}
        token={token} user={user} close_log={close_log_handle}
        log_remove={log_remove} log_update={log_update} log_select={log_select}
        pins_set={pins_set} />
      {selected_log && <div style={{height: 'calc(100% - 56px)',
        display: 'flex', flexDirection: 'column'}}>
        <div style={{height: '100%', position: 'relative', overflowY: 'auto'}}>
          <div ref={pins_container_ref} style={{height: '100%',
            padding: '0 12px', overflowY: 'auto'}} onScroll={scroll_handle}>
            {pins.map(pin=>{
              return (
                <Pin key={pin._id} tc_in={pin.tc_in} msg={pin.msg}
                  tc_out={pin.tc_out} scene={pin.scene}
                  cameras={camera_trans.indicator2arr(pin.cameras,
                    selected_log.cameras)} shot={pin.shot} take={pin.take}
                  is_own_pin={pin.user_id == user_full.id}
                  on_edit={edit_handler_get(pin)}
                  on_delete={delete_handler_get(pin)} />
              );
            })}
          </div>
          {is_scroll_btn_visible && <Button shape="circle"
            icon={<DownOutlined />} onClick={scroll_to_bottom}
            style={{position: 'absolute', bottom: 12, right: 12 + scroll_width}}
            size="large" />}
        </div>
        <div style={{display: 'flex', flexDirection: 'column',
          background: gray[7]}}>
          {editing_pin_id && <div style={{padding: '12px', display: 'flex',
            justifyContent: 'space-between'}}>
            <Text strong>{t('Edit pin')}</Text>
            <Button type="text" onClick={editing_cancel} size="small"
              icon={<CloseCircleOutlined />} />
          </div>}
          <div style={{padding: '12px', display: 'flex'}}>
            {editing_pin_id
              ? <div style={{display: 'flex', gap: '12px'}}>
                <TimePicker onChange={editing_tc_in_change_handle}
                  onOk={editing_tc_in_change_handle}
                  onSelect={editing_tc_in_change_handle}
                  value={dayjs(editing_tc_in, 'HH:mm:ss')} />
                <Text>—</Text>
                <TimePicker onChange={editing_tc_out_change_handle}
                  onOk={editing_tc_out_change_handle}
                  onSelect={editing_tc_out_change_handle}
                  value={dayjs(editing_tc_out, 'HH:mm:ss')} />
              </div>
              : <div style={{display: 'flex', gap: '12px'}}>
                <Text>{tc_in}</Text>
                <Text>—</Text>
                <Text>{tc_out}</Text>
              </div>}
          </div>
          <div style={{padding: '12px', display: 'flex', flexWrap: 'wrap'}}>
            <div style={{display: 'flex', gap: '12px'}}>
              <Text>Camera:</Text>
              <Checkbox.Group options={checkbox_options}
                onChange={cameras_change_handle}
                value={cur_cameras} />
            </div>
          </div>
          <div style={{padding: '12px', display: 'flex', gap: '24px',
            alignItems: 'center', flexWrap: 'wrap'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
              <Text>Scene:</Text>
              <Input style={{width: '120px'}} onChange={scene_change_handle}
                value={cur_scene} />
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
              <Text>Shot:</Text>
              {editing_pin_id
                ? <Input value={editing_shot} style={{width: '60px'}}
                  onChange={editing_shot_change_handle} />
                : <Tooltip title={t('Increase shot')} placement="top">
                  <Button icon={<DoubleRightOutlined rotate={270} />}
                    onClick={shot_click_handle}>
                    {shot}
                  </Button>
                </Tooltip>}
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
              <Text>Take:</Text>
              {editing_pin_id
                ? <InputNumber value={editing_take} min={1}
                  onChange={editing_take_change_handle} />
                : <Tooltip title={t('Increase take')} placement="top">
                  <Button icon={<DoubleRightOutlined rotate={270} />}
                    onClick={take_click_handle}>
                    {take}
                  </Button>
                </Tooltip>}
            </div>
          </div>
          <div style={{padding: '12px', display: 'flex', gap: '12px'}}>
            <Tooltip title={t('Attach')} placement="topLeft">
              <Button type="text" icon={<PaperClipOutlined />}
                onClick={premium_modal_open} />
            </Tooltip>
            <TextArea placeholder={t('Type a pin')} value={cur_msg}
              onChange={msg_change_handle} autoSize={{maxRows: 6}} />
            <Dropdown.Button style={{width: 'auto'}} type="dashed"
              menu={{items: stt_langs, onClick: stt_lang_change_handle,
                selectedKeys: [stt_locale]}} icon={stt_locale.split('-')[0]}
              buttonsRender={stt_btns_render}>
              {is_recording ? <AudioFilled style={{color: 'red'}} />
                : <AudioOutlined />}
            </Dropdown.Button>
            <Tooltip placement="topRight" title={btn_tooltip}>
              <Button type="text" onClick={submit_handle} icon={btn_icon} />
            </Tooltip>
          </div>
        </div>
      </div>}
    </div>
  );
});
let E = ()=>{
  let {user, token, user_full, org} = auth.use_auth();
  let [logs, logs_set] = useState([]);
  let [selected_log, selected_log_set] = useState(null);
  let [is_premium_modal_open, is_premium_modal_open_set] = useState(false);
  let [is_settings_modal_open, is_settings_modal_open_set] = useState(false);
  let [is_new_log_modal_open, is_new_log_modal_open_set] = useState(false);
  let [is_drawer_open, is_drawer_open_set] = useState(true);
  let premium_modal_open = useCallback(()=>{
    is_premium_modal_open_set(true);
  }, []);
  let premium_modal_close = useCallback(()=>{
    is_premium_modal_open_set(false);
  }, []);
  let settings_modal_open = useCallback(()=>{
    is_settings_modal_open_set(true);
  }, []);
  let settings_modal_close = useCallback(()=>{
    is_settings_modal_open_set(false);
  }, []);
  let new_log_modal_open = useCallback(()=>{
    is_new_log_modal_open_set(true);
  }, []);
  let new_log_modal_close = useCallback(()=>{
    is_new_log_modal_open_set(false);
  }, []);
  let log_select_handle = useCallback(log=>{
    selected_log_set(log);
  }, []);
  let close_log_handle = useCallback(()=>{
    selected_log_set(null);
  }, []);
  let logs_sort = useCallback(_logs=>{
    return _logs.sort((a, b)=>{
      return new Date(b.ts.modified) - new Date(a.ts.modified);
    });
  }, []);
  let log_add = useCallback(log=>{
    let _logs = [...logs, log];
    _logs = logs_sort(_logs);
    logs_set(_logs);
  }, [logs, logs_sort]);
  let log_update = useCallback(log=>{
    let updated_log = logs.find(_log=>_log._id == log._id);
    if (!updated_log)
      return;
    let _logs = logs.map(_log=>{
      if (_log == updated_log)
        return log;
      return _log;
    });
    _logs = logs_sort(_logs);
    logs_set(_logs);
    if (selected_log == updated_log)
      selected_log_set(log);
  }, [logs, logs_sort, selected_log]);
  let log_remove = useCallback(log=>{
    let _logs = logs.filter(_log=>_log != log);
    _logs = logs_sort(_logs);
    logs_set(_logs);
  }, [logs, logs_sort]);
  let logs_get = useCallback(()=>eserf(function* _logs_get(){
    if (!token)
      return;
    let res = yield back_app.log.get_list(token);
    // XXX Vladimir: show ui message
    if (res.err)
      return;
    let _logs = logs_sort(res.logs);
    logs_set(_logs);
  }), [logs_sort, token]);
  let drawer_close_handle = useCallback(()=>{
    is_drawer_open_set(false);
  }, []);
  let drawer_open_handle = useCallback(()=>{
    is_drawer_open_set(true);
  }, []);
  let user_refetch = useCallback(()=>{
    je.set_inc('auth.update_n');
  }, []);
  useEffect(()=>{
    let es = logs_get();
    return ()=>es.return();
  }, [logs_get]);
  return (
    <>
      <Premium_modal is_open={is_premium_modal_open}
        close_handle={premium_modal_close} />
      <Log_settings_modal token={token} is_open={is_settings_modal_open}
        close_handle={settings_modal_close} user_refetch={user_refetch}
        org={org} />
      <New_log_modal token={token} is_open={is_new_log_modal_open}
        close_handle={new_log_modal_close} drawer_close={drawer_close_handle}
        log_add={log_add} user_full={user_full} org={org}
        log_select={log_select_handle} />
      <div style={{height: '90vh'}}>
        <Logs_list_drawer logs={logs} user={user} log_select={log_select_handle}
          new_log_modal_open={new_log_modal_open} selected_log={selected_log}
          settings_modal_open={settings_modal_open}
          is_open={is_drawer_open} close_handle={drawer_close_handle} />
        <Log_conv token={token} selected_log={selected_log}
          user_full={user_full} premium_modal_open={premium_modal_open}
          logs_get={logs_get} log_update={log_update} log_remove={log_remove}
          drawer_open_handle={drawer_open_handle} user={user}
          close_log_handle={close_log_handle} log_select={log_select_handle} />
      </div>
    </>
  );
};

export default E;
