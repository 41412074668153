// LICENSE_CODE TLM
import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';
import {Typography, Layout, theme, Button, Dropdown, message, Input, Table,
  Select, Tree, Modal, Form, Tabs, Spin, Checkbox, ConfigProvider, Row,
  Col} from 'antd';
import Icon, {MoreOutlined, SearchOutlined, DownOutlined, CheckOutlined,
  FolderFilled, LoadingOutlined, CloseOutlined,
  FileFilled} from '@ant-design/icons';
import {purple} from '@ant-design/colors';
import {useTranslation} from 'react-i18next';
import assert from 'assert';
import {closestCenter as closest_center, DndContext, DragOverlay, PointerSensor,
  useSensor, useSensors} from '@dnd-kit/core';
import {restrictToHorizontalAxis as restrict_to_horizontal_axis}
  from '@dnd-kit/modifiers';
import {arrayMove,
  horizontalListSortingStrategy as horizontal_list_sorting_strategy,
  SortableContext} from '@dnd-kit/sortable';
import _ from 'lodash';
import {multi_sort, Tbin_table_body_cell, Tbin_table_header_cell,
  Tbin_col_choose_modal, Drag_idx_ctx} from './editor.js';
import str from '../../../util/str.js';
import eserf from '../../../util/eserf.js';
import tc from '../../../util/tc.js';
import xutil from '../../../util/util.js';
import csv from '../../../util/csv.js';
import {use_effect_eserf, use_storage, use_qs} from './comp.js';
import Text_view_mode_icon from './assets/text_view_mode_icon.svg?react';
import Frame_view_mode_icon from './assets/frame_view_mode_icon.svg?react';
import metric from './metric.js';
import xfs from './fs.js';
import back_tcoder from './back_tcoder.js';
import {bytes_format} from './workspace.js';
import Sequence_icon from './assets/sequence_icon.svg?react';

let {Title} = Typography;
let Header = React.memo(()=>{
  let {t} = useTranslation();
  let {token: {colorBgContainer}} = theme.useToken();
  let dropdown_items = useMemo(()=>{
    return [
      {key: 'settings', label: t('Settings'), disabled: true},
    ];
  }, [t]);
  let dropdown_click_handle = useCallback(()=>{}, []);
  return (
    <Layout.Header style={{background: colorBgContainer, display: 'flex',
      justifyContent: 'space-between', alignItems: 'center',
      padding: '0 24px'}}>
      <Title level={3} style={{margin: 0}}>
        {t('Project')}
      </Title>
      <Dropdown menu={{items: dropdown_items, onClick: dropdown_click_handle}}
        placement="bottomRight" trigger={['click']}>
        <Button type="text" shape="circle" icon={<MoreOutlined />} />
      </Dropdown>
    </Layout.Header>
  );
});
let tree_data_update = (tree, key, children)=>{
  return tree.map(node=>{
    if (node.key == key)
      return {...node, children};
    if (key.startsWith(node.key) && node.children)
    {
      return {...node, children: tree_data_update(node.children, key,
        children)};
    }
    return node;
  });
};
let probe_supported_exts = ['wmv', 'mov', 'mp4', 'avi', 'oga', 'spx', 'opus',
  'ogg', 'webm', 'mp3', 'ts', 'm2t', 'm2ts', 'mts', 'mxf', 'wav', 'xml', 'ale'];
let File_tree = React.memo(({selected_paths, on_selected_paths_change,
  tree_data, on_tree_data_change, on_selected_dir_change, active_bin,
  on_restart_modal_open, on_active_bin_change, probe_queues,
  on_probe_queues_change})=>{
  let {t} = useTranslation();
  let [message_api, message_ctx_holder] = message.useMessage();
  let node_children_get = useCallback(path=>eserf(function* _node_get(){
    let files = yield back_tcoder.fs_ls(path);
    if (files.err)
      return {err: files.err};
    let children = [];
    for (let file of files)
    {
      let title = file.file_base;
      if (!file.is_dir && probe_supported_exts.includes(file.ext))
      {
        children.push({title, key: file.path, is_dir: false});
        continue;
      }
      if (!file.is_dir)
        continue;
      let _files = yield back_tcoder.fs_ls(file.path);
      if (_files.err)
      {
        console.error('fs_ls', _files.err);
        continue;
      }
      let is_empty = _files.every(_o=>!_o.is_dir);
      children.push({title, key: file.path, is_empty, is_dir: file.is_dir});
    }
    return children;
  }), []);
  let load_data_handle = useCallback(({key, children})=>eserf(function*
  _load_data_handle(){
    if (children)
      return;
    let new_children = yield node_children_get(key);
    if (new_children.err == 'tcoder_down')
      return on_restart_modal_open();
    if (new_children.err == 'EACCES' || new_children.err == 'EPERM')
      return message_api.error(t('Permission denied'));
    if (new_children.err == 'ENOENT')
      return message_api.error(t('Folder does not exist'));
    if (new_children.err == 'ENOTDIR')
      return message_api.error(t('Not a directory'));
    if (new_children.err)
    {
      message_api.error(new_children.err);
      return metric.error('node_children_get', new_children.err);
    }
    let _tree_data = tree_data_update(tree_data, key, new_children);
    on_tree_data_change(_tree_data);
  }), [message_api, node_children_get, on_tree_data_change, t, tree_data,
    on_restart_modal_open]);
  let dropdown_items = useMemo(()=>{
    let _dropdown_items = [
      {key: 'add_files_to_bin', label: t('Add files to bin'),
        children: Object.keys(selected_paths)
          .map(bin=>({key: bin, label: bin}))},
    ];
    if (active_bin != 'files')
    {
      _dropdown_items.push({key: 'add_files_to_cur_bin',
        label: t('Add files to bin in view')});
    }
    return _dropdown_items;
  }, [active_bin, selected_paths, t]);
  let dropdown_click_handler_get = useCallback(node=>{
    return ({key})=>eserf(function* _dropdown_click_handle(){
      let bin = key == 'add_files_to_cur_bin' ? active_bin : key;
      let _selected_paths = {...selected_paths,
        [bin]: [...selected_paths[bin]]};
      let _probe_queues = {...probe_queues, [bin]: [...probe_queues[bin]]};
      let _tree_data = tree_data;
      let queue = [node];
      while (queue.length)
      {
        let _node = queue.shift();
        if (!_node.is_dir && !_selected_paths[bin].includes(_node.key))
        {
          _selected_paths[bin].push(_node.key);
          _probe_queues[bin].push(_node.key);
          continue;
        }
        if (!_node.is_dir)
          continue;
        let children = _node.children;
        if (_node.is_dir && !children)
        {
          children = yield node_children_get(_node.key);
          if (children.err)
            continue;
          _tree_data = tree_data_update(_tree_data, _node.key, children);
        }
        queue.push(...children);
      }
      on_selected_paths_change(_selected_paths);
      on_probe_queues_change(_probe_queues);
      if (key != 'add_files_to_cur_bin')
        on_active_bin_change(key);
    });
  }, [active_bin, node_children_get, on_selected_paths_change, selected_paths,
    tree_data, on_active_bin_change, on_probe_queues_change, probe_queues]);
  // XXX vladimir: do not use jsx style to render tree nodes
  let tree_nodes_render = useCallback(data=>{
    if (!data)
      return [];
    return data
      .filter(node=>node.is_dir)
      .sort((a, b)=>str.cmp(a.title, b.title))
      .map(node=>{
        return <Tree.TreeNode key={node.key} isLeaf={node.is_empty} title={
          <Dropdown trigger={['contextMenu']} menu={{items: dropdown_items,
            onClick: dropdown_click_handler_get(node)}}>
            <div style={{whiteSpace: 'nowrap'}}>
              {node.title}
            </div>
          </Dropdown>
        }>
          {tree_nodes_render(node.children)}
        </Tree.TreeNode>;
      });
  }, [dropdown_click_handler_get, dropdown_items]);
  let select_handle = useCallback(selected_keys=>{
    on_selected_dir_change(selected_keys[0]);
  }, [on_selected_dir_change]);
  return (
    <div style={{overflow: 'hidden'}}>
      {message_ctx_holder}
      <ConfigProvider theme={{components: {
        Tree: {nodeSelectedBg: purple.primary}}}}>
        <Tree showLine switcherIcon={<DownOutlined />} onSelect={select_handle}
          loadData={load_data_handle}>
          {tree_nodes_render(tree_data)}
        </Tree>
      </ConfigProvider>
    </div>
  );
});
let Tcode_modal = React.memo(({is_open, on_close, paths, probes,
  output_dir, tmp_dir, on_restart_modal_open})=>{
  let {t} = useTranslation();
  let [modal_api, modal_ctx_holder] = Modal.useModal();
  let [form] = Form.useForm();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [last_output_dir, last_output_dir_set]
    = use_storage('tlm.tcoder.output_dir');
  let [last_fps, last_fps_set] = use_storage('tlm.tcoder.fps');
  let [last_cmd, last_cmd_set] = use_storage('tlm.tcoder.cmd');
  let [last_subformat, last_subformat_set]
    = use_storage('tlm.tcoder.subformat');
  let [last_is_lut, last_is_lut_set] = use_storage('tlm.tcoder.is_lut');
  let [last_is_reframe, last_is_reframe_set]
    = use_storage('tlm.tcoder.is_reframe');
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err)
      return;
    last_output_dir_set(values.output_dir);
    last_fps_set(values.fps);
    last_cmd_set(values.cmd);
    last_subformat_set(values.subformat);
    last_is_lut_set(values.is_lut);
    last_is_reframe_set(values.is_reframe);
    on_close();
    let tcode_modal = modal_api.info({
      icon: <div style={{height: '100%', paddingRight: '16px'}}>
        <Spin indicator={<LoadingOutlined spin
          style={{fontSize: '24px'}} />} />
      </div>,
      title: 'test',
      okText: t('Cancel'),
      onOk: ()=>{
        this.return();
      },
    });
    let idx = 0;
    let out_files = [];
    let err_files = [];
    for (let path of paths)
    {
      idx++;
      let title = `${t('Transcoding')} ${xfs.basename(path)}`
        + ` (${idx}/${paths.length})`;
      tcode_modal.update({title});
      let mpath = str.path2mongo(path);
      let streams = probes[mpath];
      let stream = streams[0];
      let probe = {[mpath]: probes[mpath]};
      let blob = new Blob([JSON.stringify(probe)], {type: 'application/json'});
      let file = new File([blob], 'probe.json', {type: 'application/json'});
      let data = {[file.name]: file};
      let probe_path = tmp_dir + '/probe.json';
      let err_path = tmp_dir + '/err.csv';
      let out_path = tmp_dir + '/out.json';
      let set_res = yield back_tcoder.fs_set(probe_path, data);
      if (set_res.err == 'tcoder_down')
        return on_restart_modal_open();
      if (set_res.err)
      {
        message_api.error(set_res.err);
        return metric.error('pub_fs_upload', set_res.err);
      }
      let cmd = ['--cmd', values.cmd, '--probe', probe_path, '--dir',
        values.output_dir, '--out', out_path, '--err', err_path];
      if (values.fps != 'use_src_fps')
        cmd.push('--fps', values.fps);
      if (values.subformat == 'is_xdcam')
        cmd.push('--is_xdcam');
      if (values.subformat == 'is_lb')
        cmd.push('--is_lb');
      if (values.is_lut)
        cmd.push('--is_lut');
      if (values.is_reframe)
        cmd.push('--is_reframe');
      if (values.is_allow_fps_conv)
        cmd.push('--is_allow_fps_conv');
      if (stream.camroll)
        cmd.push('--camroll', stream.camroll);
      if (stream.lut)
        cmd.push('--lut', stream.lut);
      if (stream.reframe)
        cmd.push('--reframe', stream.reframe);
      let cmd_res = yield back_tcoder.cmd(cmd);
      if (cmd_res.err == 'tcoder_down')
        return on_restart_modal_open();
      if (cmd_res.err)
      {
        message_api.error(cmd_res.err);
        return metric.error('pub_tcoder_cmd', cmd_res.err);
      }
      let probe_out_files = yield back_tcoder.fs_get(out_path);
      if (probe_out_files.err == 'tcoder_down')
        return on_restart_modal_open();
      if (probe_out_files.err)
      {
        message_api.error(probe_out_files.err);
        return metric.error('fs_get', probe_out_files.err);
      }
      let s = yield back_tcoder.fs_get(err_path);
      err_files = yield csv.s2j(s);
      out_files = [...out_files, ...probe_out_files];
    }
    tcode_modal.destroy();
    modal_api.success({
      title: t('Transcoded successfully'),
      content: <>
        {!!err_files.length && <Row span={24}>
          <Col span={24}>{t('Errors')+':'}</Col>
        </Row>}
        <Row span={24}>
          <Col span={24}>
            {err_files.map(row=><Row key={row.file+row.err}>
              <Col span={12}>{xfs.basename(row.file)}</Col>
              <Col span={12}>{xfs.basename(row.err)}</Col>
            </Row>)}
          </Col>
        </Row>
        {!!out_files.length && <Row span={24}>
          <Col>{t('Output')+':'}</Col>
        </Row>}
        <Row span={24}>
          <Col span={24}>
            {out_files.map(path=><Row key={path}>
              <Col span={24}>{xfs.basename(path)}</Col>
            </Row>)}
          </Col>
        </Row>
      </>,
      width: 800,
    });
  }), [form, message_api, on_close, paths, probes, tmp_dir, last_fps_set, t,
    on_restart_modal_open, last_output_dir_set, last_cmd_set, modal_api,
    last_subformat_set, last_is_lut_set, last_is_reframe_set]);
  let fps_opts = useMemo(()=>{
    return [
      {value: 'use_src_fps', label: t('Use Source File Rate')},
      ...tc.editrates
        .filter(editrate=>!editrate.is_audio)
        .map(editrate=>({value: editrate.fps, label: editrate.fps})),
    ];
  }, [t]);
  let format_opts = useMemo(()=>{
    return [
      {value: 'tcode_dailies_dir', label: t('Dailies in H264')},
      {value: 'tcode_avid_dir', label: t('Avid Media Files')},
      {value: 'tcode_premiere_dir', label: t('Premiere Proxies')},
      {value: 'tcode_tlm_dir', label: t('Toolium Proxies')},
    ];
  }, [t]);
  let subformat_opts = useMemo(()=>{
    return [
      {value: 'none', label: t('DNxHD36')},
      {value: 'is_lb', label: t('DNxHR low resolution')},
      {value: 'is_xdcam', label: t('XDCAM50 HD')},
    ];
  }, [t]);
  let initial_values = useMemo(()=>{
    return {output_dir: last_output_dir || output_dir,
      fps: last_fps || 'use_src_fps', cmd: last_cmd || 'tcode_dailies_dir',
      subformat: last_subformat || 'none',
      is_lut: last_is_lut !== undefined ? last_is_lut : false,
      is_allow_fps_conv: false,
      is_reframe: last_is_reframe !== undefined ? last_is_reframe : false};
  }, [last_output_dir, output_dir, last_fps, last_cmd, last_subformat,
    last_is_lut, last_is_reframe]);
  let title = useMemo(()=>{
    return t('Transcode/Convert') + ' ' + paths.length + ' '
      + (paths.length == 1 ? t('file') : t('files'));
  }, [paths, t]);
  let cmd = Form.useWatch('cmd', form);
  return (
    <Modal title={title} open={is_open} okText={t('Transcode')}
      onOk={submit_handle} onCancel={on_close} preserve={false}>
      {message_ctx_holder}
      {modal_ctx_holder}
      <Form form={form} layout="vertical" preserve={false}
        initialValues={initial_values} onFinish={submit_handle}>
        <Form.Item name="output_dir" label={t('Output directory')}
          rules={[{required: true,
            message: t('Please, input the output directory')},
          {max: 255, message: t('Name is too long')}]}>
          <Input />
        </Form.Item>
        <Form.Item name="fps" label={t('FPS')}
          rules={[{required: true, message: t('Please, choose the fps')}]}>
          <Select options={fps_opts} />
        </Form.Item>
        <Form.Item name="cmd" label={t('Format')}
          rules={[{required: true, message: t('Please, choose the format')}]}>
          <Select options={format_opts} />
        </Form.Item>
        {cmd == 'tcode_avid_dir' && <Form.Item name="subformat"
          rules={[{required: true, message: t('Please, choose the format')}]}>
          <Select options={subformat_opts} />
        </Form.Item>}
        <Form.Item name="is_lut" valuePropName="checked" label={null}>
          <Checkbox>{t('Burn in Color LUT')}</Checkbox>
        </Form.Item>
        <Form.Item name="is_reframe" valuePropName="checked" label={null}>
          <Checkbox>{t('Burn in reframing')}</Checkbox>
        </Form.Item>
        <Form.Item name="is_allow_fps_conv" valuePropName="checked"
          label={null}>
          <Checkbox>{t('Force to FPS, may cause relink issues')}
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
});
let Restart_modal = React.memo(({is_open, on_close})=>{
  let {t} = useTranslation();
  return (
    <Modal title={t('Tcoder is offline')} open={is_open}
      onOk={on_close} onCancel={on_close} destroyOnClose preserve={false}
      footer={(origin_node, {OkBtn})=><OkBtn />}>
      <p>{t('Please, restart the server.')}</p>
    </Modal>
  );
});
let Files_tab = React.memo(({selected_dir, on_restart_modal_open})=>{
  let [message_api, message_ctx_holder] = message.useMessage();
  let {t} = useTranslation();
  let [files, files_set] = useState([]);
  use_effect_eserf(()=>eserf(function* use_effect_get_files(){
    if (!selected_dir)
      return files_set([]);
    let _files = yield back_tcoder.fs_ls(selected_dir);
    if (_files.err == 'tcoder_down')
      return on_restart_modal_open();
    if (_files.err == 'EACCES' || _files.err == 'EPERM')
      return message_api.error(t('Permission denied'));
    if (_files.err == 'ENOENT')
      return message_api.error(t('Folder does not exist'));
    if (_files.err == 'ENOTDIR')
      return message_api.error(t('Not a directory'));
    if (_files.err)
    {
      message_api.error(_files.err);
      return metric.error('fs_ls', _files.err);
    }
    files_set(_files);
  }), [message_api, on_restart_modal_open, selected_dir, t]);
  let cols = useMemo(()=>{
    return [
      {key: 'icon', dataIndex: 'icon', width: 48,
        sorter: (a, b)=>str.cmp(a.type, b.type)},
      {title: t('Filename'), key: 'filename', dataIndex: 'filename',
        sorter: (a, b)=>str.cmp(a.file.file_base, b.file.file_base),
        defaultSortOrder: 'ascend'},
      {title: t('Size'), key: 'size', dataIndex: 'size',
        sorter: (a, b)=>b.file.size - a.file.size},
    ];
  }, [t]);
  let data_src = useMemo(()=>{
    return files.map(file=>{
      let ext = xfs.extname(file.file_base, {is_no_period: true});
      let type;
      let icon;
      if (file.is_dir)
      {
        type = 'dir';
        icon = <FolderFilled style={{color: '#272A3C', fontSize: '24px'}} />;
      }
      else if (ext == 'aaf')
      {
        type = 'aaf';
        icon = <Icon component={Sequence_icon} style={{color: '#272A3C',
          fontSize: '20px'}} />;
      }
      else
      {
        type = 'file';
        icon = <FileFilled style={{color: '#272A3C', fontSize: '20px'}} />;
      }
      icon = <div style={{display: 'flex', justifyContent: 'center'}}>
        {icon}
      </div>;
      return {file, filename: file.file_base, size: bytes_format(file.size),
        icon, type};
    });
  }, [files]);
  return <div>
    {message_ctx_holder}
    <Table columns={cols} dataSource={data_src} pagination={false}
      rowKey="path" size="small" />
  </div>;
});
let Set_col_modal = React.memo(({is_open, on_close, col_name, on_submit,
  init_value='', opts})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err)
      return;
    on_submit(values.value);
    on_close();
  }), [form, on_close, on_submit]);
  let initial_values = useMemo(()=>{
    return {value: init_value};
  }, [init_value]);
  let title = useMemo(()=>{
    return t('Set') + ' ' + col_name;
  }, [col_name, t]);
  return (
    <Modal title={title} open={is_open} onOk={submit_handle} onCancel={on_close}
      destroyOnClose preserve={false}>
      <Form form={form} layout="vertical" preserve={false}
        initialValues={initial_values} onFinish={submit_handle}>
        {opts ? <Form.Item name="value" label={null}>
          <Select options={opts} />
        </Form.Item> : <Form.Item name="value" label={null}
          rules={[{max: 255, message: t('Value is too long')}]}>
          <Input />
        </Form.Item>}
      </Form>
    </Modal>
  );
});
let track_num_parse = track_id=>{
  return parseInt(track_id.slice(1), 10);
};
let are_tracks_consecutive = (prev_track_id, current_track_id)=>{
  let prev_num = track_num_parse(prev_track_id);
  let cur_num = track_num_parse(current_track_id);
  return cur_num === prev_num + 1;
};
let track_ids_shorten = track_ids=>{
  if (!Array.isArray(track_ids) || track_ids.length == 0)
    return '';
  let result = [];
  let start = track_ids[0];
  let end = start;
  for (let i = 1; i <= track_ids.length; i++)
  {
    if (i < track_ids.length
      && are_tracks_consecutive(track_ids[i - 1], track_ids[i]))
    {
      end = track_ids[i];
    }
    else
    {
      if (start === end)
        result.push(start);
      else
        result.push(`${start}-${end}`);
      start = track_ids[i];
      end = start;
    }
  }
  return result.join(',');
};
let default_col_keys = ['reel_name', 'status', 'tc', 'fps', 'tracks'];
let Bin_tab = React.memo(({output_dir, selected_paths, probes,
  on_selected_paths_change, on_probes_change, tab_keys, tab_add, tmp_dir,
  on_restart_modal_open, lut_files, probe_queue, on_probe_queue_change})=>{
  let {token: {borderRadiusLG}} = theme.useToken();
  let {qs_o} = use_qs();
  let [modal_api, modal_ctx_holder] = Modal.useModal();
  let [message_api, message_ctx_holder] = message.useMessage();
  let {t} = useTranslation();
  let [selected_files, selected_files_set] = useState([]);
  let [last_selected_file, last_selected_file_set] = useState(null);
  let [selected_cols, selected_cols_set] = useState([]);
  let [is_row_ctx_open, is_row_ctx_open_set] = useState(false);
  let [, ctx_record_set] = useState(null);
  let [is_header_ctx_open, is_header_ctx_open_set] = useState(false);
  let [is_col_choose_modal_open,
    is_col_choose_modal_open_set] = useState(false);
  let [ctx_col, ctx_col_set] = useState(null);
  let [sorters, sorters_set] = useState([]);
  let [view_mode, view_mode_set] = useState('text');
  let [query, query_set] = useState('');
  let [col_keys, col_keys_set] = useState(default_col_keys);
  let [drag_idx, drag_idx_set] = useState({active: null, over: null});
  let [is_tcode_modal_open, is_tcode_modal_open_set] = useState(false);
  let [editing_col_key, editing_col_key_set] = useState(null);
  let [editing_path, editing_path_set] = useState(null);
  let [is_col_set_modal_open, is_col_set_modal_open_set] = useState(false);
  let sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {distance: 1}}));
  let header_cell_handle = useCallback(col=>{
    return {
      id: col.key,
      onMouseDown: e=>{
        if ((e.ctrlKey || e.metaKey) && selected_cols.includes(col.key))
        {
          let _selected_cols = selected_cols.filter(id=>id != col.key);
          selected_cols_set(_selected_cols);
          selected_files_set([]);
          return;
        }
        if ((e.ctrlKey || e.metaKey) && !selected_cols.includes(col.key))
        {
          selected_cols_set([...selected_cols, col.key]);
          selected_files_set([]);
          return;
        }
        selected_cols_set([col.key]);
        selected_files_set([]);
      },
      onContextMenu: ()=>{
        is_header_ctx_open_set(true);
        ctx_col_set(col.key);
      },
    };
  }, [selected_cols]);
  let cell_handler_get = useCallback(key=>{
    return record=>{
      return {
        onClick: e=>{
          if (e.ctrlKey || e.metaKey || e.shiftKey)
            return;
          editing_col_key_set(key);
          editing_path_set(record.src_path);
        },
      };
    };
  }, []);
  let selected_and_linked_files = useMemo(()=>{
    return selected_files.filter(path=>{
      return Object.values(probes).some(streams=>streams[0].file_orig == path);
    });
  }, [probes, selected_files]);
  let selected_and_linked_paths = useMemo(()=>{
    return selected_paths.filter(path=>{
      return Object.values(probes).some(streams=>streams[0].file_orig == path);
    });
  }, [probes, selected_paths]);
  let reframe_opts = useMemo(()=>{
    return [
      {value: 'none', label: t('None')},
      {value: 'letter_box', label: t('Letter box')},
      {value: 'center_crop', label: t('Center crop')},
      {value: 'stretch', label: t('Stretch')},
    ];
  }, [t]);
  let lut_opts = useMemo(()=>{
    return lut_files.map(file=>({value: file.path, label: file.file_base}));
  }, [lut_files]);
  let cols = useMemo(()=>[
    {key: 'reel_name', dataIndex: 'reel_name', title: t('Name'),
      _sorter: (a, b)=>str.cmp(b.reel_name, a.reel_name), is_always_show: true},
    {key: 'status', dataIndex: 'status', title: t('Status'), is_no_copy: true,
      is_always_show: true, _sorter: (a, b)=>{
        return selected_and_linked_paths.includes(a.src_path)
          - selected_and_linked_paths.includes(b.src_path);
      }},
    {key: 'tc', dataIndex: 'tc',
      title: t('Timecode'), _sorter: (a, b)=>str.cmp(b.tc, a.tc)},
    {key: 'fps', dataIndex: 'fps', title: t('FPS'),
      _sorter: (a, b)=>b.fps - a.fps},
    {key: 'tracks', dataIndex: 'tracks',
      title: t('Tracks'), _sorter: (a, b)=>str.cmp(b.tracks, a.tracks)},
    {key: 'src_path', dataIndex: 'src_path', title: t('Source Path'),
      _sorter: (a, b)=>str.cmp(b.src_path, a.src_path)},
    {key: 'src_name', dataIndex: 'src_name', title: t('Source Name'),
      _sorter: (a, b)=>str.cmp(b.src_name, a.src_name)},
    {key: 'codec_name', dataIndex: 'codec_name',
      title: t('Codec'), _sorter: (a, b)=>str.cmp(b.codec_name, a.codec_name)},
    {key: 'duration', dataIndex: 'duration',
      title: t('Duration'), _sorter: (a, b)=>str.cmp(b.duration, a.duration)},
    {key: 'img_size', dataIndex: 'img_size',
      title: t('Image size'), _sorter: (a, b)=>{
        let width_a = a.stream?.width || 0;
        let height_a = a.stream?.height || 0;
        let width_b = b.stream?.width || 0;
        let height_b = b.stream?.height || 0;
        if (width_a != width_b)
          return width_a - width_b;
        return height_a - height_b;
      }},
    {key: 'display_aspect_ratio', dataIndex: 'display_aspect_ratio',
      title: t('Aspect ratio'),
      _sorter: (a, b)=>str.cmp(b.display_aspect_ratio, a.display_aspect_ratio)},
    {key: 'camroll', dataIndex: 'camroll', is_edit: true,
      title: t('Camroll'), _sorter: (a, b)=>str.cmp(b.camroll, a.camroll)},
    {key: 'lut', dataIndex: 'lut', is_edit: true, opts: lut_opts,
      title: t('LUT'), _sorter: (a, b)=>str.cmp(b.lut, a.lut)},
    {key: 'reframe', dataIndex: 'reframe', is_edit: true, opts: reframe_opts,
      title: t('Reframe'), _sorter: (a, b)=>str.cmp(b.reframe, a.reframe)},
    {key: 'model', dataIndex: 'model', title: t('Model'),
      _sorter: (a, b)=>str.cmp(b.model, a.model)},
  ], [lut_opts, reframe_opts, t, selected_and_linked_paths]);
  let editing_col = useMemo(()=>{
    return cols.find(col=>col.key == editing_col_key);
  }, [cols, editing_col_key]);
  let render_handler_get = useCallback(key=>{
    return (text, record)=>{
      if (editing_col?.key == key && !editing_col.is_no_copy
        && editing_path == record.src_path)
      {
        let focus_handle = e=>{
          e.target.select();
        };
        let blur_handle = e=>{
          editing_col_key_set(null);
          editing_path_set(null);
        };
        return <Input autoFocus size="small"
          value={record[editing_col_key]} onFocus={focus_handle}
          onBlur={blur_handle} />;
      }
      return text;
    };
  }, [editing_col, editing_col_key, editing_path]);
  let col_opts = useMemo(()=>{
    return cols
      .filter(col=>!col.is_always_show)
      .map(col=>({label: col.title, value: col.key}));
  }, [cols]);
  let cols_in_use = useMemo(()=>{
    return col_keys
      .map(key=>{
        let _col = cols.find(col=>col.key == key);
        return {..._col, className: selected_cols.includes(key)
          ? 'bin-table-row-selected' : null, onHeaderCell: header_cell_handle,
        onCell: cell_handler_get(_col.key),
        render: render_handler_get(_col.key)};
      });
  }, [cell_handler_get, col_keys, cols, header_cell_handle, render_handler_get,
    selected_cols]);
  let data_src = useMemo(()=>{
    let _query = query.toLowerCase().trim();
    let linked_records = Object.values(probes)
      .filter(streams=>{
        return streams.some(stream=>{
          return [...Object.values(stream)].some(value=>{
            return String(value).toLowerCase().includes(_query);
          });
        });
      })
      .map(streams=>{
        let stream = streams[0];
        let reel_name = stream.reel_name || '';
        let _tc = stream.tc || '';
        let tracks = track_ids_shorten(streams.map(_stream=>_stream.tr));
        let fps = stream.editrate ? tc.editrate2fps(stream.editrate) || '' : '';
        let src_path = stream.file_orig;
        let src_name = xfs.basename(stream.file_orig);
        let codec_name = stream.codec_name || '';
        let duration = stream.dur_frame !== undefined && fps
          ? tc.frame2tc(stream.dur_frame, stream.editrate) : '';
        let img_size = stream.width !== undefined && stream.height !== undefined
          ? `${stream.width}x${stream.height}` : '';
        let display_aspect_ratio = stream.display_aspect_ratio || '';
        let camroll = stream.camroll || '';
        let lut = stream.lut ? xfs.basename(stream.lut) : '';
        let reframe = stream.reframe || '';
        let model = stream.model || '';
        let status = <CheckOutlined style={{color: 'lightgreen'}} />;
        return {stream, reel_name, tc: _tc, tracks, fps, model,
          src_path, src_name, codec_name, duration, img_size,
          display_aspect_ratio, camroll, lut, reframe, status};
      });
    let unlinked_records = selected_paths
      .filter(path=>{
        return !Object.values(probes).some(streams=>{
          return streams[0].file_orig == path;
        });
      })
      .filter(path=>path.toLowerCase().includes(_query))
      .map(path=>{
        let src_name = xfs.basename(path);
        return {reel_name: src_name, tc: '', tracks: '', fps: '', model: '',
          src_path: path, src_name, codec_name: '', duration: '', img_size: '',
          display_aspect_ratio: '', camroll: '', lut: '', reframe: '',
          status: <CloseOutlined style={{color: 'red'}} />};
      });
    let _data_src = [...linked_records, ...unlinked_records];
    let sort_criteria = sorters.map(({key, dir})=>({dir,
      sorter: cols.find(col=>col.key == key)._sorter}));
    return multi_sort(_data_src, sort_criteria);
  }, [query, probes, selected_paths, sorters, cols]);
  let row_dropdown_items = useMemo(()=>{
    let _row_dropdown_items = [
      {label: t('Relink files'), key: 'relink_files'},
      {label: t('Import camera metadata'), key: 'import_cam_metadata',
        disabled: true},
      {label: t('Transcode/convert'), key: 'transcode_convert',
        disabled: !selected_and_linked_files.length},
      {label: t('Create dailies bin'), key: 'create_dailies_bin',
        disabled: !selected_and_linked_files.length},
      {label: t('Export ale'), key: 'export_ale', disabled: true},
      {label: t('Remove file(s)'), key: 'remove_files'},
    ];
    if (ctx_col)
    {
      let col = cols.find(_col=>_col.key == ctx_col);
      if (!col)
        assert(0, 'column not found');
      if (col.is_edit)
      {
        _row_dropdown_items.push({type: 'divider'});
        _row_dropdown_items.push({label: t('Set') + ' ' + col.title + ' ' +
          t('column for selected clips...'), key: 'col_set'});
      }
    }
    return _row_dropdown_items;
  }, [cols, ctx_col, selected_and_linked_files, t]);
  let linking_modal_ref = useRef(null);
  let linking_modal_destroy = useCallback(()=>{
    if (!linking_modal_ref.current)
      return;
    linking_modal_ref.current.destroy();
    linking_modal_ref.current = null;
  }, []);
  let prev_probe_queue_ref = useRef([]);
  useEffect(()=>{
    if (xutil.obj_is_equal(probe_queue.sort(),
      prev_probe_queue_ref.current.sort()))
    {
      return;
    }
    prev_probe_queue_ref.current = probe_queue;
    if (!probe_queue.length)
      return;
    let es = eserf(function* use_effect_probe(){
      let sorted_probe_queues = data_src
        .filter(record=>probe_queue.includes(record.src_path))
        .map(record=>record.src_path);
      let [path, ..._probe_queue] = sorted_probe_queues;
      let title = t('Linking') + ' ' + xfs.basename(path);
      if (linking_modal_ref.current)
        linking_modal_ref.current.update({title});
      else
      {
        linking_modal_ref.current = modal_api.info({
          icon: <div style={{height: '100%', paddingRight: '16px'}}>
            <Spin indicator={<LoadingOutlined spin
              style={{fontSize: '24px'}} />} />
          </div>,
          title,
          okText: t('Cancel'),
          onOk: ()=>{
            es.return();
            linking_modal_destroy();
            on_probe_queue_change([]);
          },
        });
      }
      let mpath = str.path2mongo(path);
      let ext = xfs.extname(path, {is_no_period: true});
      let probe_path = tmp_dir + '/probe.json';
      let probe_img_path = tmp_dir + '/probe_img.json';
      let err_path = tmp_dir + '/err.csv';
      let cmd = ['xml', 'ale'].includes(ext) ? 'probe_nrm' : 'probe';
      let cmd_res = yield back_tcoder.cmd(['--cmd', cmd, '--in', path,
        '--out', probe_path, '--err', err_path]);
      if (cmd_res.err == 'tcoder_down')
        return on_restart_modal_open();
      if (cmd_res.err)
      {
        message_api.error(cmd_res.err);
        metric.error('pub_tcoder_cmd', cmd_res.err);
        on_probe_queue_change(_probe_queue);
        if (!_probe_queue.length)
          linking_modal_destroy();
        return;
      }
      let probe = yield back_tcoder.fs_get(probe_path);
      if (probe.err == 'tcoder_down')
        return on_restart_modal_open();
      if (probe.err == 'EACCES' || probe.err == 'EPERM')
      {
        message_api.error(t('Permission denied'));
        on_probe_queue_change(_probe_queue);
        if (!_probe_queue.length)
          linking_modal_destroy();
        return;
      }
      if (probe.err == 'ENOENT')
      {
        message_api.error(t('Folder does not exist'));
        on_probe_queue_change(_probe_queue);
        if (!_probe_queue.length)
          linking_modal_destroy();
        return;
      }
      if (probe.err == 'EISDIR')
      {
        message_api.error(t('Not a file'));
        on_probe_queue_change(_probe_queue);
        if (!_probe_queue.length)
          linking_modal_destroy();
        return;
      }
      if (probe.err)
      {
        message_api.error(probe.err);
        metric.error('fs_get', probe.err);
        on_probe_queue_change(_probe_queue);
        if (!_probe_queue.length)
          linking_modal_destroy();
        return;
      }
      let _probes = _.cloneDeep(probes);
      if (cmd == 'probe_nrm')
      {
        let reel_name = probe.reel_name;
        let umid = probe.umid;
        for (let _mpath in _probes)
        {
          let streams = _probes[_mpath];
          let stream = streams[0];
          if (reel_name !== undefined && stream.reel_name != reel_name)
            continue;
          if (umid !== undefined && stream.umid != umid)
            continue;
          for (let _stream of streams)
          {
            if (!_stream.user_cmts)
              _stream.user_cmts = [];
            for (let key in probe)
            {
              let cmt = _stream.user_cmts.find(_cmt=>_cmt.key == key);
              if (cmt)
                continue;
              _stream.user_cmts.push({key, key_orig: key.toUpperCase(),
                v: probe[key]});
            }
          }
        }
      }
      else
      {
        if (qs_o.dbg)
        {
          cmd_res = yield back_tcoder.cmd(['--cmd', 'probe_img', '--probe',
            probe_path, '--dir', tmp_dir, '--out', probe_img_path,
            '--err', err_path]);
          let probe_img = yield back_tcoder.fs_get(probe_img_path);
          for (let i in probe_img)
            probe[i].imgs = probe_img[i];
        }
        _probes[mpath] = probe;
      }
      on_probes_change(_probes);
      on_probe_queue_change(_probe_queue);
      if (!_probe_queue.length && linking_modal_ref.current)
        linking_modal_destroy();
    });
    return ()=>es.return();
  }, [message_api, modal_api, on_probe_queue_change, on_probes_change,
    on_restart_modal_open, probe_queue, probes, t, tmp_dir,
    linking_modal_destroy, qs_o, data_src]);
  let dropdown_click_handle = useCallback(({key})=>eserf(function*
  _dropdown_click_handle(){
    if (key == 'relink_files')
    {
      let _probe_queues = [...probe_queue, ...selected_files];
      on_probe_queue_change(_probe_queues);
    }
    if (key == 'transcode_convert')
      is_tcode_modal_open_set(true);
    if (key == 'create_dailies_bin')
    {
      if (!selected_and_linked_files.length)
        assert(0, 'no files to probe daily');
      let probe = {};
      for (let path of selected_and_linked_files)
      {
        let mpath = str.path2mongo(path);
        probe[mpath] = probes[mpath];
      }
      let blob = new Blob([JSON.stringify(probe)], {type: 'application/json'});
      let file = new File([blob], 'probe.json', {type: 'application/json'});
      let data = {[file.name]: file};
      let probe_path = tmp_dir + '/probe.json';
      let set_res = yield back_tcoder.fs_set(probe_path, data);
      if (set_res.err == 'tcoder_down')
        return on_restart_modal_open();
      if (set_res.err)
      {
        message_api.error(set_res.err);
        return metric.error('fs_set', set_res.err);
      }
      let out_file = tmp_dir + '/probe_dailies.json';
      let err_path = tmp_dir + '/err.csv';
      let cmd_res = yield back_tcoder.cmd(['--cmd', 'probe_dailies',
        '--probe', probe_path, '--out', out_file, '--err', err_path]);
      if (cmd_res.err == 'tcoder_down')
        return on_restart_modal_open();
      if (cmd_res.err)
      {
        message_api.error(cmd_res.err);
        return metric.error('pub_tcoder_cmd', cmd_res.err);
      }
      let probe_dailies = yield back_tcoder.fs_get(out_file);
      if (probe_dailies.err == 'tcoder_down')
        return on_restart_modal_open();
      if (probe_dailies.err)
      {
        message_api.error(probe_dailies.err);
        return metric.error('fs_get', probe_dailies.err);
      }
      let bin_dailies_keys = tab_keys
        .filter(_key=>_key.startsWith('Bin: Dailies'));
      let tab_key_num = 1;
      if (bin_dailies_keys.length)
      {
        let nums = bin_dailies_keys
          .map(_key=>parseInt(_key.split(' ').at(-1), 10))
          .sort((a, b)=>b - a);
        tab_key_num = nums[0] + 1;
      }
      let tab_key = t('Bin: Dailies') + ' ' + tab_key_num;
      tab_add(tab_key, selected_and_linked_files, probe_dailies);
    }
    if (key == 'remove_files')
    {
      let _probes = {...probes};
      for (let path of selected_files)
        delete _probes[str.path2mongo(path)];
      on_probes_change(_probes);
      let _selected_paths = selected_paths
        .filter(path=>!selected_files.includes(path));
      on_selected_paths_change(_selected_paths);
      let _selected_files = selected_files
        .filter(path=>!selected_files.includes(path));
      selected_files_set(_selected_files);
    }
    if (key.startsWith('col_set'))
    {
      let col = cols.find(_col=>_col.key == ctx_col);
      if (!col)
        assert(0, 'column not found');
      if (col.is_edit)
        is_col_set_modal_open_set(true);
    }
  }), [message_api, on_selected_paths_change, tmp_dir, probes, tab_add, cols,
    selected_files, selected_paths, t, on_probes_change, tab_keys, ctx_col,
    on_restart_modal_open, on_probe_queue_change, probe_queue,
    selected_and_linked_files]);
  let header_dropdown_items = useMemo(()=>{
    return [
      {label: t('Choose columns...'), key: 'choose_cols'},
      {label: t('Add Custom Column'), key: 'add_custom_col', disabled: true},
      {label: t('Rename Column'), key: 'rename_col', disabled: true},
      {label: t('Hide Column'), key: 'hide_col', disabled: true},
      {type: 'divider'},
      {label: t('Sort on Column, Ascending'), key: 'sort_asc'},
      {label: t('Sort on Column, Descending'), key: 'sort_desc'},
    ];
  }, [t]);
  let col_preset_options = useMemo(()=>{
    return [
      {label: t('Basic'), value: 'basic', col_keys: ['reel_name', 'tc', 'fps',
        'tracks']},
    ];
  }, [t]);
  let col_keys_change = useCallback(_col_keys=>{
    col_keys_set(_col_keys);
    sorters_set([]);
  }, []);
  let row_class_name_handle = useCallback(record=>{
    if (record.is_upload)
      return 'bin-table-row';
    if (selected_files.includes(record.src_path))
      return 'bin-table-row bin-table-row-selected';
    return 'bin-table-row';
  }, [selected_files]);
  let row_handle = useCallback(record=>{
    let path = record.src_path;
    return {
      onMouseDown: e=>{
        e.stopPropagation();
        let _selected_files;
        if (e.shiftKey && !last_selected_file)
          _selected_files = [path];
        else if (e.shiftKey && last_selected_file)
        {
          let idx = data_src.findIndex(_record=>{
            return _record.src_path == path;
          });
          let start_idx = Math.min(idx, data_src.findIndex(_record=>{
            return _record.src_path == last_selected_file;
          }));
          let end_idx = Math.max(idx, data_src.findIndex(_record=>{
            return _record.src_path == last_selected_file;
          }));
          let part_selected_files = data_src
            .slice(start_idx, end_idx + 1)
            .map(r=>r.src_path);
          _selected_files = [...selected_files, ...part_selected_files];
          _selected_files = [...new Set(_selected_files)];
        }
        else if ((e.ctrlKey || e.metaKey) && selected_files.includes(path))
        {
          _selected_files = selected_files.filter(_path=>{
            return _path != path;
          });
        }
        else if ((e.ctrlKey || e.metaKey) && !selected_files.includes(path))
          _selected_files = [...selected_files, path];
        else if (selected_files.includes(path))
          _selected_files = [...selected_files];
        else
          _selected_files = [path];
        selected_files_set(_selected_files);
        last_selected_file_set(path);
        selected_cols_set([]);
      },
      onContextMenu: e=>{
        // onContextMenu in onCell doesn't work
        let parent_element = e.target.parentElement;
        let children = Array.from(parent_element.children);
        let idx = children.indexOf(e.target);
        let col_key = col_keys[idx];
        ctx_col_set(col_key);
        is_row_ctx_open_set(true);
        ctx_record_set(record);
      },
    };
  }, [last_selected_file, selected_files, data_src, col_keys]);
  let header_dropdown_click_handle = useCallback(({key})=>{
    if (key == 'choose_cols')
      is_col_choose_modal_open_set(true);
    if (key == 'sort_asc')
    {
      let _sorters = [...sorters];
      let idx = _sorters.findIndex(sorter=>sorter.key == ctx_col);
      if (idx != -1)
        _sorters.splice(idx, 1);
      _sorters.unshift({key: ctx_col, dir: 'asc'});
      sorters_set(_sorters);
    }
    if (key == 'sort_desc')
    {
      let _sorters = [...sorters];
      let idx = _sorters.findIndex(sorter=>sorter.key == ctx_col);
      if (idx != -1)
        _sorters.splice(idx, 1);
      _sorters.unshift({key: ctx_col, dir: 'desc'});
      sorters_set(_sorters);
    }
  }, [ctx_col, sorters]);
  let row_open_change_handle = useCallback(is_open=>{
    if (!is_open)
      is_row_ctx_open_set(false);
  }, []);
  let header_open_change_handle = useCallback(is_open=>{
    if (!is_open)
      is_header_ctx_open_set(false);
  }, []);
  let col_choose_modal_close_handle = useCallback(()=>{
    is_col_choose_modal_open_set(false);
  }, []);
  let set_col_modal_close_handle = useCallback(()=>{
    is_col_set_modal_open_set(false);
  }, []);
  let set_col_modal_col_name = useMemo(()=>{
    if (!ctx_col)
      return '';
    let col = cols.find(_col=>_col.key == ctx_col);
    if (!col)
      assert(0, 'column not found');
    return col.title;
  }, [cols, ctx_col]);
  let set_col_modal_opts = useMemo(()=>{
    if (!ctx_col)
      return null;
    let col = cols.find(_col=>_col.key == ctx_col);
    if (!col)
      assert(0, 'column not found');
    return col.opts;
  }, [cols, ctx_col]);
  let set_col_modal_submit_handle = useCallback(value=>{
    if (!ctx_col)
      assert(0, 'ctx_col is not set');
    let _probes = _.cloneDeep(probes);
    for (let path of selected_files)
    {
      let mpath = str.path2mongo(path);
      _probes[mpath][0][ctx_col] = value;
    }
    on_probes_change(_probes);
  }, [ctx_col, on_probes_change, probes, selected_files]);
  let col_choose_modal_submit_handle = useCallback(value=>{
    let _col_keys = col_keys.filter(key=>{
      return value.includes(key)
        || cols.find(col=>col.key === key && col.is_always_show);
    });
    let new_keys = value.filter(key=>!_col_keys.includes(key));
    _col_keys = [..._col_keys, ...new_keys];
    is_col_choose_modal_open_set(false);
    col_keys_change(_col_keys);
    selected_cols_set([]);
  }, [col_keys, col_keys_change, cols]);
  let text_view_mode_click_handle = useCallback(()=>{
    view_mode_set('text');
  }, []);
  let frame_view_mode_click_handle = useCallback(()=>{
    view_mode_set('frame');
    selected_cols_set([]);
  }, []);
  let card_click_handler_get = useCallback(file_orig=>{
    return e=>{
      if ((e.ctrlKey || e.metaKey) && selected_files.includes(file_orig))
      {
        let _selected_files = selected_files.filter(id=>id != file_orig);
        selected_files_set(_selected_files);
        selected_cols_set([]);
        return;
      }
      if ((e.ctrlKey || e.metaKey) && !selected_files.includes(file_orig))
      {
        selected_files_set([...selected_files, file_orig]);
        selected_cols_set([]);
        return;
      }
      selected_files_set([file_orig]);
      selected_cols_set([]);
    };
  }, [selected_files]);
  let query_change_handle = useCallback(e=>{
    query_set(e.target.value);
  }, []);
  let select_value = useMemo(()=>{
    let found_preset = col_preset_options.find(preset=>{
      return preset.col_keys.length == col_keys.length
        && preset.col_keys.every((key, idx)=>col_keys[idx] == key);
    });
    if (!found_preset)
      return null;
    return found_preset.value;
  }, [col_keys, col_preset_options]);
  let select_change_handle = useCallback((value, option)=>{
    col_keys_change(option.col_keys);
    selected_cols_set([]);
  }, [col_keys_change]);
  let drag_end_handle = ({active, over})=>{
    if (active.id != over?.id)
    {
      let active_idx = col_keys.findIndex(key=>key == active?.id);
      let over_idx = col_keys.findIndex(key=>key == over?.id);
      col_keys_change(arrayMove(col_keys, active_idx, over_idx));
    }
    drag_idx_set({active: null, over: null});
  };
  let drag_over_handle = ({active, over})=>{
    let active_idx = col_keys.findIndex(col=>col.key == active.id);
    let over_idx = col_keys.findIndex(col=>col.key == over?.id);
    drag_idx_set({active: active.id, over: over?.id,
      direction: over_idx > active_idx ? 'right' : 'left'});
  };
  let dropdown_items = useMemo(()=>{
    return [
      {label: t('Relink files'), key: 'relink_files'},
      {label: t('Import camera metadata'), key: 'import_cam_metadata',
        disabled: true},
      {label: t('Transcode/convert'), key: 'transcode_convert',
        disabled: !selected_and_linked_files.length},
      {label: t('Create dailies bin'), key: 'create_dailies_bin',
        disabled: !selected_and_linked_files.length},
      {label: t('Export ale'), key: 'export_ale', disabled: true},
      {label: t('Remove file(s)'), key: 'remove_files'},
    ];
  }, [selected_and_linked_files, t]);
  let tcode_modal_close_handle = useCallback(()=>{
    is_tcode_modal_open_set(false);
  }, []);
  let sorted_selected_and_linked_files = useMemo(()=>{
    return data_src
      .filter(record=>selected_and_linked_files.includes(record.src_path))
      .map(record=>record.src_path);
  }, [data_src, selected_and_linked_files]);
  return (
    <div style={{display: 'flex', flexDirection: 'column', overflow: 'hidden',
      height: '100%', gap: '8px'}}>
      {modal_ctx_holder}
      {message_ctx_holder}
      <Tcode_modal is_open={is_tcode_modal_open} tmp_dir={tmp_dir}
        on_close={tcode_modal_close_handle}
        paths={sorted_selected_and_linked_files}
        output_dir={output_dir} probes={probes}
        on_restart_modal_open={on_restart_modal_open} />
      <Tbin_col_choose_modal is_open={is_col_choose_modal_open}
        options={col_opts} col_keys={col_keys}
        on_close={col_choose_modal_close_handle}
        on_submit={col_choose_modal_submit_handle} />
      <Set_col_modal is_open={is_col_set_modal_open}
        on_close={set_col_modal_close_handle}
        col_name={set_col_modal_col_name} opts={set_col_modal_opts}
        on_submit={set_col_modal_submit_handle} />
      <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
        <Select options={col_preset_options} style={{width: '200px'}}
          value={select_value} onChange={select_change_handle} />
        <Button title={t('Display bin In Text View mode')} size="middle"
          onClick={text_view_mode_click_handle}>
          <Text_view_mode_icon />
        </Button>
        <Button title={t('Display bin In Frame View mode')} size="middle"
          onClick={frame_view_mode_click_handle}>
          <Frame_view_mode_icon />
        </Button>
        <Input size="small" allowClear value={query}
          styles={{input: {borderRadius: 0}}}
          onChange={query_change_handle} prefix={<SearchOutlined />} />
        <Dropdown menu={{items: dropdown_items, onClick: dropdown_click_handle}}
          trigger={['click']}>
          <Button title={t('Display bin In Frame View mode')} size="middle">
            <MoreOutlined />
          </Button>
        </Dropdown>
      </div>
      {view_mode == 'text' && <div style={{width: '100%', overflowX: 'auto',
        height: '100%', position: 'relative'}}>
        <Dropdown menu={{items: row_dropdown_items,
          onClick: dropdown_click_handle}} trigger={['contextMenu']}
        open={is_row_ctx_open} onOpenChange={row_open_change_handle}>
          <Dropdown menu={{items: header_dropdown_items,
            onClick: header_dropdown_click_handle}} trigger={['contextMenu']}
          open={is_header_ctx_open} onOpenChange={header_open_change_handle}>
            <div style={{height: '100%', width: '100%'}}>
              <DndContext sensors={sensors} onDragEnd={drag_end_handle}
                modifiers={[restrict_to_horizontal_axis]}
                onDragOver={drag_over_handle}
                collisionDetection={closest_center}>
                <SortableContext items={cols_in_use.map(col=>col.key)}
                  strategy={horizontal_list_sorting_strategy}>
                  <Drag_idx_ctx.Provider value={drag_idx}>
                    <Table columns={cols_in_use} dataSource={data_src}
                      size="small" pagination={false} rowKey="src_path"
                      rowClassName={row_class_name_handle} onRow={row_handle}
                      style={{width: '100%'}} scroll={{x: 'max-content'}}
                      components={{header: {cell: Tbin_table_header_cell},
                        body: {cell: Tbin_table_body_cell}}} />
                  </Drag_idx_ctx.Provider>
                </SortableContext>
                <DragOverlay>
                  <th style={{backgroundColor: '#5987B6', padding: '8px',
                    marginBottom: '1px', fontWeight: 600, fontSize: '16px',
                    lineHeight: 1.5}}>
                    {cols_in_use[cols_in_use.findIndex(i=>{
                      return i.key == drag_idx.active;
                    })]?.title}
                  </th>
                </DragOverlay>
              </DndContext>
            </div>
          </Dropdown>
        </Dropdown>
      </div>}
      {view_mode == 'frame' && <div style={{display: 'flex', flexWrap: 'wrap',
        gap: '4px', padding: '4px', overflowY: 'auto'}}>
        {Object.values(probes).map(streams=>{
          let stream = streams[0];
          return (
            <div key={stream.file_orig} style={{width: '160px', height: '100px',
              display: 'flex', flexDirection: 'column', overflow: 'hidden',
              background: selected_files.includes(stream.file_orig)
                ? '#5987B6' : '#3D3D3D', cursor: 'pointer',
              borderRadius: borderRadiusLG}}
            onClick={card_click_handler_get(stream.file_orig)}>
              <div style={{height: '80px', background: '#222'}} />
              <div style={{overflow: 'hidden', padding: '2px',
                whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                {stream.reel_name}
              </div>
            </div>
          );
        })}
      </div>}
    </div>
  );
});
let E = ()=>{
  let {t} = useTranslation();
  let {token: {colorBgContainer}} = theme.useToken();
  let [tree_data, tree_data_set] = useState([]);
  let [storage_selected_paths, storage_selected_paths_set]
    = use_storage('tlm.tcoder.selected_paths');
  let default_selected_paths = useMemo(()=>{
    return storage_selected_paths || {[t('Bin') + ' 1']: []};
  }, [storage_selected_paths, t]);
  let [selected_paths, selected_paths_set] = useState(default_selected_paths);
  let default_probe_queues = useMemo(()=>{
    return Object.keys(selected_paths)
      .map(key=>({[key]: []}))
      .reduce((accum, cur)=>({...accum, ...cur}));
  }, [selected_paths]);
  let [probe_queues, probe_queues_set] = useState(default_probe_queues);
  let default_probes = useMemo(()=>{
    return Object.keys(selected_paths)
      .map(key=>({[key]: {}}))
      .reduce((accum, cur)=>({...accum, ...cur}));
  }, [selected_paths]);
  let [probes, probes_set] = useState(default_probes);
  let [selected_dir, selected_dir_set] = useState(null);
  let [tmp_dir, tmp_dir_set] = useState(null);
  let [output_dir, output_dir_set] = useState(null);
  let [root_dirs, root_dirs_set] = useState(null);
  let [active_bin, active_bin_set] = useState('files');
  let [is_restart_modal_open, is_restart_modal_open_set] = useState(false);
  let [lut_files, lut_files_set] = useState([]);
  let restart_modal_open_handle = useCallback(()=>{
    is_restart_modal_open_set(true);
  }, []);
  let restart_modal_close_handle = useCallback(()=>{
    is_restart_modal_open_set(false);
  }, []);
  let selected_paths_change_handle = useCallback(_selected_paths=>{
    selected_paths_set(_selected_paths);
    storage_selected_paths_set(_selected_paths);
  }, [storage_selected_paths_set]);
  useEffect(()=>{
    if (!root_dirs)
      return;
    let arr = [];
    for (let root_dir of root_dirs)
    {
      arr.push({title: root_dir, key: root_dir, isLeaf: false,
        is_dir: true});
    }
    tree_data_set(arr);
  }, [root_dirs]);
  use_effect_eserf(()=>eserf(function* use_effect_dirs_get(){
    let res = yield back_tcoder.fs_dirs_get();
    if (res.err == 'tcoder_down')
      return restart_modal_open_handle();
    if (res.err)
      return metric.error('fs_dirs_get', res.err);
    tmp_dir_set(res.tmp_dir);
    output_dir_set(res.output_dir);
    root_dirs_set(res.root_dirs);
  }), [restart_modal_open_handle]);
  use_effect_eserf(()=>eserf(function* use_effect_lut_files_get(){
    if (!output_dir)
      return;
    let luts_dir = output_dir + '/lut';
    let files = yield back_tcoder.fs_ls(luts_dir);
    if (files.err == 'tcoder_down')
      return restart_modal_open_handle();
    if (files.err)
      return metric.error('fs_ls', files.err);
    lut_files_set(files);
  }), [output_dir, restart_modal_open_handle]);
  let tab_add = useCallback((tab_key, bin_selected_paths=[], bin_probes={})=>{
    if (!tab_key)
      assert(0, 'no tab_key');
    let _selected_paths = {...selected_paths, [tab_key]: bin_selected_paths};
    let _probe_queues = {...probe_queues, [tab_key]: []};
    let _probes = {...probes, [tab_key]: bin_probes};
    selected_paths_change_handle(_selected_paths);
    probe_queues_set(_probe_queues);
    probes_set(_probes);
  }, [probe_queues, probes, selected_paths, selected_paths_change_handle]);
  let tab_items = useMemo(()=>{
    let is_bins_closable = Object.keys(selected_paths).length > 1;
    return [
      {key: 'files', label: 'Files', closable: false,
        children: <Files_tab selected_dir={selected_dir}
          on_restart_modal_open={restart_modal_open_handle} />},
      ...Object.keys(selected_paths).map(tab_key=>{
        let bin_selected_paths_change_handle = bin_selected_paths=>{
          let _selected_paths = {...selected_paths,
            [tab_key]: bin_selected_paths};
          selected_paths_change_handle(_selected_paths);
        };
        let bin_probe_queue_change_handle = bin_probe_queue=>{
          probe_queues_set(o=>({...o, [tab_key]: bin_probe_queue}));
        };
        let bin_probes_change_handle = bin_probes=>{
          probes_set(o=>({...o, [tab_key]: bin_probes}));
        };
        return {key: tab_key, label: tab_key, closable: is_bins_closable,
          children: <Bin_tab output_dir={output_dir} tmp_dir={tmp_dir}
            on_selected_paths_change={bin_selected_paths_change_handle}
            selected_paths={selected_paths[tab_key]} probes={probes[tab_key]}
            on_probes_change={bin_probes_change_handle} tab_add={tab_add}
            tab_keys={Object.keys(selected_paths)} lut_files={lut_files}
            on_restart_modal_open={restart_modal_open_handle}
            probe_queue={probe_queues[tab_key]}
            on_probe_queue_change={bin_probe_queue_change_handle} />};
      }),
    ];
  }, [selected_paths, selected_dir, output_dir, probes, tab_add,
    tmp_dir, restart_modal_open_handle, lut_files, probe_queues,
    selected_paths_change_handle]);
  let tab_change_handle = useCallback(key=>{
    active_bin_set(key);
  }, []);
  let edit_handle = useCallback((key, action)=>{
    let _selected_paths = {...selected_paths};
    let _probe_queues = {...probe_queues};
    let _probes = {...probes};
    let _active_bin = active_bin;
    if (action == 'add')
    {
      let nums = Object.keys(selected_paths)
        .map(_key=>parseInt(_key.split(' ').at(-1), 10))
        .sort((a, b)=>b - a);
      let new_key = t('Bin') + ' ' + (nums[0] + 1);
      _selected_paths[new_key] = [];
      _probe_queues[new_key] = [];
      _probes[new_key] = {};
    }
    else
    {
      delete _selected_paths[key];
      delete _probe_queues[key];
      delete _probes[key];
      if (active_bin == key)
        _active_bin = Object.keys(_selected_paths)[0];
    }
    selected_paths_change_handle(_selected_paths);
    probe_queues_set(_probe_queues);
    probes_set(_probes);
    active_bin_set(_active_bin);
  }, [selected_paths, probe_queues, probes, active_bin, t,
    selected_paths_change_handle]);
  return (
    <Layout style={{minHeight: '90vh'}}>
      <Header />
      <Restart_modal is_open={is_restart_modal_open}
        on_close={restart_modal_close_handle} />
      <Layout>
        <Layout.Sider width="25%" style={{background: colorBgContainer,
          padding: '0 24px 24px'}}>
          <File_tree selected_paths={selected_paths}
            on_selected_paths_change={selected_paths_change_handle}
            tree_data={tree_data} on_tree_data_change={tree_data_set}
            on_selected_dir_change={selected_dir_set}
            on_restart_modal_open={restart_modal_open_handle}
            active_bin={active_bin} on_active_bin_change={active_bin_set}
            probe_queues={probe_queues}
            on_probe_queues_change={probe_queues_set} />
        </Layout.Sider>
        <Layout.Content style={{padding: '24px'}}>
          <Tabs type="editable-card" items={tab_items} activeKey={active_bin}
            onChange={tab_change_handle} onEdit={edit_handle} />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default E;
