// LICENSE_CODE MIT
import {create, all} from 'mathjs';

let E = {};
export default E;

let math = create(all, {number: 'Fraction'});
export let fraction = E.fraction = num=>{
  let ret = math.fraction(num);
  ret = math.format(ret, {fraction: 'ratio'});
  return ret;
};
