// LICENSE_CODE TLM
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Col, Row, Avatar, Button, Space, Typography, Select,
  message} from 'antd';
import {ContactsOutlined} from '@ant-design/icons';
import {JSONTree} from 'react-json-tree';
import auth from './auth.js';
import xdate from '../../../util/date.js';
import eserf from '../../../util/eserf.js';
import je from '../../../util/je.js';
import back_app from './back_app.js';
import metric from './metric.js';
import {Loading, use_qs, use_effect_eserf} from './comp.js';

let {Title, Text} = Typography;
export const E = ()=>{
  let {t} = useTranslation();
  let {qs_o, search} = use_qs();
  let [is_select_load, is_select_load_set] = useState(false);
  let [org_options, org_options_set] = useState([]);
  let [message_api, message_ctx_holder] = message.useMessage();
  let [tasks, tasks_set] = useState([]);
  let {user, token, user_full, org} = auth.use_auth();
  let [name, name_set] = useState();
  use_effect_eserf(()=>eserf(function* use_effect_profile(){
    if (!token)
      return;
    let _tasks = yield back_app.org_task_list(token, user_full.email,
      org.id);
    if (_tasks.err)
      return void metric.error('org_task_list_err', _tasks.err);
    tasks_set(_tasks);
  }), [token, org, user_full]);
  useEffect(()=>{
    if (!user_full)
      return;
    if (user_full.first && user_full.last)
      name_set(user_full.first+' '+user_full.last);
    let _org_options = [];
    for (let org_id in user_full.orgs)
    {
      let _org = user_full.orgs[org_id];
      _org_options.push({value: _org.id, label: _org.lbl});
    }
    org_options_set(_org_options);
  }, [user_full]);
  let on_org_select = org_id=>eserf(function* _on_org_select(){
    is_select_load_set(true);
    let res = yield back_app.user_set_org_id_select(token, user.email, org_id);
    is_select_load_set(false);
    if (res.err)
      return void message_api.error(t('org select failed'));
    je.set_inc('profile.update_n');
  });
  if (!user_full)
    return <Loading/>;
  return <Row justify="center">
    {message_ctx_holder}
    <Col>
      <Row justify="center"><Title>{t('PROFILE')}</Title></Row>
      <Row>
        <Col offset={1} span={22}>
          <Row>
            <Col xs={{span: 24}} md={{span: 2}}>
              <Avatar src={user.picture}/>
            </Col>
            <Col xs={{span: 24}} md={{span: 21, offset: 1}}>
              <Title level={3}>{user.email}</Title>
            </Col>
          </Row>
          {name && <Row>
            <Title level={3}>{name}</Title>
          </Row>}
          {!user.email_verified && <Row>
            <Button onClick={
              ()=>back_app.user_email_resend(token, user.email)}>
              {t('Resend verification email')}
            </Button>
          </Row>}
          {user_full &&
          <Row>
            <Col span={8}>
              <Title level={4}>Org selector:</Title>
            </Col>
            <Col span={10}>
              <Select defaultValue={org?.id}
                loading={is_select_load}
                onChange={on_org_select}
                options={org_options} />
            </Col>
          </Row>}
          {user_full &&
          <Row>
            <Col span={8}>
              <Title level={4}>User permissions:</Title>
            </Col>
            <Col span={10}>
              <Link to={{pathname: '/perm', search}}>
                <ContactsOutlined style={{fontSize: '200%'}}/>
              </Link>
            </Col>
          </Row>}
          <Row>
            <Space>
              <Col><Title level={4}>{t('Your current credits')}</Title></Col>
              <Col><Title level={4}>{org?.credit?.v}</Title></Col>
            </Space>
          </Row>
          {qs_o.dbg && <>
            <Row><JSONTree data={user||{}} /></Row>
            <Row><JSONTree data={user_full||{}} /></Row>
          </>}
        </Col>
      </Row>
      <Row>
        <Col>
          <Row justify="center"><Col><Text>{t('USAGE HISTORY')}</Text></Col>
          </Row>
          {tasks.map((task, i)=><Row key={'tasks_'+i}>
            <Col>
              <Space>
                <Text>{xdate.delta2lbl(xdate.diffdates(new Date(),
                  task.ts.insert, 'seconds'), t)} {t('ago')}</Text>
                {/* XXX colin: use func for this */}
                <Text>{task.event.user_id.replaceAll('@@', '.')}</Text>
                <Text>{t(task.event.type)}</Text>
                {task.event.ip && <Text>{task.event.ip}</Text>}
                <Text>{task.event.country}</Text>
                <Text>{t('Credit cost')} {task.event.credit.v}</Text>
                {qs_o.dbg && <>
                  <Button>{t('Open in Editor')}</Button>
                  <Button>{t('Download')}</Button>
                </>}
              </Space>
            </Col>
            {qs_o.dbg && <>
              <JSONTree data={task} />
            </>}
          </Row>)}
        </Col>
      </Row>
    </Col>
  </Row>;
};

export default auth.with_auth_req(E);
