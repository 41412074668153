// LICENSE_CODE TLM
import {UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import {Button, message, Upload, Row, Space, Rate, Modal, Table, Form, Select,
  Typography, Col, Tooltip, Switch, Radio} from 'antd';
import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import xurl from '../../../util/xurl.js';
import csv from '../../../util/csv.js';
import str from '../../../util/str.js';
import eserf from '../../../util/eserf.js';
import ereq from '../../../util/ereq.js';
import je from '../../../util/je.js';
import auth from './auth.js';
import Contact from './contact.js';
import config_ext from './config_ext.js';
import {Clickable, Loading, download, use_qs} from './comp.js';
import back_app from './back_app.js';
import metric from './metric.js';
import {Timeline_panel} from './editor.js';

let {Title, Text} = Typography;
let prefix = config_ext.back.app.url;

let Error_tcs_modal = React.memo(({token, err_tcs, is_open, on_close,
  err_id2str})=>{
  let {t} = useTranslation();
  let [message_api, message_ctx_holder] = message.useMessage();
  const [form] = Form.useForm();
  let [selected_row, selected_row_set] = useState(null);
  let [src_ids, src_ids_set] = useState([]);
  let columns = useMemo(()=>{
    return [
      {dataIndex: 'tcs', key: 'tcs', title: t('Timecodes')},
      {dataIndex: 'src_id', key: 'src_id', title: t('Source ID')},
      {dataIndex: 'err_lbl', key: 'err_lbl', title: t('Error')},
    ];
  }, [t]);
  let data = useMemo(()=>{
    if (!err_tcs)
      return [];
    return err_tcs.map(({in_tc, out_tc, camroll, err})=>({
      key: camroll + in_tc + out_tc, tcs: `${in_tc} - ${out_tc}`,
      src_id: camroll, err_lbl: err_id2str(err), in_tc, out_tc, err}));
  }, [err_id2str, err_tcs]);
  let checkbox_props_get = useCallback(record=>{
    return {name: record.key};
  }, []);
  let radio_change_handle = useCallback((selected_row_keys, selected_rows)=>{
    selected_row_set(selected_rows[0]);
  }, []);
  let csv_get = useCallback(()=>{
    let content = csv.row('tc_in', 'tc_out', 'src_id', 'err');
    if (!err_tcs)
      return content;
    for (let row of err_tcs)
      content += csv.row(row.in_tc, row.out_tc, row.camroll, row.err);
    return content;
  }, [err_tcs]);
  let csv_download = useCallback(()=>{
    let content = csv_get();
    let blob = new Blob([content], {type: 'text/csv;charset=utf-8;'});
    download(URL.createObjectURL(blob), 'data.csv');
  }, [csv_get]);
  let modal_footer_get = useCallback((_, {OkBtn})=>{
    return (
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Button icon={<DownloadOutlined />} onClick={csv_download}>
          Download CSV
        </Button>
        <OkBtn />
      </div>
    );
  }, [csv_download]);
  let pagination = useMemo(()=>{
    return {hideOnSinglePage: true, showSizeChanger: false};
  }, []);
  let row_selection = useMemo(()=>{
    return {type: 'radio', getCheckboxProps: checkbox_props_get,
      onChange: radio_change_handle};
  }, [checkbox_props_get, radio_change_handle]);
  let src_ids_options = useMemo(()=>{
    return src_ids.map(src_id=>({value: src_id, label: src_id}));
  }, [src_ids]);
  useEffect(()=>{
    let es = eserf(function* _use_effect_src_ids_get(){
      let _src_ids = yield back_app.aff_tc_src_ids_get(token);
      if (_src_ids.err)
      {
        message_api.error(t('Something went wrong'));
        return metric.error('aff_tc_src_ids_get_err', _src_ids.err);
      }
      src_ids_set(Object.keys(_src_ids));
    });
    return ()=>es.return();
  }, [message_api, t, token]);
  return (
    <Modal title={t('Timecode Errors')} open={is_open} onOk={on_close}
      onCancel={on_close} width={800} footer={modal_footer_get} destroyOnClose>
      {message_ctx_holder}
      <Table columns={columns} dataSource={data} pagination={pagination}
        rowSelection={row_selection} />
      {selected_row?.err == 'Timecodes not found' && <Form form={form}
        style={{display: 'flex', justifyContent: 'space-between',
          marginBottom: '20px'}}>
        <Form.Item label={t('New Source ID')}>
          <Select defaultValue={src_ids[0]} options={src_ids_options} />
        </Form.Item>
        <Form.Item>
          <Button type="primary">Replace</Button>
        </Form.Item>
      </Form>}
    </Modal>
  );
});
let E = ()=>{
  let {t} = useTranslation();
  let {qs_o} = use_qs();
  let [modal, modal_ctx_holder] = Modal.useModal();
  let [message_api, message_ctx_holder] = message.useMessage();
  let {user, token, org, user_full} = auth.use_auth();
  let [lbin, lbin_set] = useState(null);
  let [is_rate, set_is_rate] = useState(false);
  let [tc_json, set_tc_json] = useState();
  let [is_delta, set_is_delta] = useState(true);
  let [task_v, task_v_set] = useState();
  let [is_not_highlight, set_is_not_highlight] = useState(false);
  let [is_no_extra, set_is_no_extra] = useState(false);
  let [is_src_cut_ids, set_is_src_cut_ids] = useState(false);
  let [is_reduce, set_is_reduce] = useState(true);
  let [is_rename, set_is_rename] = useState(false);
  let [is_wps_detect, set_is_wps_detect] = useState(false);
  let [is_loading, set_is_loading] = useState(false);
  let [is_err_tcs_modal_open, is_err_tcs_modal_open_set] = useState(false);
  let _err_id2str = useMemo(()=>({
    is_not_aaf: 'Is not an AAF format, look at how you exported it',
    invalid_file_type: 'Invalid file type',
    org_no_credit:
      <>
        <Space direction="vertical">
          <div>
            {t('Contact your Toolium representative to buy more credits')}
          </div>
          <div>{t('See your credits in profile')}</div>
          <Contact is_no_txt={true}/>
        </Space>
      </>,
    org_is_disable:
      <>
        <div>
          {t('Contact your Toolium representative to activate your '
            +'account')}
        </div>
        <Contact is_no_txt={true}/>
      </>,
    proc_type_unknown: 'Process type unknown',
    no_clips_found_on_sequence: 'No clips found on sequence',
    no_clips_for_writing_sequence: 'No clips for writing sequence',
    no_sequence_found_in_aaf_file: 'No sequence found in AAF file',
    sequence_has_length_of_0: 'Sequence_has_length of 0',
    group_clip_found_on_sequence: 'Group clip found on sequence',
    group_clip_found_on_sequence_2: 'Group clip found on sequence 2',
    unknown_selector_type_found_on_sequence:
      'Unknown selector type found in sequence',
    clip_framerate_does_not_match_sequence_framerate:
      'Clip framerate does not match sequence framerate',
    subclips_with_motion_effects_are_not_supported:
      'Subclips with motion effects are not supported',
    in_greater_equal_out: 'Some of your timecodes are invalid',
    file_corrupt: 'Upload file is corrupt',
    invalid_file_type_use_docx:
      <>
        <Space direction="vertical">
          <div>
            {t('Invalid file DOC, use DOCX instead, '
              +'you can try this 3rd party tool')}
          </div>
          <Clickable>
            <Button type="primary"
              href="https://www.zamzar.com/convert/doc-to-docx/"
              target="_blank" rel="noreferrer">
              {t('Open DOC to DOCX converter')}
            </Button>
          </Clickable>
        </Space>
      </>,
    invalid_mismatch_aaf_no_src_cut_ids_found:
      'Invalid AAF no sequence or camroll found in AAF using it rom DOCX',
    empty:
      <>
        <div>{t('File is missing any cuts, try to click')}</div>
        <Clickable>
          <Button type="primary"
            onClick={()=>set_is_not_highlight(true)}>
            {t('SET ALL ROWS')}
          </Button>
        </Clickable>
      </>,
    tc_not_found: 'Cut not found',
    tcs_not_found: 'No cuts found in timeline AAF',
    no_server: 'Unable to contact server',
  }), [t]);
  let err_id2str = useCallback(err_id=>{
    let ret = _err_id2str[err_id]||err_id;
    if (ret==err_id)
      metric.error('cutter_missing_err_id', ret);
    else
      metric.error('cutter_upload_err_'+err_id);
    return ret;
  }, [_err_id2str]);
  React.useEffect(()=>{
    set_is_rate(org && user_full && user_full.rate==undefined && org.credit
      && org.credit.v<-50);
  }, [org, user_full]);
  let task_v_on_change = event=>{
    let task_i = event.target.value;
    let task = task_arr[task_i];
    task_v_set(task);
  };
  let submit_on_click = (_tc_json, _task_v)=>eserf(function* _submit_on_click(){
    set_is_loading(true);
    let res = yield back_app.cutter_aaf_upload(token, user.email, _tc_json,
      {aaf_in: _task_v.f_in, etag: _task_v.etag, task_id: _task_v.task_id,
        is_src_cut_ids, is_rename, is_reduce});
    set_is_loading(false);
    if (res.err)
    {
      metric.error('submit_on_click_err', str.j2s(_task_v));
      return void message_api.error(`${_task_v.f_in} file upload failed `
        +`${res.err}`);
    }
    let _lbin = res.lbin;
    if (_lbin)
      _lbin.rec_monitor_in = _lbin.rec_monitor_out;
    lbin_set(_lbin);
    let url = xurl.url(prefix+'/private/aaf/get.aaf',
      {email: user.email, file: res.file, token, ver: config_ext.ver});
    je.set_inc('auth.update_n'); // update org to get list of tasks
    // XXX colin: merge same code when uploading an aaf
    if (qs_o.dbg && _lbin?.err_tcs?.length)
    {
      let table = <>
        {_lbin.err_tcs.map((v, i, arr)=><Row key={i}>
          <Col span={14}><Text>{v.in_tc} - {v.out_tc}</Text></Col>
          <Col span={1}></Col>
          <Col span={4}><Text>{v.camroll}</Text></Col>
          <Col span={1}></Col>
          <Col span={4}><Text>{err_id2str(v.err)}</Text></Col>
        </Row>)}
      </>;
      message_api.error(table, 0);
    }
    else
      message_api.success(`${_task_v.f_in} file uploaded successfully`);
    // XXX colin: fix so doenst need to be after all ui changes
    download(url);
  });
  let err_tcs_modal_close = useCallback(()=>{
    is_err_tcs_modal_open_set(false);
  }, []);
  // XXX colin: wait till token exists before allowing usage
  // XXX colin: change to use ereq and send to metric on err
  let props = {
    name: 'file',
    listType: 'picture',
    action: xurl.url(prefix+'/private/cutter/aaf/upload.json', {
      email: user.email, ver: config_ext.ver, tc_json, is_src_cut_ids,
      is_rename, is_reduce, dbg_py: qs_o.dbg_py}),
    onChange(info){
      if (info.file.status != 'uploading')
        console.log(info.file, info.fileList);
      if (info.file.status == 'done')
      {
        let resp = info.file.response;
        if (resp.err)
          return metric.error('error on done', resp.err);
        message_api.success(`${info.file.name} file uploaded successfully`);
        let file = resp.file;
        let _lbin = resp.lbin;
        if (_lbin)
          _lbin.rec_monitor_in = _lbin.rec_monitor_out;
        lbin_set(_lbin);
        info.file.url = xurl.url(prefix+'/private/aaf/get.aaf',
          {email: user.email, file, token, ver: config_ext.ver});
        let file_prev = info.file.name;
        info.file.name =
          <>
            <span>
              {str.trun(file_prev, 50)} {'->'} {str.trun(file, 20)}
            </span>
            <Button icon={<DownloadOutlined/>}/>
          </>;
        je.set_inc('auth.update_n'); // update org to get list of tasks
        if (_lbin?.err_tcs?.length)
          is_err_tcs_modal_open_set(true);
        download(info.file.url);
      }
      else if (info.file.status == 'error')
      {
        let resp = info.file.response;
        let err_s = err_id2str(info.file.error?.target?.status===0
          ? 'no_server' : resp?.err);
        info.file.error.message = err_s;
        modal.error({title: `${info.file.name} file upload failed`,
          content: err_s});
        return void message_api.error(`${info.file.name} file upload failed `
          +`${err_s}`);
      }
    },
  };
  let props_csv = {
    name: 'file',
    listType: 'picture',
    action: xurl.url(prefix+'/private/cutter/csv/upload.json',
      {email: user.email, ver: config_ext.ver,
        delta_sec: is_delta ? 30 : undefined, is_not_highlight, is_no_extra,
        is_wps_detect}),
    onChange(info){
      if (info.file.status != 'uploading')
        console.log(info.file, info.fileList);
      if (info.file.status == 'done')
      {
        let resp = info.file.response;
        if (resp.err)
          return metric.error('error on done', resp.err);
        set_tc_json(resp.file);
        message_api.success(`${info.file.name} file uploaded successfully`);
      }
      else if (info.file.status == 'error')
      {
        let resp = info.file.response;
        let err_s = err_id2str(info.file.error?.target?.status===0
          ? 'no_server' : resp?.err);
        info.file.error.message = err_s;
        modal.error({title: `${info.file.name} file upload failed`,
          content: err_s});
        return void message_api.error(`${info.file.name} file upload failed `
          +`${err_s}`);
      }
    },
  };
  let _on_change = rate=>{
    // XXX colin: ask for feedback and why?
    back_app.user_set_rate(token, user.email, rate);
    if (rate<4)
      return void set_is_rate(false);
    // XXX colin: change to be toolium.org
    window.location.href = 'https://www.trustpilot.com/review/www.toolium.org';
  };
  if (token)
    props.headers = ereq.auth_hdr(token);
  if (token)
    props_csv.headers = ereq.auth_hdr(token);
  if (is_rate)
  {
    modal.confirm({
      title: 'Rate the app to help us improve',
      content: <Clickable>
        <Rate onChange={_on_change} allowHalf defaultValue={3.5} />
      </Clickable>,
      okButtonProps: {
        disabled: true,
        style: {display: 'none'},
      },
      cancelText: 'I don\'t wanna',
      onOk(){},
      onCancel(){},
    });
  }
  else
    Modal.destroyAll();
  if (!token)
    return <Loading/>;
  let tasks = org?.tasks?.cutter||{};
  // XXX colin: add file and check on back_app if file exists if not get
  let task_arr = [];
  for (let task_id in tasks)
  {
    if (!tasks[task_id]?.etag)
      continue;
    task_arr.push({...tasks[task_id], task_id, i: task_arr.length});
  }
  return (
    <Row justify="center">
      {modal_ctx_holder}
      {message_ctx_holder}
      <Error_tcs_modal is_open={is_err_tcs_modal_open} token={token}
        err_tcs={lbin?.err_tcs} err_id2str={err_id2str}
        on_close={err_tcs_modal_close} />
      <Col>
        <Space direction="vertical" size="large" align="center">
          <Row><Title>CUTTER</Title></Row>
          <Row>
            <Col span={24}>
              <iframe width="100%" height="100%" type="text/html"
                src="https://www.youtube.com/embed/qxLzLzxhH8g?autoplay=1&mute=1"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope;
                picture-in-picture" allowFullScreen>
              </iframe>
            </Col>
          </Row>
          <Row>
            <Space size="large" direction="vertical">
              <Clickable>
                <Tooltip title={
                  <Space direction="vertical">
                    <Row>{t('OFF: Timecodes have start and end')}</Row>
                    <Row>{t('ON: No end timecode and just add 30 seconds '
                      +'from start timecode')}</Row>
                  </Space>}>
                  <Switch defaultChecked
                    checkedChildren={t('NO END TIMECODE USE 30 SECONDS')}
                    unCheckedChildren={t('HAS END TIMECODE')}
                    checked={is_delta}
                    onChange={(checked, event)=>set_is_delta(checked)}/>
                </Tooltip>
              </Clickable>
              <Clickable>
                <Tooltip title={
                  <Space direction="vertical">
                    <Row>{t('OFF: Create cuts using highlighted text')}</Row>
                    <Row>{t('ON: Create cuts for all rows in '
                      +'the document. Ignoring the highlights')}</Row>
                  </Space>}>
                  <Switch checkedChildren={t('ALL ROWS')}
                    unCheckedChildren={t('ONLY HIGHLIGHTED')}
                    checked={is_not_highlight}
                    onChange={(checked, event)=>set_is_not_highlight(checked)}/>
                </Tooltip>
              </Clickable>
              <Clickable>
                <Tooltip title={
                  <Space direction="vertical">
                    <Row>{t('OFF: Use 0 seconds for handles')}</Row>
                    <Row>{t('ON: Use 5 seconds for handles')}</Row>
                  </Space>}>
                  <Switch checkedChildren={t('HANDLES OFF')}
                    unCheckedChildren={t('HANDLES ON')}
                    checked={is_no_extra}
                    onChange={(checked, event)=>set_is_no_extra(checked)}/>
                </Tooltip>
              </Clickable>
              <Clickable>
                <Tooltip title={
                  <Space direction="vertical">
                    <Row>{t('OFF: Ignore source camrolls. This will '
                      + 'create cuts only according to source timecode.')}
                    </Row>
                    <Row>{t('ON: Use source camrolls. This will '
                      + 'create cuts according to source timecode '
                      + 'and source clip camroll')}</Row>
                  </Space>}>
                  <Switch checkedChildren={t('USE CAMROLL IN DOCX')}
                    unCheckedChildren={t('IGNORE CAMROLL IN DOCX')}
                    checked={is_src_cut_ids}
                    onChange={(checked, event)=>set_is_src_cut_ids(checked)}/>
                </Tooltip>
              </Clickable>
              <Clickable>
                <Tooltip title={
                  <Space direction="vertical">
                    <Row>{t('OFF: AAF downloaded will not be renamed')}
                    </Row>
                    <Row>{t('ON: Rename AAF to DOCX file name')}</Row>
                  </Space>}>
                  <Switch checkedChildren={t('RENAME AAF')}
                    unCheckedChildren={t('NO RENAME')}
                    checked={is_rename}
                    onChange={(checked, event)=>set_is_rename(checked)}/>
                </Tooltip>
              </Clickable>
              <Clickable>
                <Tooltip title={
                  <Space direction="vertical">
                    <Row>{t('OFF: word-per-second will be 0.37')}
                    </Row>
                    <Row>{t('ON: word-per-second will be detected')}</Row>
                  </Space>}>
                  <Switch checkedChildren={t('DETECT WPS')}
                    unCheckedChildren={t('WPS IS 0.37')}
                    checked={is_wps_detect}
                    onChange={(checked, event)=>set_is_wps_detect(checked)}/>
                </Tooltip>
              </Clickable>
              <Clickable>
                <Tooltip title={
                  <Space direction="vertical">
                    <Row>{t('OFF: don\'t change order or merge cuts')}</Row>
                    <Row>{t('ON: reduce cuts by merging and sorting may '
                      +'change order')}
                    </Row>
                  </Space>}>
                  <Switch checkedChildren={t('USE REDUCE')}
                    unCheckedChildren={t('KEEP ORDER AND CUTS')}
                    checked={is_reduce}
                    onChange={(checked, event)=>set_is_reduce(checked)}/>
                </Tooltip>
              </Clickable>
            </Space>
          </Row>
          <Row>
            <Upload {...props_csv}>
              <Clickable>
                <Button type="primary"
                  style={{height: '5vw', width: '60vw', fontSize: '3vw'}}
                  icon={<UploadOutlined />}>
                  {t('Click to Upload DOCX')}
                </Button>
              </Clickable>
            </Upload>
          </Row>
          <Row>
            <Upload {...props}>
              <Clickable>
                <Button type="primary" disabled={!tc_json}
                  style={{height: '5vw', width: '60vw', fontSize: '3vw'}}
                  icon={<UploadOutlined />}>
                  {t('Click to Upload AAF')}
                </Button>
              </Clickable>
            </Upload>
          </Row>
          <Row>
            <Radio.Group disabled={!tc_json} onChange={task_v_on_change}
              value={task_v?.i}>
              <Space direction="vertical">
                {task_arr.map((v, i, arr)=><Clickable key={i}>
                  <Radio value={i} key={i}>{v.f_in}</Radio>
                </Clickable>)}
              </Space>
            </Radio.Group>
          </Row>
          <Row>
            <Clickable>
              <Button type="primary" disabled={!tc_json || !task_v}
                style={{height: '5vw', width: '60vw', fontSize: '3vw'}}
                loading={is_loading}
                onClick={()=>submit_on_click(tc_json, task_v)}>
                {t('Submit')}
              </Button>
            </Clickable>
          </Row>
        </Space>
      </Col>
      {lbin?.rec_monitor_in && <Row
        style={{minHeight: '45vh', width: '100%', marginTop: '48px'}}
        data-aos="fade-up"
      >
        <Col span={24}>
          <Timeline_panel lbin={lbin} />
        </Col>
      </Row>}
    </Row>
  );
};

export default auth.with_auth_req(E);
