// LICENSE_CODE TLM
import React, {useState, useEffect} from 'react';
import React_markdown from 'react-markdown';
import Rehype_raw from 'rehype-raw';
import Remark_gfm from 'remark-gfm';
import {Typography, Divider, Row, Col, theme} from 'antd';
import {useTranslation} from 'react-i18next';
import blog__002 from './blog__002__grouper_rules.md';
import blog__003 from './blog__003__grouper_features.md';
import blog__004 from './blog__004__save_time.md';
import blog__005 from './blog__005__happy_new_year_2023.md';
import blog__006 from './blog__006__rename_feature.md';
import blog__007 from './blog__007__sec.md';
import blog__008 from './blog__008__api.md';
import eserf from '../../../util/eserf.js';
import ereq from '../../../util/ereq.js';

// XXX colin: move into config
const {Title} = Typography;
// order is important first is most recent
let blogs = [{
  url: blog__008,
  title: 'How to use our API?',
  subtitle: 'Edit AAFs with an API',
  ts: {insert: new Date('2024-06-17')},
}, {
  url: blog__007,
  title: 'How secure is Toolium?',
  subtitle: 'Upload your files with peace of mind',
  ts: {insert: new Date('2023-02-19')},
}, {
  url: blog__006,
  author: 'Cavan Xem',
  author_link: 'https://www.linkedin.com/in/cavan-xem-49b77615b',
  title: 'How does Track Rename Feature save on editor hours?',
  subtitle: 'No more wasting time trying to find the correct audio track!',
  ts: {insert: new Date('2023-01-17')},
}, {
  is_hide: true,
  url: blog__005,
  author: 'Cavan Xem',
  author_link: 'https://www.linkedin.com/in/cavan-xem-49b77615b',
  title: 'Happy new year',
  subtitle: 'Updates for 2023',
  ts: {insert: new Date('2022-12-26')},
}, {
  url: blog__004,
  author: 'Cavan Xem',
  author_link: 'https://www.linkedin.com/in/cavan-xem-49b77615b',
  title: 'Acmetools 2 Toolium',
  subtitle: 'If you had an extra three hours in your day, '
    +'how would you spend it?',
  ts: {insert: new Date('2022-11-24')},
}, {
  url: blog__002,
  author: 'Cavan Xem',
  author_link: 'https://www.linkedin.com/in/cavan-xem-49b77615b',
  title: 'Grouper Basic Rules',
  subtitle: 'How does the Toolium Grouper create group clips for'
    +' Avid Media Composer?',
  ts: {insert: new Date('2022-11-15')},
}, {
  url: blog__003,
  author: 'Cavan Xem',
  author_link: 'https://www.linkedin.com/in/cavan-xem-49b77615b',
  title: 'Grouper Options and Features',
  subtitle: 'Everything you didn\'t know about Toolium Grouper but where '
    +'forced to find out!',
  ts: {insert: new Date('2022-11-14')},
}];
let E = ()=>{
  let {t} = useTranslation();
  let {token} = theme.useToken();
  let [blog_data, blog_data_set] = useState([]);
  useEffect(()=>{
    let es = eserf(function* blog_get(){
      let ess = [], _blogs = [];
      for (let i=0; i<blogs.length; i++)
      {
        if (blogs[i].is_hide)
          continue;
        ess.push(ereq.get(blogs[i].url));
        _blogs.push(blogs[i]);
      }
      let ess_ret = yield this.wait_ret(ess);
      for (let i=0; i<ess_ret.length; i++)
      {
        let b = _blogs[i];
        let es_ret = ess_ret[i];
        b.txt = es_ret.data;
      }
      blog_data_set(_blogs);
    });
    return ()=>es.return();
  }, []);
  return <>
    <Row justify="center"><Title>{t('BLOGS')}</Title></Row>
    <Row justify="left" style={{color: token.colorTextBase}}>
      <Col xs={{span: 22, offset: 1}} md={{span: 16, offset: 4}}>
        {blog_data.map(b=><div key={b.url}>
          <Title key={b.url+b.ts.insert.toISOString()}>
            {b.title}
          </Title>
          <Title level={4}>
            {t('Published')} {b.ts.insert.toLocaleDateString()}
          </Title>
          {b.author && <Title level={4}>{t('Author')} <a href={b.author_link}
            target="_blank" rel="noreferrer">
            {b.author}</a>
          </Title>}
          <Title level={3} style={{backgroundColor: token.colorPrimary}}>
            {b.subtitle}
          </Title>
          <React_markdown
            className="react-markdown"
            remarkPlugins={[Remark_gfm]}
            rehypePlugins={[Rehype_raw]} key={b.url+'__txt'}>
            {b.txt}
          </React_markdown>
          <Divider/>
        </div>)}
      </Col>
    </Row>
  </>;
};

export default E;
