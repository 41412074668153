// LICENSE_CODE MIT
import React, {useMemo, useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Spin, Button, Grid, Modal, Typography, Row, Col} from 'antd';
import {LoadingOutlined, CloseCircleOutlined,
  ThunderboltOutlined} from '@ant-design/icons';
import {purple} from '@ant-design/colors';
import {useTranslation} from 'react-i18next';
import config_ext from './config_ext.js';
import je from '../../../util/je.js';
import storage from '../../../util/storage.js';
import xurl from '../../../util/xurl.js';
import eserf from '../../../util/eserf.js';

let E = {};
let {useBreakpoint} = Grid;
let {Title, Text} = Typography;
let ant_icon = <LoadingOutlined style={{fontSize: '25vh'}} spin />;

export let Clickable = React.memo(({children, style, cursor, ...rest})=>{
  return <div className={`grow ${cursor ? 'cursor' : ''}`}
    style={style} {...rest}>{children}</div>;
});

export let Bounce = React.memo(({children, style, is_bounce, ...rest})=>{
  let classname = is_bounce?
    'animate__animated animate__bounce animate__repeat-2':'';
  return <div {...rest} className={classname} style={style}>{children}</div>;
});

export let Closable = React.memo(({children, style={}, on_close, tooltip})=>{
  let [is_visible, set_is_visible]=useState(true);
  let _on_close = useCallback(()=>{
    if (on_close)
    {
      on_close();
      return;
    }
    set_is_visible(false);
  }, [on_close]);

  if (!is_visible)
    return null;
  return <div style={{...style, position: 'relative'}}>
    {children}
    <Button shape="circle" title={tooltip} icon={<CloseCircleOutlined/>}
      onClick={_on_close} style={{position: 'absolute', top: 0, right: 0,
        transform: 'translate(50%, -50%)'}} />
  </div>;
});

// XXX colin: support different background colors
let is_dark = true;
export let Loading = E.Loading = ()=><>
  <Spin style={{textAlign: 'center', paddingTop: '25vh',
    position: 'absolute', zIndex: '99',
    top: '0', left: '0', width: '100vw',
    height: '100vh', background: is_dark ? '#000000' : '#ffffff'}}
  indicator={ant_icon} />
</>;

export let Scroll_to_top = ()=>{
  let {pathname} = useLocation();
  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

let inited_values = {};
// path must always be a hardcoded string
export let use_je = (path, initial_value)=>{
  if (!inited_values[path] && initial_value !== undefined)
  {
    je.set(path, initial_value);
    inited_values[path] = true;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let [value, value_set] = useState(je.get(path));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(()=>{
    let listener = je.on(path, ()=>{
      value_set(je.get(path));
    }, {init: false});
    return ()=>je.off(listener);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let je_set = useCallback(next_value=>je.set(path, next_value), [path]);
  return [value, je_set];
};

export let use_storage = (key, opt)=>{
  // XXX: pass opt with is_json
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let [value, value_set] = useState(storage.get_json(key));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let _value_set = useCallback(_value=>{
    if (typeof _value == 'function')
      _value = _value(value);
    value_set(_value);
    if (typeof _value == 'object')
      storage.set_json(key, _value);
    else
      storage.set(key, _value);
  }, [key, value]);
  return [value, _value_set];
};

export let download = (url, filename)=>{
  if (!filename)
    filename = url.split('/').pop();
  let link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
};

export let Gctx = React.createContext({});
export let use_focus = E.use_focus = E.useFocus = ()=>{
  let [is_focus, set_is_focus] = useState(true);
  useEffect(()=>{
    let focus_cb = ()=>set_is_focus(true);
    let blur_cb = ()=>set_is_focus(false);
    window.addEventListener('focus', focus_cb);
    window.addEventListener('blur', blur_cb);
    return ()=>{
      window.removeEventListener('focus', focus_cb);
      window.removeEventListener('blur', blur_cb);
    };
  }, []);
  return {is_focus};
};

export let use_qs = E.use_qs = E.useQs = ()=>{
  let location = useLocation();
  let navigate = useNavigate();
  let qs_o = useMemo(()=>{
    return xurl.qs_parse(location.search, true);
  }, [location.search]);
  let qs_set = useCallback(_qs_o=>{
    navigate(xurl.url(location.pathname, _qs_o));
  }, [location.pathname, navigate]);
  return {qs_o, search: location.search, pathname: location.pathname, qs_set};
};

export let use_qs_clear = E.use_qs_clear = E.useQsClear = qs_o_clear=>{
  let navigate = useNavigate();
  return useEffect(()=>()=>{
    let _qs_o = xurl.qs_parse(window.location.search, true);
    for (let field in qs_o_clear)
      delete _qs_o[field];
    navigate(xurl.url(window.location.pathname, _qs_o));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
};

export let use_es_root = E.use_es_root = E.useEsRoot = ()=>{
  let es_root = useMemo(()=>{
    return eserf(function* workspace_init_wait(){yield this.wait();});
  }, []);
  useEffect(()=>()=>{
    es_root.return();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return es_root;
};

export let use_on_unmount = E.use_on_unmount = E.useOnUnmount = (cb, deps)=>{
  let is_unmounting = useRef(false);
  useEffect(()=>{
    return ()=>is_unmounting.current = true;
  }, []);
  useEffect(()=>{
    return ()=>{
      if (!is_unmounting.current)
        return;
      cb();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export let Stars_bg = React.memo(()=>{
  return <div className="bg-animation">
    <div className="stars"></div>
    <div className="stars2"></div>
    <div className="stars3"></div>
    <div className="stars4"></div>
  </div>;
});

export let use_is_mobile = E.use_is_mobile = E.useIsMobile = ()=>{
  let screens = useBreakpoint();
  let is_mobile = useMemo(()=>screens?.xs, [screens]);
  if (screens?.xs===undefined)
    return {is_ready: false};
  return {is_mobile: !!is_mobile, is_ready: true};
};

export let use_effect_eserf = E.useEffectEserf = (es_cb, deps)=>{
  return useEffect(()=>{
    let es = es_cb();
    return ()=>es.return();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

let Thunderbolt_icon = React.memo(()=>{
  return <ThunderboltOutlined style={{color: purple.primary,
    marginRight: 4}}/>;
});
export let Org_no_credit = React.memo(()=>{
  let {t} = useTranslation();
  let nav = useNavigate();
  let {is_mobile} = use_is_mobile();
  let [visible, set_visible] = useState(true);
  let on_click = useCallback(()=>{
    nav('/plan');
  }, [nav]);
  return <Modal open={visible} footer={null} width={is_mobile ? '90vw': 800}
    onCancel={()=>set_visible(false)}>
    <Row><Title level={2} style={{color: purple.primary}}>
      {t('You have no credits left')}</Title></Row>
    <Row>
      <Title level={4}>
        {t('You need to buy more credits in order to keep using our platform')}
      </Title>
    </Row>
    <Row>
      <Col><Text style={{color: purple.primary, marginRight: 4}}>
        {t('Note')}</Text></Col>
      <Col><Text italic>
        {t('You will still get free daily credits even if you'
          + ' don\'t make a purchase')}
      </Text></Col></Row>
    <Row style={{marginTop: 64}}><Title level={3}>
      {t('Benefits of buying credits')}</Title></Row>
    <Row><Col><Thunderbolt_icon/><Text>
      {t('Bulk discount (up to 50%)')}</Text></Col></Row>
    <Row><Col><Thunderbolt_icon/><Text>
      {t('Get to request features to fit your worksflow')}
    </Text></Col></Row>
    <Row><Col><Thunderbolt_icon/><Text>
      {t('You only pay for what you use')}</Text></Col></Row>
    <Row><Col><Thunderbolt_icon/><Text>
      {t('No monthly membership and no automatic recurring payments')}
    </Text></Col></Row>
    <Row><Col><Thunderbolt_icon/><Text>
      {t('You get to support our site')}
    </Text></Col></Row>
    <Row style={{marginTop: 64}}>
      <Text italic>
        {t('If you really need more credits and you don\'t have any money,'
          + ' send us an essay explaining why we should give you free credits')}
      </Text>
    </Row>
    <Row style={{marginTop: 32}}>
      <Col xs={{span: 24}} md={{span: 4}}>
        <Button type="primary" onClick={on_click} style={{marginBottom: 8}}>
          {t('Buy credits')}</Button>
      </Col>
      <Col xs={{offset: 0}} md={{offset: 2}}>
        <a href={'mailto:'+config_ext.front.email}>
          <Button type="dashed">
            {t('Ask for free credits, I don\'t have any money')}
          </Button>
        </a>
      </Col>
    </Row>
  </Modal>;
});
export let Desktop_required_modal = React.memo(({title})=>{
  let {t} = useTranslation();
  let nav = useNavigate();
  let {is_mobile, is_ready} = use_is_mobile();
  let footer = useMemo(()=>[
    <Button key="go_to_ws" onClick={()=>nav('/workspace')}>
      {t('Go to workspace')}
    </Button>,
    <Button key="go_back" type="primary" onClick={()=>nav('/')}>
      {t('Go back')}
    </Button>,
  ], [nav, t]);
  if (!is_ready)
    return null;
  return <Modal title={title || t('Desktop is required')} open={is_mobile}
    footer={footer} maskClosable={false} closeIcon={null}>
    <p>{t('We\'ve noticed that you\'re using a smaller screen device. For the'
      + ' best experience, please use a desktop.')}</p>
  </Modal>;
});

export default E;
