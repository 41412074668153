// LICENSE_CODE TLM
import Icon, {UploadOutlined, DownloadOutlined, ScissorOutlined, PauseOutlined,
  LeftOutlined, LockOutlined, LoadingOutlined, SearchOutlined, UndoOutlined,
  ArrowsAltOutlined, ShrinkOutlined, RedoOutlined, MoreOutlined,
  SoundOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {Button, message, Upload, Row, Space, Rate, Modal, Dropdown, Select,
  Typography, Col, Divider, Tooltip, Slider, Spin, Table, theme, Input,
  ConfigProvider, Popover, Switch, ColorPicker, Form, Checkbox} from 'antd';
import {gray, cyan, purple, green} from '@ant-design/colors';
import React, {useState, useRef, useEffect, useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import assert from 'assert';
import {parseKeybinding as key_bind_parse, tinykeys} from 'tinykeys';
import {useDropzone} from 'react-dropzone';
import {closestCenter as closest_center, DndContext, DragOverlay, PointerSensor,
  useSensor, useSensors} from '@dnd-kit/core';
import {restrictToHorizontalAxis as restrict_to_horizontal_axis}
  from '@dnd-kit/modifiers';
import {arrayMove,
  horizontalListSortingStrategy as horizontal_list_sorting_strategy,
  SortableContext} from '@dnd-kit/sortable';
import xurl from '../../../util/xurl.js';
import xutil from '../../../util/util.js';
import ereq from '../../../util/ereq.js';
import eserf from '../../../util/eserf.js';
import xdate from '../../../util/date.js';
import auth from './auth.js';
import Contact from './contact.js';
import config_ext from './config_ext.js';
import {Clickable, Loading, download, Desktop_required_modal, use_je,
  use_qs, use_qs_clear, use_effect_eserf, use_es_root} from './comp.js';
import back_app from './back_app.js';
import lbin_demo from './lbin_demo.js';
import metric from './metric.js';
import player from './player.js';
import tc from '../../../util/tc.js';
import str from '../../../util/str.js';
import deep_diff from 'deep-diff';
import {editor, Marker_seg, Mark_in_seg, Mark_out_seg, Mark_in_indicator,
  Mark_out_indicator, multi_sort, Tbin_table_body_cell, Tbin_color_icon,
  Tbin_table_header_cell, Tbin_item_icon,
  Tbin_col_choose_modal, Drag_idx_ctx} from './editor.js';
import {file_id2filename} from './workspace.js';
import Purple_icon from './assets/purple_icon.svg?react';
import Motion_icon from './assets/motion_icon.svg?react';
import Gray_icon from './assets/gray_icon.svg?react';
import Play_icon from './assets/play_icon.svg?react';
import Play_next_icon from './assets/play_next_icon.svg?react';
import Play_prev_icon from './assets/play_prev_icon.svg?react';
import Zoom_to_fit_icon from './assets/zoom_to_fit_icon.svg?react';
import Audio_meter_icon from './assets/audio_meter_icon.svg?react';
import Mark_in_icon from './assets/mark_in_icon.svg?react';
import Mark_out_icon from './assets/mark_out_icon.svg?react';
import Clear_both_marks_icon from './assets/clear_both_marks_icon.svg?react';
import Extract_icon from './assets/extract_icon.svg?react';
import Lift_icon from './assets/lift_icon.svg?react';
import Marker_icon from './assets/marker_icon.svg?react';
import Quad_split_icon from './assets/quad_split_icon.svg?react';
import Marker_seg_icon from './assets/marker_seg_icon.svg?react';
import First_frame_icon from './assets/first_frame_icon.svg?react';
import Clip_first_frame_left_icon from './assets/clip_first_frame_left_icon.svg?react';
import Clip_first_frame_right_icon from './assets/clip_first_frame_right_icon.svg?react';
import Last_frame_icon from './assets/last_frame_icon.svg?react';
import media_offline_poster from './assets/media_offline_poster.jpeg';
import selection_cursor from './assets/selection_cursor.svg';
import ew_resize_cursor from './assets/ew_resize_cursor.svg';
import Text_view_mode_icon from './assets/text_view_mode_icon.svg?react';
import Frame_view_mode_icon from './assets/frame_view_mode_icon.svg?react';
import Select_icon from './assets/select_icon.svg?react';

let {Title} = Typography;
let prefix = config_ext.back.app.url;

let is_allow_edit = false;
let is_allow_solo_mute = true;

let marker_edit_allowed_keys = ['CommentMarkerUSer', 'Comment',
  'CommentMarkerColor', 'track_id'];

let proj_lbl = 'main';

// XXX vladimir: move to comp.js
let key_binding_map_get = action2func=>{
  return Object.entries(action2func)
    .map(([action, func])=>{
      let key_bind = action2key_bind[action];
      if (Array.isArray(key_bind))
      {
        return key_bind
          .map(key=>({
            [key]: e=>{
              if (['INPUT', 'TEXTAREA'].includes(e.target.tagName))
                return;
              e.preventDefault();
              if (typeof func == 'function')
                func(e);
            }
          }))
          .reduce((accum, curr)=>({...accum, ...curr}));
      }
      return {
        [action2key_bind[action]]: e=>{
          if (['INPUT', 'TEXTAREA'].includes(e.target.tagName))
            return;
          e.preventDefault();
          if (typeof func == 'function')
            func(e);
        },
      };
    })
    .reduce((accum, cur)=>({...accum, ...cur}));
};
let coords_get = elem=>{
  let box = elem.getBoundingClientRect();
  return {top: box.top + window.scrollY, right: box.right + window.scrollX,
    bottom: box.bottom + window.scrollY, left: box.left + window.scrollX};
};
let monitor_sync = (prev_monitor, next_monitor)=>{
  let _next_monitor = _.cloneDeep(next_monitor);
  let min_length = Math.min(prev_monitor.tracks.length,
    next_monitor.tracks.length);
  for (let i = 0; i < min_length; i++)
  {
    if (!_next_monitor.tracks[i] || !prev_monitor.tracks[i])
      continue;
    _next_monitor.tracks[i].is_edit = prev_monitor.tracks[i].is_edit;
    _next_monitor.tracks[i].is_monitor_selected =
      prev_monitor.tracks[i].is_monitor_selected;
  }
  return _next_monitor;
};
let src_seg_get = seg=>{
  if (seg.type == 'source_clip' && seg.src)
    return seg;
  if (seg.is_no_arr)
    return null;
  return seg.arr.find(src_seg_get);
};
let track_height2px = height=>{
  switch (height)
  {
  case 'tall':
    return 38;
  case 'tall_no_fill':
    return 32;
  case 'medium':
    return 32;
  case 'medium_no_fill':
    return 26;
  case 'small':
    return 26;
  case 'small_no_fill':
    return 20;
  default:
    return 32;
  }
};
let action2key_bind = {
  zoom_in: '$mod+]',
  zoom_out: '$mod+[',
  change_selector_prev: 'ArrowUp',
  change_selector_next: 'ArrowDown',
  move_one_frame_left: 'ArrowLeft',
  move_one_frame_right: 'ArrowRight',
  move_ten_frames_left: 'Shift+ArrowLeft',
  move_ten_frames_right: 'Shift+ArrowRight',
  play_stop: 'Space',
  play_backwards: 'KeyJ',
  stop: 'KeyK',
  play: 'KeyL',
  go_to_prev_cut: 'KeyA',
  go_to_next_cut: 'KeyS',
  go_to_prev_marker: 'Shift+KeyA',
  go_to_next_marker: 'Shift+KeyS',
  go_to_mark_in: 'KeyQ',
  go_to_mark_out: 'KeyW',
  marker_remove: ['Delete', 'Backspace'],
  mark_in: 'KeyE',
  mark_out: 'KeyR',
  mark_clip: 'KeyT',
  clear_both_marks: 'KeyG',
  cut: 'KeyH',
  lift: 'KeyZ',
  extract: 'KeyX',
  marker_add: '=',
  focused_monitor_toggle: 'Escape',
  play_in_to_out: 'Alt+Space',
  undo: '$mod+z',
  redo: '$mod+r',
  mark_all_tracks: '$mod+a',
  unmark_all_tracks: '$mod+Shift+a',
  toggle_timeline_monitor: 'Control+Shift+`',
};
let key_bind2lbl = key_bind=>{
  let parsed_key_bind = key_bind_parse(key_bind)[0];
  let lbl = parsed_key_bind.flat().map(key=>{
    return key.replace('Key', '').replace('Meta', '⌘');
  }).join(' + ');
  return lbl;
};
let Editor_panel = React.memo(({children, style={}, ...rest})=>{
  return (
    <div {...rest} style={{width: '100%', height: '100%', display: 'flex',
      ...style}}>
      {children}
    </div>
  );
});
let min_zoom = -2;
let max_zoom = 12;
// XXX vladimir: move to player.js
let Scrub_bar = React.memo(({len, fps, frame, on_frame_change, arr=[],
  max_width='100%'})=>{
  let [container_width, container_width_set] = useState(0);
  let [is_ptr_moving, is_ptr_moving_set] = useState(false);
  let [ticks_gap, ticks_gap_set] = useState();
  let container_ref = useRef(null);
  let mouse_move_handle = useCallback(e=>{
    let container = container_ref.current;
    let container_offset = coords_get(container).left;
    let frame_rel = (e.clientX - container_offset) / container.offsetWidth;
    let _frame = len * frame_rel;
    on_frame_change(_frame);
  }, [on_frame_change, len]);
  let mouse_down_handle = useCallback(e=>{
    mouse_move_handle(e);
    is_ptr_moving_set(true);
  }, [mouse_move_handle]);
  let mouse_up_handle = useCallback(()=>is_ptr_moving_set(false), []);
  let resize_handle = useCallback(()=>{
    let _container_width = container_ref.current.offsetWidth;
    container_width_set(_container_width);
    let min_gap_px = 30;
    let t = len / fps;
    let min_gap_s = t * min_gap_px / _container_width;
    let periods_s = [0.04, 0.2, 1, 2, 5, 10, 15]; // 15 * 2 ^ n
    let min_period = periods_s.at(0);
    if (min_gap_s < periods_s.at(min_period))
    {
      let _ticks_gap = min_gap_s * _container_width / t;
      ticks_gap_set(_ticks_gap);
      return;
    }
    let sec_gap = periods_s.find((period, index)=>{
      return min_gap_s >= periods_s[index - 1] && min_gap_s <= period;
    });
    if (sec_gap)
    {
      let _ticks_gap = sec_gap * _container_width / t;
      ticks_gap_set(_ticks_gap);
      return;
    }
    let base_period = periods_s.at(-1);
    let power = Math.floor(Math.log2(2 * min_gap_s / base_period));
    sec_gap = base_period * 2 ** power;
    let _ticks_gap = sec_gap * _container_width / t;
    ticks_gap_set(_ticks_gap);
  }, [fps, len]);
  useEffect(()=>{
    let container = container_ref.current;
    let resize_observer = new ResizeObserver(resize_handle);
    resize_observer.observe(container);
    return ()=>resize_observer.unobserve(container);
  }, [resize_handle]);
  useEffect(()=>{
    if (!is_ptr_moving)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [mouse_move_handle, mouse_up_handle, is_ptr_moving]);
  let f2px_k = useMemo(()=>len / container_width,
    [container_width, len]);
  let ticks = useMemo(()=>{
    if (!ticks_gap)
      return [];
    let _ticks = [];
    for (let left = 0; left <= container_width; left += ticks_gap)
    {
      _ticks.push(<div key={left} style={{position: 'absolute',
        left: `${left}px`, transform: 'translateX(-50%)', bottom: 0,
        height: '8px', width: '1px', background: gray[9]}} />);
    }
    return _ticks;
  }, [container_width, ticks_gap]);
  let left = useMemo(()=>{
    return player.f2px(frame, f2px_k, 0);
  }, [f2px_k, frame]);
  return (
    <div ref={container_ref} style={{height: '16px', background: gray[7],
      position: 'relative', zIndex: 0, width: '100%', maxWidth: max_width,
      cursor: 'pointer', bottom: 0}}
    onMouseDown={mouse_down_handle} onTouchStart={mouse_down_handle}>
      <div style={{position: 'absolute', overflow: 'hidden', width: '100%',
        height: '100%'}}>
        {ticks}
        {arr.map((seg, idx)=><Seg key={idx} seg={seg} fps={fps}
          zoom={0} f2px_k={f2px_k} />)}
      </div>
      <Frame_ptr left={left} style={{background: purple.primary}} />
    </div>
  );
});
let Markers_table = React.memo(({lbin, query='', on_frame_change,
  cmts_col_width='40%', on_selected_records_change, cmd_marker_edit,
  cmd_marker_remove, marker_edit, ...rest})=>{
  let {t} = useTranslation();
  let [selected_mob_ids, selected_mob_ids_set] = useState([]);
  let [last_selected_mob_id, last_selected_mob_id_set] = useState(null);
  let [is_ctx_open, is_ctx_open_set] = useState(false);
  let [ctx_row, ctx_row_set] = useState(null);
  let [table_sorter, table_sorter_set] = useState({});
  let [sorted_data_src, sorted_data_src_set] = useState([]);
  let [editing_col, editing_col_set] = useState(null);
  let [editing_mob_id, editing_mob_id_set] = useState(null);
  let cell_handler_get = useCallback(col_name=>{
    return record=>{
      return {
        onDoubleClick: ()=>{
          editing_col_set(col_name);
          editing_mob_id_set(record.mob_id);
        },
      };
    };
  }, []);
  let cols = useMemo(()=>[
    {key: 'idx', dataIndex: 'idx', title: '#', hidden: true},
    {key: 'color', dataIndex: 'color', title: '',
      sorter: (a, b)=>str.cmp(b.marker.color, a.marker.color)},
    {key: 'name', dataIndex: 'name', title: t('Marker Name'),
      sorter: (a, b)=>str.cmp(b.name, a.name),
      onCell: cell_handler_get('name')},
    {key: 'tc', dataIndex: 'tc', title: t('TC'),
      sorter: (a, b)=>b.marker.abs_start - a.marker.abs_start},
    {key: 'track', dataIndex: 'track', title: t('Track'),
      sorter: (a, b)=>str.cmp(b.track, a.track)},
    {key: 'cmt', dataIndex: 'cmt', title: t('Comment'),
      sorter: (a, b)=>str.cmp(b.cmt, a.cmt), width: cmts_col_width,
      onCell: cell_handler_get('cmt')},
  ], [cmts_col_width, t, cell_handler_get]);
  let color_opts = useMemo(()=>{
    return Object.entries(marker_colors).map(([label, color])=>{
      return {key: `change_color_${color}`, label: _.capitalize(label)};
    });
  }, []);
  let data_src = useMemo(()=>{
    if (!lbin?.rec_monitor_in)
      return [];
    let _query = query.toLowerCase().trim();
    let _data_src = [];
    let idx = 1;
    lbin.rec_monitor_in.tracks.forEach(track=>{
      let markers = editor.markers_get(track);
      markers = markers.filter(marker=>{
        return [...Object.values(marker), track.id].some(value=>{
          return String(value).toLowerCase().includes(_query);
        });
      });
      let track_data_src = markers.map(marker=>{
        // XXX vladimir: use real mob_id
        let mob_id = track.id + '__' + marker.abs_start;
        let name;
        if (editing_col == 'name' && editing_mob_id == mob_id)
        {
          let focus_handle = e=>{
            e.target.select();
          };
          let blur_handle = ()=>{
            editing_col_set(null);
            editing_mob_id_set(null);
          };
          name = <Input autoFocus defaultValue={marker.name || ''}
            onFocus={focus_handle} onBlur={blur_handle} />;
        }
        else
          name = marker.name || '—';
        let cmt;
        if (editing_col == 'cmt' && editing_mob_id == mob_id)
        {
          let focus_handle = e=>{
            e.target.select();
          };
          let blur_handle = e=>{
            editing_col_set(null);
            editing_mob_id_set(null);
            if (e.target.value == marker.comment)
              return;
            cmd_marker_edit(lbin.rec_monitor_in.mob_id, track.id,
              marker.abs_start, 'Comment', e.target.value);
          };
          cmt = <Input.TextArea autoFocus defaultValue={marker.comment || ''}
            onFocus={focus_handle} onBlur={blur_handle} />;
        }
        else
          cmt = marker.comment || '—';
        let color_picker_dropdown_click_handle = ({key})=>eserf(function*
        _color_picker_dropdown_click_handle(){
          let color = key.replace('change_color_', '');
          for (let _marker of markers)
          {
            let _mob_id = track.id + '__' + _marker.abs_start;
            if (!selected_mob_ids.includes(_mob_id))
              continue;
            let res = yield cmd_marker_edit(lbin.rec_monitor_in.mob_id,
              track.id, _marker.abs_start, 'CommentMarkerColor', color);
            if (res.err)
            {
              metric.error('cmd_marker_edit', str.j2s(res));
              return;
            }
          }
        });
        let color = <Dropdown menu={{items: color_opts,
          onClick: color_picker_dropdown_click_handle}}
        trigger={['contextMenu']}>
          <ColorPicker value={marker.color} size="small" open={false} />
        </Dropdown>;
        let _tc = tc.frame2tc(lbin.rec_monitor_in.start_tc + marker.abs_start,
          lbin.rec_monitor_in.editrate);
        return {marker, color, cmt, name, track: track.id, tc: _tc,
          idx: (idx++).toString().padStart(4, '0'), track_id: track.id,
          mob_id};
      });
      _data_src = [..._data_src, ...track_data_src];
    });
    return _data_src;
  }, [editing_col, editing_mob_id, lbin?.rec_monitor_in, query,
    cmd_marker_edit, selected_mob_ids, color_opts]);
  let row_handle = useCallback(record=>{
    let mob_id = record.mob_id;
    return {
      onMouseDown: e=>{
        let is_dropdown = !!e.target.closest(
          '.ant-dropdown, .ant-dropdown-menu, .ant-dropdown-menu-submenu');
        if (is_dropdown)
          return;
        let is_right_click = e.nativeEvent.which == 3;
        let _selected_mob_ids;
        if (e.shiftKey && !last_selected_mob_id)
          _selected_mob_ids = [mob_id];
        else if (e.shiftKey && last_selected_mob_id && is_right_click)
          _selected_mob_ids = [mob_id];
        else if (e.shiftKey && last_selected_mob_id && !is_right_click)
        {
          let idx = sorted_data_src.findIndex(r=>r.mob_id == mob_id);
          let start_idx = Math.min(idx, sorted_data_src.findIndex(r=>{
            return r.mob_id == last_selected_mob_id;
          }));
          let end_idx = Math.max(idx, sorted_data_src.findIndex(r=>{
            return r.mob_id == last_selected_mob_id;
          }));
          let part_selected_files = sorted_data_src
            .slice(start_idx, end_idx + 1)
            .map(r=>r.mob_id);
          _selected_mob_ids = [...selected_mob_ids, ...part_selected_files];
        }
        else if ((e.ctrlKey || e.metaKey) && selected_mob_ids.includes(mob_id)
          && is_right_click)
        {
          _selected_mob_ids = [...selected_mob_ids];
        }
        else if ((e.ctrlKey || e.metaKey) && selected_mob_ids.includes(mob_id)
          && !is_right_click)
        {
          _selected_mob_ids = selected_mob_ids.filter(id=>id != mob_id);
        }
        else if ((e.ctrlKey || e.metaKey) && !selected_mob_ids.includes(mob_id))
          _selected_mob_ids = [...selected_mob_ids, mob_id];
        else if (selected_mob_ids.includes(mob_id) && is_right_click)
          _selected_mob_ids = [...selected_mob_ids];
        else
          _selected_mob_ids = [mob_id];
        selected_mob_ids_set(_selected_mob_ids);
        if (on_selected_records_change)
        {
          on_selected_records_change(data_src.filter(_record=>{
            return _selected_mob_ids.includes(_record.mob_id);
          }));
        }
        last_selected_mob_id_set(mob_id);
      },
      onDoubleClick: ()=>{
        on_frame_change(record.marker.abs_start);
      },
      onContextMenu: e=>{
        let is_color_picker = !!e.target.closest('.ant-color-picker-trigger');
        if (is_color_picker)
          return;
        is_ctx_open_set(true);
        ctx_row_set(record);
      },
    };
  }, [data_src, last_selected_mob_id, on_frame_change,
    on_selected_records_change, selected_mob_ids, sorted_data_src]);
  let row_class_name_handle = useCallback(record=>{
    if (selected_mob_ids.includes(record.mob_id))
      return 'markers-table-row markers-table-row-selected';
    return 'markers-table-row';
  }, [selected_mob_ids]);
  let dropdown_items = useMemo(()=>{
    return [
      {label: t('Jump to Marker'), key: 'jump_to_marker'},
      {label: t('Edit Marker'), key: 'edit_marker'},
      {label: t('Change Color'), key: 'change_color', children: color_opts},
      {label: t('Change Track'), key: 'change_track', disabled: true},
      {label: t('Import Markers'), key: 'import_markers', disabled: true},
      {label: t('Export Markers'), key: 'export_markers', disabled: true},
      {label: t('Choose Columns'), key: 'choose_markers', disabled: true},
      {label: t('Remove'), key: 'remove'},
    ];
  }, [color_opts, t]);
  let dropdown_click_handle = useCallback(({key})=>eserf(function*
  _dropdown_click_handle(){
    if (key == 'jump_to_marker')
      on_frame_change(ctx_row.marker.abs_start);
    if (key == 'edit_marker')
      marker_edit(ctx_row.track_id, ctx_row.marker);
    if (key.startsWith('change_color_'))
    {
      let color = key.replace('change_color_', '');
      for (let record of data_src)
      {
        if (!selected_mob_ids.includes(record.mob_id))
          continue;
        let res = yield cmd_marker_edit(lbin.rec_monitor_in.mob_id,
          record.track_id, record.marker.abs_start, 'CommentMarkerColor',
          color);
        if (res.err)
        {
          metric.error('cmd_marker_edit', str.j2s(res));
          return;
        }
      }
    }
    if (key == 'remove')
    {
      for (let record of data_src)
      {
        if (!selected_mob_ids.includes(record.mob_id))
          continue;
        let res = yield cmd_marker_remove(lbin.rec_monitor_in.mob_id,
          record.track_id, record.marker.abs_start);
        if (res.err)
        {
          metric.error('cmd_marker_remove', str.j2s(res));
          return;
        }
      }
    }
  }), [ctx_row, on_frame_change, cmd_marker_remove, data_src, cmd_marker_edit,
    lbin?.rec_monitor_in?.mob_id, selected_mob_ids, marker_edit]);
  let dropdown_open_change_handle = useCallback(is_open=>{
    if (!is_open)
      is_ctx_open_set(false);
  }, []);
  let table_change_handle = useCallback((pagination, filters, sorter,
    extra)=>{
    table_sorter_set(sorter);
    sorted_data_src_set(extra.currentDataSource);
  }, []);
  useEffect(()=>{
    if (table_sorter.field)
    {
      let sorted = [...data_src].sort((a, b)=>{
        if (table_sorter.order === 'ascend')
          return table_sorter.column.sorter(a, b);
        else if (table_sorter.order === 'descend')
          return table_sorter.column.sorter(b, a);
        return 0;
      });
      sorted_data_src_set(sorted);
      return;
    }
    sorted_data_src_set(data_src);
  }, [table_sorter, data_src]);
  let blur_handle = useCallback(e=>{
    let is_in_table = !!e.target.closest('.ant-table');
    if (is_in_table)
      return;
    let is_dropdown = !!e.target.closest(
      '.ant-dropdown, .ant-dropdown-menu, .ant-dropdown-menu-submenu');
    if (is_dropdown)
      return;
    let is_markers_dropdown_btn = !!e.target.closest('#markers-dropdown-btn');
    if (is_markers_dropdown_btn)
      return;
    selected_mob_ids_set([]);
    if (on_selected_records_change)
      on_selected_records_change([]);
    last_selected_mob_id_set(null);
  }, [on_selected_records_change]);
  useEffect(()=>{
    window.addEventListener('mousedown', blur_handle);
    return ()=>{
      window.removeEventListener('mousedown', blur_handle);
    };
  }, [blur_handle]);
  let markers_remove = useCallback(()=>{
    let tracks_ids = [];
    let abs_frames = [];
    for (let row of data_src)
    {
      if (selected_mob_ids.includes(row.mob_id))
      {
        tracks_ids.push(row.track_id);
        abs_frames.push(row.marker.abs_start);
      }
    }
    cmd_marker_remove(lbin.rec_monitor_in.mob_id, tracks_ids, abs_frames);
  }, [cmd_marker_remove, data_src, lbin?.rec_monitor_in?.mob_id,
    selected_mob_ids]);
  let action2func = useMemo(()=>{
    return {marker_remove: markers_remove};
  }, [markers_remove]);
  useEffect(()=>{
    let unsubscribe = tinykeys(window, key_binding_map_get(action2func));
    return ()=>unsubscribe();
  }, [action2func]);
  return (
    <Dropdown menu={{items: dropdown_items,
      onClick: dropdown_click_handle}} trigger={['contextMenu']}
    open={is_ctx_open} onOpenChange={dropdown_open_change_handle}>
      <div>
        <Table columns={cols} dataSource={data_src} size="small"
          pagination={false} style={{width: '100%'}}
          onRow={row_handle} rowKey="mob_id"
          onChange={table_change_handle} id="markers-table"
          rowClassName={row_class_name_handle} {...rest} />
      </div>
    </Dropdown>
  );
});
let Markers_modal = React.memo(({is_open, on_close, lbin, on_frame_change,
  cmd_marker_remove, marker_edit})=>{
  let {t} = useTranslation();
  let frame_change_handle = useCallback(frame=>{
    on_frame_change(frame);
    on_close();
  }, [on_close, on_frame_change]);
  return (
    <Modal title={t('Markers')} open={is_open} footer={null} width="80vw"
      onCancel={on_close} closeIcon={<ShrinkOutlined />}>
      <div>
        <Markers_table lbin={lbin} scroll={{y: 400}} cmts_col_width="60%"
          on_frame_change={frame_change_handle} marker_edit={marker_edit}
          cmd_marker_remove={cmd_marker_remove} />
      </div>
    </Modal>
  );
});
let Add_seq_modal = React.memo(({is_open, on_close, cmd_sequence_new})=>{
  let {t} = useTranslation();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [form] = Form.useForm();
  let [is_loading, is_loading_set] = useState(false);
  let editrate_opts = useMemo(()=>{
    let editrates = [23.97, 23.976, 24, 25, 29.97, 30, 48, 50, 59.94, 60];
    return editrates
      .map(editrate=>({value: editrate, label: editrate}));
  }, []);
  let init_values = useMemo(()=>{
    return {editrate: 23.97};
  }, []);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err)
      return;
    is_loading_set(true);
    let res = yield cmd_sequence_new(values.editrate);
    is_loading_set(false);
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('cmd_sequence_new', res.err);
    }
    on_close();
  }), [cmd_sequence_new, form, message_api, on_close, t]);
  return (
    <Modal title={t('New Sequence')} open={is_open} okText={t('Add')}
      onOk={submit_handle} onCancel={on_close} destroyOnClose
      confirmLoading={is_loading}>
      {message_ctx_holder}
      <Form form={form} preserve={false} onFinish={submit_handle}
        initialValues={init_values} layout="vertical">
        <Form.Item name="editrate" label={t('Editrate')}>
          <Select options={editrate_opts} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
let default_col_keys = ['color', 'icon', 'name', 'start_tc', 'end_tc', 'fps'];
let Tbin_pane = React.memo(({lbin, cmd_rec_monitor_load_clip, user_full,
  cmd_src_monitor_load_clip, user, token, cmd_clip_duplicate, tbin_upload,
  cmd_sequence_new, on_selected_monitor_change, aaf_file,
  on_src_frame_change, on_rec_frame_change})=>{
  let {t} = useTranslation();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [selected_objs, selected_objs_set] = useState([]);
  let [selected_cols, selected_cols_set] = useState([]);
  let [is_row_ctx_open, is_row_ctx_open_set] = useState(false);
  let [ctx_record, ctx_record_set] = useState(null);
  let [is_header_ctx_open, is_header_ctx_open_set] = useState(false);
  let [is_col_choose_modal_open,
    is_col_choose_modal_open_set] = useState(false);
  let [ctx_col, ctx_col_set] = useState(null);
  let [sorters, sorters_set] = useState([]);
  let [view_mode, view_mode_set] = useState('text');
  let [query, query_set] = useState('');
  let [col_keys, col_keys_set] = useState(user_full.tbin_cols
    || default_col_keys);
  let [drag_idx, drag_idx_set] = useState({active: null, over: null});
  let [is_add_seq_modal_open, is_add_seq_modal_open_set] = useState(false);
  let [uploading_files, uploading_files_set] = useState({});
  let [uploading_progress, uploading_progress_set] = useState({});
  let sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {distance: 1}}));
  let drop_files_handle = useCallback(files=>eserf(function*
  _drop_files_handle(){
    if (!files.length)
      return;
    let uploading_files_part = {};
    let uploading_progress_part = {};
    for (let file of files)
    {
      uploading_files_part[file.path] = file;
      uploading_progress_part[file.path] = 0;
      uploading_progress_part[file.path] = 0;
    }
    uploading_files_set({...uploading_files, ...uploading_files_part});
    uploading_progress_set({...uploading_progress, ...uploading_progress_part});
    for (let file of files)
    {
      let res = yield tbin_upload([file], aaf_file.id, ({event: _e})=>{
        uploading_progress_set(_uploading_progress=>{
          return {..._uploading_progress, [file.name]: _e.progress};
        });
      });
      uploading_files_set(_uploading_files=>{
        _uploading_files = {..._uploading_files};
        delete _uploading_files[file.path];
        return _uploading_files;
      });
      uploading_progress_set(_uploading_progress=>{
        _uploading_progress = {..._uploading_progress};
        delete _uploading_progress[file.path];
        return _uploading_progress;
      });
      if (res.err)
      {
        message_api.error(t('Something went wrong'));
        return metric.error('back_app.editor.tbin_upload', res.err);
      }
    }
  }), [message_api, t, tbin_upload, uploading_files, uploading_progress,
    aaf_file]);
  let {getRootProps: root_props_get, getInputProps: input_props_get,
    isDragActive: is_drag_active} = useDropzone({noClick: true,
    noKeyboard: true, onDrop: drop_files_handle});
  let header_cell_handle = useCallback(col=>{
    return {
      id: col.key,
      onMouseDown: e=>{
        if ((e.ctrlKey || e.metaKey) && selected_cols.includes(col.key))
        {
          let _selected_cols = selected_cols.filter(id=>id != col.key);
          selected_cols_set(_selected_cols);
          selected_objs_set([]);
          return;
        }
        if ((e.ctrlKey || e.metaKey) && !selected_cols.includes(col.key))
        {
          selected_cols_set([...selected_cols, col.key]);
          selected_objs_set([]);
          return;
        }
        selected_cols_set([col.key]);
        selected_objs_set([]);
      },
      onContextMenu: ()=>{
        is_header_ctx_open_set(true);
        ctx_col_set(col.key);
      },
    };
  }, [selected_cols]);
  let cell_handle = useCallback(col=>{
    return {id: col.key};
  }, []);
  let str_sorter_get = useCallback(key=>{
    return (a, b)=>str.cmp(b.tbin[key]||'', a.tbin[key]||'');
  }, []);
  let tcs_sorter_get = useCallback(key=>{
    return (a, b)=>{
      if (!a.tbin[key])
        return -1;
      if (!b.tbin[key])
        return 1;
      let a_frame = tc.tc_o2frame(tc.str2tc_o(a.tbin[key]), null, a.fps);
      let b_frame = tc.tc_o2frame(tc.str2tc_o(b.tbin[key]), null, b.fps);
      return b_frame - a_frame;
    };
  }, []);
  let cols = useMemo(()=>[
    {key: 'audio_sr', dataIndex: 'audio_sr', title: t('Audio SR'),
      _sorter: (a, b)=>b.audio_sr - a.audio_sr},
    {key: 'cmts', dataIndex: 'cmts', title: t('Comments'),
      _sorter: str_sorter_get('cmts')},
    {key: 'color', dataIndex: 'color', title: t('Color'),
      _sorter: str_sorter_get('color')},
    {key: 'creation_date', dataIndex: 'creation_date',
      title: t('Creation Date'),
      _sorter: (b, a)=>{
        return new Date(b.tbin.creation_date).getTime()
          - new Date(a.tbin.creation_date).getTime();
      }},
    {key: 'drive', dataIndex: 'drive', title: t('Drive'),
      _sorter: str_sorter_get('drive')},
    {key: 'dur', dataIndex: 'dur', title: t('Duration'),
      _sorter: tcs_sorter_get('dur')},
    {key: 'end_tc', dataIndex: 'end_tc', title: t('End'),
      _sorter: tcs_sorter_get('end_tc')},
    {key: 'fps', dataIndex: 'fps', title: t('FPS'),
      _sorter: (a, b)=>a.fps - b.fps},
    {key: 'icon', dataIndex: 'icon', title: '',
      _sorter: (a, b)=>str.cmp(b.tbin.type, a.tbin.type)},
    {key: 'in_out', dataIndex: 'in_out', title: t('IN-OUT'),
      _sorter: tcs_sorter_get('in_out')},
    {key: 'mark_in', dataIndex: 'mark_in', title: t('Mark IN'),
      _sorter: tcs_sorter_get('mark_in')},
    {key: 'mark_out', dataIndex: 'mark_out', title: t('Mark OUT'),
      _sorter: tcs_sorter_get('mark_out')},
    {key: 'name', dataIndex: 'name', title: t('Name'),
      _sorter: (a, b)=>str.cmp(b.name, a.name)},
    {key: 'start_tc', dataIndex: 'start_tc', title: t('Start'),
      _sorter: tcs_sorter_get('start_tc')},
    {key: 'tracks', dataIndex: 'tracks', title: t('Tracks'),
      _sorter: str_sorter_get('tracks')},
    {key: 'tape', dataIndex: 'tape', title: t('Tape'),
      _sorter: str_sorter_get('tape')},
    {key: 'tape_id', dataIndex: 'tape_id', title: t('TapeID'),
      _sorter: str_sorter_get('tape_id')},
    {key: 'video', dataIndex: 'video', title: t('Video'),
      _sorter: str_sorter_get('video')},
  ], [str_sorter_get, t, tcs_sorter_get]);
  let col_opts = useMemo(()=>{
    return cols
      .map(col=>{
        if (col.key == 'icon')
          return {label: 'Icon', value: col.key};
        return {label: col.title, value: col.key};
      });
  }, [cols]);
  let cols_in_use = useMemo(()=>{
    return col_keys
      .map(key=>{
        let _col = cols.find(col=>col.key == key);
        return {..._col, className: selected_cols.includes(key)
          ? 'bin-table-row-selected' : null, onHeaderCell: header_cell_handle,
        onCell: cell_handle};
      });
  }, [cell_handle, col_keys, cols, header_cell_handle, selected_cols]);
  let tbins = useMemo(()=>{
    if (!lbin?.tbin)
      return [];
    let _tbins = Object.values(lbin.tbin);
    let _query = query.toLowerCase().trim();
    if (!query)
      return _tbins;
    return _tbins
      .filter(tbin=>{
        if (tbin.is_hide)
          return false;
        let filter_keys = selected_cols.length ? selected_cols
          : Object.keys(tbin);
        return Object.entries(tbin).some(([key, value])=>{
          return filter_keys.includes(key)
            && String(value).toLowerCase().includes(_query);
        });
      });
  }, [lbin?.tbin, query, selected_cols]);
  let data_src = useMemo(()=>{
    let _data_src = tbins
      .map(tbin=>({color: <Tbin_color_icon color={tbin.color} />, tbin,
        icon: <Tbin_item_icon type={tbin.type}
          is_audio_only={tbin.is_audio_only} />, name: tbin.name,
        start_tc: tbin.start_tc, end_tc: tbin.end_tc, fps: tbin.fps,
        mob_id: tbin.mob_id}));
    for (let file_path in uploading_files)
    {
      let file = uploading_files[file_path];
      _data_src.push({name: file.name, is_upload: true,
        icon: <LoadingOutlined style={{color: 'white', fontSize: '12px'}} />});
    }
    let sort_criteria = sorters.map(({key, dir})=>({dir,
      sorter: cols.find(col=>col.key == key)._sorter}));
    return multi_sort(_data_src, sort_criteria);
  }, [cols, sorters, tbins, uploading_files]);
  let row_dropdown_items = useMemo(()=>{
    return [
      {label: t('New Sequence'), key: 'add_seq'},
      {label: t('Source settings...'), key: 'src_settings', disabled: true},
      {label: t('Get Info'), key: 'get_info', disabled: true},
      {label: t('Bulk Edit...'), key: 'bulk_edit', disabled: true},
      {label: t('Find And Replace'), key: 'find_and_replace', disabled: true},
      {label: t('Duplicate'), key: 'duplicate'},
    ];
  }, [t]);
  let row_dropdown_click_handle = useCallback(({key})=>{
    if (key == 'add_seq')
      is_add_seq_modal_open_set(true);
    if (key == 'duplicate')
      cmd_clip_duplicate(ctx_record.tbin.mob_id);
  }, [cmd_clip_duplicate, ctx_record]);
  let header_dropdown_items = useMemo(()=>{
    return [
      {label: t('Choose columns...'), key: 'choose_cols'},
      {label: t('Add Custom Column'), key: 'add_custom_col', disabled: true},
      {label: t('Rename Column'), key: 'rename_col', disabled: true},
      {label: t('Hide Column'), key: 'hide_col', disabled: true},
      {type: 'divider'},
      {label: t('Sort on Column, Ascending'), key: 'sort_asc'},
      {label: t('Sort on Column, Descending'), key: 'sort_desc'},
      {label: t('Sift Bin Contents...'), key: 'sift_bin_contents',
        disabled: true},
      {type: 'divider'},
      {label: t('Bulk Edit...'), key: 'bulk_edit', disabled: true},
      {label: t('Find And Replace'), key: 'find_and_replace', disabled: true},
      {type: 'divider'},
      {label: t('What\' This?'), key: 'what_is_this', disabled: true},
    ];
  }, [t]);
  let col_preset_options = useMemo(()=>{
    return [
      {label: t('Basic'), value: 'basic', col_keys: ['icon', 'name',
        'start_tc', 'end_tc', 'dur', 'tracks', 'video', 'cmts']},
      {label: t('Media Tool'), value: 'media_tool', col_keys: ['color', 'icon',
        'name', 'creation_date', 'dur', 'drive', 'in_out', 'mark_in',
        'mark_out', 'audio_sr', 'tracks', 'start_tc', 'tape', 'video',
        'tape_id']},
    ];
  }, [t]);
  let col_keys_change = useCallback(_col_keys=>eserf(function*
  _col_keys_change(){
    col_keys_set(_col_keys);
    sorters_set([]);
    let res = yield back_app.user_set_tbin_cols(token, user.email, _col_keys);
    if (res.err)
      return metric.error('col_keys_change_err', res.err);
  }), [token, user.email]);
  let row_class_name_handle = useCallback(record=>{
    if (record.is_upload)
      return 'markers-table-row';
    if (selected_objs.includes(record.tbin.mob_id))
      return 'bin-table-row bin-table-row-selected';
    return 'bin-table-row';
  }, [selected_objs]);
  let row_handle = useCallback(record=>{
    if (record.is_upload)
      return {};
    let mob_id = record.tbin.mob_id;
    return {
      onMouseDown: e=>{
        if ((e.ctrlKey || e.metaKey) && selected_objs.includes(mob_id))
        {
          let _selected_objs = selected_objs.filter(id=>id != mob_id);
          selected_objs_set(_selected_objs);
          selected_cols_set([]);
          return;
        }
        if ((e.ctrlKey || e.metaKey) && !selected_objs.includes(mob_id))
        {
          selected_objs_set([...selected_objs, mob_id]);
          selected_cols_set([]);
          return;
        }
        selected_objs_set([mob_id]);
        selected_cols_set([]);
      },
      onContextMenu: ()=>{
        is_row_ctx_open_set(true);
        ctx_record_set(record);
      },
      onDoubleClick: ()=>eserf(function* _double_click_handle(){
        if (record.tbin.type == 'sequence')
        {
          let res = yield cmd_rec_monitor_load_clip(record.tbin.mob_id);
          if (res.err)
            return;
          on_selected_monitor_change('rec');
          on_rec_frame_change(0);
          return;
        }
        let res = yield cmd_src_monitor_load_clip(record.tbin.mob_id);
        if (res.err)
          return;
        on_selected_monitor_change('src');
        on_src_frame_change(0);
      }),
    };
  }, [cmd_rec_monitor_load_clip, cmd_src_monitor_load_clip, selected_objs,
    on_selected_monitor_change, on_src_frame_change, on_rec_frame_change]);
  let header_dropdown_click_handle = useCallback(({key})=>{
    if (key == 'choose_cols')
      is_col_choose_modal_open_set(true);
    if (key == 'sort_asc')
    {
      let _sorters = [...sorters];
      let idx = _sorters.findIndex(sorter=>sorter.key == ctx_col);
      if (idx != -1)
        _sorters.splice(idx, 1);
      _sorters.unshift({key: ctx_col, dir: 'asc'});
      sorters_set(_sorters);
    }
    if (key == 'sort_desc')
    {
      let _sorters = [...sorters];
      let idx = _sorters.findIndex(sorter=>sorter.key == ctx_col);
      if (idx != -1)
        _sorters.splice(idx, 1);
      _sorters.unshift({key: ctx_col, dir: 'desc'});
      sorters_set(_sorters);
    }
  }, [ctx_col, sorters]);
  let row_open_change_handle = useCallback(is_open=>{
    if (!is_open)
      is_row_ctx_open_set(false);
  }, []);
  let header_open_change_handle = useCallback(is_open=>{
    if (!is_open)
      is_header_ctx_open_set(false);
  }, []);
  let col_choose_modal_close_handle = useCallback(()=>{
    is_col_choose_modal_open_set(false);
  }, []);
  let col_choose_modal_submit_handle = useCallback(_col_keys=>{
    is_col_choose_modal_open_set(false);
    col_keys_change(_col_keys);
    selected_cols_set([]);
  }, [col_keys_change]);
  let text_view_mode_click_handle = useCallback(()=>{
    view_mode_set('text');
  }, []);
  let frame_view_mode_click_handle = useCallback(()=>{
    view_mode_set('frame');
    selected_cols_set([]);
  }, []);
  let card_click_handler_get = useCallback(mob_id=>{
    return e=>{
      if ((e.ctrlKey || e.metaKey) && selected_objs.includes(mob_id))
      {
        let _selected_objs = selected_objs.filter(id=>id != mob_id);
        selected_objs_set(_selected_objs);
        selected_cols_set([]);
        return;
      }
      if ((e.ctrlKey || e.metaKey) && !selected_objs.includes(mob_id))
      {
        selected_objs_set([...selected_objs, mob_id]);
        selected_cols_set([]);
        return;
      }
      selected_objs_set([mob_id]);
      selected_cols_set([]);
    };
  }, [selected_objs]);
  let query_change_handle = useCallback(e=>{
    query_set(e.target.value);
  }, []);
  let select_value = useMemo(()=>{
    let found_preset = col_preset_options.find(preset=>{
      return preset.col_keys.length == col_keys.length
        && preset.col_keys.every((key, idx)=>col_keys[idx] == key);
    });
    if (!found_preset)
      return null;
    return found_preset.value;
  }, [col_keys, col_preset_options]);
  let select_change_handle = useCallback((value, option)=>{
    col_keys_change(option.col_keys);
    selected_cols_set([]);
  }, [col_keys_change]);
  let drag_end_handle = ({active, over})=>{
    if (active.id != over?.id)
    {
      let active_idx = col_keys.findIndex(key=>key == active?.id);
      let over_idx = col_keys.findIndex(key=>key == over?.id);
      col_keys_change(arrayMove(col_keys, active_idx, over_idx));
    }
    drag_idx_set({active: null, over: null});
  };
  let drag_over_handle = ({active, over})=>{
    let active_idx = col_keys.findIndex(col=>col.key == active.id);
    let over_idx = col_keys.findIndex(col=>col.key == over?.id);
    drag_idx_set({active: active.id, over: over?.id,
      direction: over_idx > active_idx ? 'right' : 'left'});
  };
  let dropdown_items = useMemo(()=>{
    return [
      {label: t('New Sequence'), key: 'add_seq'},
      {label: t('Source settings...'), key: 'src_settings', disabled: true},
      {label: t('Get Info'), key: 'get_info', disabled: true},
      {label: t('Bulk Edit...'), key: 'bulk_edit', disabled: true},
      {label: t('Find And Replace'), key: 'find_and_replace', disabled: true},
      {label: t('Duplicate'), key: 'duplicate', disabled: true},
    ];
  }, [t]);
  let dropdown_click_handle = useCallback(({key})=>{
    if (key == 'add_seq')
      is_add_seq_modal_open_set(true);
  }, []);
  let add_seq_modal_close = useCallback(()=>{
    is_add_seq_modal_open_set(false);
  }, []);
  return (
    <div style={{display: 'flex', flexDirection: 'column', overflow: 'hidden',
      height: '100%', gap: '8px'}}>
      {message_ctx_holder}
      <Tbin_col_choose_modal is_open={is_col_choose_modal_open}
        options={col_opts} col_keys={col_keys}
        on_close={col_choose_modal_close_handle}
        on_submit={col_choose_modal_submit_handle} />
      <Add_seq_modal is_open={is_add_seq_modal_open}
        on_close={add_seq_modal_close} cmd_sequence_new={cmd_sequence_new} />
      <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px',
        padding: '0 8px'}}>
        <Select options={col_preset_options} style={{width: '200px'}}
          value={select_value} onChange={select_change_handle} />
        <Button title={t('Display bin In Text View mode')} size="middle"
          onClick={text_view_mode_click_handle}>
          <Text_view_mode_icon />
        </Button>
        <Button title={t('Display bin In Frame View mode')} size="middle"
          onClick={frame_view_mode_click_handle}>
          <Frame_view_mode_icon />
        </Button>
        <Input size="small" allowClear value={query} prefix={<SearchOutlined />}
          styles={{input: {borderRadius: 0}}} onChange={query_change_handle} />
        <Dropdown menu={{items: dropdown_items, onClick: dropdown_click_handle}}
          trigger={['click']}>
          <Button title={t('Display bin In Frame View mode')} size="middle">
            <MoreOutlined />
          </Button>
        </Dropdown>
      </div>
      {view_mode == 'text' && <div {...root_props_get({width: '100%',
        style: {overflowX: 'auto', height: '100%', position: 'relative'}})}>
        <input {...input_props_get()} />
        {is_drag_active && <div style={{position: 'absolute', top: 0, left: 0,
          width: '100%', height: '100%', border: `2px solid ${purple.primary}`,
          background: `${purple.primary}50`, zIndex: 1}} />}
        <Dropdown menu={{items: row_dropdown_items,
          onClick: row_dropdown_click_handle}} trigger={['contextMenu']}
        open={is_row_ctx_open} onOpenChange={row_open_change_handle}>
          <Dropdown menu={{items: header_dropdown_items,
            onClick: header_dropdown_click_handle}} trigger={['contextMenu']}
          open={is_header_ctx_open} onOpenChange={header_open_change_handle}>
            <div style={{height: '100%', width: '100%', position: 'absolute'}}>
              <DndContext sensors={sensors} onDragEnd={drag_end_handle}
                modifiers={[restrict_to_horizontal_axis]}
                onDragOver={drag_over_handle}
                collisionDetection={closest_center}>
                <SortableContext items={cols_in_use.map(col=>col.key)}
                  strategy={horizontal_list_sorting_strategy}>
                  <Drag_idx_ctx.Provider value={drag_idx}>
                    <Table columns={cols_in_use} dataSource={data_src}
                      size="small" pagination={false} rowKey="mob_id"
                      rowClassName={row_class_name_handle} onRow={row_handle}
                      style={{width: '100%'}} scroll={{x: 'max-content'}}
                      components={{header: {cell: Tbin_table_header_cell},
                        body: {cell: Tbin_table_body_cell}}} />
                  </Drag_idx_ctx.Provider>
                </SortableContext>
                <DragOverlay>
                  <th style={{backgroundColor: 'gray', padding: '4px 8px',
                    fontSize: 11, marginBottom: '1px', fontWeight: 600,
                    lineHeight: 1.5}}>
                    {cols_in_use[cols_in_use.findIndex(i=>{
                      return i.key == drag_idx.active;
                    })]?.title}
                  </th>
                </DragOverlay>
              </DndContext>
            </div>
          </Dropdown>
        </Dropdown>
      </div>}
      {view_mode == 'frame' && <div style={{display: 'flex', flexWrap: 'wrap',
        gap: '4px', padding: '4px', overflowY: 'auto'}}>
        {tbins.map(tbin=><div key={tbin.mob_id} style={{width: '80px',
          height: '60px', display: 'flex', flexDirection: 'column',
          background: selected_objs.includes(tbin.mob_id)
            ? theme.dark_blue : '#3D3D3D', cursor: 'pointer'}}
        onClick={card_click_handler_get(tbin.mob_id)}>
          <div style={{height: '40px', background: '#222'}} />
          <div style={{fontSize: 11, overflow: 'hidden', padding: '2px',
            textOverflow: 'ellipsis'}}>
            {tbin.name}
          </div>
        </div>)}
      </div>}
    </div>
  );
});
let Markers_pane = React.memo(({lbin, on_frame_change, cmd_marker_edit,
  cmd_marker_remove, marker_edit})=>{
  let {t} = useTranslation();
  let {token: {colorBgContainer: color_bg_container}} = theme.useToken();
  let table_container_ref = useRef(null);
  let [height, height_set] = useState(0);
  let [query, query_set] = useState('');
  let [is_markers_modal_open, is_markers_modal_open_set] = useState(false);
  let [selected_records, selected_records_set] = useState([]);
  let color_opts = useMemo(()=>{
    return Object.entries(marker_colors).map(([label, color])=>{
      return {key: `change_color_${color}`, label: _.capitalize(label)};
    });
  }, []);
  let dropdown_items = useMemo(()=>{
    return [
      {label: t('Jump to Marker'), key: 'jump_to_marker',
        disabled: selected_records.length != 1},
      {label: t('Edit Marker'), key: 'edit_marker',
        disabled: selected_records.length != 1},
      {label: t('Change Color'), key: 'change_color', children: color_opts},
      {label: t('Change Track'), key: 'change_track', disabled: true},
      {label: t('Import Markers'), key: 'import_markers', disabled: true},
      {label: t('Export Markers'), key: 'export_markers', disabled: true},
      {label: t('Choose Columns'), key: 'choose_markers', disabled: true},
      {label: t('Remove'), key: 'remove'},
    ];
  }, [color_opts, t, selected_records]);
  let resize_handle = useCallback(()=>{
    let container = table_container_ref.current;
    let container_rect = container.getBoundingClientRect();
    let container_height = container_rect.height;
    let table_header = container.querySelector('.ant-table-header');
    let table_header_rect = table_header.getBoundingClientRect();
    let table_header_height = table_header_rect.height;
    let _height = Math.floor(container_height - table_header_height);
    height_set(_height);
  }, []);
  useEffect(()=>{
    let container = table_container_ref.current;
    let resize_observer = new ResizeObserver(resize_handle);
    resize_observer.observe(container);
    return ()=>resize_observer.unobserve(container);
  }, [resize_handle]);
  let query_change_handle = useCallback(e=>{
    query_set(e.target.value);
  }, []);
  let markers_modal_open_handle = useCallback(()=>{
    is_markers_modal_open_set(true);
  }, []);
  let markers_modal_close_handle = useCallback(()=>{
    is_markers_modal_open_set(false);
  }, []);
  let dropdown_click_handle = useCallback(({key})=>eserf(function*
  _dropdown_click_handle(){
    if (!selected_records.length)
      return;
    let first_record = selected_records[0];
    if (key == 'jump_to_marker')
      on_frame_change(first_record.marker.abs_start);
    if (key == 'edit_marker')
      marker_edit(first_record.track_id, first_record.marker);
    if (key.startsWith('change_color_'))
    {
      let color = key.replace('change_color_', '');
      for (let record of selected_records)
      {
        let res = yield cmd_marker_edit(lbin.rec_monitor_in.mob_id,
          record.track_id, record.marker.abs_start, 'CommentMarkerColor',
          color);
        if (res.err)
        {
          metric.error('cmd_marker_edit', str.j2s(res));
          return;
        }
      }
    }
    if (key == 'remove')
    {
      for (let record of selected_records)
      {
        let res = yield cmd_marker_remove(lbin.rec_monitor_in.mob_id,
          record.track_id, record.marker.abs_start);
        if (res.err)
        {
          metric.error('cmd_marker_remove', str.j2s(res));
          return;
        }
      }
    }
  }), [selected_records, on_frame_change, cmd_marker_remove, cmd_marker_edit,
    lbin?.rec_monitor_in?.mob_id, marker_edit]);
  return (
    <ConfigProvider theme={{components: {Table: {borderRadius: 0,
      headerBorderRadius: 0}}}}>
      <Markers_modal is_open={is_markers_modal_open} lbin={lbin}
        on_close={markers_modal_close_handle} marker_edit={marker_edit}
        on_frame_change={on_frame_change}
        cmd_marker_remove={cmd_marker_remove} />
      <div style={{display: 'flex', flexDirection: 'column', height: '100%',
        gap: '8px'}}>
        <div style={{display: 'flex', justifyContent: 'space-between',
          padding: '0 8px'}}>
          <Tooltip title={t('Expand')} placement="bottomRight">
            <Button icon={<ArrowsAltOutlined />} type="text"
              onClick={markers_modal_open_handle} />
          </Tooltip>
          <div style={{display: 'flex', gap: '8px'}}>
            <Input allowClear value={query} onChange={query_change_handle}
              prefix={<SearchOutlined style={{color: '#2A2A2A'}} />}
              style={{maxWidth: '200px'}} />
            <Dropdown menu={{items: dropdown_items,
              onClick: dropdown_click_handle}} trigger={['click']}
            disabled={!selected_records.length}>
              <Button icon={<MoreOutlined />} type="text"
                id="markers-dropdown-btn"
                disabled={!selected_records.length} />
            </Dropdown>
          </div>
        </div>
        <div style={{height: '100%', background: color_bg_container}}
          ref={table_container_ref}>
          <Markers_table lbin={lbin} query={query} scroll={{y: height}}
            on_frame_change={on_frame_change} marker_edit={marker_edit}
            on_selected_records_change={selected_records_set}
            cmd_marker_edit={cmd_marker_edit}
            cmd_marker_remove={cmd_marker_remove} />
        </div>
      </div>
    </ConfigProvider>
  );
});
let Bin_panel = React.memo(({lbin, on_frame_change, cmd_marker_edit, aaf_file,
  cmd_marker_remove, token, user, user_full, cmd_rec_monitor_load_clip,
  cmd_src_monitor_load_clip, cmd_clip_duplicate, marker_edit, tbin_upload,
  cmd_sequence_new, on_selected_monitor_change, on_src_frame_change,
  on_rec_frame_change})=>{
  let {t} = useTranslation();
  let [pane, pane_set] = useState('bin');
  let pane_opts = useMemo(()=>{
    let _pane_opts = [
      {value: 'bin', label: t('Bin')},
    ];
    if (lbin?.rec_monitor_in)
    {
      _pane_opts.push({value: 'markers',
        label: `${t('Markers')}: ${lbin.rec_monitor_in.lbl}`});
    }
    return _pane_opts;
  }, [lbin?.rec_monitor_in, t]);
  let pane_select_change_handle = useCallback(value=>{
    pane_set(value);
  }, []);
  return (
    <Editor_panel style={{width: '100%', height: '100%', display: 'flex',
      flexDirection: 'column', gap: '8px', background: gray[9]}}>
      <div style={{display: 'flex', padding: '0 8px'}}>
        <Select options={pane_opts} value={pane} style={{width: '100%'}}
          onChange={pane_select_change_handle} />
      </div>
      <div style={{height: '100%'}}>
        {pane == 'bin' && <Tbin_pane lbin={lbin} user_full={user_full}
          cmd_rec_monitor_load_clip={cmd_rec_monitor_load_clip}
          cmd_src_monitor_load_clip={cmd_src_monitor_load_clip}
          cmd_clip_duplicate={cmd_clip_duplicate} user={user} token={token}
          cmd_sequence_new={cmd_sequence_new} tbin_upload={tbin_upload}
          on_selected_monitor_change={on_selected_monitor_change}
          aaf_file={aaf_file} on_src_frame_change={on_src_frame_change}
          on_rec_frame_change={on_rec_frame_change} />}
        {pane == 'markers' && <Markers_pane lbin={lbin}
          on_frame_change={on_frame_change} cmd_marker_edit={cmd_marker_edit}
          cmd_marker_remove={cmd_marker_remove} marker_edit={marker_edit} />}
      </div>
    </Editor_panel>
  );
});
let Shortcuts_modal = React.memo(({is_open, on_close})=>{
  let {t} = useTranslation();
  let [query, query_set] = useState('');
  let action2lbl = useMemo(()=>({
    zoom_in: t('Zoom In'),
    zoom_out: t('Zoom Out'),
    change_selector_prev: t('Change Selector to Previous'),
    change_selector_next: t('Change Selector to Next'),
    move_one_frame_left: t('Move One Frame Left'),
    move_one_frame_right: t('Move One Frame Right'),
    move_ten_frames_left: t('Move Ten Frames Left'),
    move_ten_frames_right: t('Move Ten Frames Right'),
    play_stop: t('Play/Stop'),
    play_backwards: t('Play Backwards'),
    stop: t('Stop'),
    play: t('Play'),
    go_to_prev_cut: t('Go to Previous Cut'),
    go_to_next_cut: t('Go to Next Cut'),
    go_to_prev_marker: t('Go to Previous Marker'),
    go_to_next_marker: t('Go to Next Marker'),
    go_to_mark_in: t('Go to Mark In'),
    go_to_mark_out: t('Go to Mark Out'),
    marker_remove: t('Delete Marker'),
    mark_in: t('Mark In'),
    mark_out: t('Mark Out'),
    mark_clip: t('Mark Clip'),
    clear_both_marks: t('Clear Both Marks'),
    cut: t('Cut'),
    lift: t('Lift'),
    extract: t('Extract'),
    marker_add: t('Edit Marker'),
    play_in_to_out: t('Play In to Out'),
    undo: t('Undo'),
    redo: t('Redo'),
    mark_all_tracks: t('Mark All Tracks'),
    unmark_all_tracks: t('Unmark All Tracks'),
  }), [t]);
  let cols = useMemo(()=>{
    return [
      {key: 'lbl', dataIndex: 'lbl', title: 'name'},
      {key: 'key_bind', dataIndex: 'key_bind', title: 'Shortcut'},
    ];
  }, []);
  let data_src = useMemo(()=>{
    return Object.entries(action2key_bind)
      .filter(([action])=>action2lbl[action])
      .map(([action, key_bind])=>{
        if (!Array.isArray(key_bind))
          key_bind = [key_bind];
        let key_bind_lbl = key_bind
          .map(_key_bind=>key_bind2lbl(_key_bind))
          .join(', ');
        return {lbl: action2lbl[action], key_bind: key_bind_lbl};
      })
      .filter(({lbl, key_bind})=>{
        if (!query)
          return true;
        return lbl.toLowerCase().includes(query.toLowerCase()) ||
          key_bind.toLowerCase().includes(query.toLowerCase());
      });
  }, [action2lbl, query]);
  let query_change_handle = useCallback(e=>{
    query_set(e.target.value);
  }, []);
  return (
    <Modal title={t('Shortcuts')} open={is_open} footer={null}
      onCancel={on_close}>
      <Space direction="vertical" size="middle" style={{display: 'flex'}}>
        <Row>
          <Col span={12} offset={12}>
            <Input placeholder={t('Search...')} value={query} allowClear
              onChange={query_change_handle} />
          </Col>
        </Row>
        <Table dataSource={data_src} columns={cols} pagination={false} />
      </Space>
    </Modal>
  );
});
let Composer_panel = React.memo(({lbin, etag, fps, cmd_cut, token, cmd_mark_in,
  rec_playback_rate, rec_frame, src_frame, cmd_mark_out, rec_playing_toggle,
  cmd_lift, cmd_extract, user, zoom, on_zoom_change, aaf_file, tbin_files,
  cmd_clear_both_marks, aaf_in, marker_add, tbin_upload, cmd_overwrite,
  on_rec_frame_change, on_src_playback_rate_change, on_rec_playback_rate_change,
  undo, redo, loading_cmd, cur_lbin_change_idx, lbin_changes,
  on_loading_state_change, marker_edit, cmd_marker_edit, cmd_marker_remove,
  user_full, cmd_rec_monitor_load_clip, cmd_src_monitor_load_clip,
  cmd_clip_duplicate, on_src_frame_change, src_playback_rate,
  src_playing_toggle, cmd_sequence_new, selected_monitor,
  on_selected_monitor_change, ...rest})=>{
  let {t} = useTranslation();
  let navigate = useNavigate();
  let {qs_o} = use_qs();
  let splits_wrapper_ref = useRef(null);
  let [is_edit_mode, is_edit_mode_set] = use_je('highlighter.is_edit_mode',
    false);
  let [, drag_mode_set] = use_je('highlighter.drag_mode', null);
  let [, trim_mode_set] = use_je('highlighter.trim_mode', null);
  let initial_src = useMemo(()=>qs_o.src, [qs_o.src]);
  let [src_tc, src_tc_set] = useState(lbin?.rec_monitor_in?.tracks[0]?.id);
  let [rec_tc, rec_tc_set] = useState('abs');
  let [track_src_segs, track_src_segs_set] = useState(null);
  let [is_split, is_split_set] = useState(false);
  let [split_size, split_size_set] = useState(1);
  let [split_bank_idx, split_bank_idx_set] = useState(0);
  let [downloads_num, downloads_num_set] = useState(0);
  let [volume, volume_set] = useState(1);
  let [splits_width, splits_width_set] = useState(0);
  let [splits_height, splits_height_set] = useState(0);
  let [is_shortcuts_modal_open, is_shortcuts_modal_open_set] = useState(false);
  let [video_render_segs, video_render_segs_set] = useState([]);
  let [audio_render_segs, audio_render_segs_set] = useState([]);
  let zoom_to_fit = useCallback(()=>{
    on_zoom_change(0);
  }, [on_zoom_change]);
  let frame_change_handle = useCallback(_frame=>{
    if (selected_monitor == 'src')
      return on_src_frame_change(_frame);
    if (selected_monitor == 'rec')
      return on_rec_frame_change(_frame);
    assert(0, 'unexpected selected monitor ' + selected_monitor);
  }, [on_rec_frame_change, on_src_frame_change, selected_monitor]);
  let playback_rate_change_handle = useCallback(_playback_rate=>{
    if (selected_monitor == 'src')
      return on_src_playback_rate_change(_playback_rate);
    if (selected_monitor == 'rec')
      return on_rec_playback_rate_change(_playback_rate);
    assert(0, 'unexpected selected monitor ' + selected_monitor);
  }, [on_rec_playback_rate_change, on_src_playback_rate_change,
    selected_monitor]);
  let cur_monitor = useMemo(()=>{
    if (selected_monitor == 'src')
      return lbin?.src_monitor;
    if (selected_monitor == 'rec')
      return lbin?.rec_monitor_in;
    assert(0, 'unexpected selected monitor ' + selected_monitor);
  }, [lbin?.rec_monitor_in, lbin?.src_monitor, selected_monitor]);
  let cur_frame = useMemo(()=>{
    if (selected_monitor == 'src')
      return src_frame;
    if (selected_monitor == 'rec')
      return rec_frame;
    assert(0, 'unexpected selected monitor ' + selected_monitor);
  }, [rec_frame, selected_monitor, src_frame]);
  let cur_playback_rate = useMemo(()=>{
    if (selected_monitor == 'src')
      return src_playback_rate;
    if (selected_monitor == 'rec')
      return rec_playback_rate;
    assert(0, 'unexpected selected monitor ' + selected_monitor);
  }, [rec_playback_rate, selected_monitor, src_playback_rate]);
  let cuts = useMemo(()=>{
    if (!cur_monitor?.tracks)
      return [];
    return editor.cuts_get(cur_monitor.tracks);
  }, [cur_monitor?.tracks]);
  let go_to_prev_cut = useCallback(()=>{
    if (!cuts.length)
      return;
    let _cur_frame = cuts.find((cut, index)=>{
      return cut < cur_frame && cur_frame <= cuts[index + 1];
    });
    if (!_cur_frame)
      _cur_frame = cuts.at(0);
    frame_change_handle(_cur_frame);
    playback_rate_change_handle(0);
  }, [cur_frame, cuts, frame_change_handle, playback_rate_change_handle]);
  let go_to_next_cut = useCallback(()=>{
    if (!cuts.length)
      return;
    let _cur_frame = cuts.find(cut=>cur_frame < cut);
    if (!_cur_frame)
      _cur_frame = cuts.at(-1);
    frame_change_handle(_cur_frame);
    playback_rate_change_handle(0);
  }, [cur_frame, cuts, frame_change_handle, playback_rate_change_handle]);
  let external_srcs = useMemo(()=>{
    if (!tbin_files.length)
      return {};
    return tbin_files
      .map(tbin_file=>tbin_file.streams)
      .reduce((accum, cur)=>({...accum, ...cur}));
  }, [tbin_files]);
  use_effect_eserf(()=>eserf(function* _use_effect_render_segs_load(){
    if (!token || !cur_monitor)
    {
      video_render_segs_set([]);
      audio_render_segs_set([]);
      return;
    }
    let first_track = cur_monitor?.tracks[0];
    let [_video_render_segs, _audio_render_segs] = yield this.wait_ret([
      player.video_render_segs_get(token, cur_monitor,
        {track_id: first_track.id, is_solo: false}, external_srcs),
      player.audio_render_segs_get(token, cur_monitor,
        {track_id: first_track.id, is_solo: false}),
    ]);
    video_render_segs_set(_video_render_segs);
    audio_render_segs_set(_audio_render_segs);
  }), [cur_monitor, token]);
  let src_tc_select_opts = useMemo(()=>{
    if (!cur_monitor)
      return [];
    return cur_monitor.tracks
      .filter(track=>track.type != 'tc_track')
      .map(track=>({value: track.id, label: track.lbl}));
  }, [cur_monitor]);
  let src_tc_select_change_handle = useCallback(value=>{
    src_tc_set(value);
  }, []);
  let rec_tc_select_opts = useMemo(()=>{
    return [
      {value: 'mas', label: t('Master')},
      {value: 'abs', label: t('Absolute')},
    ];
  }, [t]);
  let rec_tc_select_change_handle = useCallback(value=>{
    rec_tc_set(value);
  }, []);
  let cur_src_tc = useMemo(()=>{
    if (!cur_monitor || !track_src_segs)
      return '00:00:00:00';
    let src_segs = track_src_segs[src_tc];
    if (!src_segs)
      return '00:00:00:00';
    let src_seg = src_segs.find(seg=>{
      return player.frame_start(seg) <= rec_frame
        && rec_frame <= player.frame_end(seg);
    });
    if (!src_seg)
      return '01:00:00:00';
    let tc_str = tc.frame2tc(src_seg.start_tc + rec_frame - src_seg.abs_start,
      cur_monitor.editrate);
    return tc_str;
  }, [cur_monitor, rec_frame, src_tc, track_src_segs]);
  let cur_rec_tc = useMemo(()=>{
    if (cur_monitor?.start_tc === undefined || !cur_monitor?.editrate)
      return '00:00:00:00';
    if (rec_tc == 'mas')
    {
      return tc.frame2tc(cur_monitor.start_tc + rec_frame,
        cur_monitor.editrate);
    }
    if (rec_tc == 'abs')
      return tc.frame2tc(rec_frame, cur_monitor.editrate);
  }, [cur_monitor?.start_tc, cur_monitor?.editrate, rec_frame, rec_tc]);
  let end_rec_tc = useMemo(()=>{
    if (!cur_monitor?.editrate
      || cur_monitor?.len === undefined
      || cur_monitor?.start_tc === undefined)
    {
      return '00:00:00:00';
    }
    if (rec_tc == 'mas')
    {
      return tc.frame2tc(cur_monitor.start_tc + cur_monitor.len,
        cur_monitor.editrate);
    }
    if (rec_tc == 'abs')
      return tc.frame2tc(cur_monitor.len, cur_monitor.editrate);
  }, [cur_monitor?.editrate, cur_monitor?.len, cur_monitor?.start_tc, rec_tc]);
  let aspect_ratio = useMemo(()=>{
    if (!cur_monitor?.resolution)
      return 16 / 9;
    return cur_monitor.resolution.w / cur_monitor.resolution.h;
  }, [cur_monitor?.resolution]);
  use_effect_eserf(()=>eserf(function* track_src_segs_get(){
    if (!cur_monitor?.tracks)
      return;
    let video_tracks = cur_monitor.tracks
      .filter(track=>track.id[0] == 'V')
      .sort((track1, track2)=>{
        let num1 = parseInt(track1.id.slice(1), 10);
        let num2 = parseInt(track2.id.slice(1), 10);
        return num2 - num1;
      });
    let audio_tracks = cur_monitor.tracks
      .filter(track=>track.id[0] == 'A')
      .sort((track1, track2)=>{
        let num1 = parseInt(track1.id.slice(1), 10);
        let num2 = parseInt(track2.id.slice(1), 10);
        return num2 - num1;
      });
    let _track_src_segs = {};
    for (let idx = 0; idx < video_tracks.length; idx++)
    {
      let track = video_tracks[idx];
      _track_src_segs[track.id] = yield player.src_segs_get(token,
        video_tracks.slice(idx));
    }
    for (let idx = 0; idx < audio_tracks.length; idx++)
    {
      let track = audio_tracks[idx];
      _track_src_segs[track.id] = yield player.src_segs_get(token,
        audio_tracks.slice(idx));
    }
    track_src_segs_set(_track_src_segs);
  }), [cur_monitor?.tracks, token]);
  useEffect(()=>{
    if (!cur_monitor)
      return;
    src_tc_set(cur_monitor.tracks[0]?.id);
  }, [cur_monitor]);
  let aaf_export = useCallback(()=>{
    if (!token || !cur_monitor || !aaf_in || !user?.email)
      return;
    let basename = cur_monitor.lbl;
    let filename = `${basename}__v${downloads_num + 1}__TOOLIUM_ORG.aaf`;
    let url = config_ext.back.app.url + xurl.url('/private/aaf/get.aaf',
      {file: aaf_in, email: user.email, token, ver: config_ext.ver, filename});
    download(url, filename);
    downloads_num_set(downloads_num + 1);
  }, [aaf_in, downloads_num, token, user.email, cur_monitor]);
  useEffect(()=>{
    downloads_num_set(0);
  }, [etag]);
  let resize_handle = useCallback(()=>{
    let wrapper = splits_wrapper_ref.current;
    let wrapper_rect = wrapper.getBoundingClientRect();
    let _splits_width = wrapper_rect.width;
    let _splits_height = wrapper_rect.width / aspect_ratio;
    if (_splits_height > wrapper_rect.height)
    {
      _splits_height = wrapper_rect.height;
      _splits_width = wrapper_rect.height * aspect_ratio;
    }
    splits_width_set(_splits_width);
    splits_height_set(_splits_height);
  }, [aspect_ratio]);
  useEffect(()=>{
    let wrapper = splits_wrapper_ref.current;
    // if splits mode is disabled
    if (!wrapper)
      return;
    let resize_observer = new ResizeObserver(resize_handle);
    resize_observer.observe(wrapper);
    return ()=>resize_observer.unobserve(wrapper);
  }, [resize_handle]);
  let markers = useMemo(()=>{
    if (!cur_monitor)
      return [];
    return editor.markers_get(cur_monitor.tracks);
  }, [cur_monitor]);
  let timeline_arr = useMemo(()=>{
    if (!cur_monitor)
      return [];
    let arr = [...markers];
    if (cur_monitor.mark_in)
    {
      arr.push({is_no_arr: true, len: 1, start: cur_monitor.mark_in,
        type: 'mark_in', zidx: 200});
    }
    if (cur_monitor.mark_out)
    {
      arr.push({is_no_arr: true, len: 1, start: cur_monitor.mark_out,
        type: 'mark_out', zidx: 200});
    }
    return arr;
  }, [cur_monitor, markers]);
  let cur_video_render_seg = useMemo(()=>{
    return video_render_segs.find(render_seg=>{
      return player.frame_start(render_seg) <= rec_frame
        && rec_frame <= player.frame_end(render_seg);
    });
  }, [rec_frame, video_render_segs]);
  let splits_num = useMemo(()=>{
    if (!cur_video_render_seg?.split_render_segs)
      return 1;
    return cur_video_render_seg.split_render_segs.length;
  }, [cur_video_render_seg?.split_render_segs]);
  let split_view_toggle = useCallback(()=>{
    if (!cur_video_render_seg?.split_render_segs)
      return;
    if (is_split)
      return is_split_set(false);
    if (splits_num == 1)
      split_size_set(1);
    else if (splits_num <= 4)
      split_size_set(4);
    else
      split_size_set(9);
    split_bank_idx_set(0);
    is_split_set(true);
  }, [cur_video_render_seg?.split_render_segs, is_split, splits_num]);
  let split_size_click_handler_get = useCallback(_split_size=>{
    if (!cur_video_render_seg?.split_render_segs)
      return;
    return ()=>split_size_set(_split_size);
  }, [cur_video_render_seg?.split_render_segs]);
  let swap_cam_bank_click_handle = useCallback(()=>{
    if (!cur_video_render_seg?.split_render_segs || splits_num <= split_size)
      return;
    let _split_bank_idx = (split_bank_idx + split_size) % splits_num;
    split_bank_idx_set(_split_bank_idx);
  }, [cur_video_render_seg?.split_render_segs, split_bank_idx, split_size,
    splits_num]);
  let splits = useMemo(()=>{
    if (!is_split || !cur_video_render_seg
      || !cur_video_render_seg.split_render_segs.length)
    {
      return [[{video_render_segs, audio_render_segs}]];
    }
    let _splits = [];
    for (let render_seg of cur_video_render_seg.split_render_segs)
      _splits.push({video_render_segs: [render_seg]});
    let grid_size = Math.floor(Math.sqrt(split_size));
    let rows = [];
    for (let row_idx = 0; row_idx < grid_size; row_idx++)
    {
      let start_idx = split_bank_idx + row_idx * grid_size;
      let end_idx = split_bank_idx + (row_idx + 1) * grid_size;
      let row = _splits.slice(start_idx, end_idx);
      let empty_splits = new Array(grid_size - row.length).fill(null);
      row = [...row, ...empty_splits];
      rows.push(row);
    }
    return rows;
  }, [audio_render_segs, cur_video_render_seg, is_split, split_bank_idx,
    split_size, video_render_segs]);
  let back_handle = useCallback(()=>{
    navigate(xurl.url('/workspace', {...qs_o, path: qs_o.src_path,
      is_dir: qs_o.src_is_dir}));
  }, [navigate, qs_o]);
  let volume_change_handle = useCallback(_volume=>{
    volume_set(_volume / 100);
  }, []);
  let is_undo_disabled = useMemo(()=>{
    return cur_lbin_change_idx == -1;
  }, [cur_lbin_change_idx]);
  let is_redo_disabled = useMemo(()=>{
    return cur_lbin_change_idx == lbin_changes.length - 1;
  }, [cur_lbin_change_idx, lbin_changes.length]);
  let dropdown_items = useMemo(()=>{
    return [
      {label: t('Undo'), key: 'undo', disabled: is_undo_disabled,
        icon: <UndoOutlined />},
      {label: t('Redo'), key: 'redo', disabled: is_redo_disabled,
        icon: <RedoOutlined />},
      {label: t('Shortcuts'), key: 'shortcuts',
        icon: <InfoCircleOutlined />},
      {label: t('Export'), key: 'export', disabled: !aaf_in,
        icon: <DownloadOutlined />},
    ];
  }, [aaf_in, is_redo_disabled, is_undo_disabled, t]);
  let dropdown_click_handle = useCallback(({key})=>{
    if (key == 'undo')
      undo();
    if (key == 'redo')
      redo();
    if (key == 'shortcuts')
      is_shortcuts_modal_open_set(true);
    if (key == 'export')
      aaf_export();
  }, [aaf_export, redo, undo]);
  let is_mark_in = useMemo(()=>{
    if (!cur_monitor?.mark_in)
      return false;
    return cur_monitor.mark_in == rec_frame;
  }, [cur_monitor?.mark_in, rec_frame]);
  let is_mark_out = useMemo(()=>{
    if (!cur_monitor?.mark_out)
      return false;
    return cur_monitor.mark_out == rec_frame;
  }, [cur_monitor?.mark_out, rec_frame]);
  let is_first_frame = useMemo(()=>{
    if (!cur_monitor)
      return false;
    return rec_frame == 0;
  }, [rec_frame, cur_monitor]);
  let is_penultimate_frame = useMemo(()=>{
    if (!cur_monitor?.len)
      return false;
    return rec_frame == cur_monitor.len - 1;
  }, [rec_frame, cur_monitor?.len]);
  let is_last_frame = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.len)
      return false;
    return rec_frame == lbin.rec_monitor_in.len;
  }, [rec_frame, lbin?.rec_monitor_in?.len]);
  let cur_marker = useMemo(()=>{
    return markers.find(seg=>seg.start == rec_frame);
  }, [rec_frame, markers]);
  let video_tracks = useMemo(()=>{
    if (!cur_monitor?.tracks)
      return [];
    return cur_monitor.tracks.filter(track=>track.id[0] == 'V');
  }, [cur_monitor?.tracks]);
  let is_clip_first_frame_left = useMemo(()=>{
    if (!video_tracks.length)
      return false;
    let _cuts = editor.cuts_get(video_tracks);
    return _cuts.some(cut=>cut == rec_frame);
  }, [rec_frame, video_tracks]);
  let is_clip_first_frame_right = useMemo(()=>{
    if (!video_tracks.length)
      return false;
    let _cuts = editor.cuts_get(video_tracks);
    let next_frame = rec_frame + 1;
    return _cuts.some(cut=>cut == next_frame);
  }, [rec_frame, video_tracks]);
  let rec_mark_in = useCallback(()=>{
    if (!lbin?.rec_monitor_in)
      return;
    cmd_mark_in(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_in, lbin?.rec_monitor_in, rec_frame]);
  let rec_mark_out = useCallback(()=>{
    if (!lbin?.rec_monitor_in)
      return;
    cmd_mark_out(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_out, lbin?.rec_monitor_in, rec_frame]);
  let mark_in = useCallback(()=>{
    if (!cur_monitor)
      return;
    cmd_mark_in(cur_monitor.mob_id, rec_frame);
  }, [cmd_mark_in, cur_monitor, rec_frame]);
  let mark_out = useCallback(()=>{
    if (!cur_monitor)
      return;
    cmd_mark_out(cur_monitor.mob_id, rec_frame);
  }, [cmd_mark_out, cur_monitor, rec_frame]);
  let clear_both_marks = useCallback(()=>{
    if (!cur_monitor)
      return;
    cmd_clear_both_marks(cur_monitor.mob_id);
  }, [cmd_clear_both_marks, cur_monitor]);
  let rec_clear_both_marks = useCallback(()=>{
    if (!lbin?.rec_monitor_in)
      return;
    cmd_clear_both_marks(lbin.rec_monitor_in.mob_id);
  }, [cmd_clear_both_marks, lbin]);
  let rec_cut = useCallback(()=>{
    if (!lbin?.rec_monitor_in)
      return;
    cmd_cut(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_cut, lbin?.rec_monitor_in, rec_frame]);
  let rec_lift = useCallback(()=>{
    if (lbin?.rec_monitor_in?.mark_in === undefined
      || lbin?.rec_monitor_in?.mark_out === undefined)
    {
      return;
    }
    cmd_lift(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
      lbin.rec_monitor_in.mark_out);
  }, [cmd_lift, lbin?.rec_monitor_in]);
  let rec_extract = useCallback(()=>{
    if (lbin?.rec_monitor_in?.mark_in === undefined
      || lbin?.rec_monitor_in?.mark_out === undefined)
    {
      return;
    }
    cmd_extract(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
      lbin.rec_monitor_in.mark_out);
  }, [cmd_extract, lbin?.rec_monitor_in]);
  let rec_overwrite = useCallback(()=>{
    if (!lbin?.rec_monitor_in || !lbin?.src_monitor)
      return;
    let intersection_track_ids = [];
    for (let rec_track of lbin.rec_monitor_in.tracks)
    {
      let src_track = lbin.src_monitor.tracks.find(_src_track=>{
        return _src_track.id === rec_track.id;
      });
      if (!src_track)
        continue;
      intersection_track_ids.push(rec_track.id);
    }
    if (!intersection_track_ids.length)
      return;
    let abs_frame_in_src = lbin.src_monitor.mark_in || src_frame;
    let abs_frame_in_rec = lbin.rec_monitor_in.mark_in || rec_frame;
    let abs_frame_out_src = lbin.src_monitor.mark_out;
    let abs_frame_out_rec = lbin.rec_monitor_in.mark_out;
    cmd_overwrite(lbin.src_monitor.mob_id, lbin.rec_monitor_in.mob_id,
      abs_frame_in_src, abs_frame_in_rec, abs_frame_out_src, abs_frame_out_rec,
      intersection_track_ids, intersection_track_ids);
  }, [cmd_overwrite, lbin?.rec_monitor_in, lbin?.src_monitor, src_frame,
    rec_frame]);
  let resolution = useMemo(()=>{
    if (!cur_monitor)
      return {w: 1920, h: 1080};
    return cur_monitor.resolution;
  }, [cur_monitor]);
  let switch_click_handle = useCallback(is_checked=>{
    is_edit_mode_set(is_checked);
    if (is_checked)
    {
      drag_mode_set({type: 'select', deltas: new Map(), is_dragging: false,
        init_track_ids: new Map(), track_ids: new Map()});
      trim_mode_set({left_segs: {}, right_segs: {}, start_pos: {}, deltas: {},
        init_pos: 0, is_dragging: false});
    }
    else
    {
      drag_mode_set(null);
      trim_mode_set(null);
    }
  }, [drag_mode_set, is_edit_mode_set, trim_mode_set]);
  let shortcuts_modal_close = useCallback(()=>{
    is_shortcuts_modal_open_set(false);
  }, []);
  let monitor_opts = useMemo(()=>{
    return [
      {value: 'rec', label: t('REC')},
      {value: 'src', label: t('SRC')},
    ];
  }, [t]);
  let monitor_change_handle = useCallback(value=>{
    on_selected_monitor_change(value);
  }, [on_selected_monitor_change]);
  let playing_toggle = useCallback(()=>{
    if (selected_monitor == 'src')
      return src_playing_toggle();
    if (selected_monitor == 'rec')
      return rec_playing_toggle();
    assert(0, 'unexpected selected monitor ' + selected_monitor);
  }, [rec_playing_toggle, selected_monitor, src_playing_toggle]);
  return (
    <Editor_panel style={{display: 'flex', flexDirection: 'column',
      background: gray[9]}} {...rest}>
      <Shortcuts_modal is_open={is_shortcuts_modal_open}
        on_close={shortcuts_modal_close} />
      <div style={{display: 'flex', justifyContent: 'space-between',
        alignItems: 'center', margin: '2px', padding: '8px'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
          {initial_src && <Tooltip title={t('Back to Workspace')}
            placement="bottomRight">
            <Button icon={<LeftOutlined />} type="text"
              onClick={back_handle} />
          </Tooltip>}
          {lbin?.rec_monitor_in?.lbl && <div style={{maxWidth: '50vw',
            overflow: 'hidden', textOverflow: 'ellipsis', color: 'white'}}>
            {lbin.rec_monitor_in.lbl}
          </div>}
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end',
          alignItems: 'center', gap: '4px'}}>
          {loading_cmd && <div style={{display: 'flex', alignItems: 'center',
            gap: '12px', color: 'white', paddingRight: '8px'}}>
            <Spin indicator={<LoadingOutlined spin />} />
            {loading_cmd}
          </div>}
          <Tooltip title={t('Undo')} placement="bottom">
            <Button type="text" onClick={undo} icon={<UndoOutlined />}
              disabled={is_undo_disabled} />
          </Tooltip>
          <Tooltip title={t('Redo')} placement="bottom">
            <Button type="text" onClick={redo} icon={<RedoOutlined />}
              disabled={is_redo_disabled} />
          </Tooltip>
          <Button type="text" onClick={aaf_export} disabled={!aaf_in}>
            {t('Export')}
          </Button>
          <Dropdown menu={{items: dropdown_items,
            onClick: dropdown_click_handle}} trigger={['click']}>
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        </div>
      </div>
      <Row style={{height: '100%'}}>
        <Col span={8} style={{height: '100%'}}>
          <Bin_panel lbin={lbin} on_frame_change={frame_change_handle}
            cmd_marker_edit={cmd_marker_edit} user={user} user_full={user_full}
            token={token} cmd_marker_remove={cmd_marker_remove}
            cmd_rec_monitor_load_clip={cmd_rec_monitor_load_clip}
            cmd_src_monitor_load_clip={cmd_src_monitor_load_clip}
            tbin_upload={tbin_upload} cmd_clip_duplicate={cmd_clip_duplicate}
            marker_edit={marker_edit} cmd_sequence_new={cmd_sequence_new}
            on_selected_monitor_change={on_selected_monitor_change}
            aaf_file={aaf_file} on_src_frame_change={on_src_frame_change}
            on_rec_frame_change={on_rec_frame_change}/>
        </Col>
        <Col span={16} style={{width: '100%', maxWidth: '100%', display: 'flex',
          height: '100%', flexDirection: 'column', background: '#222222',
          alignItems: 'center'}}>
          <div style={{display: 'flex', gap: '8px', alignItems: 'center',
            padding: '0 8px 8px', background: gray[9], width: '100%'}}>
            <Select options={monitor_opts} value={selected_monitor}
              onChange={monitor_change_handle} />
            {cur_monitor?.lbl}
          </div>
          {is_split && <div style={{display: 'flex', justifyContent: 'center',
            alignItems: 'center', padding: '8px', background: gray[9],
            width: '100%'}}>
            <Button type="text" onClick={split_size_click_handler_get(1)}>
              {t('Single Cam')}
            </Button>
            <Button type="text" onClick={split_size_click_handler_get(4)}>
              {t('4 Split')}
            </Button>
            <Button type="text" onClick={split_size_click_handler_get(9)}>
              {t('9 Split')}
            </Button>
            <Button type="text" onClick={swap_cam_bank_click_handle}>
              {t('Swap Cam Bank')}
            </Button>
          </div>}
          <div style={{width: '100%', height: '100%', display: 'flex',
            justifyContent: 'center', position: 'relative'}}
          ref={splits_wrapper_ref}>
            <div style={{display: 'flex', flexDirection: 'column',
              position: 'absolute', top: '50%', left: '50%',
              transform: 'translate(-50%, -50%)', width: '100%',
              maxWidth: splits_width, height: '100%',
              maxHeight: splits_height}}>
              {splits.map((row, row_idx)=><div key={row_idx}
                style={{display: 'flex', height: '100%', width: splits_width,
                  justifyContent: 'center',
                  borderTop: row_idx != 0 ? '1px solid black' : 'none'}}>
                {row.map((col, col_idx)=>{
                  if (!col)
                  {
                    return <div key={col_idx} style={{height: '100%',
                      aspectRatio: aspect_ratio}} />;
                  }
                  return (
                    <div key={col_idx} style={{position: 'relative',
                      height: '100%', aspectRatio: aspect_ratio,
                      borderLeft: col_idx != 0 ? '1px solid black' : 'none'}}>
                      <player.Player video_render_segs={col.video_render_segs}
                        resolution={resolution}
                        frame={selected_monitor == 'rec'
                          ? rec_frame : src_frame}
                        playback_rate={selected_monitor == 'rec'
                          ? rec_playback_rate : src_playback_rate}
                        audio_render_segs={col.audio_render_segs} fps={fps}
                        is_audio_video_sync volume={volume}
                        on_loading_state_change={on_loading_state_change} />
                    </div>
                  );
                })}
              </div>)}
              {is_mark_in && <Mark_in_indicator style={{left: 0, top: '50%',
                transform: 'translateY(-50%)'}} />}
              {is_mark_out && <Mark_out_indicator style={{right: 0,
                top: '50%', transform: 'translateY(-50%)'}} />}
              {is_first_frame && <div style={{position: 'absolute',
                left: '5px', bottom: '2px'}}>
                <First_frame_icon />
              </div>}
              {!is_first_frame && !is_last_frame
                && is_clip_first_frame_left
                && <div style={{position: 'absolute', left: '5px',
                  bottom: '2px'}}>
                  <Clip_first_frame_left_icon />
                </div>}
              {!is_penultimate_frame && is_clip_first_frame_right
                && <div style={{position: 'absolute', right: '5px',
                  bottom: '2px'}}>
                  <Clip_first_frame_right_icon />
                </div>}
              {is_penultimate_frame && <div style={{position: 'absolute',
                right: '5px', bottom: '2px'}}>
                <Last_frame_icon />
              </div>}
              {is_last_frame && <div style={{position: 'absolute',
                right: '5px', bottom: '2px'}}>
                <Last_frame_icon />
              </div>}
              {cur_marker && <div style={{position: 'absolute',
                display: 'flex', flexDirection: 'column',
                alignItems: 'center', bottom: '4px', left: '50%',
                transform: 'translateX(-50%)',
                maxWidth: 'calc(100% - 40px)'}}>
                <Icon component={Marker_seg_icon}
                  style={{color: cur_marker.color, fontSize: '24px'}} />
                {cur_marker.comment && <span style={{color: 'white',
                  fontSize: '11px', maxWidth: '100%',
                  textShadow: '-1px 0 black, 0 1px black, 1px 0 black,'
                    +' 0 -1px black', whiteSpace: 'nowrap',
                  overflow: 'hidden', textOverflow: 'ellipsis'}}>
                  {cur_marker.comment}
                </span>}
              </div>}
            </div>
          </div>
          <Scrub_bar len={cur_monitor?.len} fps={fps} frame={cur_frame}
            arr={timeline_arr} max_width={splits_width}
            on_frame_change={frame_change_handle} />
          <div style={{display: 'flex', justifyContent: 'center',
            background: gray[9], width: '100%', gap: '8px'}}>
            <Tooltip title={t('Mark IN')}>
              <Button type="text" onClick={mark_in} disabled={!aaf_in}
                icon={<Mark_in_icon />} />
            </Tooltip>
            <Tooltip title={t('Mark OUT')}>
              <Button type="text" onClick={mark_out} disabled={!aaf_in}
                icon={<Mark_out_icon />} />
            </Tooltip>
            <Tooltip title={t('Clear Both Marks')}>
              <Button type="text" onClick={clear_both_marks}
                disabled={!aaf_in} icon={<Clear_both_marks_icon />} />
            </Tooltip>
          </div>
        </Col>
      </Row>
      <div style={{display: 'flex', color: 'white',
        justifyContent: 'space-between', padding: '8px'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
          <Switch checkedChildren={t('Edit')} unCheckedChildren={t('View')}
            checked={is_edit_mode} onClick={switch_click_handle} />
          <Tooltip title={t('Split')}>
            <Button type="text" onClick={rec_cut} disabled={!aaf_in}
              icon={<ScissorOutlined />} />
          </Tooltip>
          <Tooltip title={t('Mark IN')}>
            <Button type="text" onClick={rec_mark_in} disabled={!aaf_in}
              icon={<Mark_in_icon />} />
          </Tooltip>
          <Tooltip title={t('Mark OUT')}>
            <Button type="text" onClick={rec_mark_out} disabled={!aaf_in}
              icon={<Mark_out_icon />} />
          </Tooltip>
          <Tooltip title={t('Clear Both Marks')}>
            <Button type="text" onClick={rec_clear_both_marks}
              disabled={!aaf_in} icon={<Clear_both_marks_icon />} />
          </Tooltip>
          <Tooltip title={t('Extract')}>
            <Button type="text" onClick={rec_extract} disabled={!aaf_in}
              icon={<Extract_icon />} />
          </Tooltip>
          <Tooltip title={t('Lift')}>
            <Button type="text" onClick={rec_lift} disabled={!aaf_in}
              icon={<Lift_icon />} />
          </Tooltip>
          <Tooltip title={t('Add Marker')}>
            <Button type="text" onClick={marker_add} disabled={!aaf_in}
              icon={<Marker_icon />} />
          </Tooltip>
          <Tooltip title={t('Split View')}>
            <Button type="text" onClick={split_view_toggle}
              icon={<Quad_split_icon />} />
          </Tooltip>
          <Tooltip title={t('Overwrite')}>
            <Button type="text" onClick={rec_overwrite} icon={<Select_icon />}
              disabled={!aaf_in || !lbin?.src_monitor} />
          </Tooltip>
        </div>
        <div style={{display: 'flex', justifyContent: 'center',
          alignItems: 'center', gap: '8px'}}>
          <Select options={src_tc_select_opts} style={{width: '150px'}}
            value={src_tc} onChange={src_tc_select_change_handle} />
          <div style={{display: 'flex', alignItems: 'center', gap: '8px',
            width: '100px', justifyContent: 'center'}}>
            {cur_src_tc}
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Button type="text" onClick={go_to_prev_cut}
              icon={<Icon style={{fontSize: '12px'}}
                component={Play_prev_icon} />} />
            <Button type="text" onClick={playing_toggle}>
              {cur_playback_rate ? <PauseOutlined style={{fontSize: '24px'}} />
                : <Icon style={{fontSize: '24px'}}
                  component={Play_icon} />}
            </Button>
            <Button type="text" onClick={go_to_next_cut}
              icon={<Icon style={{fontSize: '12px'}}
                component={Play_next_icon} />} />
          </div>
          <div style={{display: 'flex', alignItems: 'center', gap: '8px',
            width: '200px', justifyContent: 'center'}}>
            <span>{cur_rec_tc}</span>
            <span> | </span>
            <span>{end_rec_tc}</span>
          </div>
          <Select options={rec_tc_select_opts} style={{width: '150px'}}
            value={rec_tc} onChange={rec_tc_select_change_handle} />
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end',
          alignItems: 'center', gap: '4px'}}>
          <Tooltip title={t('Zoom to fit')}>
            <Button type="text" onClick={zoom_to_fit}
              icon={<Icon component={Zoom_to_fit_icon} />} />
          </Tooltip>
          <Slider min={min_zoom} max={max_zoom} style={{width: '100px'}}
            onChange={on_zoom_change} value={zoom} step={0.1} />
          <Divider type="vertical" />
          <Popover content={<Slider min={0} max={200} value={volume * 100}
            onChange={volume_change_handle} step={1} style={{width: '200px'}}
            tooltip={{formatter: n=>`${n}%`}} />}>
            <Button type="text" icon={<SoundOutlined />} />
          </Popover>
          <Tooltip title={t('Audio meter')}>
            <Button type="text" disabled
              icon={<Icon component={Audio_meter_icon} />} />
          </Tooltip>
        </div>
      </div>
    </Editor_panel>
  );
});
let Track_actions = React.memo(({rec_track, cmd_toggle_lock, cmd_solo, cmd_mute,
  lbin})=>{
  let [rec_editing_track_ids, rec_editing_track_ids_set] = use_je(
    'highlighter.rec_editing_track_ids', []);
  let [tracks_mark_sliding, tracks_mark_sliding_set] = use_je(
    'highlighter.tracks_mark_sliding', null);
  let no_fill = useMemo(()=>{
    return rec_track.height ? rec_track.height.endsWith('no_fill') : false;
  }, [rec_track.height]);
  let height = useMemo(()=>{
    return track_height2px(rec_track.height);
  }, [rec_track.height]);
  let is_audio_track = useMemo(()=>rec_track.id[0] == 'A', [rec_track.id]);
  let solo_handle = useCallback(e=>{
    let is_solo = !rec_track.solo.v;
    let track_ids;
    if (e.altKey)
    {
      track_ids = lbin.rec_monitor_in.tracks
        .filter(track=>track.id[0] == 'A' && track.solo.v != is_solo)
        .map(track=>track.id);
    }
    else
      track_ids = [rec_track.id];
    cmd_solo(lbin.rec_monitor_in.mob_id, track_ids, is_solo);
  }, [cmd_solo, rec_track, lbin]);
  let mute_handle = useCallback(e=>{
    let is_mute = rec_track.mute.v == !!rec_track.mute.is_solo;
    let track_ids;
    if (e.altKey)
    {
      track_ids = lbin.rec_monitor_in.tracks
        .filter(track=>track.id[0] == 'A' && track.mute.v != is_mute)
        .map(track=>track.id);
    }
    else
      track_ids = [rec_track.id];
    cmd_mute(lbin.rec_monitor_in.mob_id, track_ids, is_mute);
  }, [cmd_mute, rec_track, lbin]);
  let rec_mouse_enter_handle = useCallback(()=>{
    if (!tracks_mark_sliding || !rec_track)
      return;
    if (tracks_mark_sliding.is_editing
      && !rec_editing_track_ids.includes(rec_track.id))
    {
      return rec_editing_track_ids_set([...rec_editing_track_ids,
        rec_track.id]);
    }
    if (!tracks_mark_sliding.is_editing
      && rec_editing_track_ids.includes(rec_track.id))
    {
      let _rec_editing_track_ids = [...rec_editing_track_ids];
      let idx = _rec_editing_track_ids.indexOf(rec_track.id);
      _rec_editing_track_ids.splice(idx, 1);
      rec_editing_track_ids_set(_rec_editing_track_ids);
    }
  }, [rec_editing_track_ids, rec_editing_track_ids_set, tracks_mark_sliding,
    rec_track]);
  let mouse_up_handle = useCallback(()=>{
    tracks_mark_sliding_set(null);
  }, [tracks_mark_sliding_set]);
  let rec_edit_toggle_handle = useCallback(e=>{
    if (e.nativeEvent.which != 1)
      return;
    let is_editing = rec_editing_track_ids.includes(rec_track.id);
    if (e.shiftKey)
      tracks_mark_sliding_set({is_editing: !is_editing});
    if (!is_editing)
    {
      return rec_editing_track_ids_set([...rec_editing_track_ids,
        rec_track.id]);
    }
    let _rec_editing_track_ids = [...rec_editing_track_ids];
    let idx = _rec_editing_track_ids.indexOf(rec_track.id);
    _rec_editing_track_ids.splice(idx, 1);
    rec_editing_track_ids_set(_rec_editing_track_ids);
  }, [rec_editing_track_ids, rec_track.id, tracks_mark_sliding_set,
    rec_editing_track_ids_set]);
  let is_editing = useMemo(()=>{
    return rec_editing_track_ids.includes(rec_track.id);
  }, [rec_editing_track_ids, rec_track.id]);
  useEffect(()=>{
    if (!tracks_mark_sliding)
      return;
    window.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      window.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [mouse_up_handle, tracks_mark_sliding]);
  return (
    <div style={{display: 'flex', height: `${height}px`, padding: '0 8px',
      margin: no_fill && '3px 0', position: 'relative', marginBottom: '4px',
      width: '100%'}}>
      <Button type={is_editing ? 'primary': 'text'} style={{width: '36px'}}
        onMouseDown={rec_edit_toggle_handle}
        onMouseEnter={rec_mouse_enter_handle}>
        {rec_track.id}
      </Button>
      <Button type="text" style={{width: '36px', color: 'white'}}
        onMouseDown={cmd_toggle_lock} icon={<LockOutlined />} />
      {is_allow_solo_mute && is_audio_track && <Button type="text"
        style={{width: '36px', color: 'white', padding: 0,
          background: rec_track.solo.v && rec_track.solo.color}}
        onMouseDown={solo_handle}>
        S
      </Button>}
      {is_allow_solo_mute && is_audio_track && <Button type="text"
        style={{width: '36px', color: 'white', padding: 0,
          background: rec_track.mute.v && rec_track.mute.color}}
        onMouseDown={mute_handle}>
        M
      </Button>}
    </div>
  );
});
let Tracks_left_container = React.memo(({cmd_mute, cmd_solo, cmd_toggle_lock,
  f2px_k, lbin, scroll_x, scroll_x_set, scroll_y, scroll_y_set,
  tracks_container_height, tracks_pad, tracks_total_height,
  tracks_wrapper_height, tracks_wrapper_width, zoom})=>{
  let container_ref = useRef(null);
  let wheel_handle = useCallback(e=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let delta_x = e.ctrlKey ? e.deltaY : e.deltaX;
    let delta_y = e.ctrlKey ? e.deltaX : e.deltaY;
    let can_move_x;
    if (e.deltaX > 0)
    {
      can_move_x = scroll_x
        + player.px2f(tracks_wrapper_width, f2px_k, zoom)
        < lbin.rec_monitor_in.len;
    }
    else
      can_move_x = !!scroll_x;
    let can_move_y = e.deltaY > 0
      ? scroll_y + tracks_wrapper_height < tracks_container_height : !!scroll_y;
    if (delta_x && can_move_x || delta_y && can_move_y)
      e.preventDefault();
    scroll_x_set(scroll_x + player.px2f(delta_x, f2px_k, zoom));
    scroll_y_set(scroll_y + delta_y);
  }, [f2px_k, lbin?.rec_monitor_in?.len, scroll_x, scroll_x_set, scroll_y,
    scroll_y_set, tracks_container_height, tracks_wrapper_height,
    tracks_wrapper_width, zoom]);
  useEffect(()=>{
    let target = container_ref.current;
    if (!target)
      return;
    target.addEventListener('wheel', wheel_handle,
      {passive: false});
    return ()=>{
      target.removeEventListener('wheel', wheel_handle);
    };
  }, [wheel_handle]);
  return (
    <div ref={container_ref} style={{position: 'relative'}}>
      <div style={{minWidth: is_allow_solo_mute ? '160px' : '88px',
        width: is_allow_solo_mute ? '160px' : '88px', height: '100%',
        position: 'relative', overflow: 'hidden'}}>
        <div style={{height: `${tracks_total_height + tracks_pad * 2}px`,
          display: 'flex', flexDirection: 'column', alignItems: 'flex-end',
          justifyContent: 'center', position: 'absolute',
          top: `${tracks_pad - scroll_y}px`, right: 0}}>
          {lbin?.rec_monitor_in?.tracks?.map((track, idx)=>{
            if (track.type != 'timeline_track')
              return null;
            if (track.id[0] == 'V' && idx != 0)
              return null;
            return <Track_actions key={track.lbl} rec_track={track} lbin={lbin}
              cmd_toggle_lock={cmd_toggle_lock} cmd_solo={cmd_solo}
              cmd_mute={cmd_mute} />;
          })}
        </div>
      </div>
    </div>
  );
});
// XXX vladimir: move to player.js
let Frame_ptr = React.memo(({left, color=theme.light_blue, is_dashed,
  style={}})=>{
  return <div style={{height: '100%', width: '1px', borderLeftWidth: '1px',
    borderLeftColor: color, borderLeftStyle: is_dashed ? 'dashed' : 'solid',
    position: 'absolute', left: `${left}px`, pointerEvents: 'none',
    top: '0px', zIndex: 102, ...style}} />;
});
let allow_select_fillers = false;
let Seg = React.memo(({editrate, start_tc, track_idx, abs_start, seg, fps, zoom,
  depth=1, is_no_border, ctx_data_set, f2px_k, track_id,
  premium_modal_open, lbin, cmd_trim, playback_rate=1,
  is_trim_start_moved=false, trim_offset=0, is_parent_selected=false})=>{
  let [is_edit_mode] = use_je('highlighter.is_edit_mode', false);
  let [drag_mode, drag_mode_set] = use_je('highlighter.drag_mode', null);
  let [trim_mode, trim_mode_set] = use_je('highlighter.trim_mode', null);
  let ref = useRef(null);
  let is_selected = useMemo(()=>{
    return is_parent_selected || drag_mode?.deltas.has(seg);
  }, [is_parent_selected, seg, drag_mode?.deltas]);
  let is_any_delta_changed = useMemo(()=>{
    if (!drag_mode)
      return false;
    return drag_mode.deltas.values().some(delta=>delta != 0);
  }, [drag_mode]);
  let is_on_another_track = useMemo(()=>{
    if (!drag_mode)
      return false;
    if (!drag_mode.track_ids.has(seg))
      return false;
    return drag_mode.track_ids.get(seg) != track_id;
  }, [seg, track_id, drag_mode]);
  let is_from_another_track = useMemo(()=>{
    if (!drag_mode)
      return false;
    if (!drag_mode.init_track_ids.has(seg))
      return false;
    return drag_mode.init_track_ids.get(seg) != track_id;
  }, [seg, track_id, drag_mode]);
  let ctx_menu_handle = useCallback(e=>{
    e.preventDefault();
    let is_audio_track = track_id[0] == 'A';
    if (!seg.is_on_right_click_menu || is_audio_track)
      return;
    let items = seg.arr
      .map(child_seg=>{
        let src_seg = src_seg_get(child_seg);
        if (!src_seg)
          return null;
        return player.src2url(src_seg.src);
      })
      .filter(src=>src);
    ctx_data_set({visible: true, track_id, seg, items});
  }, [seg, ctx_data_set, track_id]);
  let mouse_down_handle = useCallback(e=>{
    if (!drag_mode || seg.depth != 2)
      return;
    if (!allow_select_fillers && seg.type == 'filler')
      return;
    let is_dragging = false;
    let deltas = new Map(drag_mode.deltas);
    let track_ids = new Map(drag_mode.track_ids);
    for (let track of lbin.rec_monitor_in.tracks)
    {
      let abs_starts_map = player.abs_starts_get(track);
      if (e.shiftKey && drag_mode.deltas.has(seg))
      {
        for (let [_seg] of abs_starts_map.entries())
        {
          if (_seg.abs_start != seg.abs_start)
            continue;
          deltas.delete(_seg);
          track_ids.delete(_seg);
        }
      }
      if (e.shiftKey && !drag_mode.deltas.has(seg))
      {
        for (let [_seg] of abs_starts_map.entries())
        {
          if (_seg.abs_start != seg.abs_start || _seg.depth != 2
            || _seg.type == 'filler')
          {
            continue;
          }
          deltas.set(_seg, 0);
          track_ids.set(_seg, track.id);
        }
      }
      if (!e.shiftKey && drag_mode.deltas.has(seg))
        is_dragging = true;
      if (!e.shiftKey && !drag_mode.deltas.has(seg))
      {
        is_dragging = true;
        for (let [_seg] of abs_starts_map.entries())
        {
          if (_seg.depth != 2 || _seg.type == 'filler')
            continue;
          if (_seg.abs_start == seg.abs_start)
          {
            deltas.set(_seg, 0);
            track_ids.set(_seg, track.id);
          }
          else
          {
            deltas.delete(_seg);
            track_ids.delete(_seg);
          }
        }
      }
    }
    let container = e.target.closest('#tracks-container');
    if (!container)
      return;
    let drag_cur_frame = player.px2f(e.clientX - coords_get(container).left,
      f2px_k, zoom);
    drag_cur_frame = Math.round(drag_cur_frame);
    drag_mode_set({...drag_mode, deltas, init_track_ids: new Map(track_ids),
      track_ids, is_dragging, init_frame: drag_cur_frame,
      init_track_id: track_id});
    // reset trim mode
    trim_mode_set({left_segs: {}, right_segs: {}, start_pos: {}, deltas: {},
      init_pos: 0, is_dragging: false});
  }, [drag_mode, seg, f2px_k, zoom, drag_mode_set, track_id,
    lbin?.rec_monitor_in?.tracks, trim_mode_set]);
  let child_segs = useMemo(()=>{
    if (seg.is_no_arr)
      return [];
    if (seg.type == 'selector')
      return [seg.arr[seg.select_idx]];
    return seg.arr;
  }, [seg]);
  let color = useMemo(()=>{
    if (seg.is_hide)
      return 'transparent';
    if (is_selected || is_parent_selected)
      return '#608DAF';
    if (seg.rndr?.is_mute)
      return '#848484';
    if (seg.color)
      return seg.color;
    return '#222222';
  }, [seg, is_selected, is_parent_selected]);
  let show_icons_container = useMemo(()=>{
    return seg.icon?.is_icon_inp || seg.icon?.is_icon_out;
  }, [seg.icon?.is_icon_inp, seg.icon?.is_icon_out]);
  let trim_mode_enable = useCallback(frame=>{
    let start_pos = {};
    let deltas = {};
    let left_segs = {};
    let right_segs = {};
    let trimming_segs_num = 0;
    for (let track of lbin.rec_monitor_in.tracks)
    {
      let tracks_cuts = editor.cuts_get([track]);
      if (!tracks_cuts.includes(frame))
        continue;
      start_pos[track.id] = frame;
      deltas[track.id] = 0;
      left_segs[track.id] = [];
      right_segs[track.id] = [];
      let abs_starts_map = player.abs_starts_get(track);
      for (let [_seg] of abs_starts_map.entries())
      {
        if (!_seg.trim)
          continue;
        if (_seg.abs_start == frame)
        {
          left_segs[track.id].push(_seg);
          trimming_segs_num += 1;
        }
        if (_seg.abs_start + _seg.len == frame)
        {
          right_segs[track.id].push(_seg);
          trimming_segs_num += 1;
        }
      }
    }
    if (!trimming_segs_num)
      return;
    trim_mode_set({left_segs, right_segs, start_pos, deltas,
      init_pos: frame, is_dragging: true});
    // reset drag mode
    drag_mode_set({type: 'select', deltas: new Map(), is_dragging: false,
      init_track_ids: new Map(), track_ids: new Map()});
  }, [lbin?.rec_monitor_in, trim_mode_set, drag_mode_set]);
  let left_edge_mouse_down_handle = useCallback(e=>{
    e.stopPropagation();
    trim_mode_enable(seg.abs_start);
  }, [seg.abs_start, trim_mode_enable]);
  let right_edge_mouse_down_handle = useCallback(e=>{
    e.stopPropagation();
    trim_mode_enable(seg.abs_start + seg.len);
  }, [seg.abs_start, seg.len, trim_mode_enable]);
  let is_left_trim = useMemo(()=>{
    return trim_mode && trim_mode.left_segs[track_id]?.includes(seg);
  }, [trim_mode, track_id, seg]);
  let is_right_trim = useMemo(()=>{
    return trim_mode && trim_mode.right_segs[track_id]?.includes(seg);
  }, [trim_mode, track_id, seg]);
  let is_trim = useMemo(()=>{
    return is_left_trim || is_right_trim;
  }, [is_left_trim, is_right_trim]);
  let trim_delta = useMemo(()=>{
    if (is_trim)
      return trim_mode.deltas[track_id];
    return 0;
  }, [is_trim, track_id, trim_mode?.deltas]);
  let drag_delta = useMemo(()=>{
    if (is_selected)
      return drag_mode.deltas.get(seg);
    return 0;
  }, [drag_mode?.deltas, is_selected, seg]);
  let left = useMemo(()=>{
    if (is_selected)
      return player.f2px(seg.start + drag_delta, f2px_k, zoom);
    if (is_left_trim)
      return player.f2px(seg.start + trim_delta, f2px_k, zoom);
    if (is_right_trim)
      return player.f2px(seg.start, f2px_k, zoom);
    if (seg.start == 0)
      return player.f2px(0, f2px_k, zoom);
    return player.f2px(seg.start - trim_offset, f2px_k, zoom);
  }, [f2px_k, is_right_trim, seg.start, trim_delta, trim_offset, zoom,
    is_left_trim, is_selected, drag_delta]);
  let len = useMemo(()=>{
    if (is_left_trim)
    {
      return player.f2px(seg.len - trim_delta, f2px_k, zoom)
        * playback_rate;
    }
    if (is_right_trim)
    {
      return player.f2px(seg.len + trim_delta, f2px_k, zoom)
        * playback_rate;
    }
    return player.f2px(seg.len - trim_offset, f2px_k, zoom) * playback_rate;
  }, [f2px_k, is_left_trim, is_right_trim, playback_rate, seg.len, trim_delta,
    zoom, trim_offset]);
  let drag_filler_left = useMemo(()=>{
    if (is_selected && !is_parent_selected)
      return player.f2px(seg.start, f2px_k, zoom);
    return 0;
  }, [f2px_k, is_parent_selected, is_selected, seg.start, zoom]);
  let drag_filler_len = useMemo(()=>{
    return player.f2px(seg.len, f2px_k, zoom);
  }, [f2px_k, seg.len, zoom]);
  let next_playback_rate = useMemo(()=>{
    if (seg.type == 'operation_motion' && seg.playrate !== undefined)
      return playback_rate / seg.playrate;
    return playback_rate;
  }, [playback_rate, seg.playrate, seg.type]);
  let next_trim_offset = useMemo(()=>{
    if (trim_offset)
      return trim_offset;
    if (is_left_trim)
      return trim_delta;
    return 0;
  }, [is_left_trim, trim_offset, trim_delta]);
  let dragged_segs = useMemo(()=>{
    if (seg.depth != 1 || !drag_mode?.init_track_ids)
      return [];
    let _dragged_segs = [];
    let seg_idx = 0;
    for (let [_seg, init_track_id] of drag_mode.init_track_ids.entries())
    {
      let seg_track_id = drag_mode.track_ids.get(_seg);
      if (init_track_id == seg_track_id || seg_track_id != track_id)
        continue;
      _dragged_segs.push([_seg, init_track_id + seg_idx]);
      seg_idx++;
    }
    return _dragged_segs;
  }, [drag_mode?.init_track_ids, drag_mode?.track_ids, seg.depth, track_id]);
  let is_on_boundary = useMemo(()=>{
    if (!trim_mode || !seg.trim)
      return false;
    return is_left_trim
      && (trim_delta == -seg.trim?.start_pre
        || trim_delta == seg.trim?.start_post)
      || is_right_trim
      && (trim_delta == -seg.trim?.end_pre
        || trim_delta == seg.trim?.end_post);
  }, [is_left_trim, is_right_trim, seg.trim, trim_delta, trim_mode]);
  let trim_handle_color = useMemo(()=>{
    if (!trim_mode)
      return null;
    if (is_on_boundary && trim_mode.is_dragging)
      return 'linear-gradient(90deg, #ff0101 0%, #6b0000 100%)';
    return 'linear-gradient(90deg, #fb5ce3 0%, #79286d 100%)';
  }, [is_on_boundary, trim_mode]);
  if (seg.type == 'marker')
  {
    return (
      <Marker_seg color={seg.color} left={left}
        zidx={seg.zidx} opacity={seg.opacity} />
    );
  }
  if (seg.type == 'mark_in')
    return <Mark_in_seg left={left} zidx={seg.zidx} opacity={seg.opacity} />;
  if (seg.type == 'mark_out')
    return <Mark_out_seg left={left} zidx={seg.zidx} opacity={seg.opacity} />;
  return (
    <>
      {is_selected && !!drag_filler_len
        && !is_from_another_track && <div style={{display: 'flex', top: 0,
        position: 'absolute', background: '#222222', height: '100%',
        borderRadius: '4px', whiteSpace: 'nowrap',
        left: `${drag_filler_left}px`, width: `${drag_filler_len}px`,
        overflow: 'hidden', zIndex: seg.zidx, opacity: seg.opacity}} />}
      {!!len && !is_on_another_track && <div style={{display: 'flex', top: 0,
        position: 'absolute', height: '100%', borderRadius: '4px',
        whiteSpace: 'nowrap', left: `${left}px`, width: `${len}px`,
        background: color, overflow: seg.depth < 2 ? 'visible' : 'hidden',
        zIndex: is_selected ? seg.zidx + 100 : seg.zidx,
        opacity: is_selected && is_any_delta_changed ? 0.7 : seg.opacity}}
      ref={ref} onContextMenu={ctx_menu_handle} onMouseDown={mouse_down_handle}>
        {child_segs.map((child_seg, index)=>{
          return <Seg key={index} editrate={editrate} start_tc={start_tc}
            track_idx={track_idx} track_id={track_id}
            is_parent_selected={is_selected} trim_offset={next_trim_offset}
            abs_start={abs_start + player.frame_start(child_seg)}
            fps={fps} zoom={zoom} depth={depth + 1} lbin={lbin}
            is_no_border={is_no_border || seg.is_no_border} seg={child_seg}
            ctx_data_set={ctx_data_set} f2px_k={f2px_k}
            premium_modal_open={premium_modal_open} cmd_trim={cmd_trim}
            playback_rate={next_playback_rate}
            is_trim_start_moved={is_trim_start_moved || is_edit_mode} />;
        })}
        {dragged_segs.map(([_seg, key])=><Seg key={key}
          editrate={lbin.rec_monitor_in.editrate} track_idx={track_idx}
          lbin={lbin} premium_modal_open={premium_modal_open}
          zoom={zoom} start_tc={lbin.rec_monitor_in.start_tc}
          track_id={track_id} abs_start={player.frame_start(_seg)} seg={_seg}
          fps={fps} is_no_border={is_no_border} ctx_data_set={ctx_data_set}
          f2px_k={f2px_k} cmd_trim={cmd_trim} />)}
        {seg.lbl && !seg.is_lbl_hide && <span style={{fontSize: 12,
          userSelect: 'none', zIndex: is_selected ? seg.zidx + 100 : seg.zidx,
          pointerEvents: 'none',
          fontStyle: seg.is_lbl_italic ? 'italic' : 'normal',
          color: 'white', position: 'absolute', left: '2px', top: '2px'}}>
          {seg.lbl}
        </span>}
        {show_icons_container && <div style={{display: 'flex', top: '50%',
          position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)',
          gap: '2px', zIndex: is_selected ? seg.zidx + 100 : seg.zidx}}>
          {seg.icon.is_icon_inp && <Icon component={Gray_icon} />}
          {seg.icon.is_icon_out && seg.icon.icon_out == 'transition'
            && <Icon component={Purple_icon} />}
          {seg.icon.is_icon_out && seg.icon.icon_out == 'timewarp'
            && <Icon component={Motion_icon} />}
        </div>}
        {!!seg.outsync && <div style={{position: 'absolute', bottom: 0,
          left: `${player.f2px(seg.outsync.start, f2px_k, zoom)}px`,
          width: `${player.f2px(seg.outsync.len, f2px_k, zoom)}px`,
          height: '16px', borderBottom: `2px solid ${seg.outsync.color}`,
          display: 'flex', justifyContent: 'flex-end', fontSize: '12px'}}>
          <span style={{userSelect: 'none'}}>{seg.outsync.v}</span>
        </div>}
        {!!seg.dup && <div style={{position: 'absolute', top: 0, height: '2px',
          left: `${player.f2px(seg.dup.start, f2px_k, zoom)}px`,
          width: `${player.f2px(seg.dup.len, f2px_k, zoom)}px`,
          background: seg.dup.color, borderBottom: '1px solid black'}} />}
      </div>}
      {is_left_trim && <div style={{position: 'absolute', top: 2,
        height: 'calc(100% - 4px)', left: left, zIndex: 101,
        width: '5px', background: trim_handle_color}} />}
      {is_right_trim && <div style={{position: 'absolute', top: 2,
        height: 'calc(100% - 4px)', left: left + len - 5, zIndex: 101,
        width: '5px', background: trim_handle_color}} />}
      {is_edit_mode && seg.trim && !is_on_another_track && <div
        style={{width: '8px', height: '100%', top: 0, position: 'absolute',
          zIndex: 101, left: left,
          cursor: `url(${ew_resize_cursor}) 8 6, ew-resize`}}
        onMouseDown={left_edge_mouse_down_handle} />}
      {is_edit_mode && seg.trim && !is_on_another_track && <div
        style={{width: '8px', height: '100%', top: 0, position: 'absolute',
          zIndex: 101, left: left + len - 8,
          cursor: `url(${ew_resize_cursor}) 8 6, ew-resize`}}
        onMouseDown={right_edge_mouse_down_handle} />}
      {seg.is_match_cut && !is_on_another_track && <div
        style={{position: 'absolute', top: '50%', left: `${left - 5}px`,
          zIndex: seg.zidx, transform: 'translateY(-50%)', width: '7px',
          height: '7px', borderTop: '3px solid #8A8AF7',
          borderBottom: '3px solid #8A8AF7'}} />}
    </>
  );
});
let Tc_lbl = React.memo(({tc_lbl, pos})=>{
  return (
    <div style={{position: 'absolute', left: `${pos}px`,
      transform: 'translateX(-50%)', height: '100%', fontSize: '12px',
      display: 'flex', flexDirection: 'column', alignItems: 'center',
      justifyContent: 'space-around'}}>
      <div style={{height: '2px', width: '2px', borderRadius: '50%',
        background: 'white'}} />
      <span style={{userSelect: 'none', color: 'white'}}>{tc_lbl}</span>
    </div>
  );
});
let Ticks = React.memo(({lbin, len, zoom, scroll_x, frames_gap, f2px_k,
  on_frame_change, fps})=>{
  let [is_edit_mode] = use_je('highlighter.is_edit_mode', false);
  let [is_ptr_moving, is_ptr_moving_set] = useState(false);
  let container_ref = useRef(null);
  let mouse_move_handle = useCallback(e=>{
    let container = container_ref.current;
    let container_offset = coords_get(container).left;
    let frame_rel = (e.clientX - container_offset) / container.offsetWidth;
    let _frame = len * frame_rel;
    on_frame_change(_frame);
  }, [on_frame_change, len]);
  let mouse_down_handle = useCallback(e=>{
    mouse_move_handle(e);
    is_ptr_moving_set(true);
  }, [mouse_move_handle]);
  let mouse_up_handle = useCallback(()=>is_ptr_moving_set(false), []);
  useEffect(()=>{
    if (!is_ptr_moving || !is_edit_mode)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [mouse_move_handle, mouse_up_handle, is_ptr_moving, is_edit_mode]);
  let start_tc = useMemo(()=>{
    if (lbin?.rec_monitor_in?.start_tc === undefined)
      return 0;
    return lbin.rec_monitor_in.start_tc;
  }, [lbin?.rec_monitor_in?.start_tc]);
  let width = useMemo(()=>player.f2px(len, f2px_k, zoom), [f2px_k, len, zoom]);
  let tc_lbls = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.editrate)
      return [];
    let _tc_lbls = [];
    let min_frame = scroll_x - scroll_x % frames_gap;
    let max_frame = Math.ceil(scroll_x + len);
    for (let frame = min_frame; frame <= max_frame; frame += frames_gap)
    {
      let sec = player.f2px(frame, f2px_k, zoom);
      let tc_lbl = tc.frame2tc(start_tc + frame, lbin.rec_monitor_in.editrate);
      _tc_lbls.push(<Tc_lbl key={frame} tc_lbl={tc_lbl} pos={sec} />);
    }
    return _tc_lbls;
  }, [f2px_k, frames_gap, lbin?.rec_monitor_in?.editrate, scroll_x, start_tc,
    zoom, len]);
  let tc_track = useMemo(()=>{
    if (!lbin?.rec_monitor_in)
      return null;
    return lbin.rec_monitor_in.tracks.find(track=>track.type == 'tc_track');
  }, [lbin?.rec_monitor_in]);
  return (
    <div style={{position: 'relative', height: '26px', left: 0,
      width: `${width}px`, zIndex: 201}} onMouseDown={mouse_down_handle}
    onTouchStart={mouse_down_handle} ref={container_ref}>
      {tc_lbls}
      {tc_track?.arr.map((seg, idx)=><Seg key={idx} seg={seg} fps={fps}
        zoom={0} f2px_k={f2px_k} />)}
    </div>
  );
});
let Track = React.memo(React.forwardRef(({idx, id, lbin, height, start, len,
  color, arr=[], fps, zoom, is_no_border, f2px_k, ctx_data_set,
  premium_modal_open, cmd_trim}, ref)=>{
  let root_seg = useMemo(()=>arr[0], [arr]);
  let no_fill = useMemo(()=>{
    return height ? height.endsWith('no_fill') : false;
  }, [height]);
  let left = useMemo(()=>{
    return player.f2px(start, f2px_k, zoom);
  }, [f2px_k, start, zoom]);
  let width = useMemo(()=>player.f2px(len, f2px_k, zoom), [f2px_k, len, zoom]);
  return (
    <div style={{position: 'relative', height: `${track_height2px(height)}px`,
      left: `${left}px`, width: `${width}px`, margin: no_fill && '3px 0',
      background: color, marginBottom: '4px'}} ref={ref}>
      {!!root_seg && <Seg editrate={lbin.rec_monitor_in.editrate} lbin={lbin}
        premium_modal_open={premium_modal_open} zoom={zoom}
        start_tc={lbin.rec_monitor_in.start_tc} track_idx={idx} track_id={id}
        abs_start={player.frame_start(root_seg)} seg={root_seg} fps={fps}
        is_no_border={is_no_border} ctx_data_set={ctx_data_set} f2px_k={f2px_k}
        cmd_trim={cmd_trim} />}
    </div>
  );
}));
let Tracks_right_container = React.memo(({lbin, fps, zoom, f2px_k, scroll_x,
  scroll_x_set, scroll_y, scroll_y_set, tracks_total_height, tracks_pad,
  rec_frame, on_rec_frame_change, on_src_frame_change,
  on_rec_playback_rate_change, on_src_playback_rate_change,
  cmd_trim, selected_monitor,
  tracks_container_width, is_v_scroll_visible, premium_modal_open, src_frame,
  tracks_wrapper_width, tracks_wrapper_width_set, tracks_container_height,
  tracks_wrapper_height, tracks_wrapper_height_set, cuts, cmd_selector_set,
  cmd_clip_move})=>{
  let [is_edit_mode] = use_je('highlighter.is_edit_mode', false);
  let [drag_mode, drag_mode_set] = use_je('highlighter.drag_mode', null);
  let [trim_mode, trim_mode_set] = use_je('highlighter.trim_mode', false);
  let [show_v_ptr, show_v_ptr_set] = useState(false);
  let [v_ptr_frame, v_ptr_frame_set] = useState(0);
  let [is_ptr_moving, is_ptr_moving_set] = useState(false);
  let [scrolling_left, scrolling_left_set] = useState(false);
  let [scrolling_right, scrolling_right_set] = useState(false);
  let [ctx_data, ctx_data_set] = useState({visible: false});
  let tracks_wrapper_ref = useRef(null);
  let tracks_container_ref = useRef(null);
  let track_refs = useRef({});
  let frame_change_handle = useCallback(_frame=>{
    if (selected_monitor == 'src')
      return on_src_frame_change(_frame);
    if (selected_monitor == 'rec')
      return on_rec_frame_change(_frame);
    assert(0, 'unexpected selected monitor ' + selected_monitor);
  }, [on_rec_frame_change, on_src_frame_change, selected_monitor]);
  let playback_rate_change_handle = useCallback(_playback_rate=>{
    if (selected_monitor == 'src')
      return on_src_playback_rate_change(_playback_rate);
    if (selected_monitor == 'rec')
      return on_rec_playback_rate_change(_playback_rate);
    assert(0, 'unexpected selected monitor ' + selected_monitor);
  }, [on_rec_playback_rate_change, on_src_playback_rate_change,
    selected_monitor]);
  let cur_frame = useMemo(()=>{
    if (selected_monitor == 'src')
      return src_frame;
    if (selected_monitor == 'rec')
      return rec_frame;
    assert(0, 'unexpected selected monitor ' + selected_monitor);
  }, [rec_frame, selected_monitor, src_frame]);
  let mouse_enter_handle = useCallback(()=>show_v_ptr_set(true), []);
  let mouse_move_handle = useCallback(e=>{
    if (!lbin?.rec_monitor_in)
      return;
    let target = tracks_container_ref.current;
    let _v_ptr_frame = player.px2f(e.clientX - coords_get(target).left, f2px_k,
      zoom);
    _v_ptr_frame = xutil.clamp(_v_ptr_frame,
      lbin.rec_monitor_in.start, player.frame_end(lbin.rec_monitor_in));
    _v_ptr_frame = Math.floor(_v_ptr_frame);
    v_ptr_frame_set(_v_ptr_frame);
    show_v_ptr_set(_v_ptr_frame != cur_frame);
  }, [f2px_k, lbin?.rec_monitor_in, cur_frame, zoom]);
  let mouse_leave_handle = useCallback(()=>show_v_ptr_set(false), []);
  let mouse_down_handle = useCallback(e=>{
    if (is_edit_mode)
      return;
    // ignore if not left click
    if (e.nativeEvent.which != 1)
      return;
    let _cur_frame;
    if (e.ctrlKey)
      _cur_frame = editor.nearest_cut_get(cuts, v_ptr_frame);
    else
      _cur_frame = v_ptr_frame;
    is_ptr_moving_set(true);
    frame_change_handle(_cur_frame);
    show_v_ptr_set(v_ptr_frame != _cur_frame);
    playback_rate_change_handle(0);
  }, [is_edit_mode, cuts, v_ptr_frame, frame_change_handle,
    playback_rate_change_handle]);
  let wheel_handle = useCallback(e=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let delta_x = e.ctrlKey ? e.deltaY : e.deltaX;
    let delta_y = e.ctrlKey ? e.deltaX : e.deltaY;
    let can_move_x;
    if (e.deltaX > 0)
    {
      can_move_x = scroll_x
        + player.px2f(tracks_wrapper_width, f2px_k, zoom)
        < lbin.rec_monitor_in.len;
    }
    else
      can_move_x = !!scroll_x;
    let can_move_y = e.deltaY > 0
      ? scroll_y + tracks_wrapper_height < tracks_container_height : !!scroll_y;
    if (delta_x && can_move_x || delta_y && can_move_y)
      e.preventDefault();
    scroll_x_set(scroll_x + player.px2f(delta_x, f2px_k, zoom));
    scroll_y_set(scroll_y + delta_y);
  }, [f2px_k, lbin?.rec_monitor_in?.len, scroll_x, scroll_x_set, scroll_y,
    scroll_y_set, tracks_container_height, tracks_wrapper_height,
    tracks_wrapper_width, zoom]);
  let trim_shift = useCallback(delta=>{
    if (!trim_mode)
      return;
    let min_delta = -Infinity;
    let max_delta = Infinity;
    for (let track_id in trim_mode.deltas)
    {
      let left_segs = trim_mode.left_segs[track_id];
      let right_segs = trim_mode.right_segs[track_id];
      for (let _seg of left_segs)
      {
        if (!_seg.trim)
          continue;
        if (max_delta > _seg.trim.start_post)
          max_delta = _seg.trim.start_post;
        if (min_delta < -_seg.trim.start_pre)
          min_delta = -_seg.trim.start_pre;
      }
      for (let _seg of right_segs)
      {
        if (!_seg.trim)
          continue;
        if (max_delta > _seg.trim.end_post)
          max_delta = _seg.trim.end_post;
        if (min_delta < -_seg.trim.end_pre)
          min_delta = -_seg.trim.end_pre;
      }
    }
    delta = xutil.clamp(delta, min_delta, max_delta);
    let _deltas = {...trim_mode.deltas};
    for (let track_id in _deltas)
      _deltas[track_id] = delta;
    trim_mode_set({...trim_mode, deltas: _deltas});
  }, [trim_mode, trim_mode_set]);
  let trim_drag_handle = useCallback(e=>{
    let container = e.target.closest('#tracks-container');
    if (!container)
      return;
    let trim_cur_frame = player.px2f(e.clientX - coords_get(container).left,
      f2px_k, zoom);
    trim_cur_frame = Math.round(trim_cur_frame);
    let delta = trim_cur_frame - trim_mode.init_pos;
    trim_shift(delta);
  }, [f2px_k, trim_mode?.init_pos, trim_shift, zoom]);
  let trim_drag_stop_handle = useCallback(()=>eserf(function*
  _trim_drag_stop_handle(){
    if (!trim_mode)
      return;
    trim_mode_set({...trim_mode, is_dragging: false});
    if (!lbin?.rec_monitor_in)
      return;
    if (Object.values(trim_mode.deltas).every(delta=>delta == 0))
      return;
    yield cmd_trim(lbin.rec_monitor_in.mob_id, trim_mode.start_pos,
      trim_mode.deltas, 'trim');
  }), [cmd_trim, lbin?.rec_monitor_in, trim_mode, trim_mode_set]);
  useEffect(()=>{
    if (!trim_mode?.is_dragging)
      return;
    document.addEventListener('mousemove', trim_drag_handle);
    document.addEventListener('mouseup', trim_drag_stop_handle);
    return ()=>{
      document.removeEventListener('mousemove', trim_drag_handle);
      document.removeEventListener('mouseup', trim_drag_stop_handle);
    };
  }, [trim_drag_handle, trim_drag_stop_handle, trim_mode?.is_dragging]);
  let drag_mode_drag_handle = useCallback(e=>{
    let container = e.target.closest('#tracks-container');
    if (!container)
      return;
    let drag_cur_pos = player.px2f(e.clientX - coords_get(container).left,
      f2px_k, zoom);
    drag_cur_pos = Math.round(drag_cur_pos);
    let delta = drag_cur_pos - drag_mode.init_frame;
    let deltas = new Map(drag_mode.deltas);
    for (let seg of deltas.keys())
      deltas.set(seg, delta);
    let user_pointer_track_id;
    let smallest_distance = Infinity;
    for (let track_id in track_refs.current)
    {
      let el = track_refs.current[track_id];
      let coords = coords_get(el);
      let center_y = coords.top + el.offsetHeight / 2;
      let distance = Math.abs(window.scrollY + e.clientY - center_y);
      if (distance < smallest_distance)
      {
        smallest_distance = distance;
        user_pointer_track_id = track_id;
      }
    }
    let user_pointer_track_idx = lbin.rec_monitor_in.tracks.findIndex(track=>{
      return track.id == user_pointer_track_id;
    });
    if (user_pointer_track_idx == -1)
      assert(0, 'track index not found: ', user_pointer_track_id);
    let init_track_idx = lbin.rec_monitor_in.tracks.findIndex(track=>{
      return track.id == drag_mode.init_track_id;
    });
    if (init_track_idx == -1)
      assert(0, 'track index not found: ', drag_mode.init_track_id);
    let track_delta = user_pointer_track_idx - init_track_idx;
    let track_ids = new Map(drag_mode.track_ids);
    for (let [seg, init_track_id] of drag_mode.init_track_ids.entries())
    {
      let seg_track_prefix = init_track_id.slice(0, 1);
      let seg_track_num = parseInt(init_track_id.slice(1), 10);
      let next_track_num;
      if (seg_track_prefix == 'V')
        next_track_num = seg_track_num - track_delta;
      else
        next_track_num = seg_track_num + track_delta;
      let next_track_id = seg_track_prefix + next_track_num;
      if (lbin.rec_monitor_in.tracks.find(track=>track.id == next_track_id))
        track_ids.set(seg, next_track_id);
    }
    drag_mode_set({...drag_mode, track_ids, deltas});
  }, [drag_mode, drag_mode_set, f2px_k, zoom, lbin?.rec_monitor_in?.tracks]);
  let drag_mode_drag_stop_handle = useCallback(()=>eserf(function*
  _drag_mode_drag_stop_handle(){
    drag_mode_set({...drag_mode, is_dragging: false});
    let is_delta_x_changed = drag_mode.deltas.values().some(delta=>delta != 0);
    let is_track_changed = false;
    for (let [seg, init_track_id] of drag_mode.init_track_ids.entries())
    {
      let track_id = drag_mode.track_ids.get(seg);
      if (init_track_id != track_id)
        is_track_changed = true;
    }
    if (!is_delta_x_changed && !is_track_changed)
      return;
    yield cmd_clip_move(lbin.rec_monitor_in.mob_id, drag_mode.init_track_ids,
      drag_mode.track_ids, drag_mode.deltas);
    drag_mode_set({deltas: new Map(), init_track_ids: new Map(),
      track_ids: new Map()});
  }), [drag_mode, drag_mode_set, cmd_clip_move, lbin?.rec_monitor_in?.mob_id]);
  useEffect(()=>{
    if (!drag_mode?.is_dragging)
      return;
    document.addEventListener('mousemove', drag_mode_drag_handle);
    document.addEventListener('mouseup', drag_mode_drag_stop_handle);
    return ()=>{
      document.removeEventListener('mousemove', drag_mode_drag_handle);
      document.removeEventListener('mouseup', drag_mode_drag_stop_handle);
    };
  }, [drag_mode_drag_handle, drag_mode_drag_stop_handle,
    drag_mode?.is_dragging]);
  let select_handle = useCallback(selector_idx=>{
    if (!lbin?.rec_monitor_in)
      return;
    cmd_selector_set(lbin.rec_monitor_in.mob_id, ctx_data.track_id,
      selector_idx, ctx_data.seg.abs_start);
    ctx_data_set({visible: false});
  }, [cmd_selector_set, ctx_data.seg, ctx_data.track_id, lbin]);
  let resize_handle = useCallback(()=>{
    let _tracks_wrapper_width = tracks_wrapper_ref.current.offsetWidth;
    let _tracks_wrapper_height = tracks_wrapper_ref.current.offsetHeight;
    tracks_wrapper_width_set(_tracks_wrapper_width);
    tracks_wrapper_height_set(_tracks_wrapper_height);
  }, [tracks_wrapper_height_set, tracks_wrapper_width_set]);
  let ptr_move_handle = useCallback(e=>{
    let target = tracks_container_ref.current;
    let _v_ptr_frame = player.px2f(e.clientX - coords_get(target).left, f2px_k,
      zoom);
    let _cur_frame;
    if (e.ctrlKey)
      _cur_frame = editor.nearest_cut_get(cuts, v_ptr_frame);
    else
      _cur_frame = _v_ptr_frame;
    frame_change_handle(_cur_frame);
    show_v_ptr_set(_v_ptr_frame != _cur_frame);
    playback_rate_change_handle(0);
    let wrapper_coords = coords_get(tracks_wrapper_ref.current);
    if (e.clientX < wrapper_coords.left)
      scrolling_left_set(true);
    else
      scrolling_left_set(false);
    if (e.clientX > wrapper_coords.right - 40)
      scrolling_right_set(true);
    else
      scrolling_right_set(false);
  }, [f2px_k, zoom, cuts, v_ptr_frame, frame_change_handle,
    playback_rate_change_handle]);
  let ptr_leave_handle = useCallback(()=>{
    is_ptr_moving_set(false);
    scrolling_left_set(false);
    scrolling_right_set(false);
  }, []);
  let frames_gap_get = useCallback(()=>{
    let min_gap_px = 120;
    let min_gap_s = player.px2f(min_gap_px, f2px_k, zoom) / fps;
    let periods_s = [0.04, 0.2, 1, 2, 5, 10, 15]; // 15 * 2 ^ n
    let min_period = periods_s.at(0);
    if (min_gap_s < periods_s.at(min_period))
    {
      let frames_gap = min_period * fps;
      return frames_gap;
    }
    let sec_gap = periods_s.find((period, index)=>{
      return min_gap_s >= periods_s[index - 1] && min_gap_s <= period;
    });
    if (sec_gap)
    {
      let frames_gap = sec_gap * fps;
      return frames_gap;
    }
    let base_period = periods_s.at(-1);
    let power = Math.floor(Math.log2(2 * min_gap_s / base_period));
    sec_gap = base_period * 2 ** power;
    let frames_gap = sec_gap * fps;
    return frames_gap;
  }, [fps, zoom, f2px_k]);
  useEffect(()=>{
    if (!is_ptr_moving)
      return;
    document.addEventListener('mousemove', ptr_move_handle);
    document.addEventListener('mouseup', ptr_leave_handle);
    return ()=>{
      document.removeEventListener('mousemove', ptr_move_handle);
      document.removeEventListener('mouseup', ptr_leave_handle);
    };
  }, [ptr_leave_handle, ptr_move_handle, is_ptr_moving]);
  useEffect(resize_handle, [resize_handle, is_v_scroll_visible]);
  useEffect(()=>{
    resize_handle();
    window.addEventListener('resize', resize_handle);
    return ()=>window.removeEventListener('resize', resize_handle);
  }, [resize_handle, tracks_wrapper_width_set, zoom]);
  useEffect(()=>{
    let target = tracks_container_ref.current;
    target.addEventListener('wheel', wheel_handle,
      {passive: false});
    return ()=>{
      target.removeEventListener('wheel', wheel_handle);
    };
  }, [wheel_handle]);
  use_effect_eserf(()=>eserf(function* player_interval(){
    if (lbin?.rec_monitor_in?.start === undefined
      || lbin?.rec_monitor_in?.len === undefined)
    {
      return;
    }
    if (!scrolling_left && !scrolling_right)
      return;
    while (1)
    {
      let _cur_frame;
      if (scrolling_left)
        _cur_frame = cur_frame - 1;
      else if (scrolling_right)
        _cur_frame = cur_frame + 1;
      _cur_frame = Math.min(_cur_frame,
        player.frame_end(lbin.rec_monitor_in));
      frame_change_handle(_cur_frame);
      playback_rate_change_handle(0);
      if (scrolling_left)
        scroll_x_set(_cur_frame);
      else if (scrolling_right)
        scroll_x_set(scroll_x + 1);
      yield eserf.sleep(50);
    }
  }), [cur_frame, frame_change_handle, scrolling_left, scroll_x_set,
    scrolling_right, scroll_x, lbin?.rec_monitor_in?.start,
    lbin?.rec_monitor_in?.len, tracks_wrapper_width, fps, zoom,
    playback_rate_change_handle, lbin?.rec_monitor_in]);
  let frames_gap = useMemo(()=>{
    return frames_gap_get();
  }, [frames_gap_get]);
  let frame_ptr_left = useMemo(()=>{
    return player.f2px(cur_frame, f2px_k, zoom);
  }, [f2px_k, cur_frame, zoom]);
  let ghost_frame_ptr_left = useMemo(()=>{
    return player.f2px(cur_frame + 1, f2px_k, zoom);
  }, [f2px_k, cur_frame, zoom]);
  return (
    <div style={{maxWidth: '100%', width: '100%', display: 'flex',
      flexDirection: 'column', height: '100%'}}>
      <Cameras_modal is_open={ctx_data.visible} video_srcs={ctx_data.items}
        current_time={ctx_data.seg ? player.f2sec(ctx_data.seg.start, fps)
          : null}
        on_close={()=>ctx_data_set({visible: false})}
        on_select={select_handle} />
      <div ref={tracks_wrapper_ref} style={{maxWidth: '100%', width: '100%',
        overflow: 'hidden', position: 'relative', zIndex: 0,
        height: '100%'}}>
        <div ref={tracks_container_ref} id="tracks-container" style={{
          width: `${tracks_container_width}px`, height: '100%',
          position: 'absolute', top: 0,
          left: `${-player.f2px(scroll_x, f2px_k, zoom)}px`}}
        onMouseEnter={mouse_enter_handle} onMouseLeave={mouse_leave_handle}
        onMouseMove={mouse_move_handle} onMouseDown={mouse_down_handle}>
          <Ticks lbin={lbin} zoom={zoom} scroll_x={scroll_x}
            len={player.px2f(tracks_container_width, f2px_k, zoom)}
            frames_gap={frames_gap} f2px_k={f2px_k} fps={fps}
            on_frame_change={frame_change_handle} />
          <div style={{position: 'absolute', display: 'flex',
            top: `${tracks_pad - scroll_y}px`,
            height: `${tracks_total_height + tracks_pad * 2}px`,
            flexDirection: 'column', alignItems: 'flex-start',
            justifyContent: 'center'}}>
            {lbin?.rec_monitor_in?.tracks?.map((rec_track, idx)=>{
              let src_track = lbin.src_monitor?.tracks.find(sub_track=>{
                return sub_track.id == rec_track.id;
              });
              let arr = selected_monitor == 'rec' ? rec_track.arr
                : src_track?.arr || [];
              if (rec_track.type != 'timeline_track')
                return null;
              if (rec_track.id[0] == 'V' && idx != 0)
                return null;
              return <Track idx={idx} key={rec_track.lbl} lbin={lbin}
                height={rec_track.height} arr={arr}
                start={rec_track.start} len={rec_track.len}
                color={rec_track.color} fps={fps} zoom={zoom} f2px_k={f2px_k}
                scroll_x={scroll_x} frames_gap={frames_gap} id={rec_track.id}
                tracks_wrapper_width={tracks_wrapper_width}
                ctx_data_set={ctx_data_set}
                premium_modal_open={premium_modal_open} cmd_trim={cmd_trim}
                ref={el=>track_refs.current[rec_track.id] = el} />;
            })}
          </div>
          {lbin?.rec_monitor_in && <Frame_ptr left={frame_ptr_left}
            color={selected_monitor == 'src' ? green[5] : cyan[4]} />}
          {lbin?.rec_monitor_in && <Frame_ptr left={ghost_frame_ptr_left}
            color={selected_monitor == 'src' ? green[5] : cyan[4]}
            is_dashed />}
          {lbin?.rec_monitor_in && show_v_ptr && !is_edit_mode && <Frame_ptr
            left={player.f2px(v_ptr_frame, f2px_k, zoom)}
            style={{background: gray[5], opacity: 0.8}} />}
        </div>
      </div>
      <H_scroll_ctrl lbin={lbin} fps={fps} scroll_x={scroll_x}
        scroll_x_set={scroll_x_set} zoom={zoom}
        f2px_k={f2px_k} tracks_wrapper_width={tracks_wrapper_width}
        tracks_container_width={tracks_container_width}
        is_v_scroll_visible={is_v_scroll_visible} />
    </div>
  );
});
let Tracks_container = React.memo(({cmd_mute, cmd_selector_set, cmd_solo,
  cmd_toggle_lock, cmd_trim, cuts, f2px_k, fps, is_v_scroll_visible, lbin,
  src_playback_rate_set, rec_playback_rate_set,
  premium_modal_open, rec_frame, rec_frame_set, src_frame, src_frame_set,
  scroll_x, scroll_x_set, tracks_container_height, tracks_container_width,
  tracks_pad, tracks_total_height, tracks_wrapper_height, cmd_clip_move,
  tracks_wrapper_height_set, tracks_wrapper_width, tracks_wrapper_width_set,
  zoom, selected_monitor})=>{
  let [scroll_y, scroll_y_set] = useState(0);
  let scroll_y_handle = useCallback(_scroll_y=>{
    let min_scroll = 0;
    let max_scroll = tracks_total_height + tracks_pad * 3 -
    tracks_wrapper_height;
    _scroll_y = xutil.clamp(_scroll_y, min_scroll, max_scroll);
    scroll_y_set(_scroll_y);
  }, [tracks_pad, tracks_total_height, tracks_wrapper_height]);
  let tracks_wrapper_height_handle = useCallback(_tracks_wrapper_height=>{
    let min_scroll = 0;
    let max_scroll = tracks_total_height + tracks_pad * 3 -
    _tracks_wrapper_height;
    let _scroll_y = xutil.clamp(scroll_y, min_scroll, max_scroll);
    scroll_y_set(_scroll_y);
    tracks_wrapper_height_set(_tracks_wrapper_height);
  }, [scroll_y, tracks_pad, tracks_total_height, tracks_wrapper_height_set]);
  return (
    <div style={{maxWidth: '100%', height: '100%', display: 'flex'}}>
      <Tracks_left_container cmd_mute={cmd_mute} cmd_solo={cmd_solo}
        cmd_toggle_lock={cmd_toggle_lock} f2px_k={f2px_k} lbin={lbin}
        scroll_x={scroll_x} scroll_x_set={scroll_x_set} scroll_y={scroll_y}
        scroll_y_set={scroll_y_set}
        tracks_container_height={tracks_container_height}
        tracks_pad={tracks_pad} tracks_total_height={tracks_total_height}
        tracks_wrapper_height={tracks_wrapper_height}
        tracks_wrapper_width={tracks_wrapper_width} zoom={zoom} />
      <Tracks_right_container lbin={lbin} fps={fps} zoom={zoom} f2px_k={f2px_k}
        scroll_x={scroll_x} scroll_x_set={scroll_x_set} scroll_y={scroll_y}
        scroll_y_set={scroll_y_handle} tracks_total_height={tracks_total_height}
        tracks_pad={tracks_pad}
        rec_frame={rec_frame} on_rec_frame_change={rec_frame_set}
        src_frame={src_frame} on_src_frame_change={src_frame_set}
        on_src_playback_rate_change={src_playback_rate_set}
        on_rec_playback_rate_change={rec_playback_rate_set}
        tracks_container_width={tracks_container_width}
        is_v_scroll_visible={is_v_scroll_visible}
        selected_monitor={selected_monitor}
        tracks_wrapper_width={tracks_wrapper_width}
        tracks_wrapper_width_set={tracks_wrapper_width_set}
        tracks_container_height={tracks_container_height}
        tracks_wrapper_height={tracks_wrapper_height}
        tracks_wrapper_height_set={tracks_wrapper_height_handle}
        cuts={cuts} cmd_selector_set={cmd_selector_set}
        premium_modal_open={premium_modal_open} cmd_trim={cmd_trim}
        cmd_clip_move={cmd_clip_move} />
      <V_scroll_ctrl visible={is_v_scroll_visible} lbin={lbin}
        scroll_y={scroll_y} scroll_y_set={scroll_y_set}
        tracks_total_height={tracks_total_height} tracks_pad={tracks_pad}
        tracks_wrapper_height={tracks_wrapper_height} />
    </div>
  );
});
let H_scroll_ctrl = React.memo(({lbin, scroll_x, scroll_x_set,
  tracks_wrapper_width, tracks_container_width, is_v_scroll_visible, f2px_k,
  zoom})=>{
  let [is_dragging, is_dragging_set] = useState(false);
  let [shift, shift_set] = useState(0);
  let [container_width, container_width_set] = useState();
  let container_ref = useRef(null);
  let pin_ref = useRef(null);
  let scroll_height = 14;
  let min_scroll = 0;
  let max_scroll = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    return lbin.rec_monitor_in.len
    - player.px2f(tracks_wrapper_width, f2px_k, zoom);
  }, [f2px_k, lbin?.rec_monitor_in?.len, tracks_wrapper_width, zoom]);
  let min_pin_width = 25;
  let pin_width = container_width * tracks_wrapper_width
    / tracks_container_width;
  pin_width = Math.max(pin_width, min_pin_width);
  let available_space = container_width - pin_width;
  let mouse_down_handle = useCallback(e=>{
    is_dragging_set(true);
    shift_set(e.clientX - coords_get(pin_ref.current).left);
  }, []);
  let mouse_move_handle = useCallback(e=>{
    let container = pin_ref.current.parentElement;
    let container_offset = container.offsetLeft;
    let scroll_x_rel = (e.clientX - container_offset - shift)
      / available_space;
    scroll_x_rel = isFinite(scroll_x_rel) ? scroll_x_rel : 0;
    let _scroll_x = min_scroll + max_scroll * scroll_x_rel;
    scroll_x_set(_scroll_x);
  }, [shift, available_space, scroll_x_set, min_scroll, max_scroll]);
  let mouse_up_handle = useCallback(()=>{
    is_dragging_set(false);
  }, []);
  let window_resize_handle = useCallback(()=>{
    container_width_set(container_ref.current?.offsetWidth || 0);
  }, []);
  useEffect(window_resize_handle, [is_v_scroll_visible, window_resize_handle]);
  useEffect(()=>{
    window_resize_handle();
    window.addEventListener('resize', window_resize_handle);
    return ()=>window.removeEventListener('resize', window_resize_handle);
  }, [window_resize_handle]);
  useEffect(()=>{
    if (!is_dragging)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [is_dragging, mouse_move_handle, mouse_up_handle]);
  let left = scroll_x * available_space / max_scroll || 0;
  return (
    <div style={{display: 'flex', width: '100%', height: `${scroll_height}px`}}>
      <div ref={container_ref} style={{width: '100%', position: 'relative'}}>
        <div ref={pin_ref} style={{position: 'absolute', left: `${left}px`,
          background: '#222', borderRadius: '20px', cursor: 'pointer',
          width: `${pin_width}px`, height: '100%',
          display: !available_space && 'none'}}
        onMouseDown={mouse_down_handle} />
      </div>
    </div>
  );
});
let V_scroll_ctrl = React.memo(({visible, scroll_y, scroll_y_set,
  tracks_total_height, tracks_pad, tracks_wrapper_height})=>{
  let [is_dragging, is_dragging_set] = useState(false);
  let [shift, shift_set] = useState(0);
  let [container_height, container_height_set] = useState(0);
  let container_ref = useRef(null);
  let pin_ref = useRef(null);
  let scroll_width = 14;
  let min_scroll = 0;
  let max_scroll = tracks_total_height + tracks_pad * 3 - tracks_wrapper_height;
  let min_pin_height = 25;
  let pin_height = container_height
    * (tracks_wrapper_height - tracks_pad)
    / (tracks_total_height + tracks_pad * 2);
  pin_height = Math.max(pin_height, min_pin_height);
  let available_space = container_height - pin_height;
  let mouse_down_handle = useCallback(e=>{
    is_dragging_set(true);
    shift_set(e.clientY - coords_get(pin_ref.current).top + window.scrollY);
  }, []);
  let mouse_move_handle = useCallback(e=>{
    let container = pin_ref.current.parentElement;
    let container_offset = coords_get(container).top - window.scrollY;
    let scroll_y_rel = (e.clientY - container_offset - shift)
      / available_space;
    scroll_y_rel = isFinite(scroll_y_rel) ? scroll_y_rel : 0;
    let _scroll_y = min_scroll + max_scroll * scroll_y_rel;
    scroll_y_set(_scroll_y);
  }, [shift, available_space, scroll_y_set, min_scroll, max_scroll]);
  let mouse_up_handle = useCallback(()=>{
    is_dragging_set(false);
  }, []);
  let window_resize_handle = useCallback(()=>{
    container_height_set(container_ref.current?.offsetHeight || 0);
  }, []);
  useEffect(()=>{
    window_resize_handle();
    window.addEventListener('resize', window_resize_handle);
    return ()=>window.removeEventListener('resize', window_resize_handle);
  }, [window_resize_handle]);
  useEffect(window_resize_handle, [window_resize_handle, visible]);
  useEffect(()=>{
    if (!is_dragging)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [is_dragging, mouse_move_handle, mouse_up_handle]);
  let top = scroll_y * available_space / max_scroll || 0;
  if (!visible)
    return null;
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100%',
      width: `${scroll_width}px`}}>
      <div ref={container_ref} style={{height: '100%', width: '100%',
        position: 'relative'}}>
        <div ref={pin_ref} style={{position: 'absolute', top: `${top}px`,
          background: '#222', borderRadius: '20px', cursor: 'pointer',
          width: '100%', height: `${pin_height}px`}}
        onMouseDown={mouse_down_handle} />
      </div>
    </div>
  );
});
let tracks_container_pad = 26;
let Timeline_panel = React.memo(({cmd_clear_both_marks, cmd_clip_move,
  cmd_cut, cmd_extract, cmd_lift, cmd_mark_clip, cmd_mute, cmd_selector_set,
  cmd_solo, cmd_toggle_lock, cmd_trim, lbin, on_rec_frame_change,
  on_rec_playback_rate_change, on_scroll_x_change, on_selected_monitor_change,
  on_src_frame_change, on_src_playback_rate_change,
  on_tracks_wrapper_width_change, on_zoom_change, premium_modal_open,
  rec_frame, scroll_x, selected_monitor, src_frame, tracks_wrapper_width,
  zoom, ...rest})=>{
  let [tracks_wrapper_height, tracks_wrapper_height_set] = useState(0);
  let fps = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.editrate?.d
      || !lbin?.rec_monitor_in?.editrate?.n)
    {
      return 0;
    }
    return lbin.rec_monitor_in.editrate.n / lbin.rec_monitor_in.editrate.d;
  }, [lbin?.rec_monitor_in?.editrate?.d, lbin?.rec_monitor_in?.editrate?.n]);
  let f2px_k = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    if (lbin.rec_monitor_in.len == 0)
      return 1;
    return lbin.rec_monitor_in.len / tracks_wrapper_width;
  }, [lbin?.rec_monitor_in?.len, tracks_wrapper_width]);
  let tracks_container_width = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    return Math.max(player.f2px(lbin.rec_monitor_in.len, f2px_k, zoom),
      tracks_wrapper_width);
  }, [f2px_k, lbin?.rec_monitor_in?.len, tracks_wrapper_width, zoom]);
  let tracks_total_height = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return 0;
    let tracks_height = editor.tracks_total_height_get(
      lbin.rec_monitor_in.tracks);
    let gaps_height = (lbin.rec_monitor_in.tracks.length - 1) * 4;
    return tracks_height + gaps_height;
  }, [lbin?.rec_monitor_in?.tracks]);
  let tracks_container_height = useMemo(()=>{
    return tracks_total_height + tracks_container_pad * 3;
  }, [tracks_total_height]);
  let is_v_scroll_visible = useMemo(()=>{
    return tracks_wrapper_height <= tracks_container_height;
  }, [tracks_container_height, tracks_wrapper_height]);
  let cuts = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return editor.cuts_get(lbin.rec_monitor_in.tracks);
  }, [lbin?.rec_monitor_in?.tracks]);
  let [, rec_editing_track_ids_set] = use_je(
    'highlighter.rec_editing_track_ids', []);
  let zoom_change_handle = useCallback(_zoom=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    on_zoom_change(_zoom);
    let _scroll_x = rec_frame
      - player.px2f(tracks_wrapper_width, f2px_k, _zoom) / 2;
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(tracks_wrapper_width, f2px_k, _zoom);
    _scroll_x = xutil.clamp(_scroll_x, min_scroll, max_scroll);
    on_scroll_x_change(_scroll_x);
  }, [f2px_k, lbin?.rec_monitor_in?.len, on_scroll_x_change, on_zoom_change,
    rec_frame, tracks_wrapper_width]);
  let scroll_x_change_handle = useCallback(_scroll_x=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(tracks_wrapper_width, f2px_k, zoom);
    _scroll_x = xutil.clamp(_scroll_x, min_scroll, max_scroll);
    on_scroll_x_change(_scroll_x);
  }, [f2px_k, lbin?.rec_monitor_in?.len, on_scroll_x_change,
    tracks_wrapper_width, zoom]);
  let tracks_wrapper_width_handle = useCallback(_tracks_wrapper_width=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let _zoom = Math.max(zoom, min_zoom);
    on_zoom_change(_zoom);
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(_tracks_wrapper_width, f2px_k, _zoom);
    let _scroll_x = xutil.clamp(scroll_x, min_scroll, max_scroll);
    on_scroll_x_change(_scroll_x);
    on_tracks_wrapper_width_change(_tracks_wrapper_width);
  }, [f2px_k, lbin?.rec_monitor_in?.len, on_scroll_x_change,
    on_tracks_wrapper_width_change, on_zoom_change, scroll_x, zoom]);
  let tracks_wrapper_height_handle = useCallback(_tracks_wrapper_height=>{
    tracks_wrapper_height_set(_tracks_wrapper_height);
  }, []);
  let src_playback_rate_handle = useCallback(_src_playback_rate=>{
    on_src_playback_rate_change(_src_playback_rate);
  }, [on_src_playback_rate_change]);
  let rec_playback_rate_handle = useCallback(_rec_playback_rate=>{
    on_rec_playback_rate_change(_rec_playback_rate);
  }, [on_rec_playback_rate_change]);
  let rec_frame_handle = useCallback((_rec_frame, ignore_clamp, scroll)=>{
    if (lbin?.rec_monitor_in?.start === undefined
      || lbin?.rec_monitor_in?.len === undefined)
    {
      return;
    }
    _rec_frame = Math.floor(_rec_frame);
    if (!ignore_clamp)
    {
      let min_frame = lbin.rec_monitor_in.start;
      let max_frame = lbin.rec_monitor_in.len;
      _rec_frame = xutil.clamp(_rec_frame, min_frame, max_frame);
    }
    on_rec_frame_change(_rec_frame);
    if (!scroll)
      return;
    let frames_visible = player.px2f(tracks_wrapper_width, f2px_k, zoom);
    if (_rec_frame < scroll_x || _rec_frame > scroll_x + frames_visible)
      scroll_x_change_handle(_rec_frame - frames_visible / 2);
  }, [f2px_k, lbin?.rec_monitor_in?.start,
    lbin?.rec_monitor_in?.len, on_rec_frame_change, scroll_x,
    scroll_x_change_handle, tracks_wrapper_width, zoom]);
  let src_frame_handle = useCallback((_src_frame, ignore_clamp, scroll)=>{
    if (lbin?.src_monitor?.start === undefined
      || lbin?.src_monitor?.len === undefined)
    {
      return;
    }
    _src_frame = Math.floor(_src_frame);
    if (!ignore_clamp)
    {
      let min_frame = lbin.src_monitor.start;
      let max_frame = lbin.src_monitor.len;
      _src_frame = xutil.clamp(_src_frame, min_frame, max_frame);
    }
    on_src_frame_change(_src_frame);
    if (!scroll)
      return;
    let frames_visible = player.px2f(tracks_wrapper_width, f2px_k, zoom);
    if (_src_frame < scroll_x || _src_frame > scroll_x + frames_visible)
      scroll_x_change_handle(_src_frame - frames_visible / 2);
  }, [f2px_k, lbin?.src_monitor?.len, lbin?.src_monitor?.start, scroll_x,
    scroll_x_change_handle, tracks_wrapper_width, zoom, on_src_frame_change]);
  let zoom_step = 1;
  let zoom_out_handle = useCallback(()=>{
    let _zoom = Math.round(zoom - zoom_step);
    _zoom = xutil.clamp(_zoom, min_zoom, max_zoom);
    zoom_change_handle(_zoom);
  }, [zoom, zoom_change_handle, zoom_step]);
  let zoom_in_handle = useCallback(()=>{
    let _zoom = Math.round(zoom + zoom_step);
    _zoom = xutil.clamp(_zoom, min_zoom, max_zoom);
    zoom_change_handle(_zoom);
  }, [zoom, zoom_change_handle, zoom_step]);
  let rec_mark_clip = useCallback(()=>{
    if (!lbin)
      return;
    cmd_mark_clip(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_clip, lbin, rec_frame]);
  let rec_clear_both_marks = useCallback(()=>{
    if (!lbin)
      return;
    cmd_clear_both_marks(lbin.rec_monitor_in.mob_id);
  }, [cmd_clear_both_marks, lbin]);
  let rec_cut = useCallback(()=>{
    if (!lbin)
      return;
    cmd_cut(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_cut, lbin, rec_frame]);
  let rec_lift = useCallback(()=>{
    if (lbin?.rec_monitor_in?.mark_in === undefined
      || lbin?.rec_monitor_in?.mark_out === undefined)
    {
      return;
    }
    cmd_lift(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
      lbin.rec_monitor_in.mark_out);
  }, [cmd_lift, lbin]);
  let rec_extract = useCallback(()=>{
    if (lbin?.rec_monitor_in?.mark_in === undefined
      || lbin?.rec_monitor_in?.mark_out === undefined)
    {
      return;
    }
    cmd_extract(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
      lbin.rec_monitor_in.mark_out);
  }, [cmd_extract, lbin]);
  let mark_all_tracks = useCallback(()=>{
    if (!lbin?.rec_monitor_in)
      return;
    let _rec_editing_track_ids = lbin.rec_monitor_in.tracks.map(track=>{
      return track.id;
    });
    rec_editing_track_ids_set(_rec_editing_track_ids);
  }, [rec_editing_track_ids_set, lbin]);
  let unmark_all_tracks = useCallback(()=>{
    rec_editing_track_ids_set([]);
  }, [rec_editing_track_ids_set]);
  let toggle_timeline_monitor = useCallback(e=>{
    e.preventDefault();
    on_selected_monitor_change(selected_monitor == 'src' ? 'rec' : 'src');
  }, [selected_monitor, on_selected_monitor_change]);
  let action2func = useMemo(()=>({
    zoom_in: zoom_in_handle,
    zoom_out: zoom_out_handle,
    mark_all_tracks,
    unmark_all_tracks,
    mark_clip: rec_mark_clip,
    clear_both_marks: rec_clear_both_marks,
    cut: rec_cut,
    lift: rec_lift,
    extract: rec_extract,
    toggle_timeline_monitor,
  }), [zoom_in_handle, zoom_out_handle, mark_all_tracks, rec_mark_clip,
    rec_clear_both_marks, rec_cut, rec_lift, rec_extract,
    unmark_all_tracks, toggle_timeline_monitor]);
  useEffect(()=>{
    let unsubscribe = tinykeys(window, key_binding_map_get(action2func));
    return ()=>unsubscribe();
  }, [action2func]);
  return (
    <Editor_panel style={{flexDirection: 'column', background: gray[9]}}
      {...rest}>
      <Tracks_container cmd_mute={cmd_mute} cmd_selector_set={cmd_selector_set}
        cmd_solo={cmd_solo} cmd_toggle_lock={cmd_toggle_lock}
        cmd_trim={cmd_trim} cuts={cuts} f2px_k={f2px_k} fps={fps}
        is_v_scroll_visible={is_v_scroll_visible} lbin={lbin}
        src_playback_rate_set={src_playback_rate_handle}
        rec_playback_rate_set={rec_playback_rate_handle}
        premium_modal_open={premium_modal_open}
        rec_frame={rec_frame} rec_frame_set={rec_frame_handle}
        src_frame={src_frame} src_frame_set={src_frame_handle}
        selected_monitor={selected_monitor} scroll_x={scroll_x}
        scroll_x_set={scroll_x_change_handle}
        tracks_container_height={tracks_container_height}
        tracks_container_width={tracks_container_width}
        tracks_pad={tracks_container_pad} cmd_clip_move={cmd_clip_move}
        tracks_total_height={tracks_total_height}
        tracks_wrapper_height={tracks_wrapper_height}
        tracks_wrapper_height_set={tracks_wrapper_height_handle}
        tracks_wrapper_width={tracks_wrapper_width}
        tracks_wrapper_width_set={tracks_wrapper_width_handle} zoom={zoom} />
    </Editor_panel>
  );
});
let Video_thumbnail = React.memo(({src, time, on_click})=>{
  let {qs_o} = use_qs();
  let video_ref = useRef(null);
  let [is_loading, is_loading_set] = useState(true);
  let loaded_metadata_handle = useCallback(()=>{
    video_ref.current.currentTime = qs_o.dbg ? 0 : time;
  }, [qs_o.dbg, time]);
  let seeked_handle = useCallback(()=>{
    is_loading_set(false);
  }, []);
  let error_handle = useCallback(()=>{
    is_loading_set(false);
    video_ref.current.poster = media_offline_poster;
  }, []);
  useEffect(()=>{
    let video_el = video_ref.current;
    video_el.addEventListener('loadedmetadata', loaded_metadata_handle);
    video_el.addEventListener('seeked', seeked_handle);
    video_el.addEventListener('error', error_handle);
    return ()=>{
      video_el.removeEventListener('loadedmetadata', loaded_metadata_handle);
      video_el.removeEventListener('seeked', seeked_handle);
      video_el.removeEventListener('error', error_handle);
    };
  }, [error_handle, loaded_metadata_handle, qs_o.dbg, seeked_handle, time]);
  return (
    <div style={{width: 'calc(50% - 5px)', cursor: 'pointer',
      position: 'relative'}}>
      {is_loading && <div style={{position: 'absolute',
        width: '100%', height: '100%', zIndex: 9999, left: 0, top: 0,
        display: 'flex', flexDirection: 'column', pointerEvents: 'none',
        justifyContent: 'center', alignItems: 'center',
        background: '#ffffff50'}}>
        <Spin indicator={<LoadingOutlined spin
          style={{fontSize: '40px'}} />} />
      </div>}
      <video style={{width: '100%', height: '100%', objectFit: 'cover'}}
        src={src} onClick={on_click} preload="metadata" ref={video_ref} />
    </div>
  );
});
let Cameras_modal = React.memo(({is_open, on_close, on_select, current_time,
  video_srcs=[]})=>{
  let {t} = useTranslation();
  return (
    <Modal title={t('Cameras')} open={is_open} footer={null}
      onCancel={on_close}>
      <div style={{display: 'flex', flexWrap: 'wrap', gap: '5px',
        justifyContent: 'space-between'}}>
        {video_srcs.map((src, idx)=><Video_thumbnail key={src+current_time}
          src={src} time={current_time} on_click={()=>on_select(idx)} />)}
      </div>
    </Modal>
  );
});
let marker_colors = {red: '#a12f19', green: '#33cc33', blue: '#3333cc',
  cyan: '#33cccc', magenta: '#cc33cc', yellow: '#e6e619', black: '#000000',
  white: '#ffffff'};
let Edit_marker_modal = React.memo(({lbin, is_open, marker, marker_track_id,
  cmd_marker_add, cmd_marker_edit, on_close, token, user, src_frame, rec_frame,
  selected_monitor})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [is_loading, is_loading_set] = useState(false);
  let [message_api, message_ctx_holder] = message.useMessage();
  let frame = useMemo(()=>{
    return selected_monitor == 'src' ? src_frame : rec_frame;
  }, [rec_frame, selected_monitor, src_frame]);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err)
      return;
    if (values.modal_disable)
    {
      is_loading_set(true);
      let res = yield back_app.user_set_highlighter_setting(token, user.email,
        'is_disable_marker_modal', true);
      is_loading_set(false);
      if (res.err)
      {
        message_api.error(t('Something went wrong'));
        metric.error('back_app.user_set_highlighter_setting', res.err);
        return;
      }
    }
    let mob_id = selected_monitor == 'src' ? lbin?.src_monitor?.mob_id
      : lbin?.rec_monitor_in?.mob_id;
    if (!mob_id)
      return;
    is_loading_set(true);
    if (marker)
    {
      let res;
      if (marker.color != values.color)
      {
        res = yield cmd_marker_edit(mob_id, marker_track_id,
          marker.abs_start, 'CommentMarkerColor', values.color);
      }
      if (res?.err)
      {
        is_loading_set(false);
        message_api.error(t('Something went wrong'));
        metric.error('cmd_marker_edit', res.err);
        return;
      }
      if (marker_track_id != values.track_id)
      {
        res = yield cmd_marker_edit(mob_id, marker_track_id,
          marker.abs_start, 'track_id', values.track_id);
      }
      if (res?.err)
      {
        is_loading_set(false);
        message_api.error(t('Something went wrong'));
        metric.error('cmd_marker_edit', res.err);
        return;
      }
      if (marker.comment != values.cmt)
      {
        res = yield cmd_marker_edit(mob_id, marker_track_id,
          marker.abs_start, 'Comment', values.cmt);
      }
      if (res?.err)
      {
        is_loading_set(false);
        message_api.error(t('Something went wrong'));
        metric.error('cmd_marker_edit', res.err);
        return;
      }
    }
    else
    {
      let res = yield cmd_marker_add(mob_id, values.track_id, frame,
        values.color, values.cmt);
      if (res.err)
      {
        message_api.error(t('Something went wrong'));
        metric.error('cmd_marker_add', res.err);
        return;
      }
    }
    is_loading_set(false);
    on_close();
  }), [cmd_marker_edit, form, lbin?.rec_monitor_in?.mob_id, marker, message_api,
    on_close, t, marker_track_id, token, user?.email, frame, cmd_marker_add,
    lbin?.src_monitor?.mob_id, selected_monitor]);
  let track_options = useMemo(()=>{
    let tracks = selected_monitor == 'src' ? lbin?.src_monitor?.tracks
      : lbin?.rec_monitor_in?.tracks;
    if (!tracks)
      return [];
    return tracks.map(track=>{
      return {value: track.id, label: track.lbl};
    });
  }, [lbin?.rec_monitor_in?.tracks, lbin?.src_monitor?.tracks,
    selected_monitor]);
  let color_opts = useMemo(()=>{
    return Object.entries(marker_colors).map(([label, color])=>{
      return {value: color, label: _.capitalize(label)};
    });
  }, []);
  let init_values = useMemo(()=>{
    if (marker)
    {
      return {name: marker?.name, color: marker?.color, cmt: marker?.comment,
        track_id: marker_track_id};
    }
    let tracks = selected_monitor == 'src' ? lbin?.src_monitor?.tracks
      : lbin?.rec_monitor_in?.tracks;
    let track_id = tracks ? tracks[0].id : null;
    return {name: '', color: marker_colors.red, cmt: '', track_id};
  }, [lbin?.rec_monitor_in?.tracks, marker, marker_track_id, selected_monitor,
    lbin?.src_monitor?.tracks]);
  useEffect(()=>{
    if (!is_open)
      return;
    form.setFieldsValue(init_values);
  }, [form, init_values, is_open]);
  let mas_tc = useMemo(()=>{
    let start_tc = selected_monitor == 'src' ? lbin?.src_monitor?.start_tc
      : lbin?.rec_monitor_in?.start_tc;
    let editrate = selected_monitor == 'src' ? lbin?.src_monitor?.editrate
      : lbin?.rec_monitor_in?.editrate;
    if (!start_tc || !editrate)
      return null;
    if (marker)
      return tc.frame2tc(start_tc + marker.abs_start, editrate);
    return tc.frame2tc(start_tc + frame, editrate);
  }, [frame, lbin?.rec_monitor_in?.editrate, lbin?.rec_monitor_in?.start_tc,
    lbin?.src_monitor?.editrate, lbin?.src_monitor?.start_tc, marker,
    selected_monitor]);
  return (
    <Modal title={t('Edit Marker')} open={is_open} okText={t('Add')}
      onOk={submit_handle} onCancel={on_close} destroyOnClose
      confirmLoading={is_loading}>
      {message_ctx_holder}
      <Form form={form} preserve={false} onFinish={submit_handle}
        initialValues={init_values} layout="vertical">
        <Form.Item name="name" label={t('Marker Name')}>
          {/* XXX vladimir: Enable when permitted on the back end */}
          <Input allowClear disabled />
        </Form.Item>
        <Form.Item name="color" label={t('Color')}>
          <Select options={color_opts} />
        </Form.Item>
        <div style={{marginBottom: '24px'}}>
          Timecode: {mas_tc}
        </div>
        <Form.Item name="track_id" label={t('Track')}>
          <Select options={track_options} />
        </Form.Item>
        <Form.Item name="cmt">
          <Input.TextArea rows={6} allowClear />
        </Form.Item>
        <Form.Item name="modal_disable" valuePropName="checked">
          <Checkbox>{t('Disable popup')}</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
});
let E = ()=>{
  let {t} = useTranslation();
  let {user, token, org, user_full} = auth.use_auth();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [modal, modal_ctx_holder] = Modal.useModal();
  use_qs_clear({src: 1, src_path: 1, src_is_dir: 1, src_filename: 1,
    src_file_id: 1});
  let [is_edit_mode] = use_je('highlighter.is_edit_mode', false);
  let {qs_o} = use_qs();
  let es_root = use_es_root();
  let initial_src = useMemo(()=>qs_o.src, [qs_o.src]);
  let [is_rate, set_is_rate] = useState(false);
  let [is_premium_modal_open, is_premium_modal_open_set] = useState(false);
  let [lbin, lbin_set] = useState(initial_src ? null : lbin_demo.lbin);
  let [lbin_changes, lbin_changes_set] = useState([]);
  let [cur_lbin_change_idx, cur_lbin_change_idx_set] = useState(-1);
  let [aaf_in, aaf_in_set] = useState();
  let aaf_in_ref = useRef();
  let [, task_id_set] = useState();
  let task_id_ref = useRef();
  let [etag, etag_set] = useState();
  let [src_frame, src_frame_set] = useState(0);
  let [rec_frame, rec_frame_set] = useState(0);
  let [zoom, zoom_set] = useState(0);
  let [scroll_x, scroll_x_set] = useState(0);
  let [tracks_wrapper_width, tracks_wrapper_width_set] = useState();
  let [focused_panel, focused_panel_set] = useState('timeline');
  let [selected_composer_monitor,
    selected_composer_monitor_set] = useState('rec');
  let [selected_timeline_monitor,
    selected_timeline_monitor_set] = useState('rec');
  let [src_max_playing_frame, src_max_playing_frame_set] = useState();
  let [rec_max_playing_frame, rec_max_playing_frame_set] = useState();
  let [is_initial_src_loading, is_initial_src_loading_set] = useState(false);
  let [loading_cmd, loading_cmd_set] = useState(null);
  let [can_play, can_play_set] = useState(true);
  let [is_marker_modal_open, is_marker_modal_open_set] = useState(false);
  let [editing_marker, editing_marker_set] = useState(null);
  let [editing_marker_track_id, editing_marker_track_id_set] = useState(null);
  let [src_playback_rate, src_playback_rate_set] = useState(0);
  let [rec_playback_rate, rec_playback_rate_set] = useState(0);
  let [rec_playback_rate_before_stopped,
    rec_playback_rate_before_stopped_set] = useState(0);
  let [rec_editing_track_ids, rec_editing_track_ids_set] = use_je(
    'highlighter.rec_editing_track_ids', []);
  let [proj, proj_set] = useState(null);
  let [files, files_set] = useState({});
  let [aaf_file, aaf_file_set] = useState(null);
  let [tbin_files, tbin_files_set] = useState([]);
  let fps = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.editrate?.d
      || !lbin?.rec_monitor_in?.editrate?.n)
    {
      return 0;
    }
    return lbin.rec_monitor_in.editrate.n / lbin.rec_monitor_in.editrate.d;
  }, [lbin?.rec_monitor_in?.editrate?.d, lbin?.rec_monitor_in?.editrate?.n]);
  let f2px_k = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    if (lbin.rec_monitor_in.len == 0)
      return 1;
    return lbin.rec_monitor_in.len / tracks_wrapper_width;
  }, [lbin?.rec_monitor_in?.len, tracks_wrapper_width]);
  useEffect(()=>{
    if (!lbin?.rec_monitor_in || etag)
      return;
    let _rec_editing_track_ids = lbin.rec_monitor_in.tracks
      .filter(track=>track.is_edit)
      .map(track=>track.id);
    rec_editing_track_ids_set(_rec_editing_track_ids);
  }, [etag, lbin, rec_editing_track_ids_set]);
  let editor_ref = useRef(null);
  let scroll_x_handle = useCallback(_scroll_x=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(tracks_wrapper_width, f2px_k, zoom);
    _scroll_x = xutil.clamp(_scroll_x, min_scroll, max_scroll);
    scroll_x_set(_scroll_x);
  }, [f2px_k, lbin?.rec_monitor_in?.len, tracks_wrapper_width, zoom]);
  let src_frame_handle = useCallback((_src_frame, ignore_clamp)=>{
    if (lbin?.src_monitor === undefined)
      return;
    _src_frame = Math.floor(_src_frame);
    if (!ignore_clamp && lbin.src_monitor)
    {
      let min_frame = 0;
      let max_frame = lbin.src_monitor.tracks[0].len;
      _src_frame = xutil.clamp(_src_frame, min_frame, max_frame);
    }
    src_frame_set(_src_frame);
  }, [lbin?.src_monitor]);
  let rec_frame_handle = useCallback((_rec_frame, ignore_clamp, scroll,
    keep_max_playing_frame, ignore_playback_rate_before_stooped_reset)=>{
    if (lbin?.rec_monitor_in?.len === undefined
      || lbin?.rec_monitor_in?.start === undefined)
    {
      return;
    }
    _rec_frame = Math.floor(_rec_frame);
    if (!ignore_clamp)
    {
      let min_frame = lbin.rec_monitor_in.start;
      let max_frame = lbin.rec_monitor_in.len;
      _rec_frame = xutil.clamp(_rec_frame, min_frame, max_frame);
    }
    rec_frame_set(_rec_frame);
    if (!keep_max_playing_frame)
      rec_max_playing_frame_set(undefined);
    if (!scroll)
      return;
    let frames_visible = player.px2f(tracks_wrapper_width, f2px_k, zoom);
    if (_rec_frame < scroll_x || _rec_frame > scroll_x + frames_visible)
      scroll_x_handle(_rec_frame - frames_visible / 2);
    if (!ignore_playback_rate_before_stooped_reset)
      rec_playback_rate_before_stopped_set(0);
  }, [f2px_k, lbin?.rec_monitor_in?.len, lbin?.rec_monitor_in?.start, scroll_x,
    scroll_x_handle, tracks_wrapper_width, zoom]);
  let zoom_change_handle = useCallback(_zoom=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    zoom_set(_zoom);
    let _scroll_x = rec_frame
      - player.px2f(tracks_wrapper_width, f2px_k, _zoom) / 2;
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(tracks_wrapper_width, f2px_k, _zoom);
    _scroll_x = xutil.clamp(_scroll_x, min_scroll, max_scroll);
    scroll_x_set(_scroll_x);
  }, [lbin?.rec_monitor_in?.len, rec_frame, tracks_wrapper_width, f2px_k]);
  let src_playback_rate_handle = useCallback(_src_playback_rate=>{
    if (!lbin?.src_monitor)
      return;
    src_playback_rate_set(_src_playback_rate);
  }, [lbin?.src_monitor]);
  let rec_playback_rate_handle = useCallback(_rec_playback_rate=>{
    if (!can_play)
      return;
    rec_playback_rate_set(_rec_playback_rate);
    rec_playback_rate_before_stopped_set(_rec_playback_rate);
    rec_max_playing_frame_set(undefined);
  }, [can_play]);
  let src_playing_ref = useRef(null);
  use_effect_eserf(()=>eserf(function* _use_effect_src_frame_loop(){
    if (!lbin || !src_playback_rate || !can_play)
    {
      src_playing_ref.current = null;
      return;
    }
    if (!src_playing_ref.current)
    {
      src_playing_ref.current = {};
      src_playing_ref.current.start_tc = performance.now();
      src_playing_ref.current.start_frame = src_frame;
      src_playing_ref.current.playback_rate = src_playback_rate;
    }
    if (src_playback_rate != src_playing_ref.current.playback_rate)
    {
      src_playing_ref.current.start_tc = performance.now();
      src_playing_ref.current.start_frame = src_frame;
      src_playing_ref.current.playback_rate = src_playback_rate;
    }
    let frame_ms = xdate.MS_SEC / fps * 2;
    while (1)
    {
      let ms = (performance.now() - src_playing_ref.current.start_tc)
        * src_playback_rate;
      let sec = ms / xdate.MS_SEC;
      let _src_frame = src_playing_ref.current.start_frame +
        Math.floor(sec * fps);
      if (_src_frame == src_frame)
      {
        yield eserf.sleep(frame_ms);
        continue;
      }
      if (_src_frame >= src_max_playing_frame)
      {
        src_playback_rate_set(0);
        src_max_playing_frame_set(undefined);
        return ;
      }
      src_frame_handle(_src_frame, false, true, true, true);
      if (_src_frame < 0 || _src_frame == lbin.src_monitor.len - 1)
        return src_playback_rate_set(0);
      // Amount of frames in ms
      yield eserf.sleep(frame_ms);
    }
  }), [src_frame, src_frame_handle, fps, src_playback_rate,
    src_max_playing_frame, lbin, scroll_x, scroll_x_handle,
    tracks_wrapper_width, f2px_k, can_play]);
  let rec_playing_ref = useRef(null);
  use_effect_eserf(()=>eserf(function* _use_effect_rec_frame_loop(){
    if (!lbin || !rec_playback_rate || !can_play)
    {
      rec_playing_ref.current = null;
      return;
    }
    if (!rec_playing_ref.current)
    {
      rec_playing_ref.current = {};
      rec_playing_ref.current.start_tc = performance.now();
      rec_playing_ref.current.start_frame = rec_frame;
      rec_playing_ref.current.playback_rate = rec_playback_rate;
    }
    if (rec_playback_rate != rec_playing_ref.current.playback_rate)
    {
      rec_playing_ref.current.start_tc = performance.now();
      rec_playing_ref.current.start_frame = rec_frame;
      rec_playing_ref.current.playback_rate = rec_playback_rate;
    }
    let frame_ms = xdate.MS_SEC / fps * 2;
    while (1)
    {
      let ms = (performance.now() - rec_playing_ref.current.start_tc)
        * rec_playback_rate;
      let sec = ms / xdate.MS_SEC;
      let _rec_frame = rec_playing_ref.current.start_frame +
        Math.floor(sec * fps);
      if (_rec_frame == rec_frame)
      {
        yield eserf.sleep(frame_ms);
        continue;
      }
      if (_rec_frame >= rec_max_playing_frame)
      {
        rec_playback_rate_set(0);
        rec_max_playing_frame_set(undefined);
        return ;
      }
      rec_frame_handle(_rec_frame, false, true, true, true);
      if (_rec_frame < 0 || _rec_frame == lbin.rec_monitor_in.len - 1)
        return rec_playback_rate_set(0);
      // Amount of frames in ms
      yield eserf.sleep(frame_ms);
    }
  }), [rec_frame, rec_frame_handle, fps, rec_playback_rate,
    rec_max_playing_frame, lbin, scroll_x, scroll_x_handle,
    tracks_wrapper_width, f2px_k, can_play]);
  let src_playing_toggle = useCallback(()=>{
    src_playback_rate_set(src_playback_rate ? 0 : 1);
  }, [src_playback_rate_set, src_playback_rate]);
  let rec_playing_toggle = useCallback(()=>{
    rec_playback_rate_set(rec_playback_rate ? 0 : 1);
  }, [rec_playback_rate]);
  let premium_modal_open = useCallback(()=>{
    is_premium_modal_open_set(true);
  }, []);
  let tbin_upload = useCallback((_files, progress_tx_cb)=>eserf(function*
  _tbin_upload(){
    if (!_files.length)
      return {err: 'no files'};
    let res = yield back_app.editor.tbin_upload(token, aaf_in_ref.current,
      task_id_ref.current, _files, progress_tx_cb);
    if (res.err)
      return {err: res.err};
    let _lbin = {...lbin, ...res.lbin};
    lbin_set(_lbin);
    let _rec_editing_track_ids = rec_editing_track_ids
      .filter(track_id=>{
        return _lbin.rec_monitor_in.tracks.find(track=>track.id == track_id);
      });
    let diffs = deep_diff(lbin, _lbin);
    let lbin_change = {cmd: 'file_link', diffs, aaf_in: aaf_in_ref.current,
      etag};
    let _lbin_changes = lbin_changes.slice(0, cur_lbin_change_idx + 1);
    _lbin_changes.push(lbin_change);
    lbin_changes_set(_lbin_changes);
    cur_lbin_change_idx_set(cur_lbin_change_idx + 1);
    aaf_in_set(res.file);
    aaf_in_ref.current = res.file;
    task_id_set(res.task_id);
    task_id_ref.current = res.task_id;
    etag_set(res.etag);
    rec_editing_track_ids_set(_rec_editing_track_ids);
    tbin_files_set([...tbin_files, ...res.tbin_files]);
    return {aaf_in: res.file, etag: res.etag};
  }), [cur_lbin_change_idx, etag, lbin, lbin_changes, rec_editing_track_ids,
    rec_editing_track_ids_set, token, tbin_files]);
  // XXX vladimir: import from editor.js
  // XXX vladimir: make cmd_eval global and wrapper (cmd2lbin)
  // XXX vladimir: handle multiple clicking (use sequence)
  let cmd_eval = useCallback((cmd, data={}, lbl)=>eserf(function*
  _cmd_eval(){
    if (!aaf_in_ref.current)
      return {err: 'no aaf_in'};
    if (!cmd)
      assert(0, 'no cmd');
    if (!Array.isArray(data))
      data = [data];
    if (!data.length)
      assert(0, 'no data');
    let data_with_mob_ids = data.map(_data=>{
      _data = {..._data};
      if (lbin.rec_monitor_in)
        _data.mob_id_rec = lbin.rec_monitor_in.mob_id;
      if (lbin.src_monitor)
        _data.mob_id_src = lbin.src_monitor.mob_id;
      return _data;
    });
    if (lbl)
      loading_cmd_set(lbl);
    let res = yield back_app.editor.cmds(token, aaf_in_ref.current,
      task_id_ref.current, cmd, data_with_mob_ids);
    loading_cmd_set(null);
    if (res.err)
      return {err: res.err};
    let _lbin = {...lbin, ...res.lbin};
    if (res.lbin.rec_monitor_out)
    {
      let rec_monitor_in = res.lbin.rec_monitor_out;
      if (lbin.rec_monitor_in)
      {
        rec_monitor_in = monitor_sync(lbin.rec_monitor_in,
          res.lbin.rec_monitor_out);
      }
      _lbin.rec_monitor_in = rec_monitor_in;
    }
    if (res.lbin.src_monitor)
    {
      let src_monitor = res.lbin.src_monitor;
      if (lbin.src_monitor)
      {
        src_monitor = monitor_sync(lbin.src_monitor,
          res.lbin.src_monitor);
      }
      _lbin.src_monitor = src_monitor;
    }
    lbin_set(_lbin);
    let _rec_editing_track_ids = rec_editing_track_ids
      .filter(track_id=>{
        return _lbin.rec_monitor_in.tracks.find(track=>track.id == track_id);
      });
    let diffs = deep_diff(lbin, _lbin);
    let lbin_change = {cmd, diffs, aaf_in: aaf_in_ref.current, etag};
    let _lbin_changes = lbin_changes.slice(0, cur_lbin_change_idx + 1);
    _lbin_changes.push(lbin_change);
    lbin_changes_set(_lbin_changes);
    cur_lbin_change_idx_set(cur_lbin_change_idx + 1);
    aaf_in_set(res.file);
    aaf_in_ref.current = res.file;
    task_id_set(res.task_id);
    task_id_ref.current = res.task_id;
    etag_set(res.etag);
    rec_editing_track_ids_set(_rec_editing_track_ids);
    return {aaf_in: res.file, etag: res.etag};
  }), [cur_lbin_change_idx, lbin, lbin_changes, token, etag,
    rec_editing_track_ids, rec_editing_track_ids_set]);
  let active_track_ids = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return lbin.rec_monitor_in.tracks.map(track=>track.id);
  }, [lbin?.rec_monitor_in?.tracks]);
  let cmd_trim = useCallback((mob_id, starts, deltas, cmd='trim')=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (starts === undefined)
      assert(0, 'no starts');
    if (deltas === undefined)
      assert(0, 'no deltas');
    if (!['trim', 'trim_pre', 'trim_post'].includes(cmd))
      assert(0, 'unexpected cmd: ' + cmd);
    let data = Object.entries(deltas)
      .filter(([track_id, delta])=>delta)
      .map(([track_id, delta])=>{
        let abs_frame = Math.round(starts[track_id]);
        let abs_frame_set = Math.round(starts[track_id] + delta);
        return {mob_id, track_id, abs_frame, abs_frame_set};
      });
    return cmd_eval(cmd, data, t('Trim'));
  }, [cmd_eval, t]);
  let cmd_mark_in = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval('mark_in', {mob_id, abs_frame}, 'Mark In');
  }, [cmd_eval]);
  let cmd_mark_out = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval('mark_out', {mob_id, abs_frame}, 'Mark Out');
  }, [cmd_eval]);
  let cmd_mark_clip = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval('mark_clip', {mob_id, abs_frame}, 'Mark Clip');
  }, [cmd_eval]);
  let cmd_clear_both_marks = useCallback(mob_id=>eserf(function*
  _cmd_clear_both_marks(){
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    let res = yield cmd_eval('clear_mark_in', {mob_id}, 'Clear Mark In');
    if (res.err)
      return {err: res.err};
    return yield cmd_eval('clear_mark_out', {mob_id},
      'Clear Mark Out');
  }), [cmd_eval]);
  let cmd_cut = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    if (!lbin?.rec_monitor_in?.tracks)
      return;
    let data = lbin.rec_monitor_in.tracks
      .filter(track=>{
        let track_cuts = editor.cuts_get([track]);
        return !track_cuts.includes(abs_frame);
      })
      .map(track=>({mob_id, track_id: track.id, abs_frame}));
    if (!data.length)
      return {};
    return cmd_eval('cut', data, 'Cut');
  }, [cmd_eval, lbin?.rec_monitor_in?.tracks]);
  let cmd_lift = useCallback((mob_id, abs_frame_in, abs_frame_out)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame_in === undefined)
      assert(0, 'no abs_frame_in');
    if (abs_frame_out === undefined)
      assert(0, 'no abs_frame_out');
    let data = active_track_ids.map(track_id=>{
      return {mob_id, track_id, abs_frame_in, abs_frame_out};
    });
    return cmd_eval('lift', data, 'Lift');
  }, [active_track_ids, cmd_eval]);
  let cmd_extract = useCallback((mob_id, abs_frame_in, abs_frame_out)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame_in === undefined)
      assert(0, 'no abs_frame_in');
    if (abs_frame_out === undefined)
      assert(0, 'no abs_frame_out');
    let data = active_track_ids.map(track_id=>{
      return {mob_id, track_id, abs_frame_in, abs_frame_out};
    });
    return cmd_eval('extract', data, 'Extract');
  }, [active_track_ids, cmd_eval]);
  let cmd_selector_set = useCallback((mob_id, track_id, selector_idx,
    abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (selector_idx === undefined)
      assert(0, 'no selector_idx');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval('selector_set', {mob_id, track_id, selector_idx,
      abs_frame}, 'Selector set');
  }, [cmd_eval]);
  let cmd_solo = useCallback((mob_id, track_ids, is_solo)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_ids === undefined)
      assert(0, 'no track_ids');
    if (is_solo === undefined)
      assert(0, 'no is_solo');
    let data = track_ids.map(track_id=>({mob_id, track_id, is_solo}));
    return cmd_eval('solo', data, t('Solo'));
  }, [cmd_eval, t]);
  let cmd_mute = useCallback((mob_id, track_ids, is_mute)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_ids === undefined)
      assert(0, 'no track_ids');
    if (is_mute === undefined)
      assert(0, 'no is_mute');
    let data = track_ids.map(track_id=>({mob_id, track_id, is_mute}));
    return cmd_eval('mute', data, t('Move'));
  }, [cmd_eval, t]);
  let cmd_clip_move = useCallback((mob_id, track_ids, next_track_ids, deltas)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_ids === undefined)
      assert(0, 'no track_ids');
    if (next_track_ids === undefined)
      assert(0, 'no next_track_ids');
    if (deltas === undefined)
      assert(0, 'no deltas');
    let data = [];
    for (let seg of track_ids.keys())
    {
      let track_id = track_ids.get(seg);
      let track_id_set = next_track_ids.get(seg);
      let abs_frame = seg.abs_start;
      let delta = deltas.get(seg);
      let abs_frame_set = abs_frame + delta;
      if (abs_frame_set < 0)
        abs_frame_set = 0;
      if (abs_frame == abs_frame_set && track_id == track_id_set)
        continue;
      data.push({mob_id, track_id, track_id_set, abs_frame, abs_frame_set});
    }
    if (!data.length)
      return {};
    return cmd_eval('clip_move', data, t('Move clip'));
  }, [cmd_eval, t]);
  let cmd_marker_add = useCallback((mob_id, track_id, abs_frame, color, cmt)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    if (color === undefined)
      assert(0, 'no color');
    if (cmt === undefined)
      assert(0, 'no cmt');
    return cmd_eval('marker_add', {mob_id, track_id, abs_frame, color,
      comment: cmt}, t('Add Marker'));
  }, [cmd_eval, t]);
  let cmd_marker_edit = useCallback((mob_id, track_id, abs_frame, key, v)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    if (!marker_edit_allowed_keys.includes(key))
      assert(0, 'unexpected key: ', key);
    if (v === undefined)
      assert(0, 'no v');
    return cmd_eval('marker_edit', {mob_id, track_id, abs_frame, attribute: key,
      value: v}, t('Marker Edit'));
  }, [cmd_eval, t]);
  let cmd_marker_remove = useCallback((mob_id, track_ids, abs_frames)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_ids === undefined)
      assert(0, 'no track_ids');
    if (abs_frames === undefined)
      assert(0, 'no abs_frames');
    if (!Array.isArray(track_ids))
      track_ids = [track_ids];
    if (!Array.isArray(abs_frames))
      abs_frames = [abs_frames];
    let data = track_ids.map((track_id, idx)=>{
      let abs_frame = abs_frames[idx];
      return {mob_id, track_id, abs_frame};
    });
    return cmd_eval('marker_remove', data, t('Remove marker'));
  }, [cmd_eval, t]);
  let cmd_rec_monitor_load_clip = useCallback(mob_id=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    return cmd_eval('rec_monitor_load_clip', {mob_id}, t('Load clip'));
  }, [cmd_eval, t]);
  let cmd_src_monitor_load_clip = useCallback(mob_id=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    return cmd_eval('src_monitor_load_clip', {mob_id}, t('Load clip'));
  }, [cmd_eval, t]);
  let cmd_clip_duplicate = useCallback(mob_id=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    return cmd_eval('clip_duplicate', {mob_id}, t('Duplicate clip'));
  }, [cmd_eval, t]);
  let cmd_overwrite = useCallback((mob_id_src, mob_id_rec, abs_frame_in_src,
    abs_frame_in_rec, abs_frame_out_src, abs_frame_out_rec, track_ids_src,
    track_ids_rec)=>{
    if (mob_id_src === undefined)
      assert(0, 'no mob_id_src');
    if (mob_id_rec === undefined)
      assert(0, 'no mob_id_rec');
    if (abs_frame_in_src === undefined)
      assert(0, 'no abs_frame_in_src');
    if (abs_frame_in_rec === undefined)
      assert(0, 'no abs_frame_in_rec');
    if (track_ids_src === undefined)
      assert(0, 'no track_ids_src');
    if (track_ids_rec === undefined)
      assert(0, 'no track_ids_rec');
    if (track_ids_src.length != track_ids_rec.length)
      assert(0, 'track_ids_src and track_ids_rec has different lengths');
    let data = track_ids_src.map((track_id_src, idx)=>{
      let track_id_rec = track_ids_rec[idx];
      return {mob_id_src, mob_id_rec, abs_frame_in_src, abs_frame_in_rec,
        abs_frame_out_src, abs_frame_out_rec, track_id_src, track_id_rec};
    });
    return cmd_eval('overwrite', data, t('Overwrite'));
  }, [cmd_eval, t]);
  let cmd_sequence_new = useCallback(editrate=>{
    if (editrate === undefined)
      assert(0, 'no editrate');
    return cmd_eval('sequence_new', {editrate}, t('New sequence'));
  }, [cmd_eval, t]);
  let marker_edit = useCallback((track_id, marker)=>{
    editing_marker_set(marker);
    editing_marker_track_id_set(track_id);
    is_marker_modal_open_set(true);
  }, []);
  let marker_modal_close = useCallback(()=>{
    is_marker_modal_open_set(false);
  }, []);
  let undo = useCallback(()=>{
    let lbin_change = lbin_changes[cur_lbin_change_idx];
    if (!lbin_change)
      return;
    let _lbin = _.cloneDeep(lbin);
    lbin_change.diffs.forEach(diff=>{
      deep_diff.revertChange(_lbin, _lbin, diff);
    });
    lbin_set(_lbin);
    aaf_in_set(lbin_change.aaf_in);
    aaf_in_ref.current = lbin_change.aaf_in;
    etag_set(lbin_change.etag);
    cur_lbin_change_idx_set(cur_lbin_change_idx - 1);
  }, [cur_lbin_change_idx, lbin, lbin_changes]);
  let redo = useCallback(()=>{
    let lbin_change = lbin_changes[cur_lbin_change_idx + 1];
    if (!lbin_change)
      return;
    let _lbin = _.cloneDeep(lbin);
    lbin_change.diffs.forEach(diff=>{
      deep_diff.applyChange(_lbin, _lbin, diff);
    });
    lbin_set(_lbin);
    aaf_in_set(lbin_change.aaf_in);
    aaf_in_ref.current = lbin_change.aaf_in;
    etag_set(lbin_change.etag);
    cur_lbin_change_idx_set(cur_lbin_change_idx + 1);
  }, [cur_lbin_change_idx, lbin, lbin_changes]);
  let selected_monitor = useMemo(()=>{
    if (focused_panel == 'composer')
      return selected_composer_monitor;
    if (focused_panel == 'timeline')
      return selected_timeline_monitor;
    assert(0, `unexpected focused panel: ${focused_panel}`);
  }, [focused_panel, selected_composer_monitor, selected_timeline_monitor]);
  let src_move_frame = useCallback(frame_shift=>{
    let _src_frame = src_frame + frame_shift;
    src_frame_handle(_src_frame, false, true);
    src_playback_rate_handle(0);
  }, [src_frame, src_frame_handle, src_playback_rate_handle]);
  let rec_move_frame = useCallback(frame_shift=>{
    let _rec_frame = rec_frame + frame_shift;
    rec_frame_handle(_rec_frame, false, true);
    rec_playback_rate_handle(0);
  }, [rec_frame, rec_frame_handle, rec_playback_rate_handle]);
  let move_one_frame_left = useCallback(()=>{
    if (selected_monitor == 'src')
      src_move_frame(-1);
    if (selected_monitor == 'rec')
      rec_move_frame(-1);
  }, [rec_move_frame, selected_monitor, src_move_frame]);
  let move_one_frame_right = useCallback(()=>{
    if (selected_monitor == 'src')
      src_move_frame(1);
    if (selected_monitor == 'rec')
      rec_move_frame(1);
  }, [rec_move_frame, selected_monitor, src_move_frame]);
  let move_ten_frames_left = useCallback(()=>{
    if (selected_monitor == 'src')
      src_move_frame(-10);
    if (selected_monitor == 'rec')
      rec_move_frame(-10);
  }, [rec_move_frame, selected_monitor, src_move_frame]);
  let move_ten_frames_right = useCallback(()=>{
    if (selected_monitor == 'src')
      src_move_frame(10);
    if (selected_monitor == 'rec')
      rec_move_frame(10);
  }, [rec_move_frame, selected_monitor, src_move_frame]);
  let play_stop = useCallback(()=>{
    if (selected_monitor == 'src')
      src_playback_rate_handle(src_playback_rate ? 0 : 1);
    if (selected_monitor == 'rec')
      rec_playback_rate_handle(rec_playback_rate ? 0 : 1);
  }, [selected_monitor, src_playback_rate_handle, src_playback_rate,
    rec_playback_rate_handle, rec_playback_rate]);
  let play_backwards_handle = useCallback(()=>{
    // XXX vladimir: move playback rate progression to comp.js
    let playback_rates = [-8, -5, -3, -2, -1, 0];
    let playback_rate = selected_monitor == 'src' ? src_playback_rate
      : rec_playback_rate;
    if (playback_rate > 0)
    {
      if (selected_monitor == 'src')
        src_playback_rate_handle(playback_rates.at(-2));
      if (selected_monitor == 'rec')
        rec_playback_rate_handle(playback_rates.at(-2));
      return;
    }
    let idx = playback_rates.indexOf(playback_rate);
    let _playback_rate = playback_rates[idx - 1];
    if (!_playback_rate)
      _playback_rate = playback_rates[0];
    if (selected_monitor == 'src')
      src_playback_rate_handle(_playback_rate);
    if (selected_monitor == 'rec')
      rec_playback_rate_handle(_playback_rate);
  }, [rec_playback_rate, rec_playback_rate_handle, selected_monitor,
    src_playback_rate_handle, src_playback_rate]);
  let stop_handle = useCallback(()=>{
    if (selected_monitor == 'src')
      src_playback_rate_handle(0);
    if (selected_monitor == 'rec')
      rec_playback_rate_handle(0);
  }, [rec_playback_rate_handle, selected_monitor, src_playback_rate_handle]);
  let play_handle = useCallback(()=>{
    // XXX vladimir: move playback rate progression to comp.js
    let playback_rates = [0, 1, 2, 3, 5, 8];
    let playback_rate = selected_monitor == 'src' ? src_playback_rate
      : rec_playback_rate;
    if (playback_rate < 0)
    {
      if (selected_monitor == 'src')
        src_playback_rate_handle(playback_rates[0]);
      if (selected_monitor == 'rec')
        rec_playback_rate_handle(playback_rates[0]);
      return;
    }
    let idx = playback_rates.indexOf(playback_rate);
    let _playback_rate = playback_rates[idx + 1];
    if (!_playback_rate)
      _playback_rate = playback_rates.at(-1);
    if (selected_monitor == 'src')
      src_playback_rate_handle(_playback_rate);
    if (selected_monitor == 'rec')
      rec_playback_rate_handle(_playback_rate);
  }, [rec_playback_rate, rec_playback_rate_handle, selected_monitor,
    src_playback_rate_handle, src_playback_rate]);
  let src_cuts = useMemo(()=>{
    if (!lbin?.src_monitor?.tracks)
      return [];
    return editor.cuts_get(lbin.src_monitor.tracks);
  }, [lbin?.src_monitor?.tracks]);
  let rec_cuts = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return editor.cuts_get(lbin.rec_monitor_in.tracks);
  }, [lbin?.rec_monitor_in?.tracks]);
  let go_to_prev_cut = useCallback(()=>{
    let cuts = selected_monitor == 'src' ? src_cuts : rec_cuts;
    let frame = selected_monitor == 'src' ? src_frame : rec_frame;
    if (!cuts.length)
      return;
    let _frame = cuts.find((cut, index)=>{
      return cut < frame && frame <= cuts[index + 1];
    });
    if (!_frame)
      _frame = cuts.at(0);
    if (selected_monitor == 'src')
    {
      src_frame_handle(_frame, false, true);
      src_playback_rate_handle(0);
    }
    if (selected_monitor == 'rec')
    {
      rec_frame_handle(_frame, false, true);
      rec_playback_rate_handle(0);
    }
  }, [rec_cuts, rec_frame, rec_frame_handle, rec_playback_rate_handle,
    selected_monitor, src_cuts, src_frame, src_frame_handle,
    src_playback_rate_handle]);
  let go_to_next_cut = useCallback(()=>{
    let cuts = selected_monitor == 'src' ? src_cuts : rec_cuts;
    let frame = selected_monitor == 'src' ? src_frame : rec_frame;
    if (!cuts.length)
      return;
    let _frame = cuts.find(cut=>frame < cut);
    if (!_frame)
      _frame = cuts.at(-1);
    if (selected_monitor == 'src')
    {
      src_frame_handle(_frame, false, true);
      src_playback_rate_handle(0);
    }
    if (selected_monitor == 'rec')
    {
      rec_frame_handle(_frame, false, true);
      rec_playback_rate_handle(0);
    }
  }, [rec_cuts, rec_frame, rec_frame_handle, rec_playback_rate_handle,
    selected_monitor, src_cuts, src_frame, src_frame_handle,
    src_playback_rate_handle]);
  let mark_in = useCallback(()=>{
    if (selected_monitor == 'src' && lbin?.src_monitor)
      cmd_mark_in(lbin.src_monitor.mob_id, src_frame);
    if (selected_monitor == 'rec' && lbin?.rec_monitor_in)
      cmd_mark_in(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_in, lbin?.rec_monitor_in, lbin?.src_monitor, rec_frame,
    selected_monitor, src_frame]);
  let mark_out = useCallback(()=>{
    if (selected_monitor == 'src' && lbin?.src_monitor)
      cmd_mark_out(lbin.src_monitor.mob_id, src_frame);
    if (selected_monitor == 'rec' && lbin?.rec_monitor_in)
      cmd_mark_out(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_out, lbin?.rec_monitor_in, lbin?.src_monitor, rec_frame,
    selected_monitor, src_frame]);
  let go_to_mark_in = useCallback(()=>{
    if (selected_monitor == 'src' && lbin?.src_monitor?.mark_in)
      src_frame_handle(lbin.src_monitor.mark_in, false, true);
    if (selected_monitor == 'rec' && lbin?.rec_monitor_in?.mark_in)
      rec_frame_handle(lbin.rec_monitor_in.mark_in, false, true);
  }, [lbin?.rec_monitor_in?.mark_in, lbin?.src_monitor?.mark_in,
    rec_frame_handle, selected_monitor, src_frame_handle]);
  let go_to_mark_out = useCallback(()=>{
    if (selected_monitor == 'src' && lbin?.src_monitor?.mark_out)
      src_frame_handle(lbin.src_monitor.mark_out, false, true);
    if (selected_monitor == 'rec' && lbin?.rec_monitor_in?.mark_out)
      rec_frame_handle(lbin.rec_monitor_in.mark_out, false, true);
  }, [lbin?.rec_monitor_in?.mark_out, lbin?.src_monitor?.mark_out,
    rec_frame_handle, selected_monitor, src_frame_handle]);
  let src_markers_pos = useMemo(()=>{
    if (!lbin?.src_monitor?.tracks)
      return [];
    return editor.markers_pos_get(lbin.src_monitor.tracks);
  }, [lbin?.src_monitor?.tracks]);
  let rec_markers_pos = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return editor.markers_pos_get(lbin.rec_monitor_in.tracks);
  }, [lbin?.rec_monitor_in?.tracks]);
  let go_to_prev_marker = useCallback(()=>{
    let markers_pos = selected_monitor == 'src' ? src_markers_pos
      : rec_markers_pos;
    let frame = selected_monitor == 'src' ? src_frame : rec_frame;
    if (!markers_pos.length)
      return;
    let min_pos = Math.min(...markers_pos);
    if (frame <= min_pos)
      return;
    let max_pos = Math.max(...markers_pos);
    let _frame;
    if (frame > max_pos)
      _frame = max_pos;
    else
    {
      _frame = markers_pos.find((marker, index)=>{
        return marker < frame && frame <= markers_pos[index + 1];
      });
    }
    if (selected_monitor == 'src')
      src_frame_handle(_frame, false, true);
    if (selected_monitor == 'rec')
      rec_frame_handle(_frame, false, true);
  }, [rec_frame, rec_frame_handle, rec_markers_pos, selected_monitor, src_frame,
    src_frame_handle, src_markers_pos]);
  let go_to_next_marker = useCallback(()=>{
    let markers_pos = selected_monitor == 'src' ? src_markers_pos
      : rec_markers_pos;
    let frame = selected_monitor == 'src' ? src_frame : rec_frame;
    if (!markers_pos.length)
      return;
    let _frame = markers_pos.find(marker=>frame < marker);
    if (!_frame)
      _frame = markers_pos.at(-1);
    if (selected_monitor == 'src')
      src_frame_handle(_frame, false, true);
    if (selected_monitor == 'rec')
      rec_frame_handle(_frame, false, true);
  }, [rec_frame, rec_frame_handle, rec_markers_pos, selected_monitor, src_frame,
    src_frame_handle, src_markers_pos]);
  let selector_change = useCallback(shift=>{
    let tracks = selected_monitor == 'src' ? lbin?.src_monitor?.tracks
      : lbin?.rec_monitor_in?.tracks;
    if (!tracks)
      return;
    let frame = selected_monitor == 'src' ? src_frame : rec_frame;
    let mob_id = selected_monitor == 'src' ? lbin.src_monitor.mob_id
      : lbin.rec_monitor_in.mob_id;
    for (let track of tracks)
    {
      let abs_starts_map = player.abs_starts_get(track);
      for (let [seg] of abs_starts_map.entries())
      {
        if (seg.type != 'selector' || seg.abs_start > frame
          || seg.abs_start + seg.len < frame)
        {
          continue;
        }
        let next_selector_idx = (seg.select_idx + shift) % seg.arr.length;
        if (next_selector_idx < 0)
          next_selector_idx += seg.arr.length;
        cmd_selector_set(mob_id, track.id, next_selector_idx, seg.abs_start);
        return;
      }
    }
  }, [cmd_selector_set, lbin?.rec_monitor_in?.mob_id,
    lbin?.rec_monitor_in?.tracks, lbin?.src_monitor?.mob_id,
    lbin?.src_monitor?.tracks, rec_frame, selected_monitor, src_frame]);
  let change_selector_prev = useCallback(()=>{
    selector_change(-1);
  }, [selector_change]);
  let change_selector_next = useCallback(()=>{
    selector_change(1);
  }, [selector_change]);
  let marker_remove = useCallback(()=>{
    let tracks = selected_monitor == 'src' ? lbin?.src_monitor?.tracks
      : lbin?.rec_monitor_in?.tracks;
    if (!tracks)
      return;
    let frame = selected_monitor == 'src' ? src_frame : rec_frame;
    let mob_id = selected_monitor == 'src' ? lbin.src_monitor.mob_id
      : lbin.rec_monitor_in.mob_id;
    let tc_track = tracks.find(track=>track.type == 'tc_track');
    let video_tracks = tracks.filter(track=>{
      return track.type == 'timeline_track' && track.lbl.startsWith('V');
    });
    let audio_tracks = tracks.filter(track=>{
      return track.type == 'timeline_track' && track.lbl.startsWith('A');
    });
    let tracks_order = [];
    if (tc_track)
      tracks_order.push(tc_track);
    tracks_order.push(...video_tracks.reverse());
    tracks_order.push(...audio_tracks);
    for (let track of tracks_order)
    {
      let _markers = editor.markers_get(track);
      for (let marker of _markers)
      {
        if (marker.abs_start == frame)
          return cmd_marker_remove(mob_id, track.id, marker.abs_start);
      }
    }
  }, [cmd_marker_remove, lbin?.rec_monitor_in?.mob_id,
    lbin?.rec_monitor_in?.tracks, lbin?.src_monitor?.mob_id,
    lbin?.src_monitor?.tracks, rec_frame, selected_monitor, src_frame]);
  let play_in_to_out = useCallback(()=>{
    if (selected_monitor == 'src' && lbin?.src_monitor)
    {
      if (src_playback_rate)
        return src_playback_rate_set(0);
      if (lbin.src_monitor_in.mark_in)
        src_frame_set(lbin.src_monitor_in.mark_in);
      src_playback_rate_set(1);
      if (lbin.src_monitor_in.mark_out)
        src_max_playing_frame_set(lbin.src_monitor_in.mark_out);
    }
    if (selected_monitor == 'rec' && lbin?.rec_monitor_in)
    {
      if (rec_playback_rate)
        return rec_playback_rate_set(0);
      if (lbin.rec_monitor_in.mark_in)
        rec_frame_set(lbin.rec_monitor_in.mark_in);
      rec_playback_rate_set(1);
      if (lbin.rec_monitor_in.mark_out)
        rec_max_playing_frame_set(lbin.rec_monitor_in.mark_out);
    }
  }, [lbin?.rec_monitor_in, lbin?.src_monitor, lbin?.src_monitor_in?.mark_in,
    lbin?.src_monitor_in?.mark_out, rec_playback_rate, selected_monitor,
    src_playback_rate]);
  let marker_add = useCallback(()=>eserf(function* _marker_add(){
    let tracks = selected_monitor == 'src' ? lbin?.src_monitor?.tracks
      : lbin?.rec_monitor_in?.tracks;
    if (!tracks)
      return;
    let frame = selected_monitor == 'src' ? src_frame : rec_frame;
    let mob_id = selected_monitor == 'src' ? lbin.src_monitor.mob_id
      : lbin.rec_monitor_in.mob_id;
    let marker_on_frame;
    let marker_track_id;
    for (let track of tracks)
    {
      let markers = editor.markers_get(track);
      marker_on_frame = markers.find(marker=>marker.abs_start == frame);
      marker_track_id = track.id;
      if (marker_on_frame)
        break;
      if (!marker_on_frame)
        continue;
      editing_marker_set(marker_on_frame);
      editing_marker_track_id_set(track.id);
      is_marker_modal_open_set(true);
    }
    if (marker_on_frame)
    {
      editing_marker_set(marker_on_frame);
      editing_marker_track_id_set(marker_track_id);
      is_marker_modal_open_set(true);
      return;
    }
    if (user_full.setting?.highlighter?.is_disable_marker_modal && aaf_in)
    {
      let res = yield cmd_marker_add(mob_id, tracks[0].id, frame,
        marker_colors.red, '');
      if (res.err)
      {
        message_api.error(t('Something went wrong'));
        metric.error('back_app.user_set_editor_setting', res.err);
      }
      return;
    }
    editing_marker_set(null);
    editing_marker_track_id_set(null);
    is_marker_modal_open_set(true);
  }), [aaf_in, cmd_marker_add, lbin?.rec_monitor_in?.mob_id,
    lbin?.rec_monitor_in?.tracks, lbin?.src_monitor?.mob_id,
    lbin?.src_monitor?.tracks, message_api, rec_frame, selected_monitor,
    src_frame, t, user_full?.setting?.highlighter?.is_disable_marker_modal]);
  let action2func = useMemo(()=>({undo, redo, play_stop, marker_remove,
    play_backwards: play_backwards_handle, stop: stop_handle, play: play_handle,
    move_one_frame_left, move_one_frame_right, marker_add,
    move_ten_frames_left, move_ten_frames_right,
    go_to_prev_cut, go_to_next_cut,
    mark_in, mark_out, go_to_mark_in, go_to_mark_out,
    go_to_prev_marker, go_to_next_marker,
    change_selector_prev, change_selector_next, play_in_to_out,
  }), [undo, redo, play_stop, play_backwards_handle, stop_handle, play_handle,
    move_one_frame_left, move_one_frame_right, move_ten_frames_left, marker_add,
    move_ten_frames_right, go_to_prev_cut, go_to_next_cut, mark_in, mark_out,
    go_to_mark_in, go_to_mark_out, go_to_prev_marker, go_to_next_marker,
    change_selector_prev, change_selector_next, marker_remove, play_in_to_out]);
  useEffect(()=>{
    let unsubscribe = tinykeys(window, key_binding_map_get(action2func));
    return ()=>unsubscribe();
  }, [action2func]);
  useEffect(()=>{
    set_is_rate(org && user_full && user_full.rate==undefined && org.credit
      && org.credit.v<-50);
  }, [org, user_full]);
  let lbin_change_handle = useCallback(_lbin=>{
    lbin_set(_lbin);
    let _rec_editing_track_ids;
    if (_lbin.rec_monitor_in)
    {
      _rec_editing_track_ids = _lbin.rec_monitor_in.tracks
        .filter(track=>track.is_edit)
        .map(track=>track.id);
    }
    else
      _rec_editing_track_ids = [];
    rec_editing_track_ids_set(_rec_editing_track_ids);
    lbin_changes_set([]);
    cur_lbin_change_idx_set(-1);
  }, [rec_editing_track_ids_set]);
  use_effect_eserf(()=>eserf(function* use_effect_load_from_src(){
    if (!initial_src?.file || !initial_src?.etag || !initial_src?.task_id)
      return;
    if (!token || lbin)
      return;
    is_initial_src_loading_set(true);
    let res = yield back_app.editor_aaf_upload(token, user.email,
      {aaf_in: initial_src.file, etag: initial_src.etag,
        task_id: initial_src.task_id});
    if (res.err)
    {
      metric.error('use_effect_load_from_src', str.j2s(res));
      void message_api.error(`file upload failed ${res.err}`);
      is_initial_src_loading_set(false);
      return;
    }
    let file_res = yield back_app.file.get(token, qs_o.src_file_id);
    if (file_res.err)
    {
      metric.error('back_app.file.get', str.j2s(file_res));
      void message_api.error(`file upload failed ${file_res.err}`);
      is_initial_src_loading_set(false);
      return;
    }
    is_initial_src_loading_set(false);
    lbin_change_handle(res.lbin);
    aaf_in_set(res.file);
    aaf_in_ref.current = res.file;
    task_id_set(res.task_id);
    task_id_ref.current = res.task_id;
    etag_set(res.etag);
    aaf_file_set(file_res.file);
  }), [initial_src?.file, initial_src?.etag, initial_src?.task_id, message_api,
    token, user.email, lbin, lbin_change_handle]);
  let loading_state_change_handle = useCallback(_is_loading=>{
    can_play_set(!_is_loading);
    if (can_play && _is_loading)
    {
      rec_playback_rate_set(0);
      rec_playback_rate_before_stopped_set(rec_playback_rate);
    }
    else if (!can_play && !_is_loading)
      rec_playback_rate_set(rec_playback_rate_before_stopped);
  }, [can_play, rec_playback_rate, rec_playback_rate_before_stopped]);
  let proj_get = useCallback(proj_id=>eserf(function* _proj_get(){
    if (!token)
      return;
    if (!proj_id)
      proj_id = proj.id;
    let proj_res = yield back_app.proj.get(token, proj_id);
    if (proj_res.err && proj_res.err != 'not_found')
    {
      message_api.error(t('Something went wrong'));
      return metric.error('proj_get_err', proj_res.err);
    }
    let _proj = proj_res.proj;
    if (proj_res.err == 'not_found')
    {
      let insert_res = yield back_app.proj.insert(token, proj_lbl);
      if (insert_res.err)
      {
        message_api.error(t('Something went wrong'));
        return metric.error('proj_main_insert_err', insert_res.err);
      }
      _proj = insert_res.proj;
    }
    proj_set(_proj);
    let files_res = yield back_app.file.ls(token, proj_id);
    if (files_res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_ls_err', files_res.err);
    }
    files_set(files_res.files);
  }), [message_api, proj, t, token]);
  useEffect(()=>{
    if (!org || !token || proj)
      return;
    let proj_id = str.path2mongo(`/${org.id}/root/${proj_lbl}`);
    es_root.spawn(proj_get(proj_id));
  }, [es_root, org, proj, proj_get, token]);
  let plain_files = useMemo(()=>{
    let queue = Object.values(files);
    let _files = [];
    while (queue.length)
    {
      let file = queue.shift();
      _files.push(file);
      if (file.children)
        queue = [...queue, ...Object.values(file.children)];
    }
    return _files;
  }, [files]);
  let copy_file_id_get = useCallback(file_id=>{
    let filename = file_id2filename(file_id);
    let basename = filename.split('.').slice(0, -1).join('.');
    let ext = filename.split('.').slice(-1)[0];
    let dir_id = file_id.split('/').slice(0, -1).join('/');
    let new_file_id = file_id;
    let idx = 1;
    // eslint-disable-next-line no-loop-func
    while (plain_files.some(file=>file.id == new_file_id))
    {
      new_file_id = dir_id + '/'
        + str.path2mongo(`${basename}.copy.${idx}.${ext}`);
      idx += 1;
    }
    return new_file_id;
  }, [plain_files]);
  // XXX colin: wait till token exists before allowing usage
  // XXX colin: change to use ereq and send to metric on err
  let props = {
    name: 'file',
    listType: 'picture',
    action: xurl.url(prefix+'/private/editor/aaf/upload.json',
      {email: user.email, ver: config_ext.ver}),
    onChange: info=>eserf(function* change_hnalde(){
      if (info.file.status != 'uploading')
        console.log(info.file, info.fileList);
      if (info.file.status == 'done')
      {
        let resp = info.file.response;
        if (resp.err)
          return metric.error('error on done', resp.err);
        message_api.success(`${info.file.name} file uploaded successfully`);
        let file = resp.file;
        lbin_change_handle(resp.lbin);
        etag_set(resp.etag);
        info.file.url = xurl.url(prefix+'/private/aaf/get.aaf',
          {email: user.email, file, token, ver: config_ext.ver});
        let file_prev = info.file.name;
        info.file.name =
          <>
            <span>
              {str.trun(file_prev, 50)} {'->'} {str.trun(file, 20)}
            </span>
            <Button icon={<DownloadOutlined/>}/>
          </>;
        aaf_in_set(file);
        aaf_in_ref.current = file;
        task_id_set(resp.task_id);
        task_id_ref.current = resp.task_id;
        let init_file_id = str.path2mongo(
          `/${org.id}/root/${proj_lbl}/highlighter/${file_prev}`);
        let file_id = copy_file_id_get(init_file_id);
        let upload_res = yield back_app.file.upload(token, [file_id],
          [info.file.originFileObj]);
        if (upload_res.err)
          return metric.error('back_app.file.upload', upload_res.err);
        aaf_file_set(upload_res.files[0]);
      }
      else if (info.file.status == 'error')
      {
        let resp = info.file.response;
        let err_id2str = {
          is_not_aaf: 'Is not an AAF format, look at how you exported it',
          invalid_file_type: 'Invalid file type',
          org_no_credit:
            <>
              <Space direction="vertical">
                <div>
                  Contact your Toolium representative to buy more credits
                </div>
                <div>See your credits in profile</div>
                <Contact is_no_txt={true}/>
              </Space>
            </>,
          org_is_disable:
            <>
              <div>Contact your Toolium representative to activate your
                account
              </div>
              <Contact is_no_txt={true}/>
            </>,
          proc_type_unknown: 'Process type unknown',
          no_clips_found_on_sequence: 'No clips found on sequence',
          no_clips_for_writing_sequence: 'No clips for writing sequence',
          no_sequence_found_in_aaf_file: 'No sequence found in AAF file',
          sequence_has_length_of_0: 'Sequence_has_length of 0',
          group_clip_found_on_sequence: 'Group clip found on sequence',
          group_clip_found_on_sequence_2: 'Group clip found on sequence 2',
          unknown_selector_type_found_on_sequence:
            'Unknown selector type found in sequence',
          clip_framerate_does_not_match_sequence_framerate:
            'Clip framerate does not match sequence framerate',
          subclips_with_motion_effects_are_not_supported:
            'Subclips with motion effects are not supported',
          in_greater_equal_out: 'Some of your timecodes are invalid',
        };
        let err_s = err_id2str[resp.err]||resp.err;
        if (err_s==resp.err)
          metric.error('editor_missing_err_id', err_s);
        else
          metric.error('editor_upload_err_'+resp.err);
        info.file.error.message = err_s;
        modal.error({title: `${info.file.name} file upload failed`,
          content: err_s});
        return void message_api.error(`${info.file.name} file upload failed `
          +`${resp.err}`);
      }
    }),
  };
  let _on_change = rate=>{
    // XXX colin: ask for feedback and why?
    back_app.user_set_rate(token, user.email, rate);
    if (rate<4)
      return void set_is_rate(false);
    // XXX colin: change to be toolium.org
    window.location.href = 'https://www.trustpilot.com/review/www.toolium.org';
  };
  if (token)
    props.headers = ereq.auth_hdr(token);
  if (is_rate)
  {
    modal.confirm({
      title: t('Rate the app to help us improve'),
      content: <Clickable>
        <Rate onChange={_on_change} allowHalf defaultValue={3.5} />
      </Clickable>,
      okButtonProps: {disabled: true, style: {display: 'none'}},
      cancelText: t('I don\'t wanna'),
      onOk(){},
      onCancel(){},
    });
  }
  else
    Modal.destroyAll();
  let cursor = useMemo(()=>{
    if (is_edit_mode)
      return `url(${selection_cursor}), auto`;
    return 'auto';
  }, [is_edit_mode]);
  if (!token)
    return <Loading/>;
  return <>
    {message_ctx_holder}
    {modal_ctx_holder}
    {is_initial_src_loading && <editor.Loading_overlay />}
    {!qs_o.dbg && <Desktop_required_modal
      title={t('Desktop is required for highlighter')} />}
    <Modal
      title={t('This is premium feature, contact us at support@toolium.org')}
      open={is_premium_modal_open}
      onOk={()=>is_premium_modal_open_set(false)}
      onCancel={()=>is_premium_modal_open_set(false)}
    />
    {!initial_src && <Row justify="center">
      <Col>
        <Space direction="vertical" size="large" align="center">
          <Row><Title>HIGHLIGHTER</Title></Row>
          <Row>
            <Upload {...props}>
              <Clickable>
                <Button type="primary"
                  style={{height: '10vw', width: '60vw', fontSize: '3vw'}}
                  icon={<UploadOutlined />}>{t('Click to Upload AAF')}</Button>
              </Clickable>
            </Upload>
          </Row>
        </Space>
      </Col>
    </Row>}
    <Divider />
    <Edit_marker_modal lbin={lbin} is_open={is_marker_modal_open}
      on_close={marker_modal_close} cmd_marker_add={cmd_marker_add}
      cmd_marker_edit={cmd_marker_edit} marker={editing_marker}
      marker_track_id={editing_marker_track_id} token={token} user={user}
      src_frame={src_frame} rec_frame={rec_frame}
      selected_monitor={selected_monitor} />
    <div ref={editor_ref} style={{width: '100%', minWidth: '1146px',
      minHeight: '90vh', height: '1px', position: 'relative', cursor}}>
      <Row style={{height: '80%'}}>
        <Col span={24} style={{height: '100%'}}>
          <Composer_panel lbin={lbin} fps={fps} token={token} aaf_in={aaf_in}
            rec_playing_toggle={rec_playing_toggle} tbin_files={tbin_files}
            src_playing_toggle={src_playing_toggle}
            rec_playback_rate={rec_playback_rate} rec_frame={rec_frame}
            src_playback_rate={src_playback_rate} src_frame={src_frame}
            etag={etag} undo={undo} redo={redo} user_full={user_full}
            on_src_frame_change={src_frame_handle}
            on_rec_frame_change={rec_frame_handle}
            cmd_cut={cmd_cut} zoom={zoom} lbin_changes={lbin_changes}
            on_zoom_change={zoom_change_handle} user={user}
            cmd_mark_in={cmd_mark_in} cmd_mark_out={cmd_mark_out}
            cmd_clear_both_marks={cmd_clear_both_marks}
            cmd_extract={cmd_extract} cmd_lift={cmd_lift}
            marker_add={marker_add} loading_cmd={loading_cmd}
            on_src_playback_rate_change={src_playback_rate_handle}
            on_rec_playback_rate_change={rec_playback_rate_handle}
            onClick={()=>focused_panel_set('composer')}
            selected_monitor={selected_composer_monitor}
            on_selected_monitor_change={selected_composer_monitor_set}
            cur_lbin_change_idx={cur_lbin_change_idx} aaf_file={aaf_file}
            on_loading_state_change={loading_state_change_handle}
            cmd_marker_remove={cmd_marker_remove} tbin_upload={tbin_upload}
            cmd_marker_edit={cmd_marker_edit} marker_edit={marker_edit}
            cmd_rec_monitor_load_clip={cmd_rec_monitor_load_clip}
            cmd_src_monitor_load_clip={cmd_src_monitor_load_clip}
            cmd_overwrite={cmd_overwrite} cmd_sequence_new={cmd_sequence_new}
            cmd_clip_duplicate={cmd_clip_duplicate} />
        </Col>
      </Row>
      <Row style={{height: '20%'}}>
        <Col span={24}>
          <Timeline_panel cmd_clear_both_marks={cmd_clear_both_marks}
            cmd_clip_move={cmd_clip_move} cmd_cut={cmd_cut}
            cmd_extract={cmd_extract} cmd_lift={cmd_lift}
            cmd_mark_clip={cmd_mark_clip}
            cmd_mute={is_allow_solo_mute ? cmd_mute : null}
            cmd_selector_set={cmd_selector_set}
            cmd_solo={is_allow_solo_mute ? cmd_solo : null}
            cmd_toggle_lock={is_allow_edit ? premium_modal_open : null}
            cmd_trim={cmd_trim} lbin={lbin}
            on_rec_frame_change={rec_frame_handle}
            on_rec_playback_rate_change={rec_playback_rate_handle}
            on_scroll_x_change={scroll_x_set}
            on_selected_monitor_change={selected_timeline_monitor_set}
            on_src_frame_change={src_frame_handle}
            on_src_playback_rate_change={src_playback_rate_handle}
            on_tracks_wrapper_width_change={tracks_wrapper_width_set}
            on_zoom_change={zoom_set}
            onClick={()=>focused_panel_set('timeline')}
            premium_modal_open={premium_modal_open}
            rec_frame={rec_frame} scroll_x={scroll_x}
            selected_monitor={selected_timeline_monitor}
            src_frame={src_frame} tracks_wrapper_width={tracks_wrapper_width}
            zoom={zoom} />
        </Col>
      </Row>
    </div>
  </>;
};

export default auth.with_auth_req(E);
