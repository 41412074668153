// LICENSE_CODE MIT
import assert from 'assert';

let E = {};
export default E;

export let perfs = E.perfs = {};
export let start = E.start = (id, max_delta_ms, cb)=>{
  if (E.is_prod)
    return;
  if (E.perfs[id])
    assert(0, `perf_id_exist ${id}`);
  E.perfs[id] = {id, start_ms: performance.now(), max_delta_ms, cb};
  return E.perfs[id].start_ms;
};
export let stop = E.stop = id=>{
  if (E.is_prod)
    return;
  if (!E.perfs[id])
    assert(0, `perf_id_not_exist ${id}`);
  let perf = E.perfs[id];
  perf.stop_ms = performance.now();
  perf.delta_ms = perf.stop_ms-perf.start_ms;
  delete E.perfs[id];
  if (perf.delta_ms<=perf.max_delta_ms)
    return perf.delta_ms;
  if (perf.cb)
    perf.cb(perf.delta_ms);
  else
    console.error(`perf too long ${perf.id} ${perf.delta_ms}ms`);
  return perf.delta_ms;
};

let inited = 0;
export let init = E.init = is_prod=>{
  if (inited)
  {
    inited++;
    return;
  }
  E.is_prod = is_prod;
  inited++;
};

export let uninit = E.uninit = ()=>{
  inited--;
  if (inited)
    return; // eslint-disable-line
  delete E.is_prod;
  // XXX: check E.perfs is empty
};


