// LICENSE_CODE MIT
import assert from 'assert';
import csvtojson from 'csvtojson';
import eserf from './eserf.js';
import xurl from './xurl.js';
import str from './str.js';
import argparse from './argparse.js';
import proc from './proc.js';

let E = {};
export default E;

E.c2j = file=>eserf(function* csv_c2j(){
  let res = yield this.wait_ext2(csvtojson().fromFile(file));
  if (res.err)
    return {err: res.err};
  let arr = res;
  for (let i=0; i<arr.length; i++)
  {
    let json = str.j2s(arr[i]);
    arr[i] = xurl.json_parse(json);
  }
  return arr;
});

E.s2j = s=>eserf(function* csv_s2j(){
  let res = yield this.wait_ext2(csvtojson().fromString(s));
  if (res.err)
    return {err: res.err};
  let arr = res;
  for (let i=0; i<arr.length; i++)
  {
    let json = str.j2s(arr[i]);
    arr[i] = xurl.json_parse(json);
  }
  return arr;
});

E.row = (...cols)=>{
  let ret = '';
  for (let col of cols)
  {
    if (col && !col.replaceAll)
      assert(0, col+' is not string');
    let _col = (col||'').replaceAll('"', '""');
    ret += `"${_col}",`;
  }
  return ret.slice(0, -1)+'\n';
};

if (proc.is_main(import.meta.url, {import_meta_main: import.meta.main}))
{
  argparse.init(()=>{process.exit(1);});
  argparse.get_flag(['--is_verbose', '-v']);
  argparse.get_v_str(['--cmd', '-c']);
  argparse.get_v_str(['--in', '-i']);
  argparse.get_v_str_newline(['--txt', '-t']);
  let argo = argparse.argo;
  eserf(function* _fs_main(){
    if (argo.cmd=='c2j')
    {
      let res = yield E.c2j(argo.in);
      console.log(res);
    }
    else if (argo.cmd=='s2j')
    {
      let res = yield E.s2j(argo.txt);
      console.log(res);
    }
    else
      assert(0, 'missing cmd');
  });
}
