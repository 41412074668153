// LICENSE_CODE ABC
import React, {useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Col, Row, Card, Button, Typography, Divider, Badge} from 'antd';
import {Clickable, use_qs} from './comp.js';
import metric from './metric.js';
import back_app from './back_app.js';
import eserf from '../../../util/eserf.js';
import xurl from '../../../util/xurl.js';
import str from '../../../util/str.js';
import {get, flow, orderBy, values, toUpper, mapValues} from 'lodash/fp.js';
import {purple} from '@ant-design/colors';
import config_ext from './config_ext.js';

let email = config_ext.front.email;
let {Title, Text} = Typography;

let Text_container = React.memo(({children, height=40, margin_bottom=32})=>{
  return <div style={{height, marginBottom: margin_bottom}}>{children}</div>;
});

let Badge_container = React.memo(({txt, is_badge, children})=>{
  return is_badge ? <Badge.Ribbon text={txt}>{children}</Badge.Ribbon>:
    <>{children}</>;
});

let to_percent=number=>{
  return (number*100).toFixed(0) + '%';
};

export const E = ()=>{
  let {t} = useTranslation();
  let [plans, set_plans]=useState({});
  let [plan_credits, set_plan_credits]=useState({});
  let {qs_o} = use_qs();
  useEffect(()=>{
    let es = eserf(function* _org_get_plan(){
      let res = yield back_app.org_get_plan();
      if (res.err)
        metric.error('org_get_plan_err', res.err);
      set_plans(res);
    });
    return ()=>es.return();
  }, []);
  useEffect(()=>{
    let es = eserf(function* _org_get_cost(){
      let res = yield back_app.org_get_cost();
      if (res.err)
        metric.error('org_get_cost_err', res.err);
      set_plan_credits(res);
    });
    return ()=>es.return();
  }, []);
  let plans_arr = useMemo(()=>{
    return flow(
      values,
      orderBy(['order'], ['asc']),
    )(plans);
  }, [plans]);
  let default_plan_credits = useMemo(()=>{
    return mapValues(get(['medium_v1', 'credit']), plan_credits);
  }, [plan_credits]);
  return <Row justify="center" style={{marginInline: 16,
    marginBottom: 128}}>
    <Col xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
      lg={{span: 24}} style={{maxWidth: 1600}}>
      <Row align="center"><Title className="f-baumans land-title">
        {t('Plans')}</Title></Row>
      <Row align="center"><Title level={3} className="f-bangers"
        style={{textAlign: 'center'}}>
        {t('Choose a plan that works for you')}
      </Title></Row>
      <Row align="center"><Title level={4}>
        {t('7 days money back guarantee')}
      </Title></Row>
      <Row align="center"><Text>
        {t('For any questions or issues')}&nbsp;
        <a href={'mailto:'+email}>
          <span style={{color: purple.primary}}>{email}</span></a>
      </Text></Row>
      <Row gutter={[16, 16]} style={{marginTop: 64}}>
        {plans_arr.map((plan, index)=>{
          let link_to = plan?.cost?.v === 0 ?
            {pathname: '/'} :
            {pathname: '/pay',
              search: xurl.qs({...qs_o, plan})};
          if (plan.is_hide)
            return null;
          return <Col key={index}
            xs={{span: 24}} lg={{span: 4}}>
            <Link to={link_to}>
              <Clickable cursor>
                <Badge_container is_badge={plan.is_recommended}
                  txt={t('Recommended')}>
                  <Card style={{textAlign: 'center', paddingTop: 16}}>
                    <Title level={4} className="f-baumans land-title">
                      {t(plan.lbl)}</Title>
                    <Text_container>
                      <Title level={2} style={{color: purple.primary}}>
                        {plan?.cost?.v}&nbsp;
                        <span style={{fontSize: '50%'}}>
                          {toUpper(plan?.cost?.unit)}</span>
                      </Title>
                    </Text_container>
                    <Text_container>
                      <Title level={4}>
                        {str.large_n2lbl(plan.credit||0)}
                        <br/>
                        {t('Credits')}
                      </Title>
                    </Text_container>
                    <Text_container>
                      {!!plan.discount &&
                      <Title level={5} italic>
                        {`${to_percent(plan.discount)} ${t('Discount')}`}
                      </Title>}
                    </Text_container>
                    <Text_container>
                      {plan.credit && default_plan_credits?.grouper && <Text>
                        {t('1 group with Grouper')+' = '
                        +default_plan_credits?.grouper+' '+t('credits')}
                      </Text>}
                    </Text_container>
                    <Text_container>
                      {plan.credit && default_plan_credits?.cutter && <Text>
                        {t('1 cut with Cutter')+' = '
                        +default_plan_credits?.cutter+' '+t('credits')}
                      </Text>}
                    </Text_container>
                    <Text_container height={130} margin_bottom={0}>
                      {plan.is_free && <><Title level={5}>
                        {t('Signup with Google to get 500 free credits')}
                      </Title><Text>
                        {t('You will also get 150 additional free credits '
                        +'every day')}
                      </Text></>}
                    </Text_container>
                    <Divider/>
                    <Clickable>
                      <Button disabled={plan.is_disable}
                        className="f-bangers"
                        type={plan.is_recommended? 'primary': 'default'}>
                        {t('Select')}
                      </Button>
                    </Clickable>
                  </Card>
                </Badge_container>
              </Clickable>
            </Link>
          </Col>;
        })}
      </Row>
    </Col>
  </Row>;
};

export default E;


